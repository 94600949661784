import React from "react";
import style from "./index.module.less";
import { PropTypes } from "prop-types";

const HostedFieldsComponent = ({
  cvvErrorText,
  cardNumberErrorText,
  expirationDateErrorText,
  cardRejectedErrorText,
  children,
  hostedFieldContainerStyle,
  hostedFieldContainerCardNumberStyle,
  expirationCvvRowStyle
}) => {
  return (
    <div className={style.renderHostedFieldsContainer}>
      <form id="cardForm" className={style.hostedFieldsFormWrapper}>
        <div className={style.hostedFieldsForm}>
          <div className={style.hostedFieldInputsPanel}>
            <div className={style.paymentCardRow}>
              <div
                className={`${hostedFieldContainerStyle} ${hostedFieldContainerCardNumberStyle}`}
              >
                <label className={style.hostedFieldLabel} htmlFor="card-number">
                  Card Number
                </label>
                <div className={style.logoCardNumberContainer}>
                  <div
                    id="card-number"
                    className={`${style.hostedFieldIframe} ${style.loseHostedFieldInputHighlight}`}
                  />
                  {children}
                </div>
              </div>
              <div className={style.errorTextContainer}>
                <div className={style.cardNumberErrorTextStyle}>
                  <span>{cardNumberErrorText}</span>
                </div>
              </div>
            </div>
            <div className={expirationCvvRowStyle}>
              <div className={hostedFieldContainerStyle}>
                <label
                  className={style.hostedFieldLabel}
                  htmlFor="expiration-date"
                >
                  Expiration Date
                </label>
                <div
                  id="expiration-date"
                  className={`${style.hostedFieldIframe} ${style.hostedFieldSmall} ${style.loseHostedFieldInputHighlight}`}
                />
                <div className={style.expirationAndCvvErrorTextStyle}>
                  <span>{expirationDateErrorText}</span>
                </div>
              </div>
              <div className={hostedFieldContainerStyle}>
                <label className={style.hostedFieldLabel} htmlFor="cvv">
                  <span className={style.icon} />
                  CVV/PIN
                </label>
                <div
                  id="cvv"
                  className={`${style.hostedFieldIframe} ${style.hostedFieldSmall} ${style.loseHostedFieldInputHighlight}`}
                />
                <div className={style.expirationAndCvvErrorTextStyle}>
                  <span>{cvvErrorText}</span>
                </div>
              </div>
            </div>
            <div className={style.braintreeErrorContainer}>
              <div className={style.errorMessageStyle}>
                <span>{cardRejectedErrorText}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
HostedFieldsComponent.propTypes = {
  hostedFieldContainerStyle: PropTypes.string.isRequired,
  expirationCvvRowStyle: PropTypes.string.isRequired,
  cvvErrorText: PropTypes.string,
  cardNumberErrorText: PropTypes.string,
  expirationDateErrorText: PropTypes.string,
  cardRejectedErrorText: PropTypes.string,
  hostedFieldContainerCardNumberStyle: PropTypes.string.isRequired,
  children: PropTypes.object
};

export default HostedFieldsComponent;
