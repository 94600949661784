import subscriptionPlans from '../../constants/subscriptionPlans';
import { includes } from 'lodash';

export const fieldToUpdateTranslator = fieldToUpdate => {
    switch (fieldToUpdate) {
        case 'email':
            return 'email';
        case 'wooCommerceShopName': 
            return "store's name";
        case 'wooCommerceShopOwnerName':
            return 'name';
        default:
            return null;
    };
};

export const checkIfPlanIsValid = planId => {
    const supportedPlanIds = subscriptionPlans.map(plan => plan.id);
    return includes(supportedPlanIds, planId);
};