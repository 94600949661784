import React from 'react'
import HorizontalViewWithLogoComponent from '../../../components/HorizontalViewWithLogoComponent';
import OnboardingContainer from '../../../container/OnboardingContainer';
import FacebookAdAccountSelection from '../../../container/FacebookAdAccountSelection';
export default function OnboardingFacebookAdAccountSelectionComponent() {
    return (
        <OnboardingContainer displayMode='showHeaderMobile'>
            <HorizontalViewWithLogoComponent>
                    <FacebookAdAccountSelection redirectionUrl='/onboarding/facebook/adAccountRequestSuccess' />
            </HorizontalViewWithLogoComponent>
        </OnboardingContainer>
    );
}