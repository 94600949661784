import { typography, colorPalette } from '../../../styles/constants';
import { makeStyles } from '@material-ui/core/styles';

export const nativeSelectStyles = makeStyles(theme => ({
    root: {
        height: 20,
        paddingBottom: '2px',
        fontSize: typography.paragraphSm,
        color: '#54281f',
        
        [theme.breakpoints.up('md')]: {
            paddingBottom: '5px',
            fontSize: typography.paragraphMd,
            height: '1.1875em',
        }
    },
    select: {
        backgroundColor: 'inherit !important',
    },
}));

export const baseSelectStyles = makeStyles(() => ({
    root: {
        '&::after': {
            borderBottom: `2px solid ${colorPalette.primaryOrange}`
        }
    },
}));