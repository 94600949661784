// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { AdChannelSelectionTexts } from '../../../../utils/texts';

// Custom Components
import OnboardingContainer from '../../../../container/OnboardingContainer';
import HorizontalViewWithLogoComponent from '../../../../components/HorizontalViewWithLogoComponent';
import OnboardingPlatformSelectionSuccess from '../../../../components/OnboardingPlatformSelectionSuccess';

const SelectionSuccessComponent = ({ history, setSelectionSuccess }) => {
  const onClick = () => {
    setSelectionSuccess(true);
    history.push('/onboarding/google/createCampaign/chooseCountry');
  };

  return (
    <OnboardingContainer displayMode="showHeaderMobile">
          <HorizontalViewWithLogoComponent>
        <OnboardingPlatformSelectionSuccess 
          title={AdChannelSelectionTexts.selectionSuccess.title}
          subtitle={AdChannelSelectionTexts.selectionSuccess.subtitle}
          description={AdChannelSelectionTexts.selectionSuccess.description}
          buttonText={AdChannelSelectionTexts.selectionSuccess.buttonText}
          onClick={onClick}
        />
          </HorizontalViewWithLogoComponent>
    </OnboardingContainer>
  );
};

SelectionSuccessComponent.propTypes = {
  setSelectionSuccess: PropTypes.func.isRequired
};

export default SelectionSuccessComponent;