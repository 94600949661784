import React from 'react'
import PropTypes from 'prop-types'

import style from './styles.module.less'

import SignUpStaticLogoPage from '../SignUpStaticLogoPageComponent'

const HorizontalViewWithLogoComponent = ({ children }) => {

    return (
        <div className={style.flexWrapper}>
            <SignUpStaticLogoPage />
            <div className={style.contentWrapper}>
                {children}
            </div>
        </div>
    )
}

HorizontalViewWithLogoComponent.propTypes = {
    children: PropTypes.node,
}

export default HorizontalViewWithLogoComponent