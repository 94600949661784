// React
import { withRouter } from 'react-router';

// Redux
import { connect } from 'react-redux';
// Methods
import { setPricing } from '../../ducks/pricing';
// Selectors
import { platformSelector } from '../../utils/reduxSelectors'

// Decorated Component
import AuthenticationRouterContainer from './AuthenticationRouterContainer';

const mapStateToProps = state => {
    const isWooCommerceDataPackagePresent = (state.auth.wooCommerceEmail && state.auth.wooCommerceOwnerName && state.auth.wooCommerceShopName)
    return ({
    accessGranted: state.auth.accessGranted,
    pricing: state.pricing.planId,
    isWooCommerceUserAllowedToProceed: Boolean(isWooCommerceDataPackagePresent || state.auth.isOnboardingFinished || state.auth.isUserLoggedIn),
    platform: platformSelector(state),
    });
}; 

const mapDispatchToProps = {
    setPricing
};

const AuthenticationRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationRouterContainer));

export default AuthenticationRouter;