import React from "react";
import MaterialButtonComponent from "../MaterialComponents/MaterialButtonComponent";
import { PropTypes } from "prop-types";

const PaymentButtonsRowComponent = ({
  ConfirmButton,
  onBackClick,
  imposedStyling
}) => (
  <div className={imposedStyling}>
    <MaterialButtonComponent
      text="BACK"
      type="secondary"
      size="xSmall"
      onClick={onBackClick}
    />
    {ConfirmButton}
  </div>
);

PaymentButtonsRowComponent.propTypes = {
  ConfirmButton: PropTypes.node.isRequired,
  onBackClick: PropTypes.func.isRequired,
  imposedStyling: PropTypes.string.isRequired
};

export default PaymentButtonsRowComponent;
