
/**
* Setup step state
**/

// Actions

const SET_SETUP_METHOD = 'SET_SETUP_METHOD';
const SET_SETUP_GOOGLE_WITH_HERALDBEE = 'SET_SETUP_GOOGLE_WITH_HERALDBEE';
const SET_SETUP_PROGRESS = 'SET_SETUP_PROGRESS';

// Reducer

const initialState = {
    chosenAccountType: null,
    setupGoogleWithHeraldbee: null,
    isFinished: null
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

    case SET_SETUP_METHOD:
        return {
            ...state, 
            chosenAccountType: action.chosenAccountType
        }
    
    case SET_SETUP_GOOGLE_WITH_HERALDBEE:
        return {
            ...state,
            setupGoogleWithHeraldbee: action.setupGoogleWithHeraldbee
        }

    case SET_SETUP_PROGRESS:
        return {
            ...state,
            isFinished: action.isFinished
        }

    default:
        return state;
    }
}
export default reducer;

// Action Creators

const actionSetAccountType = chosenAccountType => ({ type: SET_SETUP_METHOD, chosenAccountType });
const actionSetSetupGoogleWithHeraldbee = setupGoogleWithHeraldbee => ({ type: SET_SETUP_GOOGLE_WITH_HERALDBEE, setupGoogleWithHeraldbee });
const actionSetIsFinished = isFinished => ({ type: SET_SETUP_PROGRESS, isFinished});

// Methods

export const setAccount = chosenAccountType => dispatch => {
    dispatch(actionSetAccountType(chosenAccountType));
}

export const setSetupGoogleWithHeraldbee = isSetupGoogleWithHeraldbee => dispatch => {
    dispatch(actionSetSetupGoogleWithHeraldbee(isSetupGoogleWithHeraldbee));
}

export const setIsFinished = value => dispatch => {
    dispatch(actionSetIsFinished(value));
}