// React
import { withRouter } from 'react-router';

// Redux
import { connect } from 'react-redux';

// Methods
import { softLogOut } from '../../ducks/auth';
import { setError, unsetError } from '../../ducks/app';

import ErrorBoundaryComponent from './ErrorBoundaryComponent';

const mapStateToProps = state => ({
    error: state.app.error,
});

const mapDispatchToProps = {
    setError,
    unsetError,
    softLogOut
};

const ErrorBoundary = withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundaryComponent));

export default ErrorBoundary;