// React
import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';

// Custom Components
import OnboardingRouterGateway from '../OnboardingRouterGateway'
import AppSpinner from '../../../components/AppSpinner';
import MainAppRouter from '../../MainAppRouter/index';
import OnboardingFacebookAdAccountRequestSuccessful from '../../../components/OnboardingDecorated/OnboardingFacebookAdAccountRequestSuccessfulComponent';
import FacebookChooseName from '../../../pages/OnboardingCreateCampaign/Facebook/ChooseName';
import FacebookChooseCountry from '../../../pages/OnboardingCreateCampaign/Facebook/ChooseCountry';
import FacebookFinish from '../../../pages/OnboardingCreateCampaign/Facebook/Finish';
import OnboardingFacebookAdAccountSelectionComponent from './../../../pages/Onboarding/OnboardingFacebookAdAccountSelectionComponent/index';
import SharedOnboardingPaymentMethodSelection from './../../../container/OnboardingPaymentMethodSelection/OnboardingPaymentMethodSelectionContainer/index';

const OnboardingRouterContainer = ({ history, isOnboardingFinished, isLoading, isUserLoggedIn }) => {
  useEffect(() => {
    if (isOnboardingFinished) {
      if (!isUserLoggedIn) {
        history.push("/campaigns");
      }
    }
  }, [history, isOnboardingFinished, isUserLoggedIn]);
  
  return isLoading === 'true' ? (
    <AppSpinner />
  ) : (
    <Switch>
      <Route path="/onboarding/facebook/adAccountSelection" component={OnboardingFacebookAdAccountSelectionComponent}/>
      <Route path="/onboarding/facebook/adAccountRequestSuccess" component={OnboardingFacebookAdAccountRequestSuccessful}/>
      <Route path="/onboarding/facebook/createCampaign/chooseName" component={FacebookChooseName}/>
      <Route path="/onboarding/facebook/createCampaign/chooseCountry" component={FacebookChooseCountry}/>
      <Route path="/onboarding/facebook/createCampaign/setPaymentAuthorization" component={SharedOnboardingPaymentMethodSelection}/>
      <Route path="/onboarding/facebook/createCampaign/finish" component={FacebookFinish}/>

      {/** BLACKBOX - it's recommended not to touch this 'dynamic router' */}
      <Route path="/onboarding" component={OnboardingRouterGateway} />
      {// Check if old user or new user after onboarding process
        isOnboardingFinished ? (
          <MainAppRouter />
        ) : (
          <Redirect exact from="/" to="/onboarding" />
        )
      }
      {/** END BLACKBOX */}

    </Switch>
  );
};

OnboardingRouterContainer.propTypes = {
  history: PropTypes.object,
  isOnboardingFinished: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
};

export default OnboardingRouterContainer;
