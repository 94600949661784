import React, { useState } from 'react';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import styles from './index.module.less'

const FaqPageItemComponent = ({ headerText, descriptionTextList }) => {

    const [openFaq, setOpenFaq] = useState(false);

    const handleArrowClick = (e) => {
        e.preventDefault()
        setOpenFaq(!openFaq)
    }

    return (
        <div className={styles.container}>
            <header className={styles.containerHeader} onClick={ handleArrowClick }>
                <p className={styles.headerText}><strong>{headerText}</strong></p>
                {openFaq ? <KeyboardArrowUp className={styles.btnArrow} /> : <KeyboardArrowDown className={styles.btnArrow} />}
            </header>
            {openFaq && descriptionTextList.map((text,index) => <p key={index + 1337} className={styles.descriptionText}>{text}</p>)}
            {openFaq && <p className={styles.helperText}>If you have more questions, <span className="OPEN_INTERCOM">write to use</span></p>}
        </div>
    )
}

export default FaqPageItemComponent