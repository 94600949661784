import { makeStyles } from "@material-ui/core/styles";
import { colorPalette, typography } from "../../../../styles/constants";

export const materialStyleGenerator = () => makeStyles({
    formControlLabel: {
        '& .MuiFormControlLabel-label': {
            fontSize: typography.body.fontSize,
        }
    },
    checkbox: {
        marginLeft: '10px',
        color: `rgba(${colorPalette.primaryBrownRGB},0.7)`,
    },
})