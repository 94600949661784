import React from "react";
import style from "./index.module.less";
import { PropTypes } from "prop-types";

const PaymentCardNumberComponent = ({ lastFourPaymentCardDigits }) => (
  <span
    className={style.maskedNumber}
  >{`**** **** **** ${lastFourPaymentCardDigits}`}</span>
);
PaymentCardNumberComponent.propTypes = {
  lastFourPaymentCardDigits: PropTypes.string.isRequired
};
export default PaymentCardNumberComponent;
