import config from '../config';
import { amplitudeEvents } from '../constants/amplitudeEvents';
import { sendAmplitudeEvent } from '../utils/amplitude';
import { actionSetError } from './app';
import { HeraldbeeApi } from '../api/HeraldbeeApi';

// Actions

const FETCH_BRAINTREE_TOKEN = 'FETCH_BRAINTREE_TOKEN';
const CREATE_CUSTOMER_PAYMENT_AUTHORIZATION = 'CREATE_CUSTOMER_PAYMENT_AUTHORIZATION';
const SET_LAST_FOUR_DIGITS = 'SET_LAST_FOUR_DIGITS';
const SET_CREDIT_CARD_VENDOR_NAME = 'SET_CREDIT_CARD_VENDOR_NAME';
const SET_HOSTED_FIELDS_INSTANCE = 'SET_HOSTED_FIELDS_INSTANCE';
const SET_IS_NONCE_READY_FOR_UPDATE = 'SET_IS_NONCE_READY_FOR_UPDATE';
const SET_FIRST_ACCEPTED_PAYMENT_METHOD = 'SET_FIRST_ACCEPTED_PAYMENT_METHOD';
const SET_ACTIVE_PAYMENT_TYPE = 'SET_ACTIVE_PAYMENT_TYPE';
const FETCH_INVOICE_DATA = 'FETCH_INVOICE_DATA';
const SET_INVOICE_DATA_ERROR = 'SET_INVOICE_DATA_ERROR';
const SET_INVOICE_DATA_SUCCESS = 'SET_INVOICE_DATA_SUCCEESS';
const SET_INVOICE_DATA_IS_UPDATING = 'SET_INVOICE_DATA_IS_UPDATING';
const SET_INVOICE_DATA_IS_FETCHING = 'SET_INVOICE_DATA_IS_FETCHING';
const SET_INVOICE_HISTORY = 'SET_INVOICE_HISTORY';
const SET_INVOICE_HISTORY_ERROR = 'SET_INVOICE_HISTORY_ERROR';
const SET_INVOICE_HISTORY_IS_FETCHING = 'SET_INVOICE_HISTORY_IS_FETCHING';
const SET_REQUESTED_INVOICE_DOWLOAD_OBJECT = 'SET_REQUESTED_INVOICE_DOWNLOAD_OBJECT'
const SET_INVOICE_PDF_IS_FETCHING = 'SET_INVOICE_PDF_IS_FETCHING';
const SET_PAYPAL_BUTTONS_INITIALIZED = 'SET_PAYPAL_BUTTONS_INITIALIZED';
const SET_PAYMENT_METHOD_FOR_EDITION = 'SET_PAYMENT_METHOD_FOR_EDITION';
const SET_INVOICE_FORM_VALUE = 'SET_INVOICE_FORM_VALUE';
const CLEAR_INVOICE_FORM_VALUES = 'CLEAR_INVOICE_FORM_VALUES';
const REPOPULATE_INVOICE_FORM_VALUES = 'REPOPULATE_INVOICE_FORM_VALUES';
const CREATE_INVOICE_UPDATE_OBJECT = 'CREATE_INVOICE_UPDATE_OBJECT';
const CLEAR_INVOICE_UPDATE_OBJECT = 'CLEAR_INVOICE_UPDATE_OBJECT';

// Reducer

const initialState = {
  clientNonce: null,
  invoiceData: {
    content: {
      customer_type: null,
      billing_address: {
        first_name: null, // string, max chars=150,
        last_name: null, // string, max chars=150,
        company_name: null, // string, max chars=250,
        address: null, // string, max chars=450,
        zip: null, // string, max chars=20,
        city: null, // string, max chars=50,
        country: null, // string, max chars=50, 2-letter ISO 3166 alpha-2 country code,
        vat_number: null, // string, max chars=20,
      }
    },
    updateObject: null,
    error: null,
    success: null,
    isFetching: false,
    isUpdating: false,
  },
  invoiceHistory: {
    content: null,
    error: null,
    isFetching: false
  },
  invoicePDF: {
    content: null,
    isFetching: false
  },
  invoiceForm: {
    selectedType: null,
    companyName: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    zip: '',
    country: '',
    vatNumber: ''
  }
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {

    case SET_HOSTED_FIELDS_INSTANCE:
      return {
        ...state,
        localHostedFieldsInstance:
          action.instance
      }

    case SET_CREDIT_CARD_VENDOR_NAME:
      return {
        ...state,
        creditCardVendorNameForSummary:
          action.vendorName
      }
    case SET_LAST_FOUR_DIGITS:
      return {
        ...state,
        lastFourPaymentCardDigits:
          action.lastFourDigits
      }
    case FETCH_BRAINTREE_TOKEN:
      return {
        ...state,
        braintreeToken: action.token
      }
    case CREATE_CUSTOMER_PAYMENT_AUTHORIZATION:
      return {
        ...state,
        clientNonce: action.nonce
      }
    case SET_IS_NONCE_READY_FOR_UPDATE:
      return {
        ...state,
        isNonceReadyForUpdate: action.boolean
      }
    case SET_FIRST_ACCEPTED_PAYMENT_METHOD:
      return {
        ...state,
        firstAcceptedPaymentMethodType: action.paymentMethod
      }
    case SET_ACTIVE_PAYMENT_TYPE:
      return {
        ...state, currentlyActivePaymentMethodType: action.paymentType
      }

    case FETCH_INVOICE_DATA:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          content: {
            customer_type: action.data.customer_type,
            billing_address: {
              ...initialState.invoiceData.content.billing_address,
              ...action.data.billing_address
            }
          },
        },
        invoiceForm: {
          selectedType: action.data.customer_type,
          companyName: action.data.billing_address.company_name || '',
          firstName: action.data.billing_address.first_name || '',
          lastName: action.data.billing_address.last_name || '',
          city: action.data.billing_address.city || '',
          zip: action.data.billing_address.zip || '',
          address: action.data.billing_address.address || '',
          country: action.data.billing_address.country || '',
          vatNumber: action.data.billing_address.vat_number || ''
        }
      }
    case SET_INVOICE_DATA_ERROR:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          error: action.error
        }
      }
    case SET_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          success: action.success
        }
      }
    case SET_INVOICE_DATA_IS_UPDATING:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          isUpdating: action.boolean
        }
      }
    case SET_INVOICE_DATA_IS_FETCHING:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          isFetching: action.boolean
        }
      }
    case SET_INVOICE_HISTORY:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          isFetching: false,
          content: action.data
        }
      }
    case SET_INVOICE_HISTORY_ERROR:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          isFetching: false,
          error: action.error
        }
      }
    case SET_INVOICE_HISTORY_IS_FETCHING:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          isFetching: action.boolean
        }
      }
    case SET_INVOICE_PDF_IS_FETCHING:
      return {
        ...state,
        invoicePDF: {
          ...state,
          isFetching: action.boolean
        }
      }
    case SET_REQUESTED_INVOICE_DOWLOAD_OBJECT:
      return {
        ...state,
        invoicePDF: {
          isFetching: false,
          content: action.data
        }
      }
    case SET_PAYPAL_BUTTONS_INITIALIZED:
      return {
        ...state,
        payPalButtonsInitialized: action.payPalButtonsCreated
      }
    case SET_PAYMENT_METHOD_FOR_EDITION:
      return {
        ...state,
        paymentMethodForEdition: action.paymentMethodForEdition
      }
    case SET_INVOICE_FORM_VALUE:
      return {
        ...state,
        invoiceForm: {
          ...state.invoiceForm,
          [action.key]: action.value
        }
      }
    case CLEAR_INVOICE_FORM_VALUES:
      return {
        ...state,
        invoiceForm: {
          ...initialState.invoiceForm,
          selectedType: state.invoiceForm.selectedType
        }
      }
    case REPOPULATE_INVOICE_FORM_VALUES:
      return {
        ...state,
        invoiceForm: {
          selectedType: state.invoiceData.content.customer_type || action.selectedType,
          companyName: state.invoiceData.content.billing_address.company_name || '',
          firstName: state.invoiceData.content.billing_address.first_name || '',
          lastName: state.invoiceData.content.billing_address.last_name || '',
          city: state.invoiceData.content.billing_address.city || '',
          zip: state.invoiceData.content.billing_address.zip || '',
          address: state.invoiceData.content.billing_address.address || '',
          country: state.invoiceData.content.billing_address.country || '',
          vatNumber: state.invoiceData.content.billing_address.vat_number || ''
        }
      }
      case CREATE_INVOICE_UPDATE_OBJECT:
        return {
          ...state,
          invoiceData: {
            ...state.invoiceData,
            updateObject: {
              customer_type: state.invoiceForm.selectedType,
              billing_address: {
                company_name: state.invoiceForm.companyName || null,
                first_name: state.invoiceForm.firstName || null,
                last_name: state.invoiceForm.lastName || null,
                address: state.invoiceForm.address || null,
                zip: state.invoiceForm.zip || null,
                city: state.invoiceForm.city || null,
                country: action.city || null,
                vat_number: state.invoiceForm.vatNumber || null
              }
            }
          }
        }
      case CLEAR_INVOICE_UPDATE_OBJECT:
        return {
          ...state,
          invoiceData: {
            ...state.invoiceData,
            updateObject: null
          }
        }

      default:
          return state;
  }
}
export default reducer;

// Action Creators

const actionBlank = () => ({ type: null });
const actionFetchBraintreeToken = token => ({ type: FETCH_BRAINTREE_TOKEN, token })
const actionCreateCustomerPayment = nonce =>
  ({ type: CREATE_CUSTOMER_PAYMENT_AUTHORIZATION, nonce })

export const actionSetLastFourPaymentCardDigitsForSummary = lastFourDigits => ({
  type: SET_LAST_FOUR_DIGITS, lastFourDigits
})

export const actionSetPaymentCardVendorNameForSummary = vendorName => ({
  type: SET_CREDIT_CARD_VENDOR_NAME, vendorName
})

export const actionSetIsNonceReadyForUpdate = boolean => ({ type: SET_IS_NONCE_READY_FOR_UPDATE, boolean });
export const actionsetFirstAcceptedPaymentMethodType = ({ paymentMethod }) => ({ type: SET_FIRST_ACCEPTED_PAYMENT_METHOD, paymentMethod });
export const actionSetActivePaymentMethodType = ({ paymentType }) => ({ type: SET_ACTIVE_PAYMENT_TYPE, paymentType })

export const actionFetchInvoiceData = data => ({ type: FETCH_INVOICE_DATA, data });
export const actionSetInvoiceDataError = error => ({ type: SET_INVOICE_DATA_ERROR, error });
export const actionSetInvoiceDataSuccess = success => ({ type: SET_INVOICE_DATA_SUCCESS, success });
export const actionSetInvoiceDataIsUpdating = boolean => ({ type: SET_INVOICE_DATA_IS_UPDATING, boolean });
export const actionSetInvoiceDataIsFetching = boolean => ({ type: SET_INVOICE_DATA_IS_FETCHING, boolean });
export const actionSetInvoiceHistory = data => ({ type: SET_INVOICE_HISTORY, data });
export const actionSetInvoiceHistoryError = error => ({ type: SET_INVOICE_HISTORY_ERROR, error });
export const actionSetInvoiceHistoryIsFetching = boolean => ({ type: SET_INVOICE_DATA_IS_FETCHING, boolean });
export const actionSetRequestedInvoiceDownloadObject = data => ({ type: SET_REQUESTED_INVOICE_DOWLOAD_OBJECT, data });
export const actionSetInvoicePDFIsFetching = boolean => ({ type: SET_INVOICE_PDF_IS_FETCHING, boolean });
export const actionSetInvoiceFormValue = (key, value) => ({ type: SET_INVOICE_FORM_VALUE, key, value });
export const actionClearInvoiceFormValues = () => ({ type: CLEAR_INVOICE_FORM_VALUES });
export const actionRepopulateInvoiceFormValues = selectedType => ({ type: REPOPULATE_INVOICE_FORM_VALUES, selectedType });
export const actionCreateInvoiceUpdateObject = city => ({ type: CREATE_INVOICE_UPDATE_OBJECT, city });
export const actionClearInvoiceUpdateObject = () => ({ type: CLEAR_INVOICE_UPDATE_OBJECT });

export const actionSetPayPalButtonsInitialized = ({ payPalButtonsInitialized }) => ({type: SET_PAYPAL_BUTTONS_INITIALIZED, payPalButtonsInitialized}); 
export const actionSetConfirmedPaymentMethodForEdition = ({paymentMethodForEdition}) => ({type: SET_PAYMENT_METHOD_FOR_EDITION, paymentMethodForEdition});
// Methods

export const fetchBraintreeToken = () => async dispatch => {
  /* In order to start the process of charging the user, we need to get 
  an authorization for client-side app, which is obtained in 
  form of a token, which then is passed into a designated container where external
  script renders its UI.*/

  const { fetchBraintreeToken } = HeraldbeeApi({
    baseUrl: config.API_URL
  });

  let { value, error } = await fetchBraintreeToken();


  if (error) {
    return dispatch(actionSetError(error));

  } else {


    const { token: { clientToken } } = value;
    sendAmplitudeEvent(amplitudeEvents.ONBOARDING_SET_CREDIT_CARD);
    return dispatch(actionFetchBraintreeToken(clientToken));
    /* In case everything is fine, cache the token. */
  }
}

export const createCustomerWithPaymentAuthorization = nonce =>
  dispatch => {
    return dispatch(actionCreateCustomerPayment(nonce));
    /* saving the received client payment nonce */
    /* A payment method nonce is a secure, one-time-use reference to payment information. It's the key element that allows your server to communicate sensitive payment information to Braintree without ever touching the raw data. */
  }

export const validateBraintreeClientInstanceEnvelope = ({ envelope: { braintreeClientInstance, braintreeClientInstanceCreationError } }) => async dispatch => {
  if (braintreeClientInstanceCreationError) {
    dispatch(actionSetError({
      message: braintreeClientInstanceCreationError
    }));
  } else {
    dispatch(actionBlank());
    return { validatedBraintreeClientInstance: braintreeClientInstance };
  }
}

export const validateBraintreeHostedFieldsEnvelope = ({ envelope: {
  hostedFieldsInstance,
  hostedFieldsCreationError
} }) => async dispatch => {

  if (hostedFieldsCreationError) {
    /** Network error === deadBee */
    dispatch(actionSetError({
      message: hostedFieldsCreationError
    }));
  } else {
    dispatch(actionBlank());
    return { validatedHostedFieldsInstance: hostedFieldsInstance };
  }
};

export const getInvoiceData = () => async dispatch => {
  dispatch(actionSetInvoiceDataIsFetching(true));

  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });

  const { value, error } = await hbApi.fetchInvoiceData();
  
  if (error) {
    if (value && value.error) {
      dispatch(actionSetInvoiceDataError(value.error));
    } else {
      dispatch(actionSetError(error));
    }
  } else if (value) {
    if (value.error) {
      dispatch(actionSetInvoiceDataError(value.error));
    } else if (value.value) { // Do not ovveride initial state if BE returns null
      dispatch(actionSetInvoiceFormValue('selectedType', 'company'));
      if (value.value.billing_address !== null) {
        dispatch(actionFetchInvoiceData(value.value));
      }
    }
  }

  dispatch(actionSetInvoiceDataIsFetching(false));
};

export const saveInvoiceData = updateObject => async dispatch => {
  dispatch(actionSetInvoiceDataIsUpdating(true));

  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });

  const { value, error } = await hbApi.updateInvoiceData(updateObject);
  
  if (error) {
    if (value && value.error) {
      dispatch(actionSetInvoiceDataError(value.error));
    } else {
      dispatch(actionSetError(error));
    }
  } else if (value) {
    if (value.error) {
      dispatch(actionSetInvoiceDataError(value.error));
    } else if (value.value) {
      dispatch(actionSetInvoiceDataSuccess('Your invoice data has been successfully updated!'));
      dispatch(actionFetchInvoiceData(value.value));
    }
  }

  dispatch(actionClearInvoiceUpdateObject());
  dispatch(actionSetInvoiceDataIsUpdating(false));
};

export const clearInvoiceDataError = () => dispatch => dispatch(actionSetInvoiceDataError(null));

export const clearInvoiceDataSuccess = () => dispatch => dispatch(actionSetInvoiceDataSuccess(null));

export const validateBraintreeInstanceEnvelope = ({
  envelope: {
    value: threeDSecureInstance,
    error: braintreeThreeDSSecureInstanceCreationError
  }

}) => async dispatch => {

  if (braintreeThreeDSSecureInstanceCreationError) {
    /** Network error === deadBee */
    return dispatch(actionSetError({
      message: braintreeThreeDSSecureInstanceCreationError
    }));
  } else {
    dispatch(actionBlank());
    return { validatedThreeDSecureInstance: threeDSecureInstance };
  }
};

export const fetchInvoiceHistory = () => async dispatch => {
  dispatch(actionSetInvoiceHistoryIsFetching(true));
  
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });

  const { value, error } = await hbApi.fetchInvoiceHistory();

  if (error) {
    if (value && value.error) {
      dispatch(actionSetInvoiceHistoryError(value.error));
      return;
    } else {
      dispatch(actionSetError(error));
      dispatch(actionSetInvoiceHistoryIsFetching(false));
      return;
    };
  } else if (value) {
    if (value.error) {
      dispatch(actionSetInvoiceHistoryError(value.error));
      return;
    } else if (value.value) {
      dispatch(actionSetInvoiceHistory(value.value));
      return;
    };
  };
};

export const fetchInvoicePDF = invoiceId => async dispatch => {
  dispatch(actionSetInvoicePDFIsFetching(true));
  
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });

  const { value, error } = await hbApi.fetchInvoicePDF(invoiceId);

  if (error) {
    dispatch(actionSetError(error));
    dispatch(actionSetInvoicePDFIsFetching(false));
    return;
  } else if (value) {
    if (value.error) {
      dispatch(actionSetError(value.error));
      return;
    } else if (value.value) {
      dispatch(actionSetRequestedInvoiceDownloadObject(value.value));
      return value.value.download.download_url;
    };
  };
};

export const updateInvoiceFormField = (key, value) => dispatch => { 
  dispatch(actionSetInvoiceFormValue(key, value));
};

export const clearInvoiceFormValues = () => dispatch => {
  dispatch(actionClearInvoiceFormValues());
};

export const repopulateInvoiceFormValues = selectedType => dispatch => {
  dispatch(actionRepopulateInvoiceFormValues(selectedType));
};

export const createInvoiceUpdateObject = city => dispatch => {
  dispatch(actionCreateInvoiceUpdateObject(city));
};

export const validatePayPalButtonInstance = ({ envelope: { error: payPalButtonsCreationError } }) => dispatch => {
    if (payPalButtonsCreationError) {
        return dispatch(actionSetError({
            message: payPalButtonsCreationError
        }));
    } else {
        return dispatch(actionBlank());
    }
};
export const setFirstAcceptedPaymentMethodType = ({ paymentMethod }) => dispatch => dispatch(actionsetFirstAcceptedPaymentMethodType({ paymentMethod }));

export const setPayPalButtonsInitialized = ({ payPalButtonsInitialized }) => dispatch => dispatch(actionSetPayPalButtonsInitialized({ payPalButtonsInitialized }))
export const clearAlreadyCachedPaymentCardDetails = () => dispatch => { dispatch(actionSetPaymentCardVendorNameForSummary(null)); dispatch(actionSetLastFourPaymentCardDigitsForSummary(null)) };

export const setConfirmedPaymentMethodForEdition = (paymentMethodForEdition) => dispatch => { dispatch(actionSetConfirmedPaymentMethodForEdition({ paymentMethodForEdition })) };
