// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { fetchInvoicePDF } from '../../../ducks/payment';

// Selectors
import { adjustedInvoiceHistorySelector, isInvoiceHistoryAvailableSelector } from '../../../utils/reduxSelectors';

import AccountInvoiceHistoryContainer from './AccountInvoiceHistoryContainer';

const mapStateToProps = state => ({
    adjustedInvoiceHistory: adjustedInvoiceHistorySelector(state),
    isInvoiceHistoryAvailable: isInvoiceHistoryAvailableSelector(state)
});

const mapDispatchToProps = {
    fetchInvoicePDF
};

const AccountInvoiceHistory = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInvoiceHistoryContainer));

export default AccountInvoiceHistory;