import React from 'react'

export const PricingTexts = {
	headerTitle: <><strong>Pricing</strong> plan</>,
	headerDescription: <>Use Heraldbee for free or with a <strong>14 day free trial</strong></>,
	devSubscriptionsPlans: {
		headers: {
			premiumContent: {
				title: 'PREMIUM',
				price: 'Individual price',
				subtitle: 'per month'
			},
			basicContent: {
				title: 'BASIC',
				price: '49$',
				subtitle: 'per month'
			},
			freeContent: {
				title: 'FREE',
				price: '0$',
				subtitle: 'per month'
			}
		},
		contents: {
			premiumContent: [
				"Reports",
				"Over 10 products listed on Google Shopping",
				"Dedicated Account Manager",
				"SEO for eCommerce",
				"Google Ads for eCommerce",
				"Facebook Ads for eCommerce",
				"VK Ads for eCommerce",
				"UX Audit for eCommerce",
				"Individual Developer",
				"Individual Marketing Strategy Plan"
			],
            basicContent: [
				"Reports",
				"Over 100 products listed on Google Shopping",
				"Chat + Email Support",
				"Discount for 3 months payment",
				"The ability to select products subject to advertising"
			],
			freeContent: [
				"Reports",
				"Up to 100 products listed on Google Shopping",
				"Chat + Email Support"
			],
		},
		buttons: {
			freeButton: "Start free",
			basicButton: "Start free trial",
			premiumButton: "Start free trial"
		}

	}
};

export const OnboardingTexts = {
	nameSlide: {
		title: {
			bold: "Choose the name",
			regular: " of your marketing campaign"
		},
		infoButtonText: "Giving your campaign a descriptive name will help you remember it's characteristics, goal and settings. It's especially helpful in case of running multiple campaigns at once. You can change the name of the campaign at any point in campaign settings tab."
	},
	countrySlide: {
		title: {
			bold: "Choose a country",
			regular: " where you want to advertise"
		},
		infoButtonText: "This is all about in which country your ads can be found. You can add more or change this one later. It would be the best to advertise in countries with the same language as in your store.",
	},
	budgetSlide: {
		title: {
			bold: "Set up a daily budget",
			regular: " for your advertising"
		},
		infoButtonText: "Set how much you would like to spend on your ads daily. Keep in mind that this amount influences your campaign results. Local and small business usually advertises for 10-20$ a day, more products mean more ad spend, so if you have more than 1000 products it would be good to spend around 30$ a day. This is a cost of advertising in one country. You can change it any time."
	},
	paymentSlide: {
		title: {
			bold: "Add payment",
			regular: " method for your ads"
		},
		infoButtonText: "Delayed payments are the most common and the easiest to solve reasons why advertising campaigns are not effective. We want to keep your campaign constantly running and profitable, so we need your card to pay for the ads. Your card will be also charged when your trial ends. You can cancel it anytime."
	},
	finishSlide: {
		title: {
			bold: "Summary",
			regular: " of your advertising"
		},
		infoButtonText: 'Heraldbee will now create your campaign! Click Create Campaign to proceeed',
		buttonText: 'Go to Campaign View'
	}
};

export const AccountsTexts = {
	content: {
		Heraldbee: "I don't have Merchant Center or Google Ads account",
		Google: "I want to use my own Merchant Center and Google Ads accounts",
		details: {
			dontHave: "Heraldbee will create necessary Google accounts for you. We need them to run your Shopping campaign.",
			have: "You can link your existing Google Ads and Merchant Center to run the campaign there."

		}
	}
};

export const AcceptAccountTexts = {
	title: `Success account
connection`,
	description: "Merchant Center and Google Ads accounts are active. Now create a campaign and start advertising your products.",
	button: "Create campaign"
};

export const AdChannelSelectionTexts = {
	selectionSuccess: {
		title: 'Success!',
		subtitle: 'Heraldbee is now ready to prepare Google marketing campaign for you',
        description: "We will prepare Google Ads and Google Merchant Center accounts dedicated for your ads. To proceed to our simple campaign creator, please click the button below.",
        buttonText: 'Campaign Creator'
	},
	verificationSuccess: {
		title: 'Success!',
		subtitle: 'Heraldbee is now connected to your Facebook account',
        // description: "We will prepare Facebook Ad Account dedicated for your ads. To proceed to our simple campaign creator, please click the button below."
        description: "On the next step, we will ask you to choose one of your Facebook Ad Accounts that you would like us to have access to, in order to help you manage with Facebook advertising.",
        buttonText: 'Choose Ad Account'
	}
};

export const AdChannelRequestSuccessfulTexts = {
		title: 'Success!',
		subtitle: 'The request has been sent to your chosen Facebook Ad Account',
        description: "Now, we can create your first Ad Campaign. Please keep in mind that accepting the request is necessary to start the Campaign, that you are about to create.",
        buttonText: 'Campaign Creator'
};

export const FacebookAdAccountSelectionTexts = {
    title: "Choose your Facebook Ad Account",
    subtitle: "We will assist you with managing the chosen Ad Account",
    description: 'Below is displayed a list of all your Ad Accounts. Upon choosing one, we will ask you to grant Agency Access to the chosen Ad Account, what will result in you receiving Facebook notification about our request. We will need you to  accept the request, to allow us to help you with your Facebook Ads'
};

export const FaqTexts = {
	howManyCustomers:
		"We have just started, but we already have around 100-200 users per month. Certainly, many issues arise, but most of the time they are not related to the performance of our application but rather to the approval of products and online store in Google."
	,
	iHaveInstalledYourApplication:
		"In 99% of all cases, we wait for products’ approval in Google Merchant Center or Google’s approval for your web-based store to be displayed in Google Shopping. Once both conditions are fulfilled, we can kick off with the campaign. We monitor the case and we regularly update its status in our Feed Fixer, where you can check it. If it’s necessary we even contact Google’s support."
	,
	howLongDoesItTake:
		"According to Google, it takes up to 72 hours. However, sometimes it takes even longer. If it’s the case, we personally contact Google to find out what is the reason and we provide you with guidelines."
	,
	whatMakesDifferent:
		"Above all, our application is focused on bringing movement to the website (prospecting). It’s also much more automated comparing to other apps. What’s important is that we are not a marketing agency with an application, but rather an application supported by the experts. What we care about the most is creating well setup and optimally managed campaigns. You can find detailed comparisons here:"
};

export const googleRequirementsTexts = {
	title: "Google Requirements",
	description: <>For the <strong>campaign to start</strong>, your products must meet <strong>these requirements.<br />Check</strong> your products and mark the ones you have.</>,
	requirementsSuffix: <><br /><br />If you have more questions, </>,
	requirementsSuffixAction: "write to us",
	requirements: [
		{ title: "Relevant and complete contact information", description: <>You need to add the following information to your online store:<br /><br />Phone number<br />Email address<br />The physical address of your store</> },
		{ title: "Privacy policy", description: "You need to provide your customers with a secure shopping process. Add a customized privacy policy, to make your customers feel safe about sharing their personal data." },
		{ title: "Return & Refund policy", description: "You have to give your customers an option to return some mismatched orders or to claim a refund when something goes wrong. That will make them feel safe." },
		{ title: "Safe checkout", description: "Remember that your checkout should be secured to protect the customers otherwise they will not be eager to buy from you." },
		{ title: "Product categories", description: <>Google restricts the advertising of some products.<br />If you want to advertise adult-oriented content as sexually suggestive and erotic goods or copyrighted items with trademarks - contact us.<br /><br />Guns and bullets, tobacco, alcohol, gambling-related, healthcare-related products are prohibited in the majority of destinations - you cannot advertise them. Contact us to make sure if this is allowed in your destination.<br /><br />You can find out more about Google Shopping policies here:<br /><a href="https://support.google.com/merchants/answer/6149970?hl=en" target="_blanck" rel="noopener noreferrer">https://support.google.com/merchants/answer/6149970?hl=en</a></> },
		{ title: "Correct links to valid products’ landing pages", description: "Remember that Shopping ads lead customers to the store. Make sure that all of your products have their own landing page and the link does not redirect customers to the home page or other products." },
		{ title: "Good quality images", description: <>You <strong>cannot</strong> put any text on your products’ images, because Google will not accept them and the product will be disapproved. Try to take them all on the white background and place only one item in one photo.</> },
		{ title: "Language of the ads and the countries should match", description: "Advertise only in those countries, for which the language of your store is supported. Otherwise, you need to add another language version to the store." },
	]
}

export const ErrorTexts = {
	title: 'Something went wrong',
	buttonText: 'Return to the application',
};

export const FaqTextList = [
    {
        header: 'I have created my campaign, what’s next?',
        description:[
            'You should wait for the app and Google to process your products.',
            ' Heraldbee needs about an hour to import your product data and save it on Google servers. Google has to check your website and approve the products. It usually takes from 3 to 5 business days.',
            ' After that, the campaign will be active until you stop it.'
        ]
    },
    {
        header: 'How exactly does Heraldbee work? What do you do?',
        description:[
            'Heraldbee works with Google to help you start advertising in the easiest way.',
            'We take care of the whole process, so you don’t need to worry if the settings are correct.',
            'You need two Google accounts (Google Ads (AdWords) and Merchant Center) to advertise on Google Shopping. Heraldbee exports your products from the store to Google Merchant Center and links it with Google Ads, where the campaign is created.'
        ]
    },
    {
        header: 'How can I check if my campaign is active?',
        description:[
            'In the campaign view, you can check your campaign status. It can be active or paused.',
            'Only an active campaign can bring additional visits to your store.'
        ]
    },
    {
        header: 'Google disapproved my products, what should I do?',
        description: [
            'If you see disapproved products you need to fix them in your store.',
            'You can find the reason for the disapproval together with a suggested Google’s solution under your product’s image.'
        ]
    },
    {
        header: 'How can I fix the issues with my products?',
        description:[
            'You can find the reason for the disapproval together with a suggested Google’s solution under your product image. You should go to your store and fix the issue directly there.'
        ]
    },
    {
        header: 'My ads are not working, what can I do?',
        description:[
            'First of all, has it been 3 days since you started your campaign? If not, please wait a bit more. If yes, please check your campaign status and try to find out what happened.',
            'Secondly, please check if your store meets all the Google requirements.',
            'If you still have no idea - contact our support team. You can use an e-mail support@heraldbee.com or a chat window on the website. You can find it in the bottom right corner of the website and the Support Hero will help you identify the problem.'
        ]
    },
    {
        header: 'How much should I spend on Google Ads?',
        description: [
            'You can spend as much as you want, but the measurable profits will appear only after setting a budget of at least 10$ a day for one campaign in one country with about 1000 products in your store.',
            'If you have more products or just want to see the results faster, it is recommended to spend more.'
        ]
    },
    {
        header: 'How is the cost per click calculated?',
        description: [
            'Google conducts an instant ads’ auction for every search on Google. It links the searched phrase with the ad (product description). If more than one ad matches the phrase, Google displays the ad for which they will earn the most.',
            'You can set the maximum cost that you want to pay for the ad if you want to control the budget. You can also choose the automated bidding option to maximize the number of clicks, but it might increase the cost per click.',
            'You won’t necessarily pay the maximum cost . Google will only charge the amount a bit higher than the maximum bid of an ad displayed above yours (the minimum necessary to display your ad).'
        ]
    },
    {
        header: 'Why is my cost per click so high?',
        description: [
            'The cost of a click depends on the category of the product and its popularity. The more stores sell the same items, the more you will have to pay for the ad.',
            'We have some ways to optimize and decrease the cost per click. Just contact us and we will figure it out together.'
        ]
    },
    {
        header: 'I have added some new products to my store. When will they be visible on Google Shopping?',
        description: [
            'Heraldbee updates your products every day, so they will be visible maximum 24 hours after adding them. Usually, it takes a couple of hours to be processed by Google, if your campaign is already active.'
        ]
    },
    {
        header: 'Can I choose which of my products will be advertised?',
        description: [
            'You don’t have to choose the products and the keywords associated with them, because Google Shopping only shows the ads of the searched products.',
            'But you can choose them - we give you unlimited possibilities to manage your feed and its settings in the Premium plan. All you have to do is to contact our support team and they will make everything exactly as you wish.',
        ]
    },
    {
        header: 'What is a product feed?',
        description: [
            'All the items from your store and their data: images, descriptions and prices make a product feed. A product feed supported by Google can be a text (.txt) or an XML file.'
        ]
    },   
]

export const modalText = {
	
	modalHeaderText: <strong>Add sales statistics from your store</strong>,
	modalSecondaryText: <>With Heraldbee you can see how many products you have sold and how much you have made.<br/><br/>Folow these steps to add tracking code to your Shopify store.</>,
	step1: `Copy the script using the "COPY" button`,
	step2: `In your Shopify panel choose ‘settings’ and then ‘checkout’. Scroll down and find the box for ‘additional scripts’. Paste the copied text there (Ctrl+V or ⌘+V) and save the changes.`,
	step3: `Click „SAVE” on the top or bottom of the Shopify page`

} 

export const productNoResultText = {
	headerText: 'Sorry, but we couldn’t get products from your store :(',
	subText: 'Please check if you have products avaliable at your website.',
	headerTextAfterFilter: 'You don’t have products with this status.',
	subTextAfterFilter: 'Check products with different status.',
	loaderHeaderText: 'Your products are on their way to Heraldbee.',
	loaderSubText: 'They will be visible here in a few minutes.',
}

export const shortenString = (string,charlen=20) => {
	const shortenedString = `${string.slice(0, charlen)}...`;
	return string.length >= 30 ? shortenedString : string;
}
