// Actions
const ADD_CAMPAIGN_SET_TARGET_CHANNEL = 'ADD_CAMPAIGN_SET_TARGET_CHANNEL';
const ADD_CAMPAIGN_SET_GOOGLE_FIELD = 'ADD_CAMPAIGN_SET_GOOGLE_FIELD';
const ADD_CAMPAIGN_SET_FACEBOOK_FIELD = 'ADD_CAMPAIGN_SET_FACEBOOK_FIELD';
const ADD_CAMPAIGN_CLEAR = 'ADD_CAMPAIGN_CLEAR';
const ADD_CAMPAIGN_DISMISS_HAS_BEEN_CREATED = 'ADD_CAMPAIGN_DISMISS_HAS_BEEN_CREATED';
const ADD_CAMPAIGN_CLEAR_CANCEL = 'ADD_CAMPAIGN_CLEAR_CANCEL';

// Reducer
const initialState = {
    targetChannel: null,
    campaignHasBeenCreated: false,
    google: {
        budget: null,
        country: null,
    },
    facebook: {
        name: null,
        country: null
    }
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ADD_CAMPAIGN_SET_TARGET_CHANNEL:
            return {
                ...state,
                targetChannel: action.channel
            }
        case ADD_CAMPAIGN_SET_GOOGLE_FIELD:
            return {
                ...state,
                google: {
                    ...state.google,
                    [action.field]: action.value
                }
            };

        case ADD_CAMPAIGN_SET_FACEBOOK_FIELD:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    [action.field]: action.value
                }
            }
        case ADD_CAMPAIGN_CLEAR:
            return {
                ...initialState,
                campaignHasBeenCreated: true
            }

        case ADD_CAMPAIGN_DISMISS_HAS_BEEN_CREATED:
            return {
                ...state,
                campaignHasBeenCreated: false
            }

        case ADD_CAMPAIGN_CLEAR_CANCEL:
            return {
                ...initialState
            }

        default:
            return state;
    }
}
export default reducer;

// Action Creators
export const actionAddCampaignSetTargetChannel = channel => ({ type: ADD_CAMPAIGN_SET_TARGET_CHANNEL, channel });
export const actionAddCampaignSetGoogleField = (field, value) => ({ type: ADD_CAMPAIGN_SET_GOOGLE_FIELD, field, value });
export const actionAddCampaignSetFacebookField = (field, value) => ({ type: ADD_CAMPAIGN_SET_FACEBOOK_FIELD, field, value});
export const actionAddCampaignClear = () => ({ type: ADD_CAMPAIGN_CLEAR });
export const actionAddCampaignDismissCampaignHasBeenCreated = () => ({ type: ADD_CAMPAIGN_DISMISS_HAS_BEEN_CREATED });
export const actionAddCampaignClearCancel = () => ({ type: ADD_CAMPAIGN_CLEAR_CANCEL });