// React
import { withRouter } from 'react-router-dom';
import MaterialFixedMainAppElementsContainer from './MaterialFixedMainAppElementsContainer';
import { connect } from 'react-redux';
import { logOut } from '../../ducks/auth';
import { platformSelector, shopNameSelector } from '../../utils/reduxSelectors';


const MaterialFixedMainAppElements = withRouter(MaterialFixedMainAppElementsContainer);

const mapStateToProps = state => ({
	wooCommerceOwnerName: state.auth.wooCommerceOwnerName,
	platform: platformSelector(state),
	shopName: shopNameSelector(state)
});

const mapDispatchToProps = {
	logOut
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialFixedMainAppElements);