import ReactGA from 'react-ga';
import config from '../config';

export const initAnalytics = () => {
  !config.DEV && ReactGA.initialize(config.ANALYTICS_ID);
}

export const recordAnalyticsPaths = location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
}