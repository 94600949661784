import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { PropTypes } from "prop-types";

const MaterialMobileMenuComponent = ({
  mobileMaterialAnchorElement,
  mobileMenuId,
  isMobileMenuOpen,
  handleMobileMenuClose,
  logOut,
  logOutStyle
}) => (
  <Menu
    anchorEl={mobileMaterialAnchorElement}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    id={mobileMenuId}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    open={isMobileMenuOpen}
    onClose={handleMobileMenuClose}
  >
    <MenuItem onClick={logOut}>
      <IconButton
        aria-label="Account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
      >
        <p className={logOutStyle}> Log Out</p>
      </IconButton>
    </MenuItem>
  </Menu>
);

MaterialMobileMenuComponent.propTypes = {
  mobileMaterialAnchorElement: PropTypes.object,
  mobileMenuId: PropTypes.string.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  handleMobileMenuClose: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  logOutStyle: PropTypes.string
};
export default MaterialMobileMenuComponent;
