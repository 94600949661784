import React from "react";
import MaterialButtonComponent from '../MaterialComponents/MaterialButtonComponent/index';

import style from "./index.module.less";
import VendorLogoComponent from '../VendorLogoComponent/index';
import { PropTypes } from 'prop-types';

const SavedPaymentCardComponent = ({
  lastFourPaymentCardDigits,
  creditCardVendorNameForSummary,
  onBackClick,
  onNextClick,
  isBlockingTheButtonJustified,
}) => {
  return (
    <div className={style.savedPaymentCardComponent}>
      <div className={style.displaySavedPaymentMethod}>
        <hr className={style.contentBreak} />

        <p className={style.contentSectionParagraph}>Payment</p>
        <div className={style.NumberAndVendorRow}>
          <span>{`**** **** **** ${lastFourPaymentCardDigits}`}</span>
          <VendorLogoComponent
            vendorName={creditCardVendorNameForSummary}
          />
        </div>
        <hr className={style.contentBreak} />
        <p className={style.cardIsValidText}>
          {"Your Payment Card is valid."}
        </p>
      </div>

      <div className={style.navButtonsRow}>
        <MaterialButtonComponent
          text="BACK"
          type="secondary"
          size="xSmall"
          onClick={onBackClick}
        />
        <MaterialButtonComponent
          text="NEXT"
          type="primary"
          size="xSmall"
          className={style.nextButton}
          onClick={onNextClick}
          disabled={isBlockingTheButtonJustified}
        />
      </div>
    </div>
  );
};

SavedPaymentCardComponent.propTypes = {
  lastFourPaymentCardDigits: PropTypes.string.isRequired,
  creditCardVendorNameForSummary: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  isBlockingTheButtonJustified: PropTypes.bool.isRequired,
};

export default SavedPaymentCardComponent;
