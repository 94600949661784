// React
import { withRouter } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
// Methods
import { getInvoiceData, saveInvoiceData, clearInvoiceDataError, clearInvoiceDataSuccess, clearInvoiceFormValues, updateInvoiceFormField, repopulateInvoiceFormValues, createInvoiceUpdateObject } from '../../../ducks/payment';

import AccountInvoiceDataContainer from './AccountInvoiceDataContainer';

const mapStateToProps = state => ({
    content: state.payment.invoiceData.content,
    error: state.payment.invoiceData.error,
    success: state.payment.invoiceData.success,
    isFetching: state.payment.invoiceData.isFetching,
    isUpdating: state.payment.invoiceData.isUpdating,
    selectedForm: state.payment.invoiceForm.selectedType,
    updateObject: state.payment.invoiceData.updateObject
});

const mapDispatchToProps = {
    getInvoiceData,
    saveInvoiceData,
    clearInvoiceDataError,
    clearInvoiceDataSuccess,
    clearInvoiceFormValues,
    updateInvoiceFormField,
    repopulateInvoiceFormValues,
    createInvoiceUpdateObject
};

const AccountInvoiceData = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInvoiceDataContainer));

export default AccountInvoiceData;