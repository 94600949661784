// React
import React from 'react';
import PropTypes from 'prop-types';

// Thrid-Party Modules
import Radio from '@material-ui/core/Radio';

// Stylesheet
import { useStyles } from './index.material';

const MaterialRadioButtonComponent = ({ isRadioButtonChecked, onChange, buttonValue, cssClass }) => {
    const classes = useStyles();

    const handleChange = e => {
        onChange(e.target.value);
    };

    return (
        <Radio
            classes={{ root: classes.root, checked: classes.checked }}
            className={`${classes[cssClass]}`}
            color={'default'}
            checked={isRadioButtonChecked}
            onChange={handleChange}
            value={buttonValue}
            name="radio-button"
            inputProps={{ 'aria-label': `${buttonValue} radio button` }}
        />
    );
};

MaterialRadioButtonComponent.propTypes = {
    isRadioButtonChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    buttonValue: PropTypes.string,
    cssClass: PropTypes.string
};

export default MaterialRadioButtonComponent;