// Redux
import { connect } from 'react-redux';
// Methods
import { patchCampaign, toggleCampaignStatus } from '../../../ducks/campaigns';

import CampaignSettingsContainer from './CampaignSettingsContainer';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    patchCampaign,
    toggleCampaignStatus
};

const CampaignSettings = connect(mapStateToProps, mapDispatchToProps)(CampaignSettingsContainer);

export default CampaignSettings;