import { connect } from 'react-redux'
import AccountSummaryContainer from './AccountSummaryContainer/index';
import { actionSetUpdateMessage } from '../../ducks/subscription';
import { actionConfirmSuccessfulPaymentAuthUpdate } from '../../ducks/subscription/index';
import { platformSelector } from '../../utils/reduxSelectors';
import { actionSetIsNonceReadyForUpdate} from '../../ducks/payment';


/** This decorator is left in place, as it will be necessary to implement WooCommerce settings */
const mapStateToProps = state => ({
    platform: platformSelector(state),
    updateMessage: state.subscription.updateMessage
});

const mapDispatchToProps = {
    actionSetUpdateMessage,
    actionConfirmSuccessfulPaymentAuthUpdate,
    actionSetIsNonceReadyForUpdate,
};

const AccountSummary = connect(mapStateToProps, mapDispatchToProps)(AccountSummaryContainer);

export default AccountSummary;
