// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setFacebookField as setCampaignName } from '../../../../ducks/additionalCampaign/methods';

import ChooseNameContainer from '../../../../components/SharedCreateCampaign/ChooseNameContainer';

const mapStateToProps = state => ({
    name: state.additionalCampaign.facebook.name
});

const mapDispatchToProps = {
    setCampaignName
};

const ChooseNameMain = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseNameContainer));

export default ChooseNameMain;