// React
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import Avatar from 'react-avatar';

// Custom Modules
import { avatarSizeTranslator } from '../../../utils/utils';
import { colorPalette } from '../../../styles/constants';

// Stylesheet
import styles from './index.module.less';

const AvatarComponent = ({ avatarUrl, shopName, size }) => 
    <Avatar 
        className={styles.avatar}
        color={colorPalette.white}
        fgColor={colorPalette.primaryYellow}
        round={true}
        src={avatarUrl}
        maxInitials={1}
        name={shopName}
        size={avatarSizeTranslator(size)}
        textSizeRatio={2}
    />

AvatarComponent.propTypes = {
    avatarUrl: PropTypes.string,
    shopName: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'large'])
};

export default AvatarComponent;