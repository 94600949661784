import React from 'react';
import PropTypes from 'prop-types';

//material-ui components
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';

import { useTableHeadStyles, useTableSortLabelStyles } from './index.material.js'

const HistoryTableHead = ({ order, orderBy, onRequestSort }) => {

    //styles hooks
    const tableHeadStyles = useTableHeadStyles()
    const tableSortLabelStyles = useTableSortLabelStyles()

    const createSortHandler = property => event => {
        onRequestSort(event, property);
      };

    const headRows = [
        { id: 'date', alignRight: false, disablePadding: true, label: 'DATE' },
        { id: 'day', alignRight: false, disablePadding: false, label: 'DAY' },
        { id: 'budget', alignRight: true, disablePadding: false, label: 'BUDGET' },
        { id: 'clicks', alignRight: true, disablePadding: false, label: 'CLICKS' },
        { id: 'impressions', alignRight: true, disablePadding: false, label: 'IMPRESSIONS' },
        { id: 'cost', alignRight: true, disablePadding: false, label: 'COST' },
    ]
    
    return (
            <TableHead className={tableHeadStyles.root}>
                <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            classes={tableSortLabelStyles}
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                        {row.label}
                        {orderBy === row.id ? (
                            <span className={tableHeadStyles.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                        ) : null}
                        </TableSortLabel>
                    </TableCell>
                    ))}
                </TableRow>
            </TableHead>
    )
}

HistoryTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default HistoryTableHead