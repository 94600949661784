import config from '../../config';
import { setError } from '../app';
import { HeraldbeeApi } from '../../api/HeraldbeeApi';

//Actions

const SET_CAMPAIGN_HISTORY = 'SET_CAMPAIGN_HISTORY';

//Reducer

const initialState = {
    history: [],
}

const reducer = (state=initialState, action={}) => {

    switch(action.type) {
        case SET_CAMPAIGN_HISTORY:
            return {
                ...state,
                history: action.history
            }
        default: return state
    }
}

export default reducer

//Action Creators

const actionSetCampaignHistory = history => ({type:SET_CAMPAIGN_HISTORY, history})

//Methods

export const fetchCampaignHistory = (campaignId, dateFrom, dateTo) => async dispatch =>  {
    const hbApi = HeraldbeeApi({
        baseUrl: config.API_URL,
    });

    const { value, error } = hbApi.fetchCampaignHistory(campaignId, dateFrom, dateTo);

    if (error){
        setError(error)(dispatch);
        return;
    }
    const  campaignHistory  = value ? value : []

    dispatch(actionSetCampaignHistory(campaignHistory))
}
