// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setFacebookField as setCampaignCountry } from '../../../../ducks/additionalCampaign/methods';

import chooseCountryContainer from '../../../../components/SharedCreateCampaign/ChooseCountryContainer';

const mapStateToProps = state => ({
    countryCode: state.additionalCampaign.facebook.country
});

const mapDispatchToProps = {
    setCampaignCountry
};

const chooseCountryMain = withRouter(connect(mapStateToProps, mapDispatchToProps)(chooseCountryContainer));

export default chooseCountryMain;