// React
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Spin } from 'antd';

// Custom Components
import MaterialButtonComponent from '../../../MaterialComponents/MaterialButtonComponent';

// Custom Modules
import config from '../../../../config';

// Assets
import FBLogo from '../../../../images/onboarding/fb-logo.png';

// Stylsheet
import styles from './index.module.less';

const AccountIntegrationComponent = ({ isUserConnectedWithFacebook, isUserIntegratedWithFacebook, findOutIfUserHasGrantedAgencyAccess }) => {
    const details = () => {
        if (isUserIntegratedWithFacebook) {
            return 'CONNECTED';
        } else if (isUserConnectedWithFacebook) {
            return 'PENDING ACCESS PERMISSION';
        } else {
            return 'NOT CONNECTED'
        }
    };

    const [ isLoading, setIsLoading ] = useState(false);

    useLayoutEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        }
    }, [ isUserIntegratedWithFacebook ])

    const onUpdateClick = () => {
        setIsLoading(true);
        findOutIfUserHasGrantedAgencyAccess();
    };

    return (
        <section className={styles.container}>
            <div className={styles.row}>
                <div className={styles.iconRowContentWrapper}>
                    <span className={styles.rowTitle}>
                        <img className={styles.rowTitleImg} src={FBLogo} alt="Marketing channel logo" />
                    </span>
                    <p className={styles.rowContentValue}>{details()}</p></div>
                <span className={styles.rowContent}>
                    {
                        isUserConnectedWithFacebook
                            ? (
                                <div className={isUserIntegratedWithFacebook ? styles.updateInactive : styles.update}>
                                    <Spin spinning={isLoading}>
                                        <MaterialButtonComponent
                                            type="primary"
                                            size={'xSmall'}
                                            text={'UPDATE'}
                                            onClick={onUpdateClick}
                                        />
                                    </Spin>
                                </div>
                            ) : (
                                <a className={styles.rowContentLink} href={`${config.API_URL}/facebook?context=main`}>Connect now</a>
                            )
                    }
                </span>
            </div>
        </section>
    );
};

AccountIntegrationComponent.propTypes = {
    isUserConnectedWithFacebook: PropTypes.bool.isRequired
};

export default AccountIntegrationComponent;