import config from '../config';
import { isLoginRequired, facebookAuthorizationStatus } from '../utils/auth';
import { readFromLocalStorage, setToLocalStorage, getSearchParam } from '../utils/utils';
import localStorageItems from '../constants/localStorageItems';
import { HeraldbeeApi } from '../api/HeraldbeeApi';
import { LOG_OUT } from '../store';
import { actionSetError, setError } from './app';


// Actions

const SET_ACCESS_GRANTED = 'SET_ACCESS_GRANTED';
const FETCH_USER = 'FETCH_USER';
const SET_USER = 'SET_USER';
const UPDATE_USER_WOOCOMMERCE = 'UPDATE_USER_WOOCOMMERCE';
const ERROR_USER = 'ERROR_USER';
const SET_PLATFORM = 'SET_PLATFORM';
const SET_WOOCOMMERCE_EMAIL = 'SET_WOOCOMMERCE_EMAIL';
const SET_WOOCOMMERCE_USER_INFO = 'SET_WOOCOMMERCE_USER_INFO';
const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';
const SET_APP_ROUTES_LIST = 'SET_APP_ROUTES_LIST';
const SET_IS_ONBOARDING_FINISHED = 'SET_IS_ONBOARDING_FINISHED';
const SET_IS_WOOCOMMERCE_TOKEN_FETCHED = 'SET_IS_WOOCOMMERCE_TOKEN_FETCHED';
const SET_DID_SOFT_LOG_OUT_OCCUR = 'SET_DID_SOFT_LOG_OUT_OCCUR';
const SET_WAS_FACEBOOK_AUTH_SUCCESSFUL = 'SET_WAS_FACEBOOK_AUTH_SUCCESSFUL';
const SET_FACEBOOK_PERMISSIONS_LIST_TO_RE_ASK = 'SET_FACEBOOK_PERMISSIONS_LIST_TO_RE_ASK';
const CLEAR_FACEBOOK_STATE = 'CLEAR_FACEBOOK_STATE';

// Reducer

const initialState = {
  user: {
    fetch: true,
    loader: true,
    content: null,
    error: null
  },
  accessGranted: false,
  isUserLoggedIn: false,
  platform: null,
  isOnboardingFinished: null,
  isWoocommerceTokenFetched: false,
  didSoftLogOutOccur: false,
  facebookListOfPermissionsToReAsk: null,
  wasFacebookAuthSuccessful: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ACCESS_GRANTED:
      return {
        ...state,
        accessGranted: action.value
      }

    case SET_APP_ROUTES_LIST: {
      return {
        ...state,
        cachedListOfAvailableAppRoutes: action.listOfAvailableAppRoutes
      };
    }

    case FETCH_USER:
      return {
        ...state,
        user: { ...state.user, fetch: false, loader: true, error: null }
      }

    case SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          loader: false,
          content: action.content,
          error: action.error
        },
        platform: action.platform,
      }

    case UPDATE_USER_WOOCOMMERCE:
      return {
        ...state,
        user: {
          ...state.user,
          content: {
            ...state.user.content,
            woocommerce: {
              ...state.user.woocommerce,
              ...action.content
            }
          }
        },
      }

    /** action type made specifically to cache user email in woocommerce case */
    case SET_WOOCOMMERCE_EMAIL:
      return {
        ...state,
        wooCommerceEmail: action.email
      }

      /** action type made specifically to cache user email in woocommerce case */
    case SET_WOOCOMMERCE_USER_INFO:
      return {
        ...state,
        wooCommerceEmail: action.payload.emailInput,
        wooCommerceOwnerName: action.payload.usernameInput,
        wooCommerceShopName: action.payload.shopNameInput
      }

    case SET_PLATFORM:
      return {
        ...state,
        platform: action.platform
      }

    case ERROR_USER:
      return {
        ...state,
        user: { ...state.user, loader: false, error: action.error }
      }
    
    case SET_USER_LOGGED_IN:
      return {
        ...state,
        isUserLoggedIn: true
      }

    case SET_IS_ONBOARDING_FINISHED:
      return {
        ...state,
        isOnboardingFinished: action.value
      }

    case SET_IS_WOOCOMMERCE_TOKEN_FETCHED:
      return {
        ...state,
        isWoocommerceTokenFetched: action.value
      }
    
    case SET_DID_SOFT_LOG_OUT_OCCUR:
        return {
            ...state,
            didSoftLogOutOccur: action.value
        }
    case SET_WAS_FACEBOOK_AUTH_SUCCESSFUL:
        return {
            ...state,
            wasFacebookAuthSuccessful: action.wasFacebookAuthSuccessful
        }
    case SET_FACEBOOK_PERMISSIONS_LIST_TO_RE_ASK: 
      return {
          ...state,
          facebookListOfPermissionsToReAsk: action.listOfPermissionsToReAsk
      }
    case CLEAR_FACEBOOK_STATE:
      return {
        ...state,
        wasFacebookAuthSuccessful: initialState.wasFacebookAuthSuccessful,
        facebookListOfPermissionsToReAsk: initialState.facebookListOfPermissionsToReAsk,
      }

    default:
        return state;
  }
}
export default reducer;

// Action Creators

const actionFetchUser = () => ({ type: FETCH_USER });
const actionSetUser = (content, platform) => ({ type: SET_USER, content, platform });
const actionUpdateUserWoocommerce = content => ({ type: UPDATE_USER_WOOCOMMERCE, content });
const actionSetWooCommerceEmail = email => ({ type: SET_WOOCOMMERCE_EMAIL, email });
export const actionErrorUser = error => ({ type: ERROR_USER, error });
export const actionSetCachedListOfAvailableAppRoutes = listOfAvailableAppRoutes => ({ type: SET_APP_ROUTES_LIST, listOfAvailableAppRoutes});
const actionSaveWooCommerceUserInfo = ({ emailInput, shopNameInput, usernameInput }) => ({ type: SET_WOOCOMMERCE_USER_INFO, payload: { emailInput, shopNameInput, usernameInput }});
const actionLogOut = () => ({ type: LOG_OUT });
const actionSetAccessGranted = value => ({ type: SET_ACCESS_GRANTED, value });
const actionSetPlatform = platform => ({ type: SET_PLATFORM, platform });
const actionSetIsUserLoggedIn = () => ({ type: SET_USER_LOGGED_IN });
const actionSetIsOnboardingFinished = value => ({ type: SET_IS_ONBOARDING_FINISHED, value });
const actionSetIsWoocommerceTokenFetched = value => ({ type: SET_IS_WOOCOMMERCE_TOKEN_FETCHED, value });
const actionDidSoftLogOutOccur = value => ({ type: SET_DID_SOFT_LOG_OUT_OCCUR, value });
const actionSetWasFacebookAuthSuccessful = ({ wasFacebookAuthSuccessful }) => ({ type: SET_WAS_FACEBOOK_AUTH_SUCCESSFUL, wasFacebookAuthSuccessful });
const actionSetFacebookPermissionsToReAsk = ({ listOfPermissionsToReAsk }) => ({
    type: SET_FACEBOOK_PERMISSIONS_LIST_TO_RE_ASK,
    listOfPermissionsToReAsk
});
export const actionClearFacebookState = () => ({ type: CLEAR_FACEBOOK_STATE });

const actionBlank = () => ({ type: null });

// Methods

export const processResponseAuth = (response, errorMsg = 'Looks like there was a problem.') => {
  return dispatch => {
    if (isLoginRequired(response.status)) {
      logOut()(dispatch);
    } else {
      return response;
    }
  }
}

export const processResponse = (response, errorMsg = 'Looks like there was a problem.') => {
  return dispatch => {
    if (response.ok) {
      return response;
    } else if (isLoginRequired(response.status)) {
      logOut()(dispatch);
    } else {
      console.error(response);
      throw new Error(errorMsg);
    }
  }
}

export const processResponseJson = (response, errorMsg = 'Looks like there was a problem.') => {
  return dispatch => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then(json => {
        console.error(json);
        dispatch(setError(json));
      });
    }
  }
}


export const fetchUser = () =>
  async dispatch => {
    dispatch(actionFetchUser());

    const { fetchUser } = HeraldbeeApi({ baseUrl: config.API_URL });
    const { value, error } = await fetchUser();
    if (error) {
      dispatch(setError(error))
    } else {
      let platform;
      if (value && value.shopify) {
        platform = 'shopify';
      } else if (value && value.woocommerce) {
        platform = 'woocommerce';
      }
      localStorage.setItem('user', JSON.stringify(value));
      return dispatch(actionSetUser(value, platform));
    }
  }

export const saveUser = user =>
  dispatch => {
    setToLocalStorage(localStorageItems.USER_OBJECT, JSON.stringify(user));
    return dispatch(actionSetUser(user));
  }

export const saveWooCommerceEmail = email => dispatch => {
  dispatch(actionSetWooCommerceEmail(email));
}

export const logOut = () => async dispatch => {
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { error } = await hbApi.logoutFromServer();
  
  if (error) {
    return dispatch(setError(error));
  }

  const storeName = readFromLocalStorage(localStorageItems.LOGIN_STORE_NAME);
  localStorage.clear();
  setToLocalStorage(localStorageItems.LOGIN_STORE_NAME, storeName);
  dispatch(actionLogOut());
};

export const softLogOut = () => dispatch => {
  localStorage.clear();
  dispatch(actionLogOut());
  dispatch(actionDidSoftLogOutOccur(true));
}

export const clearDidSoftLogOutOccur = () => dispatch => dispatch(actionDidSoftLogOutOccur(false));

export const setAccessGranted = token => async dispatch => {
  if (token.length === 165) {
    await fetchUser();
    dispatch(actionSetAccessGranted(true));
  } else {
    const error = new Error('Retrieved token is invalid, please try again');
    dispatch(setError(error));
  }
}

export const setWooCommerceDataPackageToBackend = ({ wooCommerceEmail,
  wooCommerceOwnerName, wooCommerceShopName,  userDomain }) => async dispatch => {
    const { setWooCommerceDataPackageToBackend } = HeraldbeeApi({ baseUrl: config.API_URL });
    const { error, value: success } = await setWooCommerceDataPackageToBackend({ wooCommerceEmail, wooCommerceOwnerName, wooCommerceShopName,  userDomain });
  if (success) {

    dispatch(actionBlank());
    return "Email saved";

  } else {
    /** Log the actual error into the console, and show user more palatable version */
    if (error) {
      console.log(error);
    }
    dispatch(actionSetError({ message: "We are sorry, a connection error has occurred. Please try again." }));
  }
};

export const patchWooCommerceData = (fieldToUpdate, updateValue) => async dispatch => {
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });

  const { value, error } = await hbApi.patchWooCommerceData(fieldToUpdate, updateValue);
  
  if (error) {
    return dispatch(setError(error));
  } else if (value.error) {
    return dispatch(setError(value.error));
  };

  dispatch(actionUpdateUserWoocommerce(value.value));
}

export const fetchWooCommerceToken = URL => async dispatch => {
  /** Check whether I have all of the expected params from redirection to /awaitToken */
  const [userId, isTokenCreated] = ['user_id', 'success'].map(param => getSearchParam(URL, param));
  if (userId && isTokenCreated) {
    const { fetchWooCommerceToken } = HeraldbeeApi({ baseUrl: config.API_URL });
    const { error, value: tokenObject } = await fetchWooCommerceToken(userId);
    if (tokenObject) {
      dispatch(actionSetIsWoocommerceTokenFetched(true));
    } else {
      const err = new Error(error);
      dispatch(setError(err));
    }
  } else {
    const err = new Error('Something went wrong when signing in, please try again');
    dispatch(setError(err));
  }
};

export const setPlatform = platform => dispatch => {
  setToLocalStorage(localStorageItems.PLATFORM, platform);
  dispatch(actionSetPlatform(platform));
};

export const saveWooCommerceUserInfo = (emailInput, shopNameInput, usernameInput) => dispatch => {
  dispatch(actionSaveWooCommerceUserInfo({ emailInput, shopNameInput, usernameInput }));
};

export const checkWooCommerceStoreNameValidity = storeName => async dispatch => {

  /** Store name validity checking endpoint will, in case with no error on the way, always return boolean as a response */
  const { checkWooCommerceStoreNameValidity } = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { value, error } = await checkWooCommerceStoreNameValidity(storeName);

    if (error) {
        dispatch(setError(error));
        return { value: false };

    } else {
        dispatch(actionBlank());
        return { value };

    }
};

export const checkShopifyStoreNameValidity = storeName => async dispatch => {

  /** Store name validity checking endpoint will, in case with no error on the way, always return boolean as a response */
  const { checkShopifyStoreNameValidity } = HeraldbeeApi({
    baseUrl: config.API_URL
  });

    const { value, error } = await checkShopifyStoreNameValidity(storeName);
    if (error) {
        dispatch(setError(error));

        return { value: false };

    } else {
        dispatch(actionBlank());
        return { value };

    }
};

export const checkIfDomainIsInDatabase = (platform, domain) => async dispatch => {

  const { verifyDomain } = HeraldbeeApi({
    baseUrl: config.API_URL
  });

  const adjustedDomain = platform==='shopify' ? `${domain}.myshopify.com` : domain;
  const { value, error } = await verifyDomain(platform, adjustedDomain);

  if (error) {
      dispatch(setError(error));
      return { value: null, error }

  } else {
      const adjustedResponse = value === 'true' ? true : false;
      return { value: adjustedResponse, error: null };

  }

};

export const setIsUserLoggedIn = () => dispatch => {
  dispatch(actionSetIsUserLoggedIn());
}
export const checkIfUserFinishedOnboarding = platform => async dispatch => {
  const { checkIsUserFinishedOnboarding } = HeraldbeeApi({
    baseUrl: config.API_URL
  });

  const { value, error } = await checkIsUserFinishedOnboarding(platform);

  const errorToShow = error || value.error;
  if (errorToShow) {
    return dispatch(setError(errorToShow));
  };

  const valueToSave = value && value.value ? value.value : false;
  dispatch(actionSetIsOnboardingFinished(valueToSave));
  return valueToSave;
};

export const setUserFinishedOnboarding = platform => async dispatch => {

  const { setIsUserFinishedOnboarding } = HeraldbeeApi({
    baseUrl: config.API_URL
  });

  const { value, error } = await setIsUserFinishedOnboarding(platform);

  const errorToShow = error || value.error;
  if (errorToShow) {
    return dispatch(setError(errorToShow));
  };

  dispatch(actionSetIsOnboardingFinished(true));
};

export const initializeFacebookAuthProcess = (code, context) => async dispatch => {
  const { initializeFacebookAuthProcess } = HeraldbeeApi({
      baseUrl: config.API_URL
  });

  const { value, error } = await initializeFacebookAuthProcess({ code, context });


  if (error) {
      const { message } = error;
      return dispatch(setError({ message }));
  } else {
      const { listOfPermissionsToReAsk, status } = value;
      const { reAsk, finished } = facebookAuthorizationStatus;
      switch (status) {
          case finished:
              return dispatch(actionSetWasFacebookAuthSuccessful({ wasFacebookAuthSuccessful: true }));
          case reAsk:
              dispatch(actionSetWasFacebookAuthSuccessful({ wasFacebookAuthSuccessful: false }));
              return dispatch(actionSetFacebookPermissionsToReAsk({ listOfPermissionsToReAsk }));
          default:
            return null;
      }
  }
};

export const clearFacebookAuthorization = () => dispatch => dispatch(actionClearFacebookState()); 
