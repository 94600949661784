// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { patchWooCommerceData } from '../../../ducks/auth';

// selectors
import { ownerNameSelector } from '../../../utils/reduxSelectors';

import WooCommerceDataUpdaterContainer from '../../../container/WooCommerceDataUpdaterContainer';

const mapStateToProps = state => ({
    valueToUpdate: ownerNameSelector(state)
});

const mapDispatchToProps = {
    patchWooCommerceData
};

const UpdateWooCommerceData = withRouter(connect(mapStateToProps, mapDispatchToProps)(WooCommerceDataUpdaterContainer));

export default UpdateWooCommerceData;