import React from 'react'
import { PropTypes } from 'prop-types';
import MaterialRadioButtonComponent from './../../../MaterialComponents/MaterialRadioButtonComponent/index';
import style from './index.module.less';

const FacebookAdAccountListComponent = ({ listedAdAccounts, setChosenAdAccount, chosenAdAccount }) => {
    return listedAdAccounts.map(adAccountId =>
        <div key={adAccountId} className={style.listItem}>
            <MaterialRadioButtonComponent isRadioButtonChecked={adAccountId === chosenAdAccount} onChange={() => setChosenAdAccount(adAccountId)} />
            <p className={style.accountId} key={adAccountId}>{adAccountId}</p>
        </div>
    );
}


FacebookAdAccountListComponent.propTypes = {
    listedAdAccounts: PropTypes.array.isRequired,
    setChosenAdAccount:PropTypes.func.isRequired,
    chosenAdAccount: PropTypes.string
};

export default FacebookAdAccountListComponent;