import { ErrorTrackingModule } from "../module/ErrorTrackingModule";
import { clearLocalStorage } from "../utils/utils";

// Actions

const SET_ERROR = 'SET_ERROR';
const UNSET_ERROR = 'UNSET_ERROR';
const SET_FATAL_NETWORK_ERROR = 'SET_FATAL_NETWORK_ERROR';
const SET_NONDESTRUCTIVE_ERROR_MESSAGE = 'SET_NONDESTRUCTIVE_ERROR_MESSAGE';

// Reducer

const initialState = {
  error: null
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {

    case SET_ERROR:
      return {
        ...state,
        error: action.error
      };

    case UNSET_ERROR:
      return {
        ...state,
        error: null
      }
    case SET_FATAL_NETWORK_ERROR:
      return {
        ...state,
        fatalNetworkError: action.fatalNetworkErrorMessage
      }
    case SET_NONDESTRUCTIVE_ERROR_MESSAGE:
      return {
        ...state,
        nondestructiveErrorMessage: action.displayedErrorMessage
      }


    default:
      return state;
  }
}
export default reducer;

// Action Creators

export const actionSetNondestructiveErrorMessage = ({error, displayedErrorMessage}) => {
  
  if(error){
    ErrorTrackingModule.reportError(error);
    console.error(error);

  }
  return {type: SET_NONDESTRUCTIVE_ERROR_MESSAGE, displayedErrorMessage};
};
export const actionSetError = error => {
  // Log error to an error reporting service
  ErrorTrackingModule.reportError(error);
  console.error(error);
  return ({ type: SET_ERROR, error })
};
export const actionUnsetError = () => ({ type: UNSET_ERROR });

// Methods

export const setError = (error, componentStack) => dispatch => {
  dispatch(actionSetError(error));

  // Log error to an error reporting service
  ErrorTrackingModule.reportError(error, componentStack);
}

export const unsetError = () => dispatch => {
  clearLocalStorage();
  dispatch(actionUnsetError());
}

export const setNondestructiveErrorMessage = ({error, displayedErrorMessage}) => dispatch =>{
  
  if(error){
    ErrorTrackingModule.reportError(error);

  }

  return dispatch(actionSetNondestructiveErrorMessage({error, displayedErrorMessage}));
};
