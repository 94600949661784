import React from "react";
import style from "./index.module.less";
import { PropTypes } from "prop-types";
import clsx from "clsx";
import { setCreditCardVendorLogo } from "../../utils/onboarding";
/** Memoize the component, to limit the number of renders */
const VendorLogoComponent = React.memo(
  ({
    vendorName,
    additionalStyle
  }) => {

    const [
      VisaLogoSrc,
      MasterCardLogoSrc,
      AmericanExpressLogoSrc,
      MaestroLogoSrc,
      PayPalLogoSrc
    ] = ["Visa", "MasterCardLogoNarrow", "American Express", "Maestro", "PayPal"].map(
      setCreditCardVendorLogo
    );

    const vendorLogoObject = {
      Visa: {
        logoSrc: VisaLogoSrc
      },
      MasterCard: {
        logoSrc: MasterCardLogoSrc
      },
      AmericanExpress: {
        logoSrc: AmericanExpressLogoSrc
      },
      Maestro: {
        logoSrc: MaestroLogoSrc
      },
      PayPal: {
        logoSrc: PayPalLogoSrc
      }
    };
    const logoSrc = vendorLogoObject[vendorName] && vendorLogoObject[vendorName].logoSrc;
    const logoStyle = style[`vendorLogo${vendorName}`];

    return (
      logoSrc ?
        <img
          src={logoSrc}
          className={clsx(logoStyle, additionalStyle)}
          alt={`Card Vendor Logo - ${vendorName}`}
        />
        : null
    );
  }
);
/** As this component is decorated, tis name has to be explicitly set, in order to be correctly displayed in React Developer Tools */

VendorLogoComponent.displayName = "VendorLogoComponent";

VendorLogoComponent.propTypes = {
  vendorName: PropTypes.string.isRequired,
  creditCardVendorNameForSummary: PropTypes.string,
  additionalStyle: PropTypes.string
};

export default VendorLogoComponent;
