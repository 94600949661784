// React
import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// Third-Party Modules
import queryString from "query-string";

// Custom Components
import OnboardingContainer from "../../../../container/OnboardingContainer";
import HorizontalViewWithLogoComponent from "../../../../components/HorizontalViewWithLogoComponent";
import OnboardingAdChannelSelectorComponent from "../../../../components/OnboardingAdChannelSelectorComponent";
import OnboardingReAskForPermissions from "../../../../components/OnboardingReAskForPermissions";
import SharedOnboardingRouter from "../../SharedOnboardingRouter";

const OnboardingRouterGatewayContainer = ({
  history,
  selectedAdChannel,
  wasFacebookAuthSuccessful,
  facebookListOfPermissionsToReAsk,
  isChannelSelectionLoading,
  setSelectedAdChannel,
  initializeFacebookAuthProcess,
  setIsChannelSelectionLoading
}) => {
  const [isAuthenticationRejected, setIsAuthenticationRejected] = useState();
  const adjustedTargetRoute = `${selectedAdChannel}/${
    selectedAdChannel === "google" ? "selectionSuccess" : "verificationSuccess"
  }`;
  const adjustedRoute = `${selectedAdChannel}`;

  useEffect(() => {
    if (selectedAdChannel) {
      setIsChannelSelectionLoading(false);
      history.push(`/onboarding/${adjustedTargetRoute}`);
    }
  }, [selectedAdChannel]);

  useEffect(() => {
    if (
      facebookListOfPermissionsToReAsk &&
      facebookListOfPermissionsToReAsk.length > 0
    ) {
      setIsAuthenticationRejected(true);
      setIsChannelSelectionLoading(false);
    }
  }, [
    facebookListOfPermissionsToReAsk,
    setIsAuthenticationRejected,
    setIsChannelSelectionLoading
  ]);

  // After user gets back from FB login, check returned params and initialize authentication on BE
  useEffect(() => {
    if (history.location && history.location.search) {
      const parsedQuery = queryString.parse(history.location.search);

      if (parsedQuery.code && parsedQuery.code.length) {
        initializeFacebookAuthProcess(parsedQuery.code, "onboarding");
      }
    }
  }, [history.location]);

  // If authentication on BE was successful then proceed to onboarding
  useEffect(() => {
    if (wasFacebookAuthSuccessful) {
      setSelectedAdChannel("facebook");
    }
  }, [wasFacebookAuthSuccessful]);

  return selectedAdChannel ? (
    <Route
      path={`/onboarding/${adjustedRoute}`}
      component={SharedOnboardingRouter}
    />
  ) : (
    <OnboardingContainer displayMode="showHeaderMobile">
      <HorizontalViewWithLogoComponent>
        {isAuthenticationRejected ? (
          <OnboardingReAskForPermissions />
        ) : (
          <OnboardingAdChannelSelectorComponent
            setSelectedAdChannel={setSelectedAdChannel}
            isChannelSelectionLoading={isChannelSelectionLoading}
            setIsChannelSelectionLoading={setIsChannelSelectionLoading}
          />
        )}
      </HorizontalViewWithLogoComponent>
    </OnboardingContainer>
  );
};

OnboardingRouterGatewayContainer.propTypes = {
  selectedAdChannel: PropTypes.oneOf(["google", "facebook"]),
  wasFacebookAuthSuccessful: PropTypes.bool,
  facebookListOfPermissionsToReAsk: PropTypes.array,
  setSelectedAdChannel: PropTypes.func.isRequired,
  initializeFacebookAuthProcess: PropTypes.func.isRequired
};

export default OnboardingRouterGatewayContainer;
