import React from "react";
import { PropTypes } from "prop-types";

const PaymentInfoAbsentComponent = ({
  settingInnerContainerStyle
}) => (
  <div className={settingInnerContainerStyle}>
    <span>No payment method is attached to your account. Please contact our support team.</span>
  </div>
);
PaymentInfoAbsentComponent.propTypes = {
  settingInnerContainerStyle: PropTypes.string.isRequired
};
export default PaymentInfoAbsentComponent;
