// React
import React, { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Third Party Modules
import queryString from 'query-string';

// Custom Modules
import { setTargetLocation } from '../../../utils/targetLocation';
import { checkIfPlanIsValid } from '../../../utils/account';

// Custom Components
import SaveUtm from '../../../pages/SaveUtm';
import Pricing from '../../../pages/Pricing';
import LoginWithPlatform from '../../../pages/LoginWithPlatform';
import SaveToken from '../../../pages/SaveTokenComponent';
import LoginChoosePlatform from '../../../pages/LoginChoosePlatform/index';
import AwaitToken from '../../../pages/AwaitToken/index';
import { isPlatformShopify } from '../../../utils/utils';

const AuthenticationRouterContainer = ({
  children,
  platform,
  setPricing,
  history,
  accessGranted,
  isWooCommerceUserAllowedToProceed
}) => {
  AuthenticationRouterContainer.propTypes = {
    children: PropTypes.object,
    isUserLoggedIn: PropTypes.bool,
    platform: PropTypes.string,
    accessToken: PropTypes.string,
    campaign: PropTypes.array,
    wooCommerceEmail: PropTypes.string,
    wooCommerceShopName: PropTypes.string,
    wooCommerceOwnerName: PropTypes.string
  };

  useEffect(() => {
    const query = history.location && history.location.search, // provided query param
      parsedQuery = queryString.parse(query); // query parsed to an object

    // check if pricing is pre-set
    if (query && checkIfPlanIsValid(parsedQuery.pricing)) {
      setPricing(parsedQuery.pricing);
      history.push("/login");
      // check if user is registering
    } else if (query && parsedQuery.register === "true") {
      history.push("/pricing");
      // login
    }
  }, []);

  const isAuthenticationFinished = () => {
    if (!isPlatformShopify(platform)) {
      /** Boolean coercion is needed as I must check whether I have all of the values, which
       * are required to accept the authentication process as concluded
       *
       * @WARNING - LOGIC MIGHT BE CORRUPTED!!!
       */
      return Boolean(accessGranted && isWooCommerceUserAllowedToProceed);
    } else if (isPlatformShopify(platform)) {
      return accessGranted;
    }
  };

  // Caching initial URI target of User
  setTargetLocation(history.location.pathname);
  return (
    <Switch>
      <Route path='/saveUtm' component={SaveUtm} />
      <Route path='/pricing' component={Pricing} />
      <Route path='/awaitToken' component={AwaitToken} />
      <Route path='/saveToken' component={SaveToken} />
      <Route exact path='/login' component={LoginChoosePlatform} />
      <Route
        path={"/login/(shopify|woocommerce)"}
        component={LoginWithPlatform}
      />
      {isAuthenticationFinished() ? (
        /** children, which is, <OnboardingRouter /> */
        children
      ) : (
        <Redirect to='/login' />
      )}
    </Switch>
  );
};

export default AuthenticationRouterContainer;
