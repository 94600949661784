// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setFacebookVerificationSuccess } from '../../../ducks/onboarding';

import FacebookVerificationSuccessComponent from './FacebookVerificationSuccessComponent';
import { getAdAccountList } from '../../../ducks/facebook';

const mapStateToProps = state => ({


    
});

const mapDispatchToProps = {
    setFacebookVerificationSuccess,
    getAdAccountList
};

const FacebookVerificationSuccess = withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookVerificationSuccessComponent));

export default FacebookVerificationSuccess;