import { connect } from 'react-redux';
import PaymentMethodSelectionContainer from './PaymentMethodSelectionContainer/index';
import { setConfirmedPaymentMethodForEdition } from './../../ducks/payment';

const mapStateToProps = state => ({
	firstAcceptedPaymentMethodType: state.payment.firstAcceptedPaymentMethodType,
    currentlyActivePaymentMethodType: state.payment.currentlyActivePaymentMethodType,
    paymentMethodForEdition: state.payment.paymentMethodForEdition
});

const mapDispatchToProps = {
    setConfirmedPaymentMethodForEdition
  };

const PaymentMethodSelection = connect(mapStateToProps, mapDispatchToProps)(PaymentMethodSelectionContainer);

export default PaymentMethodSelection;