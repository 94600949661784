// React
import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types';

// Third-Party Modules
import queryString from 'query-string';
import { Spin } from 'antd';

// Custom Components
import FacebookAdAccountSelection from '../../FacebookAdAccountSelection';
import AccountFacebookVerificationSuccess from '../../../components/Account/AccountFacebookVerificationSuccess';
import AccountFacebookVerificationFailure from '../../../components/Account/AccountFacebookVerificationFailure';

// Stylesheet
import styles from './index.module.less';

const FacebookVerificationContainer = ({ history, wasFacebookAuthSuccessful, facebookListOfPermissionsToReAsk, initializeFacebookAuthProcess }) => {

    const isLoading = wasFacebookAuthSuccessful === null || (wasFacebookAuthSuccessful === false && facebookListOfPermissionsToReAsk.length > 0);

    useEffect(() => {
        if (history.location && history.location.search) {
            const parsedQuery = queryString.parse(history.location.search);

            if (parsedQuery.code && parsedQuery.code.length) {
                initializeFacebookAuthProcess(parsedQuery.code, 'main');
            }
        }
    }, [history.location]);

    return (
        <Spin spinning={isLoading}>
            {
                wasFacebookAuthSuccessful
                    ? (
                        <div className={styles.wrapper}>
                            <FacebookAdAccountSelection redirectionUrl={'/account/facebookIntegrationSuccess'} />
                        </div>
                    ) : <AccountFacebookVerificationFailure />}
        </Spin>
    )
};

FacebookVerificationContainer.propTypes = {
    history: PropTypes.object.isRequired,
    wasFacebookAuthSuccessful: PropTypes.bool.isRequired, facebookListOfPermissionsToReAsk: PropTypes.array, initializeFacebookAuthProcess: PropTypes.func.isRequired,
};

export default FacebookVerificationContainer;