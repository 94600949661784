import { connect } from 'react-redux';
import LoginCardComponent from './LoginCardComponent';
import { withRouter } from 'react-router-dom';
import { checkIfDomainIsInDatabase } from '../../ducks/auth';

const mapStateToProps = state => ({
    hasPricingPlanBeenSelected: state.pricing.planId
});

const mapDispatchToProps = {
    checkIfDomainIsInDatabase
}

const LoginCard = withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginCardComponent));

export default LoginCard;
