export default Object.freeze({
  LOGIN_STORE_NAME: 'loginStoreName',
  START_PLAN: 'startPlan',
  GOOGLE_ACCOUNT_ADS_CREATED: 'googleAccountAdsCreated',
  GOOGLE_ACCOUNT_MC_CREATED: 'googleAccountMcCreated',
  CAMPAIGN_TO_SAVE: 'campaignToSave',
  RATE_MODAL_SHOWED: 'rateModalShowed',
  PLATFORM: 'platform',
  USER_OBJECT: 'user',
  ONBOARDING_STEP_GOOGLE: 'onboardingStepGoogle'
});