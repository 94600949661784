// Polyfill must be the first line in src/index.js
import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';

// Third Party Stylesheet
import 'antd/dist/antd.less';

// Stylesheet
import './styles/index.less';

import { Provider } from 'react-redux';
import store, { history, historyListener } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';
import { ErrorTrackingModule } from './module/ErrorTrackingModule';
import { UserTracerModule } from './module/UserTracerModule';
import { initAmplitude } from './utils/amplitude';
import { initIntercom } from './utils/intercom';
import { initAnalytics } from './utils/analytics';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './store';
config.DEV && console.log(`App started with version: ${config.VERSION}`)

ErrorTrackingModule.init();
UserTracerModule.init();
initAmplitude();
initIntercom();
initAnalytics();

historyListener(window.location);
history.listen(historyListener);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} >
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();