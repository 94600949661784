// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setSelectedAdChannel, setIsChannelSelectionLoading } from '../../../ducks/onboarding';
import { initializeFacebookAuthProcess } from '../../../ducks/auth';

import OnboardingRouterGatewayContainer from './OnboardingRouterGatewayContainer';

const mapStateToProps = state => ({
    selectedAdChannel: state.onboarding.selectedAdChannel,
    wasFacebookAuthSuccessful: state.auth.wasFacebookAuthSuccessful,
    facebookListOfPermissionsToReAsk: state.auth.facebookListOfPermissionsToReAsk,
    isChannelSelectionLoading: state.onboarding.isChannelSelectionLoading
});

const mapDispatchToProps = {
    setSelectedAdChannel,
    initializeFacebookAuthProcess,
    setIsChannelSelectionLoading
};

const OnboardingRouterGateway = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingRouterGatewayContainer));

export default OnboardingRouterGateway;