import React from "react";
import PropTypes from "prop-types";
import VendorLogoComponent from "../../VendorLogoComponent";
import style from './index.module.less';
import PaymentCardNumber from "../../PaymentCardNumber";

const PaymentCardInfoPresentComponent = ({
  LinkButton,
  settingInnerContainerStyle,
  creditCardVendorNameForSummary,
}) => (
  <div className={settingInnerContainerStyle}>
    <div className={style.cardDetailsContainer}>
      <PaymentCardNumber />
      <VendorLogoComponent vendorName={creditCardVendorNameForSummary}/>
    </div>
      {LinkButton}
  </div>
);

PaymentCardInfoPresentComponent.propTypes = {
  LinkButton: PropTypes.object.isRequired,
  settingInnerContainerStyle: PropTypes.string.isRequired,
  creditCardVendorNameForSummary: PropTypes.string,
  payPalEmail: PropTypes.string,
};

export default PaymentCardInfoPresentComponent;
