// Actions

const SET_SELECTED_AD_CHANNEL = 'SET_SELECTED_AD_CHANNEL';
const SET_SELECTION_SUCCESS = 'SET_SELECTION_SUCCESS';
const SET_FACEBOOK_VERIFICATION_SUCCESS = 'SET_VERIFICATION_SUCCESS';
const SET_ONBOARDING_FACEBOOK_CREATE_CAMPAIGN_FIELD = 'SET_ONBOARDING_FACEBOOK_CREATE_CAMPAIGN_FIELD';
const SET_ONBOARDING_GOOGLE_CREATE_CAMPAIGN_FIELD = 'SET_ONBOARDING_GOOGLE_CREATE_CAMPAIGN_FIELD';
const CLEAR_ONBOARDING_STATE_BY_CHANNEL = 'CLEAR_ONBOARDING_STATE_BY_CHANNEL';
const SET_IS_CHANNEL_SELECTION_LOADING = 'SET_IS_CHANNEL_SELECTION_LOADING';

// Reducer

const initialState = {
    selectedAdChannel: null,
    isChannelSelectionLoading: false,
    google: {
        selectionSuccess: null,
        createCampaign: {
            budget: null,
            country: null
        }
    },
    facebook: {
        verificationSuccess: null,
        createCampaign: {
            name: null,
            country: null
        }
    }
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SET_SELECTED_AD_CHANNEL:
            return {
                ...state,
                selectedAdChannel: action.selection,
            }
        case SET_SELECTION_SUCCESS:
            return {
                ...state,
                google: {
                    ...state.google,
                    selectionSuccess: action.value
                }
            }
        case SET_FACEBOOK_VERIFICATION_SUCCESS:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    verificationSuccess: action.value
                }
            }
        case SET_ONBOARDING_FACEBOOK_CREATE_CAMPAIGN_FIELD:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    createCampaign: {
                        ...state.facebook.createCampaign,
                        [action.field]: action.value
                    }
                }
            }
        case SET_ONBOARDING_GOOGLE_CREATE_CAMPAIGN_FIELD:
            return {
                ...state,
                google: {
                    ...state.google,
                    createCampaign: {
                        ...state.google.createCampaign,
                        [action.field]: action.value
                    }
                }
            }
        case CLEAR_ONBOARDING_STATE_BY_CHANNEL:
            return {
                ...state,
                [action.channel]: initialState[action.channel]
            }
        case SET_IS_CHANNEL_SELECTION_LOADING:
            return {
                ...state,
                isChannelSelectionLoading: action.value
            }

        default: return state;
    }
}
export default reducer;

// Action Creators

const actionSetSelectedAdChannel = selection => ({ type: SET_SELECTED_AD_CHANNEL, selection });
const actionSetSelectionSuccess = value => ({ type: SET_SELECTION_SUCCESS, value });
const actionSetFacebookVerificationSuccess = value => ({ type: SET_FACEBOOK_VERIFICATION_SUCCESS, value });
const actionSetOnboardingFacebookCreateCampaignField = (field, value) => ({ type: SET_ONBOARDING_FACEBOOK_CREATE_CAMPAIGN_FIELD, field, value });
const actionSetOnboardingGoogleCreateCampaignField = (field, value) => ({ type: SET_ONBOARDING_GOOGLE_CREATE_CAMPAIGN_FIELD, field, value });
const actionClearOnboardingStateByChannel = channel => ({ type: CLEAR_ONBOARDING_STATE_BY_CHANNEL, channel });
const actionSetIsChannelSelectionLoading = value => ({ type:SET_IS_CHANNEL_SELECTION_LOADING, value });

// Methods
export const setSelectedAdChannel = selection => dispatch => dispatch(actionSetSelectedAdChannel(selection));

export const setSelectionSuccess = value => dispatch => dispatch(actionSetSelectionSuccess(value));

export const setFacebookVerificationSuccess = value => dispatch => dispatch(actionSetFacebookVerificationSuccess(value));

export const setOnboardingFacebookCreateCampaignField = (field, value) => dispatch => dispatch(actionSetOnboardingFacebookCreateCampaignField(field, value));

export const setOnboardingGoogleCreateCampaignField = (field, value) => dispatch => dispatch(actionSetOnboardingGoogleCreateCampaignField(field, value));

export const clearOnboardingStateByChannel = channel => dispatch => dispatch(actionClearOnboardingStateByChannel(channel));

export const setIsChannelSelectionLoading = value => dispatch => dispatch(actionSetIsChannelSelectionLoading(value));