export const amplitudeEvents = {
  APP_OPEN_APP: 'App: Open App',
  APP_CAMPAIGN_REDIRECT: 'App: Campaign Redirect',
  ONBOARDING_SELECT_PLAN: 'Onboarding: Select Plan',
  ONBOARDING_SELECT_ACCOUNT_GOOGLE: 'Onboarding: Select Account Google',
  ONBOARDING_SELECT_ACCOUNT_HERALDBEE: 'Onboarding: Select Account Heraldbee',
  ONBOARDING_SET_COUNTRY: 'Onboarding: Set Country',
  ONBOARDING_SET_BUDGET: 'Onboarding: Set Budget',
  ONBOARDING_SET_CREDIT_CARD: 'Onboarding: Set Credit Card',
  ONBOARDING_FINISHED: 'Onboarding: Finished',
  CAMPAIGNS_OPEN_CAMAPIGN_LIST_VIEW: 'Campaigns: Open Campaign List View',
  CAMPAIGNS_CREATE_CAMPAIGN: 'Campaigns: Create Campaign',
  CAMPAIGNS_EDIT_CAMPAIGN: 'Campaigns: Edit Campaign',
  CAMPAIGNS_TOGGLE_STATUS_CAMPAIGN: 'Campaigns: Toggle Status Campaign',
  FAQ_OPEN_FAQ_VIEW: 'FAQ: Open FAQ View',
}