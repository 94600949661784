// React
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

// Custom Components
import MainCreateCampaignGateway from '../../../../components/SharedCreateCampaign/MainCreateCampaignGateway';
import MainCreateCampaignGoogleRouter from '../../MainCreateCampaignGoogleRouter';
import MainCreateCampaignFacebookRouter from '../../MainCreateCampaignFacebookRouter';
import { PropTypes } from 'prop-types';

const MainCreateCampaignRouterContainer = ({ isUserConnectedToFacebook, fetchCampaigns }) => {
    useEffect(() => {
        return () => {
            fetchCampaigns();
        };
    }, []);

    return (
        <Switch>
            <Route
                exact path="/campaigns/addCampaign"
                component={() => <MainCreateCampaignGateway isUserConnectedToFacebook={isUserConnectedToFacebook}/>}
            />
            {/** GOOGLE routes */}
            <Route
                path="/campaigns/addCampaign/google"
                component={() => <MainCreateCampaignGoogleRouter />}
            />
            {/** FACEBOOK routes */}
            {
                true /** isUserConnectedToFacebook */
                    ? (
                        <Route
                            path="/campaigns/addCampaign/facebook"
                            component={() => <MainCreateCampaignFacebookRouter />}
                        />
                    ) : null
            }
        </Switch>
    );
};

MainCreateCampaignRouterContainer.propTypes = {
    isUserConnectedToFacebook: PropTypes.bool.isRequired,
    fetchCampaigns: PropTypes.func.isRequired,
};

export default MainCreateCampaignRouterContainer;