import hostedFields from "braintree-web/hosted-fields";
import { destructureEventObject } from "./internalHelpers";
import { generateStyleAlteringMethods } from "./generateStyleAlteringMethods";
import { selectDomElement } from "../../utils/utils";
import { translateCardVendorName } from "../../utils/mainApp";



export function initializeHostedFieldsClient({ setDetectedCardVendorName, setCardRejectedErrorText, styleInitializationObject, style, handleConfirmationButtonStatus, handleErrorTextClearing, handleErrorTextSetting }) {
	return new Promise(function (resolve) {
		hostedFields.create(
			styleInitializationObject,
			function braintreeHostedFieldsCreationCallback(
				hostedFieldsInstanceCreationError,
				hostedFieldsInstance
			) {
				if (hostedFieldsInstanceCreationError) {
					/** Network error, display deadBee, log error message in devtools, until there's some reporting service implemented */
					hostedFieldsInstanceCreationError = 'Error while creating Hosted Fields instance';
					return resolve({ value: null, error: hostedFieldsInstanceCreationError });
				}

				const { moveLabelUp,
					moveLabelDown,
					setOnFocusStyleForHostedField,
					setOnErrorStyleForHostedField,
					setOnBlurStylingForHostedField
				} = generateStyleAlteringMethods({ style });


				const handleValidityDetermination = ({
					hostedFieldLabel,
					hostedFieldInput,
					hostedFieldEventEmitterNode,
					hostedFieldEventEmitterName,
					hostedFieldsList,
					currentHostedFieldsState,
					aListOfAllHostedFieldsNames,
					findLabel
				}) => {
					const areAllHostedFieldsValid = Object.keys(hostedFieldsList).every(
						hostedField => {
							/** Iterate over all fields, adjusting their styles and clearing displayed error messages, if necessary */
							if (hostedFieldsList[hostedField].isValid) {
								const hostedFieldEventEmitterNode =
									aListOfAllHostedFieldsNames[hostedField];
								const {
									container: { id: hostedFieldInputId }
								} = hostedFieldEventEmitterNode;
								const hostedFieldLabel = findLabel({
									hostedFieldEventEmitterNode
								});
								const hostedFieldInput = selectDomElement(`#${hostedFieldInputId}`);
								setOnBlurStylingForHostedField({
									hostedFieldLabel,
									hostedFieldInput
								});
								handleErrorTextClearing({
									hostedFieldEventEmitterName: hostedField
								});
							}
							return hostedFieldsList[hostedField].isValid;
						}
					);

					if (!areAllHostedFieldsValid) {
						handleConfirmationButtonStatus({ shouldConfirmationButtonBeDisabled: true });
					}
					if (hostedFieldEventEmitterNode.isValid || areAllHostedFieldsValid) {
						/** Iterate over all of the inputs, confirming their validity */
						if (areAllHostedFieldsValid) {
							handleConfirmationButtonStatus({ shouldConfirmationButtonBeDisabled: false })
							setCardRejectedErrorText('');
						}
					} else if (hostedFieldEventEmitterNode.isPotentiallyValid) {
						handleConfirmationButtonStatus({ shouldConfirmationButtonBeDisabled: true })

						if (currentHostedFieldsState === "blur") {
							handleErrorTextSetting({ hostedFieldEventEmitterName });
							setOnErrorStyleForHostedField({
								hostedFieldLabel,
								hostedFieldInput
							});
							handleConfirmationButtonStatus({ shouldConfirmationButtonBeDisabled: true });
						} else {
							setOnFocusStyleForHostedField({
								hostedFieldLabel,
								hostedFieldInput
							});
							handleErrorTextClearing({
								hostedFieldEventEmitterName
							});
						}
					} else {
						handleErrorTextSetting({ hostedFieldEventEmitterName });
						setOnErrorStyleForHostedField({
							hostedFieldLabel,
							hostedFieldInput
						});
						handleConfirmationButtonStatus({ shouldConfirmationButtonBeDisabled: true });
					}
				};

				/** Event handlers  */

				const handleOnBlurEvent = ({
					isEmpty,
					isFocused,
					isValid,
					hostedFieldLabel,
					hostedFieldInput
				}) => {
					/** Check the state of an HostedFieldInput after onBlur, and apply styles accordingly */
					if (isEmpty === false && isFocused === false && isValid === false) {
						setOnErrorStyleForHostedField({
							hostedFieldLabel,
							hostedFieldInput
						});
					} else if (isEmpty === false && isFocused === false && isValid === true) {
						setOnBlurStylingForHostedField({
							hostedFieldLabel,
							hostedFieldInput
						});
					} else {
						setOnBlurStylingForHostedField({
							hostedFieldLabel,
							hostedFieldInput
						});
						moveLabelDown({ hostedFieldLabel });
					}
				};

				hostedFieldsInstance.on("focus", function (event) {
					const {
						hostedFieldLabel,
						hostedFieldInput,
						hostedFieldEventEmitterName
					} = destructureEventObject({
						event
					});

					setOnFocusStyleForHostedField({
						hostedFieldInput,
						hostedFieldLabel
					});
					handleErrorTextClearing({
						hostedFieldEventEmitterName
					});
					moveLabelUp({ hostedFieldLabel });
				});

				hostedFieldsInstance.on("blur", function (event) {
					const {
						hostedFieldLabel,
						hostedFieldInput,
						hostedFieldEventEmitterNode,
						hostedFieldEventEmitterName,
						aListOfAllHostedFieldsNames,
						findLabel
					} = destructureEventObject({
						event
					});
					const {
						isEmpty,
						isFocused,
						isValid
					} = hostedFieldEventEmitterNode;
					const {
						fields: hostedFieldsList
					} = hostedFieldsInstance.getState();
                    if (hostedFieldInput && hostedFieldLabel) {
                        handleOnBlurEvent({
                            isEmpty,
                            isFocused,
                            isValid,
                            hostedFieldLabel,
                            hostedFieldInput
                        });
                        handleValidityDetermination({
                            hostedFieldLabel,
                            hostedFieldInput,
                            hostedFieldsList,
                            hostedFieldEventEmitterNode,
                            hostedFieldEventEmitterName,
                            currentHostedFieldsState: "blur",
                            aListOfAllHostedFieldsNames,
                            findLabel
                        });
                    } 
				});

				hostedFieldsInstance.on("validityChange", function (event) {
					const {
						hostedFieldLabel,
						hostedFieldInput,
						hostedFieldEventEmitterNode,
						hostedFieldEventEmitterName,
						aListOfAllHostedFieldsNames,
						findLabel
					} = destructureEventObject({
						event
					});
					const {
						fields: hostedFieldsList
					} = hostedFieldsInstance.getState();
					handleValidityDetermination({
						hostedFieldLabel,
						hostedFieldInput,
						hostedFieldsList,
						hostedFieldEventEmitterNode,
						hostedFieldEventEmitterName,
						aListOfAllHostedFieldsNames,
						findLabel
					});
				});

				hostedFieldsInstance.on("cardTypeChange", function (event) {
					if (event.cards.length === 1) {
						const cardVendor = translateCardVendorName({ cardVendorName: event.cards[0].type });

						setDetectedCardVendorName(cardVendor);
					} else {
						setDetectedCardVendorName(null);
					}
				});

				return resolve({ value: hostedFieldsInstance, error: null });
			}
		);
	});
};