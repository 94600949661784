// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import FacebookAdAccountRequestSuccessfulComponent from './FacebookAdAccountRequestSuccessfulComponent';
import { setFacebookVerificationSuccess } from '../../ducks/onboarding';

const mapStateToProps = state => ({

}); 

const mapDispatchToProps = {
    setFacebookVerificationSuccess

};

const FacebookAdAccountRequestSuccessful = withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookAdAccountRequestSuccessfulComponent));

export default FacebookAdAccountRequestSuccessful;