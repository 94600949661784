// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// selectors
import { ownerNameSelector, storeNameSelector, emailSelector } from '../../utils/reduxSelectors';

import AccountWooCommerceDataComponent from './AccountWooCommerceDataComponent';

const mapStateToProps = state => ({
    ownerName: ownerNameSelector(state),
    storeName: storeNameSelector(state),
    email: emailSelector(state)
});

const AccountWooCommerceData = withRouter(connect(mapStateToProps)(AccountWooCommerceDataComponent));

export default AccountWooCommerceData;