import React from 'react';
import { useEffect } from 'react';
import { spinnerStyle, acceptedPaymentMethods } from '../../../styles/constants';
import { initializePayPalButtons } from '../../../module/BraintreeModule/initializePayPal';
import { useState } from 'react';
import { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Spin } from 'antd';
import braintreeClientCreator from "braintree-web/client";
import { initializeBraintreeClient } from '../../../module/BraintreeModule/initializeBraintreeClient';
import MaterialButtonComponent from './../../../components/MaterialComponents/MaterialButtonComponent/index';
import PayPalButtonsComponent from './../../../components/PayPalButtonsComponent/index';
import style from './index.module.less';

const ChangePayPalAuthorizationContainer = ({ braintreeToken, fetchBraintreeToken, validatePayPalButtonInstance, createCustomerWithPaymentAuthorization, validateBraintreeClientInstanceEnvelope, returnToPaymentMethodSelection, history, changePaymentAuth, subscriptionId, shopId , clearAlreadyCachedPaymentCardDetails, actionSetShouldSummaryKnowOfCardUpdate, summaryShouldKnowOfCardUpdate, payPalButtonsTopMarginStyle}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [authorizationWasRejected, setAuthorizationWasRejected] = useState(
        false
    );
    const [payPalButtonsInitialized, setPayPalButtonsInitialized] = useState(
        false
    );


    const handlePayPalSuccessfulAuthorization = useCallback(
        async ({ tokenizedPayPalAccount }) => {
            setIsLoading(true);
            const { nonce: clientNonce } = tokenizedPayPalAccount;

            createCustomerWithPaymentAuthorization(clientNonce);
            clearAlreadyCachedPaymentCardDetails();
            await changePaymentAuth({
                subscriptionId,
                clientNonce,
                shopId,
                paymentMethod: acceptedPaymentMethods.payPal
            });
            actionSetShouldSummaryKnowOfCardUpdate(true);
            history.push('/account');
        },
        [
            createCustomerWithPaymentAuthorization
        ]
    );
    const initializePayPalWithBraintree = useCallback(async () => {
        const {
            value: braintreeClientInstance,
            error: braintreeClientInstanceCreationError
        } = await initializeBraintreeClient({
            braintreeClientCreator,
            braintreeToken
        });

        const {
            validatedBraintreeClientInstance
        } = await validateBraintreeClientInstanceEnvelope({
            envelope: {
                braintreeClientInstance,
                braintreeClientInstanceCreationError
            }
        });

        const { error: payPalButtonsCreationError } = await initializePayPalButtons(
            {
                validatedBraintreeClientInstance,
                handlePayPalSuccessfulAuthorization,
                setAuthorizationWasRejected,
                setPayPalButtonsInitialized,
                setIsLoading
            }
        );
        validatePayPalButtonInstance({
            envelope: { error: payPalButtonsCreationError }
        });

        setPayPalButtonsInitialized(true);
        setIsLoading(false);
    }, [
        braintreeToken,
        handlePayPalSuccessfulAuthorization,
        setPayPalButtonsInitialized,
        validateBraintreeClientInstanceEnvelope,
        validatePayPalButtonInstance
    ]);

    useEffect(() => {
        const shouldPayPalButtonsBeInitialized =
            braintreeToken &&
            !payPalButtonsInitialized && !summaryShouldKnowOfCardUpdate;
        if (shouldPayPalButtonsBeInitialized) {
            setIsLoading(true);
            initializePayPalWithBraintree();
        } else {
            setIsLoading(false);
        }
        if (!braintreeToken) {
            fetchBraintreeToken();
        }
    }, [
        braintreeToken,
        fetchBraintreeToken,
        initializePayPalWithBraintree,
        payPalButtonsInitialized
    ]);

    return (
        <Spin spinning={isLoading} size="large" style={spinnerStyle}>
            <div className={style.changePayPalContainer}>
                <PayPalButtonsComponent
                authorizationWasRejected={authorizationWasRejected}
                payPalButtonsTopMarginStyle={payPalButtonsTopMarginStyle}
                />
                <div className={style.navigationRow}>
                    <MaterialButtonComponent
                        text="BACK"
                        type="secondary"
                        size="xSmall"
                        onClick={returnToPaymentMethodSelection}
                    />
                </div></div>
        </Spin>
    );
};

export default ChangePayPalAuthorizationContainer;

ChangePayPalAuthorizationContainer.propTypes = {
    returnToPaymentMethodSelection: PropTypes.func.isRequired,
    braintreeToken: PropTypes.string,
    fetchBraintreeToken: PropTypes.func.isRequired,
    validatePayPalButtonInstance: PropTypes.func.isRequired,
    createCustomerWithPaymentAuthorization: PropTypes.func.isRequired,
    validateBraintreeClientInstanceEnvelope: PropTypes.func.isRequired,
    shopId: PropTypes.string.isRequired,
    clearAlreadyCachedPaymentCardDetails: PropTypes.func.isRequired,
    payPalButtonsTopMarginStyle: PropTypes.string.isRequired,
};

