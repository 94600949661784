// React
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Spin } from 'antd';

// Custom Components
import Avatar from '../../Avatar';
import MaterialButtonComponent from '../../MaterialComponents/MaterialButtonComponent';

// Stylesheet
import styles from './index.module.less';

const AvatarPickerComponent = ({ avatarUploadError, clearAvatarError, uploadUserAvatar, isLoading }) => {
    const inputElement = useRef(null);

    const onChangeClick = () => {
        if (avatarUploadError) {
            clearAvatarError();
        };
        inputElement.current.click();
    };

    const onInputChange = e => {
        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('avatar', file);
        uploadUserAvatar(formData);
    };

    return (
        <section className={styles.container}>
            <Avatar size="large" />
            <div className={styles.button}>
                <Spin spinning={isLoading}>
                    <MaterialButtonComponent 
                        type="primary"
                        size={'xSmall'}
                        text={'CHANGE'}
                        onClick={onChangeClick}
                    />
                </Spin>
                <input 
                    className={styles.input} 
                    ref={inputElement} 
                    type="file" 
                    onChange={onInputChange} 
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                />
                <p className={avatarUploadError ? styles.buttonErrorActive : styles.buttonErrorInactive}>{avatarUploadError}</p>
            </div>
        </section>
    );
};

AvatarPickerComponent.propTypes = {
    avatarUploadError: PropTypes.string,
    uploadUserAvatar: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    clearAvatarError: PropTypes.func.isRequired
};

export default AvatarPickerComponent;