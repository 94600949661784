// React
import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";

// Third-Party Modules
import FolderOpen from "@material-ui/icons/FolderOpen";
import SwapVert from "@material-ui/icons/SwapVert";
import clsx from "clsx";

// Custom Modules
import { googleCodeToLocation } from "../../../constants/googleAdsLocations";
import { translateCampaignCollection } from "../../../utils/campaigns";

// Custom Components
import MaterialButtonComponent from "../../../components/MaterialComponents/MaterialButtonComponent";
import MaterialNativeSelectComponent from "../../../components/MaterialComponents/MaterialNativeSelectComponent";
import CampaignSearchInputComponent from "../../../components/Campaigns/CampaignSearchInputComponent";
import CampaignGoogleItemComponent from "../../../components/Campaigns/CampaignGoogleItemComponent";
import CampaignFacebookItemComponent from "../../../components/Campaigns/CampaignFacebookItemComponent";

// Stylesheet
import styles from "./index.module.less";

const CampaignListContainer = ({
  campaignsList,
  platform,
  history,
  areConversionsActive,
  wasAgencyAccessGranted,
  findOutIfUserHasGrantedAgencyAccess,
  isUserConnectedWithFacebook
}) => {
  useLayoutEffect(() => {
    if (isUserConnectedWithFacebook) {
      findOutIfUserHasGrantedAgencyAccess();
    }
  }, [findOutIfUserHasGrantedAgencyAccess]);

  const translatedCampaigns = translateCampaignCollection(campaignsList);

  const sortingOptions = {
    newest: "NEWEST",
    oldest: "OLDEST"
  };

  const filterOptions = {
    ALL: "ALL",
    ACTIVE: "ENABLED",
    PAUSED: "PAUSED",
    PENDING: "PENDING"
  };

  const [filterObject, setFilterObject] = useState({
    filterOption: filterOptions["ALL"],
    searchText: "",
    sortOption: sortingOptions.newest
  });

  const onClickAddCampaign = () => {
    history.push("/campaigns/addCampaign");
  };

  const handleChange = key => event => {
    setFilterObject({ ...filterObject, [key]: event.target.value });
  };

  const handleFiltering = ({ filterOption, searchText, sortOption }) => {
    if (filterOption === "ALL") {
      return translatedCampaigns
        .filter(element =>
          element.name.toLowerCase().includes(searchText.toLowerCase())
        )
        .sort((currentCampaign, nextCampaign) => {
          return sortOption === sortingOptions.newest
            ? new Date(nextCampaign.startTime) -
                new Date(currentCampaign.startTime)
            : new Date(currentCampaign.startTime) -
                new Date(nextCampaign.startTime);
        });
    } else {
      return translatedCampaigns
        .filter(
          element =>
            element.name.toLowerCase().includes(searchText.toLowerCase()) &&
            element.status === filterOptions[filterOption]
        )
        .sort((currentCampaign, nextCampaign) => {
          return sortOption === sortingOptions.newest
            ? new Date(nextCampaign.startTime) -
                new Date(currentCampaign.startTime)
            : new Date(currentCampaign.startTime) -
                new Date(nextCampaign.startTime);
        });
    }
  };

  const showFilterResults = () => {
    const currentCampaigns = handleFiltering(filterObject);
    const shouldShowCampaigns = translatedCampaigns.length !== 0;

    if (shouldShowCampaigns) {
      return currentCampaigns.map(campaign => {
        const date = new Date(campaign.startTime);
        const day = date.getDay();
        const month = date.getMonth();
        const year = date.getFullYear();
        if (campaign.channel === "google") {
          return (
            <CampaignGoogleItemComponent
              key={campaign.id}
              linkUrl={`/campaigns/google/${campaign.id}`}
              campaignName={campaign.name}
              campaignStatus={campaign.status}
              country={googleCodeToLocation[campaign.salesCountryCode]}
              budget={campaign.dailyBudget}
              createdAt={`${day}-${month}-${year}`}
              click={campaign.clicks}
              impression={campaign.impressions}
              cost={campaign.cost}
              showConversion={
                platform === "shopify" && areConversionsActive ? true : false
              }
              soldProducts={campaign.soldProducts}
              earnedMoney={campaign.earnedMoney}
            />
          );
        } else if (campaign.channel === "facebook") {
          const date = new Date(campaign.startTime);
          const day = date.getDay();
          const month = date.getMonth();
          const year = date.getFullYear();
          return (
            <CampaignFacebookItemComponent
              wasAgencyAccessGranted={wasAgencyAccessGranted}
              key={campaign.id}
              linkUrl={`/campaigns/facebook/${campaign.id}`}
              campaignName={campaign.name}
              campaignStatus="PENDING"
              country={googleCodeToLocation[campaign.salesCountryCode]}
              createdAt={`${day}-${month}-${year}`}
            />
          );
        }
      });
    } else {
      return (
        <div className={styles.noCampaignsView}>
          <div>
            <p className={styles.largeText}>No records found</p>
            <p className={styles.smallText}>
              We couldn't find any results for type:{" "}
              <strong>{filterObject.filterOption}</strong> and campaign name:{" "}
              <strong>{filterObject.searchText}</strong>.
            </p>
            <p className={styles.smallText}>Please try again.</p>
            <p className={clsx(styles.smallText, styles.listHeaderText)}>
              Suggestions:
            </p>
            <ul className={styles.suggestionList}>
              <li>Make Sure all words are spelled correctly</li>
              <li>Try different keywords</li>
              <li>Try more general keywords</li>
            </ul>
          </div>
          <p className={clsx(styles.smallText, styles.helpText)}>
            If you have questions,{" "}
            <span className={clsx(styles.openIntercomButton, "OPEN_INTERCOM")}>
              contact us.
            </span>
          </p>
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <p className={styles.headerTitle}>
          <strong>Campaign list</strong>
        </p>
        <span className={styles.headerControls}>
          <span className={styles.headerControlsSort}>
            <div className={styles.select}>
              <SwapVert className={styles.sortIcon} />
              <p className={styles.selectText}>SORT</p>
              <MaterialNativeSelectComponent
                onChange={handleChange("sortOption")}
                optionList={Object.values(sortingOptions)}
              />
            </div>
            <div className={styles.select}>
              <FolderOpen className={styles.folderIcon} />
              <p className={styles.selectText}>TYPE</p>
              <MaterialNativeSelectComponent
                onChange={handleChange("filterOption")}
                optionList={Object.keys(filterOptions)}
              />
            </div>
          </span>
          <span className={styles.headerControlsSearch}>
            <CampaignSearchInputComponent
              onChange={handleChange("searchText")}
            />
          </span>
        </span>
        <div className={styles.headerButton}>
          <MaterialButtonComponent
            size="dynamic"
            text="ADD CAMPAIGN"
            type="primary"
            onClick={onClickAddCampaign}
          />
        </div>
      </header>
      {translatedCampaigns.length === 0 ? (
        <div className={styles.noCampaignsView}>
          <div>
            <p className={styles.largeText}>You don't have any campaign</p>
            <p className={styles.smallText}>
              Add new campaign, and start advertising your products:).
              <br />
              <br />
              <br />
            </p>
          </div>
          <p className={clsx(styles.smallText, styles.helpText)}>
            If you have questions,{" "}
            <span className={clsx(styles.openIntercomButton, "OPEN_INTERCOM")}>
              contact us.
            </span>
          </p>
        </div>
      ) : (
        showFilterResults()
      )}
    </div>
  );
};

CampaignListContainer.propTypes = {
  campaignsList: PropTypes.object.isRequired,
  platform: PropTypes.oneOf(["woocommerce", "shopify"])
};
export default CampaignListContainer;
