import { fade } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { colorPalette, breakpoints } from '../../../styles/constants';


export const materialStyleGenerator = ({ drawerWidth }) => makeStyles(theme => ({
	root: {
		display: "flex",
		"& .MuiAppBar-positionFixed": {
			left: 0,
			width: "100vw",
			zIndex: 1000
		},
		"& .MuiPaper-elevation4": {
			boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.05)",
			flexDirection: "row",
		},
		"& .MuiListItem-root": {
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center"
		},
		"& .makeStyles-drawerHeader-9": {
			minHeight: "60px"
		},
		"& .MuiListItemIcon-root": {
			display: "flex",
			justifyContent: "center",
			color: colorPalette.lightGray
		},
		"& .MuiListItemText-root": {
			fontSize: "12px",
			textAlign: "center"
		},
		"& .MuiListItemText-primary": {
			fontSize: "12px",
			lineHeight:1,
			color: colorPalette.secondaryOrange
		},
		"& .MuiToolbar-regular": {
			minHeight: "60px"
		},
		"& .MuiToolbar-gutters": {
			width: "100%",
			paddingLeft: "0",
			paddingRight: "0"
		},
		"& .MuiIconButton-root": {
			marginRight: "0"
		},
		"& .MuiList-root": {
			marginTop: "20px"
		},
		"& .MuiDrawer-paper": {
			overflow: "unset",
			boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
		},
		"& .MuiSvgIcon-colorPrimary": {
			color: colorPalette.secondaryOrange
		},
		"& .MuiIconButton-colorPrimary": {
			color: colorPalette.primaryBrown
		},
		"& .MuiButtonBase-root": {
			color: colorPalette.lightGray
		},
		"& .MuiTypography-body1": {
			fontSize: "12px",
			lineHeight: 1,
		},
		"& .MuiTypography-body2": {
			fontSize: "12px"
		},
		"& .MuiDrawer-paperAnchorDockedLeft": {
			border: "unset"
		}
	},
	appBar: {
		width: "100%",
		color: colorPalette.primaryBrown,
		backgroundColor: colorPalette.white,
		height: "60px",
	},
	appBarTall: {
		width: "100%",
		color: colorPalette.primaryBrown,
		backgroundColor: colorPalette.white,
		height: "88px",
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth * 2}px`,
		transition: theme.transitions.create(["all"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	hide: {
		opacity: 0
	},
	show: {
		opacity: 1
	},
	drawer: {
		position: 'absolute',
		width: "88px",
		flexShrink: 0
	},
	drawerPaper: {
		display: "flex",
		flexDirection: "column",
		width: "88px",
	},
	drawerHeader: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		padding: "0 8px",
		...theme.mixins.toolbar,
		justifyContent: "flex-start",
		height: "60px",
		width: "88px",
		marginLeft: "0",
		paddingLeft: "0",
		paddingRight: "0"
	},
	content: {
		backgroundColor: colorPalette.backgroundGray,
		marginTop: "60px",
		width: "100vw",
		height: "100%",
		marginLeft: 0,
		padding: 0,
		[theme.breakpoints.up("sm")]: {
			marginLeft: "88px",
			marginTop: "88px"
		}
	},
	grow: {
		flexGrow: 1
	},
	title: {
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block"
		}
	},
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: fade(theme.palette.common.white, 0.25)
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(3),
			width: "auto"
		}
	},
	searchIcon: {
		width: theme.spacing(7),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	inputRoot: {
		color: "inherit"
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: 200
		}
	},
	sectionDesktop: {
		display: "none",
		marginRight: "5px",
		[theme.breakpoints.up("md")]: {
			display: "flex",
			marginRight: "30px"
		}
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	}
}))


export const breakpoint = `(min-width:${breakpoints.sl})`