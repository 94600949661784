import config from '../../config';
import { HeraldbeeApi } from '../../api/HeraldbeeApi';
import { setError } from '../app';

// Actions

const SET_ARE_CONVERSIONS_ACTIVE = 'SET_ARE_CONVERSIONS_ACTIVE';

// Reducer

const initialState = {
    areConversionsActive: false,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SET_ARE_CONVERSIONS_ACTIVE:
            return {
                ...state,
                areConversionsActive: action.value
            };

        default: return state;
    }
};
export default reducer;

// Action Creators

const actionSetAreConversionsActive = value => ({ type: SET_ARE_CONVERSIONS_ACTIVE, value });
const actionBlank = () => ({ type: null });

// Methods

export const checkIfConversionsActive = () => async dispatch => {
    const hbApi = HeraldbeeApi({
        baseUrl: config.API_URL
    });

    const { value, error } = await hbApi.getConversionsStatus();
    if (error) {
        return dispatch(setError(error));
    } else if (value) {
        if (value.error) {
            return dispatch(setError(value.error));
        } else {
            dispatch(actionSetAreConversionsActive(value.value));
        };
    };
};

export const setAreConversionsActiveStatus = valueToSet => async dispatch => {
    const hbApi = HeraldbeeApi({
        baseUrl: config.API_URL
    });

    const { value, error } = await hbApi.setConversionsStatus(valueToSet);
    if (error) {
        return dispatch(setError(error));
    } else if (value) {
        if (value.error) {
            return dispatch(setError(value.error));
        } else {
            dispatch(actionSetAreConversionsActive(value.value));
        };
    };
};


export const getConversionScriptTag = () => async dispatch => {
    const hbApi = HeraldbeeApi({
        baseUrl: config.API_URL
    });

    const { value, error } = await hbApi.getConversionsScript();

    if (error) {
        dispatch(setError(error));
        return ""
    } else {
        dispatch(actionBlank())
        return value;
    }
}