// React
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Custom Components
import ChooseNameMain from '../../../../pages/MainCreateCampaign/Facebook/ChooseNameMain';
import ChooseCountryMain from '../../../../pages/MainCreateCampaign/Facebook/ChooseCountryMain';
import FinishMain from '../../../../pages/MainCreateCampaign/Facebook/FinishMain';

const MainCreateCampaignFacebookContainer = () =>
    <Switch>
        <Route
            exact path ="/campaigns/addCampaign/facebook"
            component={() => <Redirect to="/campaigns/addCampaign/facebook/chooseName"/>}
        />
        <Route
            path="/campaigns/addCampaign/facebook/chooseName"
            component={() => <ChooseNameMain designation="main" selection="facebook" shouldBackButtonBeAvailable={true}/>}
        />
        <Route
            path="/campaigns/addCampaign/facebook/chooseCountry"
            component={() => <ChooseCountryMain designation="main" selection="facebook" shouldBackButtonBeAvailable={true}/>}
        />
        <Route
            path="/campaigns/addCampaign/facebook/summary"
            component={() => <FinishMain designation="main" selection="facebook"/>}
        />
    </Switch>

export default MainCreateCampaignFacebookContainer;