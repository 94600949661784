// Redux
import store from '../store';

// Custom Modules
import { googleOnboardingSteps, facebookOnboardingSteps } from '../constants/onboardingSteps';

// Assets
import visaLogo from '../images/onboarding/visaLogo.svg';
import masterCardLogoWide from '../images/onboarding/masterCardLogoWide.svg';
import maestroLogo from '../images/onboarding/maestroLogo.svg';
import americanExpressLogo from '../images/onboarding/americanExpressLogo.svg';
import masterCardLogoNarrow from "../images/onboarding/masterCardLogo.svg";
import PayPalLogo from '../images/PayPalLogo.png';
import BeeLogo from '../images/logo_hb_bee.svg';
import GoogleLogo from '../images/logo_G_color_240px.png';
import FBLogo from '../images/onboarding/fb-logo.png';


export const generateOnboardingMap = selectedChannel => {
  return Object.values(selectedChannel === 'google' ? googleOnboardingSteps : facebookOnboardingSteps);
};

export const hasPreviousGoogleStepCompleted = (stepName) => {
  // Access current REDUX store
  const state = store.getState();

  switch (stepName) {
    case 'SELECTION_SUCCESS':
      return true;

    case 'CHOOSE_COUNTRY':
      return state.onboarding.google.selectionSuccess;

    case 'CHOOSE_BUDGET':
      return state.onboarding.google.createCampaign.country;

    case 'PAYMENT':
      return state.onboarding.google.createCampaign.budget;

    case 'FINISH':
      return state.payment.clientNonce;

    default:
      return false;
  };
};

export const hasPreviousFacebookStepCompleted = stepName => {
  // Access current REDUX store
  const state = store.getState();

  switch (stepName) {
    case 'VERIFICATION_SUCCESS':
      return true;

    case 'CHOOSE_NAME':
      return state.onboarding.facebook.verificationSuccess;

    case 'CHOOSE_COUNTRY':
      return state.onboarding.facebook.createCampaign.name;

    case 'PAYMENT':
      return state.onboarding.facebook.createCampaign.country;

    case 'FINISH':
      return state.payment.clientNonce;

    default:
      return false;
  };
};

export const onboardingGoogleSlideTitles = [
  'Country',
  'Budget',
  'Payment',
  'Summary'
];

export const onboardingFacebookSlideTitles = [
  'Name',
  'Country',
  'Payment',
  'Summary'
];

export const supportedCreditCardVendors = [
  'Visa',
  'MasterCard',
  'Maestro',
  'American Express'
];

export const setCreditCardVendorLogo = creditCardVendorNameForSummary => {
  switch (creditCardVendorNameForSummary) {
    case 'Visa':
      return visaLogo;
    case 'MasterCard':
      return masterCardLogoWide;
    case 'MasterCardLogoNarrow':
      return masterCardLogoNarrow;
    case 'Maestro':
      return maestroLogo;
    case 'American Express':
      return americanExpressLogo;
    case 'PayPal':
      return PayPalLogo;
    default:
      return null;
  }
};

export const getAdjustedCreditCardVendor = creditCardVendorNameForSummary => {
  const creditCardVendorClass =
    creditCardVendorNameForSummary === "American Express"
      ? "American"
      : creditCardVendorNameForSummary;
  return supportedCreditCardVendors.includes(creditCardVendorNameForSummary)
    ? creditCardVendorClass
    : "NotSupported";
};

export const imgSrcPicker = type => {
  switch (type) {
    case 'heraldbee':
      return BeeLogo;
    case 'google':
      return GoogleLogo;
    case 'facebook':
      return FBLogo;
    default:
      return null;
  }
};

export const availableAdChannel = {
     facebook: 'facebook',
     google: 'google'
};