// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { onboardingGoogleSlideTitles, onboardingFacebookSlideTitles } from '../../../utils/onboarding';
import { addCampaignSlideTitles } from '../../../utils/main';
import { isDesignationOnboarding } from '../../../utils/utils';
import { OnboardingTexts } from '../../../utils/texts';

// Custom Components
import DynamicCreateCampaignWrapperContainer from '../../../container/DynamicCreateCampaignWrapperContainer';
import CreateCampaignHeader from '../../../components/OnboardingCreateCampaign/CreateCampaignHeaderComponent';
import CreateCampaignStepper from '../../../components/MaterialComponents/OnboardingMaterialComponents/CreateCampaignStepperComponent/index';
import MaterialButtonComponent from '../../../components/MaterialComponents/MaterialButtonComponent/index';
import MaterialInputComponent from '../../../components/MaterialComponents/MaterialInputComponent';

// Stylesheet
import style from './index.module.less';

const ChooseNameContainer = ({ history, campaignName, setCampaignName, shouldBackButtonBeAvailable, designation = 'onboarding', selection}) => {

    const onboardingSlideTitles = selection === 'google' ? onboardingGoogleSlideTitles : onboardingFacebookSlideTitles;
    const steps = isDesignationOnboarding(designation) ? onboardingSlideTitles : addCampaignSlideTitles;
    const { title, infoButtonText } = OnboardingTexts.nameSlide;

    const [ name, setName ] = useState(campaignName);

    const saveInputToState = e => {
		setName(e.target.value)
	};

    const onNextClick = () => {
        setCampaignName('name', name);
        const url = isDesignationOnboarding(designation) ? '/onboarding/facebook/createCampaign/chooseCountry' : '/campaigns/addCampaign/facebook/chooseCountry';
        history.push(url);
    };

    const onBackClick = () => {
        const url = '/campaigns/addCampaign';
        history.push(url);
    };

    return (
        <DynamicCreateCampaignWrapperContainer designation={designation}>
            <section className={style.wrapper}>
                <CreateCampaignStepper
                    activeStep={steps.indexOf("Name")}
                    steps={steps}
                    designation={designation}
                />
                <CreateCampaignHeader
                    title={title}
                    infoButtonText={infoButtonText}
                />

                <div className={style.content}>
                    <MaterialInputComponent 
                        placeholder="Campaign name"
                        label="Campaign name"
                        size="large"
                        value={name}
                        onChange={saveInputToState}
                    />
                </div>

                <span className={`${style.buttons} ${shouldBackButtonBeAvailable ? style.buttonsExpanded : null}`}>
                    {
                        shouldBackButtonBeAvailable
                            ? (
                            <MaterialButtonComponent
                                type={'secondary'}
                                size={'xSmall'}
                                text={'BACK'}
                                onClick={onBackClick}
                            />
                            ) : null
                    }
                    <MaterialButtonComponent
                        type="primary"
                        disabled={!name}
                        size={"xSmall"}
                        text={"NEXT"}
                        onClick={onNextClick}
                    />
                </span>
            </section>
        </DynamicCreateCampaignWrapperContainer>
    );
};

ChooseNameContainer.propTypes = {
    history: PropTypes.object.isRequired,
    campaignName: PropTypes.string,
    setCampaignName: PropTypes.func.isRequired,
    shouldBackButtonBeAvailable: PropTypes.bool,
    designation: PropTypes.oneOf(['onboarding', 'main']),
    selection: PropTypes.oneOf(['google', 'facebook'])
};

export default ChooseNameContainer;