import { initInspectlet, addInspectletSessionTag } from "./inspectlet";
import config from "../../config";

const withProdEnv = (func) => {
    if (!config.DEV) {
        func();
    }
}

export const UserTracerModule = {

    init: () => withProdEnv(() => {
        initInspectlet(config.INSPECTLET_ID);
    }),

    setUser: (user) => withProdEnv(() => {
        const shopTag = user ? {
            userName: user.username, 
            testUser: user.test ? 'True' : 'False',
        } : {};
        
        addInspectletSessionTag(shopTag);
    }),
    
}