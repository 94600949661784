import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';

//css
import 'react-day-picker/lib/style.css';
import './index.less';

//less
import styles from './index.module.less';

//material ui
import Dialog from '@material-ui/core/Dialog';
import DateRange from '@material-ui/icons/DateRange';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

//moment
import moment from 'moment';

const CampaignHistoryDataPickerComponent = ({ handleDateChange, dataRange, handleFetchNewHistory }) => {

    const [visible, setVisible] = useState(false);
    const { from, to } = dataRange;
    const modifiers = { start: from, end: to };

    const handleCloseDialog = () => {
        setVisible(false)
    }
    const handleOpenDialog = () => {
        setVisible(true)
    }

    const handleOkClick = () => {
        setVisible(false)
        handleFetchNewHistory()
    }
    return (
        <div>
            <div className={styles.datePicker}>
                <p>PICK DATE: </p>
                <button className={styles.button} onClick={handleOpenDialog}><DateRange className={styles.icon}/></button>
                <span className={styles.dateFrom}><strong>FROM:</strong> {moment(dataRange.from).format('YYYY-MM-DD')}</span>
                <span className={styles.dateTo}><strong>TO:</strong> {moment(dataRange.to).format('YYYY-MM-DD')}</span>
            </div>
            <Dialog onClose={handleCloseDialog} open={visible}>
                <DayPicker
                    className="Selectable"
                    selectedDays={[from, { from, to }]}
                    modifiers={modifiers}
                    onDayClick={handleDateChange}
                />
                <DialogActions>
                    <Button onClick={handleOkClick} color="inherit">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

CampaignHistoryDataPickerComponent.propTypes = {
    handleDateChange: PropTypes.func,
    dataRange: PropTypes.object,
    handleFetchNewHistory: PropTypes.func,
}

export default CampaignHistoryDataPickerComponent;