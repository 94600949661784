import { 
    actionAddCampaignSetTargetChannel,
    actionAddCampaignSetGoogleField,
    actionAddCampaignSetFacebookField,
    actionAddCampaignClear,
    actionAddCampaignClearCancel,
    actionAddCampaignDismissCampaignHasBeenCreated
} from '..';

import { HeraldbeeApi } from '../../../api/HeraldbeeApi';
import { createCampaignParams } from '../../campaigns/logic';
import { setError } from '../../app';
import config from '../../../config';

export const setTargetChannel = channel => dispatch => {
    dispatch(actionAddCampaignSetTargetChannel(channel));
};

export const setGoogleField = (field, value) => dispatch => {
    dispatch(actionAddCampaignSetGoogleField(field, value));
};

export const setFacebookField = (field, value) => dispatch => {
    dispatch(actionAddCampaignSetFacebookField(field, value));
};

export const clearAddCampaignState = () => dispatch => {
    dispatch(actionAddCampaignClear());
};

export const addNewCampaignGoogle = (budgetValue, countryCode) => async dispatch => {
    const hbApi = HeraldbeeApi({
        baseUrl: config.API_URL
    });

    const campaign = createCampaignParams(budgetValue, countryCode, countryCode);

    const { error } = await hbApi.addNewCampaign(campaign);
    if (error) {
        return dispatch(setError(error));
    };

    dispatch(actionAddCampaignClear());
};

export const clearCampaignHasBeenCreated = () => dispatch => dispatch(actionAddCampaignDismissCampaignHasBeenCreated());

export const clearAddCampaignStateComplete = () => dispatch => dispatch(actionAddCampaignClearCancel());