// Redux
import { connect } from 'react-redux';

import AvatarComponent from './AvatarComponent';

const avatarUrlSelector = state => {
    const mainUrl = state.auth.user.content && state.auth.user.content.avatarUrl;
    const newUrl = state.avatar.avatarUrl;

    if (mainUrl) {
        if (newUrl) {
            return newUrl;
        } else {
            return mainUrl;
        }
    } else {
        return newUrl;
    }
};

const shopNameSelector = state => {
    const shopify = state.auth.user.content.shopify && state.auth.user.content.shopify.name;
    const woocommerce = (state.auth.user.content.woocommerce && state.auth.user.content.woocommerce.name) || state.auth.wooCommerceShopName;
    return shopify || woocommerce;
};

const mapStateToProps = state => ({
    avatarUrl: avatarUrlSelector(state),
    shopName: shopNameSelector(state)
});

const Avatar = connect(mapStateToProps)(AvatarComponent);

export default Avatar;

