// React
import React from 'react'
import PropTypes from 'prop-types';

// Stylesheet
import style from './style.module.less';

// Custom Components
import MaterialProductStatusIconContainer from '../../../container/MaterialProductStatusIconContainer';

//assets
import placeholderImage from '../../../images/image-placeholder-350x350.png'

const ProductItemComponent = ({ name, imageUrl, status}) => {
    return (
        <section className={style.container}>
            <header className={style.header}>
                <img alt='product preview' className={style.image} src={imageUrl ? imageUrl : placeholderImage}></img>
                <div className={style.description}>
                    <h3 className={style.name}>{name}</h3>
                    <div className={style.status}>
                        <MaterialProductStatusIconContainer status={status} />
                    </div>
                </div>
            </header>
        </section>
    )
}

ProductItemComponent.propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    imageUrl: PropTypes.string,
    status: PropTypes.oneOf(['approved', 'disapproved', 'waitingForGoogle', 'websiteClaimInProgress']),
}

export default ProductItemComponent;