import { makeStyles } from '@material-ui/core/styles';
import { breakpoints } from '../../styles/constants';

export const useStylesIcon = makeStyles({
    rootDesktop: {
        fontSize: '1.5rem',
    },
    rootMobile: {
        fontSize: '1.2rem',
    }
});

export const breakpoint = `(min-width:${breakpoints.md})`;