export const googleAdsLocations = [
  { name: 'Algeria', value: 'DZ' },
  { name: 'Angola', value: 'AO' },
  { name: 'Argentina', value: 'AR' },
  { name: 'Australia', value: 'AU' },
  { name: 'Austria', value: 'AT' },
  { name: 'Bahrain', value: 'BH' },
  { name: 'Bangladesh', value: 'BD' },
  { name: 'Belarus', value: 'BY' },
  { name: 'Belgium', value: 'BE' },
  { name: 'Brazil', value: 'BR' },
  { name: 'Cambodia', value: 'KH' },
  { name: 'Cameroon', value: 'CM' },
  { name: 'Canada', value: 'CA' },
  { name: 'Chile', value: 'CL' },
  { name: 'Colombia', value: 'CO' },
  { name: 'Costa Rica', value: 'CR' },
  { name: 'Cote d\'Ivoire', value: 'CI' },
  { name: 'Czechia', value: 'CZ' },
  { name: 'Denmark', value: 'DK' },
  { name: 'Dominican Republic', value: 'DO' },
  { name: 'Ecuador', value: 'EC' },
  { name: 'Egypt', value: 'EG' },
  { name: 'El Salvador', value: 'SV' },
  { name: 'Ethiopia', value: 'ET' },
  { name: 'Finland', value: 'FI' },
  { name: 'France', value: 'FR' },
  { name: 'Georgia', value: 'GE' },
  { name: 'Germany', value: 'DE' },
  { name: 'Ghana', value: 'GH' },
  { name: 'Greece', value: 'GR' },
  { name: 'Guatemala', value: 'GT' },
  { name: 'Hong Kong', value: 'HK' },
  { name: 'Hungary', value: 'HU' },
  { name: 'India', value: 'IN' },
  { name: 'Indonesia', value: 'ID' },
  { name: 'Ireland', value: 'IE' },
  { name: 'Israel', value: 'IL' },
  { name: 'Italy', value: 'IT' },
  { name: 'Japan', value: 'JP' },
  { name: 'Jordan', value: 'JO' },
  { name: 'Kazakhstan', value: 'KZ' },
  { name: 'Kenya', value: 'KE' },
  { name: 'Kuwait', value: 'KW' },
  { name: 'Lebanon', value: 'LB' },
  { name: 'Madagascar', value: 'MG' },
  { name: 'Malaysia', value: 'MY' },
  { name: 'Mauritius', value: 'MU' },
  { name: 'Mexico', value: 'MX' },
  { name: 'Morocco', value: 'MA' },
  { name: 'Mozambique', value: 'MZ' },
  { name: 'Myanmar', value: 'MM' },
  { name: 'Nepal', value: 'NP' },
  { name: 'Netherlands', value: 'NL' },
  { name: 'New Zealand', value: 'NZ' },
  { name: 'Nicaragua', value: 'NI' },
  { name: 'Nigeria', value: 'NG' },
  { name: 'Norway', value: 'NO' },
  { name: 'Oman', value: 'OM' },
  { name: 'Pakistan', value: 'PK' },
  { name: 'Panama', value: 'PA' },
  { name: 'Paraguay', value: 'PY' },
  { name: 'Peru', value: 'PE' },
  { name: 'Philippines', value: 'PH' },
  { name: 'Poland', value: 'PL' },
  { name: 'Portugal', value: 'PT' },
  { name: 'Puerto Rico', value: 'PR' },
  { name: 'Romania', value: 'RO' },
  { name: 'Russia', value: 'RU' },
  { name: 'Saudi Arabia', value: 'SA' },
  { name: 'Senegal', value: 'SN' },
  { name: 'Singapore', value: 'SG' },
  { name: 'Slovakia', value: 'SK' },
  { name: 'South Africa', value: 'ZA' },
  { name: 'South Korea', value: 'KR' },
  { name: 'Spain', value: 'ES' },
  { name: 'Sri Lanka', value: 'LK' },
  { name: 'Sweden', value: 'SE' },
  { name: 'Switzerland', value: 'CH' },
  { name: 'Taiwan', value: 'TW' },
  { name: 'Tanzania', value: 'TZ' },
  { name: 'Thailand', value: 'TH' },
  { name: 'Tunisia', value: 'TN' },
  { name: 'Turkey', value: 'TR' },
  { name: 'Uganda', value: 'UG' },
  { name: 'Ukraine', value: 'UA' },
  { name: 'United Arab Emirates', value: 'AE' },
  { name: 'United Kingdom', value: 'GB' },
  { name: 'United States', value: 'US' },
  { name: 'Uruguay', value: 'UY' },
  { name: 'Uzbekistan', value: 'UZ' },
  { name: 'Venezuela', value: 'VE' },
  { name: 'Vietnam', value: 'VN' },
  { name: 'Zambia', value: 'ZM' },
  { name: 'Zimbabwe', value: 'ZW' },
]

export const googleAdsLocationCodes = googleAdsLocations.map(location => location.value);

export const googleCodeToLocation = googleAdsLocations.reduce((acc,cur) => ({ ...acc, [cur.value]:cur.name }),{}) 

export default googleAdsLocations;