import React from 'react';
import { Button, Typography } from 'antd';
import PropTypes from 'prop-types';

import deadBee from '../../../images/icon_dead.svg';
import PageWrapper from '../../../components/PageWrapper';
import { clearLocalStorage } from '../../../utils/utils';

const { Title, Paragraph } = Typography;

const ErrorPageComponent = (
  { title, description, history } = {
    title: "Looks like there was problem",
    description: ""
  }
) => {
  ErrorPageComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
  };

  return (
    <PageWrapper>
      <div
        style={{
          width: "100%",
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <img height="200px" width="200px" src={deadBee} alt="Not Found" />
        <Title level={2}>{title}</Title>
        <Paragraph>{description}</Paragraph>
        {/* Wiping localStorage data in here is a fix for logging user out of onboarding flow without any message whatsoever , in case of a network error. */}
        <Button
          onClick={() => {
            clearLocalStorage();
            history.push("/login");
          }}
        >
          Return to the application
        </Button>
      </div>
    </PageWrapper>
  );
};

export default ErrorPageComponent;