import config from '../config';
import { getAuthHeaders } from '../utils/auth';
import { processResponseJson } from './auth';
import { setToLocalStorage, readFromLocalStorage } from '../utils/utils';
import localStorageItems from '../constants/localStorageItems';
import { setError } from './app';
import { HeraldbeeApi } from '../api/HeraldbeeApi';

// Actions

const FETCH_GOOGLE_ACCOUNT = 'FETCH_GOOGLE_ACCOUNT';
const FETCH_GOOGLE_ACCOUNT_ADS = 'FETCH_GOOGLE_ACCOUNT_ADS';
const FETCH_GOOGLE_ACCOUNT_MC = 'FETCH_GOOGLE_ACCOUNT_MC';
const SET_GOOGLE_ACCOUNT = 'SET_GOOGLE_ACCOUNT';
const SET_GOOGLE_ACCOUNT_ADS = 'SET_GOOGLE_ACCOUNT_ADS';
const SET_GOOGLE_ACCOUNT_MC = 'SET_GOOGLE_ACCOUNT_MC';
const ERROR_GOOGLE_ACCOUNT = 'ERROR_GOOGLE_ACCOUNT';
const ERROR_GOOGLE_ACCOUNT_ADS = 'ERROR_GOOGLE_ACCOUNT_ADS';
const ERROR_GOOGLE_ACCOUNT_MC = 'ERROR_GOOGLE_ACCOUNT_MC';

// Reducer

const googleAccountAdsCreated = readFromLocalStorage(localStorageItems.GOOGLE_ACCOUNT_ADS_CREATED);
const googleAccountMcCreated = readFromLocalStorage(localStorageItems.GOOGLE_ACCOUNT_MC_CREATED);

const initialState = {
  account: {
    fetch: true,
    loader: true,
    content: null,
    error: null
  },
  accountAds: {
    fetch: !googleAccountAdsCreated,
    loader: !googleAccountAdsCreated,
    content: googleAccountAdsCreated,
    error: null
  },
  accountMc: {
    fetch: !googleAccountMcCreated,
    loader: !googleAccountMcCreated,
    content: googleAccountMcCreated,
    error: null
  }
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_GOOGLE_ACCOUNT:
      return {
        ...state,
        account: { ...state.account, fetch: false, loader: true, error: null }
      }
    case FETCH_GOOGLE_ACCOUNT_ADS:
      return {
        ...state,
        accountAds: { ...state.accountAds, fetch: false, loader: true, error: null }
      }
    case FETCH_GOOGLE_ACCOUNT_MC:
      return {
        ...state,
        accountMc: { ...state.accountMc, fetch: false, loader: true, error: null }
      }

    case SET_GOOGLE_ACCOUNT:
      return {
        ...state,
        account: {
          ...state.account,
          loader: false,
          content: action.content,
          error: action.error
        }
      }
    case SET_GOOGLE_ACCOUNT_ADS:
      return {
        ...state,
        accountAds: {
          ...state.accountAds,
          loader: false,
          content: action.content,
          error: action.error
        }
      }
    case SET_GOOGLE_ACCOUNT_MC:
      return {
        ...state,
        accountMc: {
          ...state.accountMc,
          loader: false,
          content: action.content,
          error: action.error
        }
      }

    case ERROR_GOOGLE_ACCOUNT:
      return {
        ...state,
        account: { ...state.account, loader: false, error: action.error }
      }
    case ERROR_GOOGLE_ACCOUNT_ADS:
      return {
        ...state,
        accountAds: { ...state.accountAds, loader: false, error: action.error }
      }
    case ERROR_GOOGLE_ACCOUNT_MC:
      return {
        ...state,
        accountMc: { ...state.accountMc, loader: false, error: action.error }
      }

    default:
      return state;
  }
}
export default reducer;

// Action Creators

const actionFetchAccount = () => ({ type: FETCH_GOOGLE_ACCOUNT });
const actionSetAccount = content => ({ type: SET_GOOGLE_ACCOUNT, content });
const actionSetAccountAds = content => ({ type: SET_GOOGLE_ACCOUNT_ADS, content });
const actionSetAccountMc = content => ({ type: SET_GOOGLE_ACCOUNT_MC, content });
const actionErrorAccount = error => ({ type: ERROR_GOOGLE_ACCOUNT, error });

// Methods

export const fetchGoogleAccount = () => dispatch => {
    dispatch(actionFetchAccount());
    return fetch(`${config.API_URL}/user/google`, {
      method: 'GET',
      headers: getAuthHeaders(),
    }).then(response => processResponseJson(response)(dispatch))
      .then(json => {
        dispatch(actionSetAccount(json));
      }).catch(error => dispatch(actionErrorAccount(error)));
  }

export const fetchGoogleAccountAds = () => async dispatch => {
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { value } = await hbApi.fetchGoogleAccountAds();

  dispatch(actionSetAccountAds(value));
}


export const fetchGoogleAccountMc = () => async dispatch => {
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { value } = await hbApi.fetchGoogleAccountMc();

  dispatch(actionSetAccountMc(value));
}

export const saveGoogleAccountAds = gAccountAds =>
  dispatch => {
    setToLocalStorage(localStorageItems.GOOGLE_ACCOUNT_ADS_CREATED, gAccountAds);
    return dispatch(actionSetAccountAds(gAccountAds));
  }

export const saveGoogleAccountMc = gAccountMc =>
  dispatch => {
    setToLocalStorage(localStorageItems.GOOGLE_ACCOUNT_MC_CREATED, gAccountMc);
    return dispatch(actionSetAccountMc(gAccountMc));
  }

// Simple Methods

export const createGoogleAccountAds = () => async dispatch => {
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { error } = await hbApi.createGoogleAccountAds();

  if (error) {
    setError(error)(dispatch);
  } else {
    fetchGoogleAccountAds()(dispatch);
  }
}

export const createGoogleAccountMc = () => async dispatch => {
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { error } = await hbApi.createGoogleAccountMc();

  if (error) {
    setError(error)(dispatch);
  } else {
    fetchGoogleAccountMc()(dispatch);
  }
}
