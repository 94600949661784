import { typography, colorPalette } from "../../styles/constants";

const { primaryBrownRGB, primaryFont, paragraphLg } = typography;
const { black } = colorPalette;
export const generateStyleConfigObject = ({ validatedBraintreeClientInstance }) => {

	return {
		client: validatedBraintreeClientInstance,
		styles: {
			input: {
				"font-size": paragraphLg,
				"font-family": `${primaryFont}, verdana, sans-serif`,
				"font-weight": "normal",
				color: `rgba(${primaryBrownRGB}, 0.7)`,
				"padding-left": "16px"
			},
			":focus": {
				color: black
			},
			".valid": {
				color: black
			},
			".invalid": {
				color: black
			}
		},
		/** Point to specific DOM nodes where braintree iFrames should be initialized  */
		fields: {
			number: {
				selector: "#card-number"
			},
			cvv: {
				selector: "#cvv"
			},
			expirationDate: {
				selector: "#expiration-date"
			}
		}
	}
};