
import { makeStyles } from '@material-ui/core/styles';
import { colorPalette } from '../../../styles/constants';

export const useStyles = makeStyles(theme => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      minWidth: 200,
      height: 36,
      backgroundColor: colorPalette.secondaryGray,
      borderRadius: 4,
      border: `2px solid ${colorPalette.secondaryGray}`,
      [theme.breakpoints.down('sm')]:{
        height: 25,
        minWidth:'100%',
      },
      transition: 'border .3s',

      '&:hover':{
        border: `2px solid ${colorPalette.secondaryOrange}`,
      },

    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      color: colorPalette.primaryBrown,
    },
    iconButton: {
      padding: 0,
      color: colorPalette.primaryBrown,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }));
