import React, { useState, useRef, useEffect, useCallback } from "react";
import style from "./index.module.less";
import MaterialBreadCrumbsComponent from "../../MaterialComponents/MaterialBreadcrumbsComponent";
import { generateStyleConfigObject } from "../../../module/BraintreeModule/generateStyleConfigObject";
import HostedFieldsComponent from "../../HostedFieldsComponent";
import { Spin } from "antd";
import MaterialRadioButtonComponent from "../../MaterialComponents/MaterialRadioButtonComponent";
import MaterialButtonComponent from "../../MaterialComponents/MaterialButtonComponent";
import PaymentButtonsRowComponent from "../../PaymentButtonsRowComponent";

import braintreeClientCreator from "braintree-web/client";
import braintreeThreeDSecureCreator from 'braintree-web/three-d-secure';
import { initializeBraintreeClient } from "../../../module/BraintreeModule/initializeBraintreeClient";
import { initializeHostedFieldsClient } from "../../../module/BraintreeModule/initializeHostedFieldsClient";
import VendorLogoComponent from "../../VendorLogoComponent/index";
import { PropTypes } from "prop-types";
import { tokenizePaymentCardData } from "../../../module/BraintreeModule/tokenizePaymentCardData";
import { translateCardVendorName } from "../../../utils/mainApp";
import { initializeThreeDSecureInstance } from "../../../module/BraintreeModule/initializeThreeDSecureInstance";
import { acceptedPaymentMethods } from "../../../styles/constants";

const AccountChangePaymentAuthContainer = ({
  fetchBraintreeToken,
  braintreeToken,
  setError,
  createCustomerWithPaymentAuthorization,
  actionSetShouldSummaryKnowOfCardUpdate,
  actionConfirmSuccessfulPaymentAuthUpdate,
  actionSetIsNonceReadyForUpdate,
  isNonceReadyForUpdate,
  subscriptionId,
  changePaymentAuth,
  paymentAuthSuccessful,
  clientNonce,
  shopId,
  history,
  planId,
  validateBraintreeHostedFieldsEnvelope,
  validateBraintreeClientInstanceEnvelope,
  validateBraintreeInstanceEnvelope,
  returnToPaymentMethodSelection,
  summaryShouldKnowOfCardUpdate,
  clearPayPalEmail
}) => {
  const [detectedCardVendorName, setDetectedCardVendorName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [expirationDateErrorText, setExpirationDateErrorText] = useState();
  const [cardNumberErrorText, setCardNumberErrorText] = useState();
  const [cardRejectedErrorText, setCardRejectedErrorText] = useState();
  const [cvvErrorText, setCvvErrorText] = useState();
  const [, setShouldConfirmationButtonBeDisabled] = useState();
  const shouldConfirmationButtonBeDisabledRef = useRef(true);
  const [
    localBraintreeHostedFieldsInstance,
    setLocalBraintreeHostedFieldsInstance
  ] = useState();
  const [localBraintreeThreeDSecureInstance, setLocalBraintreeThreeDSecureInstance] = useState();

  const cleanup = useCallback(async () => {
    return new Promise(function (resolve) {
      localBraintreeHostedFieldsInstance.teardown(function teardownCallback(
        error
      ) {
        if (error) {
          resolve(setError({ message: error }));
        } else {
          resolve();
        }
      });
    });
  }, [localBraintreeHostedFieldsInstance, setError]);

  const onBackClick = useCallback(async () => {
    if (localBraintreeHostedFieldsInstance) {
      await cleanup();
    }
    returnToPaymentMethodSelection();
  }, [cleanup, history, localBraintreeHostedFieldsInstance]);


  const initializeLocalHostedFieldsInstance = useCallback(async () => {
    /** hostedFieldsInstance === initializeHostedFieldsClient(initializeBraintreeClient) */
    const {
      value: braintreeClientInstance,
      error: braintreeClientInstanceCreationError
    } = await initializeBraintreeClient({
      braintreeClientCreator,
      braintreeToken
    });

    const { value: threeDSecureInstance, error: braintreeThreeDSSecureInstanceCreationError } = await initializeThreeDSecureInstance({
      braintreeThreeDSecureCreator,
      braintreeClientInstance
    })

    const { validatedThreeDSecureInstance } = await validateBraintreeInstanceEnvelope({
      envelope: {
        value: threeDSecureInstance,
        error: braintreeThreeDSSecureInstanceCreationError
      }
    });

    const {
      validatedBraintreeClientInstance
    } = await validateBraintreeClientInstanceEnvelope({
      envelope: {
        braintreeClientInstance,
        braintreeClientInstanceCreationError
      }
    });

    const styleInitializationObject = generateStyleConfigObject({
      validatedBraintreeClientInstance
    });
    const {
      value: hostedFieldsInstance,
      error: hostedFieldsCreationError
    } = await initializeHostedFieldsClient({
      styleInitializationObject,
      setDetectedCardVendorName,
      handleErrorTextClearing,
      handleErrorTextSetting,
      handleConfirmationButtonStatus,
      setCardRejectedErrorText,
      style
    });
    const {
      validatedHostedFieldsInstance
    } = await validateBraintreeHostedFieldsEnvelope({
      envelope: {
        hostedFieldsInstance,
        hostedFieldsCreationError
      }
    });

    validatedHostedFieldsInstance.focus("number");
    setLocalBraintreeThreeDSecureInstance(validatedThreeDSecureInstance);
    setLocalBraintreeHostedFieldsInstance(validatedHostedFieldsInstance);
    setIsLoading(false);
  }, [braintreeToken, validateBraintreeClientInstanceEnvelope, validateBraintreeHostedFieldsEnvelope, validateBraintreeInstanceEnvelope]);


  useEffect(() => {
      actionSetIsNonceReadyForUpdate(false);
    if (!braintreeToken) {
      fetchBraintreeToken();
    }
  }, [braintreeToken, fetchBraintreeToken]);


  useEffect(() => {
    const shouldHostedFieldsBeInitialized =
      braintreeToken && !paymentAuthSuccessful && !isNonceReadyForUpdate;
    if (shouldHostedFieldsBeInitialized) {
      setIsLoading(true);
      initializeLocalHostedFieldsInstance();
    }
  }, [braintreeToken, initializeLocalHostedFieldsInstance, isNonceReadyForUpdate, paymentAuthSuccessful, planId]);

  useEffect(() => {
    if (paymentAuthSuccessful) {
      /** Notify SubscriptionSettingsContainer about successful update, to render the confirmation message */
      actionSetShouldSummaryKnowOfCardUpdate(true);
      actionConfirmSuccessfulPaymentAuthUpdate(false);
      actionSetIsNonceReadyForUpdate(false);
      clearPayPalEmail();
      return history.push("/account");
    }
  }, [paymentAuthSuccessful, localBraintreeHostedFieldsInstance, actionSetShouldSummaryKnowOfCardUpdate, actionConfirmSuccessfulPaymentAuthUpdate, onBackClick, actionSetIsNonceReadyForUpdate]);


  const onConfirmClick = async () => {
    setIsLoading(true);
    const {
      clientNonce,
      cardType,
      tokenizeErrorMessage
    } = await tokenizePaymentCardData({
      localBraintreeHostedFieldsInstance,
      localBraintreeThreeDSecureInstance,
    });
    if (tokenizeErrorMessage) {
      setIsLoading(false);
      setCardRejectedErrorText(tokenizeErrorMessage);
      handleConfirmationButtonStatus({
        shouldConfirmationButtonBeDisabled: true
      });
      actionSetIsNonceReadyForUpdate(false);
    } else {
      createCustomerWithPaymentAuthorization(clientNonce);
      setDetectedCardVendorName(translateCardVendorName({ cardVendorName: cardType }));
      actionSetIsNonceReadyForUpdate(true);
      if (clientNonce && (!summaryShouldKnowOfCardUpdate || !paymentAuthSuccessful)) {
      changePaymentAuth({
        subscriptionId,
        clientNonce,
        shopId,
        paymentMethod: acceptedPaymentMethods.paymentCard
      });
    }
    }
  };

  const linkArray = [
    {
      textContent: "Back to Account Settings",
      link: "/account"
    },
    {
      textContent: "Change Payment Method",
      link: null
    }
  ];

  const handleErrorTextSetting = ({ hostedFieldEventEmitterName }) => {
    switch (hostedFieldEventEmitterName) {
      case "number":
        setCardNumberErrorText("Invalid Card Number");
        break;
      case "expirationDate":
        setExpirationDateErrorText("Invalid Expiration Date");
        break;
      case "cvv":
        setCvvErrorText("Invalid CVV/PIN Number");
        break;
      default:
        return;
    }
  };

  const handleErrorTextClearing = ({ hostedFieldEventEmitterName }) => {
    switch (hostedFieldEventEmitterName) {
      case "number":
        setCardNumberErrorText("");
        break;
      case "expirationDate":
        setExpirationDateErrorText("");
        break;
      case "cvv":
        setCvvErrorText("");
        break;
      default:
        return;
    }
  };

  const handleConfirmationButtonStatus = ({
    shouldConfirmationButtonBeDisabled
  }) => {
    shouldConfirmationButtonBeDisabledRef.current = shouldConfirmationButtonBeDisabled;
    setShouldConfirmationButtonBeDisabled(shouldConfirmationButtonBeDisabled);
  };

  const SaveButton = () => (
    <MaterialButtonComponent
      text="SAVE"
      type="primary"
      size="large"
      className={style.nextButton}
      onClick={onConfirmClick}
      disabled={shouldConfirmationButtonBeDisabledRef.current}
    />
  );

  return (
    <article className={style.AccountChangePaymentAuth}>
      <div className={style.FixedElements}>
        <MaterialBreadCrumbsComponent
          className={style.Breadcrumbs}
          breadcrumbLinkArray={linkArray}
        />
        <h2 className={style.viewTitle}>Change Payment Method</h2>
      </div>
      <div className={style.withButtonsContainer}>
        <div className={style.spinnerContainer}>
          <Spin spinning={isLoading} size="large">
            <div className={style.changePaymentAuthContainer}>
              <div>
                <div className={style.methodVendorRow}>
                  <div className={style.PaymentMethodContainer}>
                    <MaterialRadioButtonComponent
                      isRadioButtonChecked={true}
                      buttonValue={"Payment Card"}
                    />
                    <span>Payment Card</span>
                  </div>

                  <p className={style.vendorEmblemsContainer}>
                    <VendorLogoComponent vendorName={"MasterCard"} />
                    <VendorLogoComponent vendorName={"Visa"} />
                  </p>
                </div>
                <HostedFieldsComponent
                  style={style}
                  cardRejectedErrorText={cardRejectedErrorText}
                  expirationDateErrorText={expirationDateErrorText}
                  cardNumberErrorText={cardNumberErrorText}
                  detectedCardVendorName={detectedCardVendorName}
                  cvvErrorText={cvvErrorText}
                  hostedFieldContainerStyle={style.hostedFieldContainer}
                  hostedFieldContainerCardNumberStyle={
                    style.hostedFieldContainerCardNumber
                  }
                  expirationCvvRowStyle={style.expirationCvvRow}
                >
                  {/* if there's a card vendor detected, render its logo */}
                  {detectedCardVendorName && (
                    <VendorLogoComponent
                      vendorName={detectedCardVendorName}
                      additionalStyle={style.logoInsideOfCardNumberInput}
                    />
                  )}
                </HostedFieldsComponent>
              </div>
            </div>
            <PaymentButtonsRowComponent
              ConfirmButton={<SaveButton />}
              onBackClick={onBackClick}
              imposedStyling={style.navButtonsRow}
            />
          </Spin>
        </div>
      </div>
    </article>
  );
};

AccountChangePaymentAuthContainer.propTypes = {
  fetchBraintreeToken: PropTypes.func.isRequired,
  braintreeToken: PropTypes.string,
  setError: PropTypes.func.isRequired,
  createCustomerWithPaymentAuthorization: PropTypes.func.isRequired,
  validateBraintreeHostedFieldsEnvelope: PropTypes.func.isRequired,
  validateBraintreeClientInstanceEnvelope: PropTypes.func.isRequired,
  actionSetShouldSummaryKnowOfCardUpdate: PropTypes.func.isRequired,
  actionConfirmSuccessfulPaymentAuthUpdate: PropTypes.func.isRequired,
  validateBraintreeInstanceEnvelope: PropTypes.func.isRequired,
  actionSetIsNonceReadyForUpdate: PropTypes.func.isRequired,
  isNonceReadyForUpdate: PropTypes.bool,
  subscriptionId: PropTypes.string,
  changePaymentAuth: PropTypes.func.isRequired,
  paymentAuthSuccessful: PropTypes.bool,
  clientNonce: PropTypes.string,
  shopId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  returnToPaymentMethodSelection: PropTypes.func.isRequired,
  summaryShouldKnowOfCardUpdate: PropTypes.bool,
  clearPayPalEmail: PropTypes.func.isRequired,  
};



export default AccountChangePaymentAuthContainer;