import React from 'react'
import { PropTypes } from 'prop-types';
import { Spin } from 'antd';

const OverlayingSpinnerContainer = ({ isLoading, children, spinnerSize = "default" }) => {
    return (
        <Spin size={spinnerSize} spinning={isLoading}>
            {children}
        </Spin>
    )
}

OverlayingSpinnerContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    spinnerSize: PropTypes.oneOf(["small", "large", "default"]),
};

export default OverlayingSpinnerContainer;