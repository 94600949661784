// React
import React from 'react';

// Third-Party Modules
import { CheckCircleOutline } from '@material-ui/icons';

// Stylesheet
import styles from './index.module.less';
import { useStylesIcon } from './index.material';

const MaterialCheckCircleOutlineComponent = ({ cssClass }) => {
    const classes = useStylesIcon();
    return (
        <CheckCircleOutline className={`${styles.icon} ${classes.root}`}/>
    );
};

export default MaterialCheckCircleOutlineComponent;