// React
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

import CampaignListContainer from "./CampaignListContainer";
import { findOutIfUserHasGrantedAgencyAccess } from "../../ducks/facebook";
import { fetchCampaigns } from "../../ducks/campaigns";

const mapStateToProps = state => ({
  areConversionsActive: state.conversions.areConversionsActive,
  wasAgencyAccessGranted: state.facebook.wasAgencyAccessGranted,
  isUserConnectedWithFacebook: state.auth.user.content.isUserConnectedWithFacebook
});

const mapDispatchToProps = {
  fetchCampaigns,
  findOutIfUserHasGrantedAgencyAccess
};

const CampaignList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignListContainer)
);

export default CampaignList;
