// Redux
import { connect } from 'react-redux';
// Methods
import { uploadUserAvatar, clearAvatarError } from '../../ducks/avatar';

import AvatarPickerComponent from './AvatarPickerComponent';

const mapStateToProps = state => ({
    avatarUploadError: state.avatar.avatarUploadError,
    isLoading: state.avatar.isLoading
});

const mapDispatchToProps = {
    uploadUserAvatar,
    clearAvatarError
};

const AvatarPicker = connect(mapStateToProps, mapDispatchToProps)(AvatarPickerComponent);

export default AvatarPicker;