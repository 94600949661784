import { makeStyles } from '@material-ui/core/styles';
import { colorPalette, typography } from '../../../styles/constants';

export const useStyles = makeStyles(() => ({
    link: {
        color: colorPalette.primaryBrown,
        opacity: '.6',
        fontSize: typography.body.paragraphMd,
    },
    icon: {
        color: `rgba(${colorPalette.primaryBrownRGB}, .6)`,
        fontSize: typography.body.fontSize,
        position: 'absolute',
    },
    iconLeft: {
        left: '-5px'
    },
    iconRight: {
        left: '-1px'
    }
}));