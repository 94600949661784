// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import config from '../../../../config';

// Custom Components
import MaterialButtonComponent from '../../../MaterialComponents/MaterialButtonComponent';

// Stylesheet
import styles from './index.module.less';

const AccountFacebookVerificationFailureComponent = ({ history, facebookListOfPermissionsToReAsk, clearFacebookAuthorization }) => {
    const onClickCancel = () => {
        clearFacebookAuthorization();
        history.push('/account');
    };

    const onClickNext = () => {
        clearFacebookAuthorization();
        window.location.href = `${config.API_URL}/facebook/ReAsk?context=main`;
    };

    return (
        <section className={styles.container}>
            <header className={styles.header}>
                <h1 className={styles.headerTitle}>Something went wrong</h1>
            </header>
            <main className={styles.content}>
                <div className={styles.contentDescription}>
                    <h2 className={styles.contentDescriptionTitle}>The following permissions have been declined:</h2>
                    <ul className={styles.contentDescriptionList}>
                        {
                            ['foo', 'bar'].map((permission, i) => {
                                return (
                                    <li className={styles.contentDescriptionListElement} key={i}>{permission}</li>
                                );
                            })
                        }
                    </ul>
                    <p className={styles.contentDescriptionText}>If you want to try and connect with your Facebook account again, then please click Next button.</p>
                </div>
                <div className={styles.contentControls}>
                    <MaterialButtonComponent
                        text='Cancel'
                        size="small"
                        type={'secondary'}
                        onClick={onClickCancel}
                    />
                    <MaterialButtonComponent
                        text='Next'
                        size="small"
                        type={'primary'}
                        onClick={onClickNext}
                    />
                </div>
            </main>
        </section>
    );
};

AccountFacebookVerificationFailureComponent.propTypes = {
    facebookListOfPermissionsToReAsk: PropTypes.array.isRequired,
    clearFacebookAuthorization: PropTypes.func.isRequired
};

export default AccountFacebookVerificationFailureComponent;