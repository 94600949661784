import * as Sentry from '@sentry/browser';

/**
 * Initialize Sentry, must be called before any other action performed in Sentry.
 */
export const initSentry = (dataSourceName, releaseVersion) => Sentry.init({ 
    dsn: dataSourceName,
    release: releaseVersion,
});

/**
 * Sets user with his/her properties to Sentry. All of the events, which are send to Sentry will be associated with this user.
 * @param {Object} user Information about the user. Each of object param is optional, but one must be present in order for the user to be captured.
 * @param {string} user.id Identifier for the user.
 * @param {string} user.username The username, mainly a better label than the internal ID.
 * @param {string} user.email An alternative to a username (or addition). Sentry is aware of email addresses and can show things like Gravatars, unlock messaging capabilities, and more.
 * @param {string} user.ip_address The IP address of the user. If the user is unauthenticated providing the IP address will suggest that this is unique to that IP. We will attempt to pull this from HTTP request data if available.
 */
export const setSentryUser = ({ id, username, email, ip_address, ...rest }) => Sentry.configureScope((scope) => {
    scope.setUser({ id, username, email, ip_address, ...rest });
});

/**
 * Send exception to Sentry.
 * @param {Object} exception
 * @param {Object} exception.error An exception-like error object.
 * @param {Object} exception.extraErrorInfo Additional error information object added to Sentry error scope.
 * @returns {string} Generated Sentry event id.
 */
export const sendSentryException = ({ error, extraErrorInfo }) => Sentry.withScope(scope => {
    scope.setExtras(extraErrorInfo);
    const eventId = Sentry.captureException(error);
    return eventId;
});
