// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { isDesignationOnboarding } from '../../utils/utils';

// Custom Components
import AddNewCampaign from '../AddNewCampaign';
import OnboardingContainer from '../OnboardingContainer';

const DynamicCreateCampaignWrapperContainer = ({ children, designation }) => 
    isDesignationOnboarding(designation)
        ? (
            <OnboardingContainer>
                {children}
            </OnboardingContainer>
        ) : (
            <AddNewCampaign>
                {children}
            </AddNewCampaign>
        );

DynamicCreateCampaignWrapperContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    designation: PropTypes.oneOf(['onboarding', 'main'])
};

export default DynamicCreateCampaignWrapperContainer;