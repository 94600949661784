// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import InfoButtonComponent from '../../InfoButtonComponent';

// Stylesheet
import style from './index.module.less';

const CreateCampaignHeader = ({ title, infoButtonText }) => {
    const { bold, regular } = title;
    return (
        <div className={style.container}>
            <span className={style.containerInner}>
                <h3 className={style.title}><strong>{bold}</strong>{regular}</h3>
                <div className={style.infoButton}>
                    <InfoButtonComponent
                        content={infoButtonText}
                        placement="right"
                        cssClass="onboardingInfoButton"
                    /> 
                </div>
            </span>
        </div>
    );
};

CreateCampaignHeader.propTypes = {
    title: PropTypes.object.isRequired,
    infoButtonText: PropTypes.string.isRequired
};

export default CreateCampaignHeader;
