// React
import React from 'react'
import PropTypes from 'prop-types'

// Custom Components
import MaterialCheckIconComponent from '../../../MaterialComponents/MaterialCheckIconComponent';

// Stylesheet
import style from './index.module.less';

const PricingPlanOptionsComponent = ({ options }) => {
  return (
    <ul className={style.list}>
      {options && options.map((option, key) => 
        <li key={key}>
          <MaterialCheckIconComponent cssClass="optionIcon" />
          {option}
        </li>  
      )}
    </ul>
  )
}

PricingPlanOptionsComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string)
}

export default PricingPlanOptionsComponent

