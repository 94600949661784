import config from '../config';
import { setError } from './app';
import { HeraldbeeApi } from '../api/HeraldbeeApi';

// Actions

const SET_GOOGLE_REQUIREMENT_MEMOS = 'SET_GOOGLE_REQUIREMENT_MEMOS';

// Reducer

const initialState = {
    googleRequirementMemos: [],
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SET_GOOGLE_REQUIREMENT_MEMOS:
            return {
                ...state,
                googleRequirementMemos: action.memos,
            }

        default: return state;
    }
}
export default reducer;

// Action Creators

const actionSetGoogleRequirementMemos = (memos) => ({ type: SET_GOOGLE_REQUIREMENT_MEMOS, memos });

// Methods

export const fetchGoogleRequirementMemosState = () => async dispatch => {
    const hbApi = HeraldbeeApi({
        baseUrl: config.API_URL,
    });

    const { value, error } = await hbApi.fetchGoogleRequirementMemosState();

    if (error) {
        setError(error)(dispatch);
        return;
    };

    dispatch(actionSetGoogleRequirementMemos(value));
}

export const setGoogleRequirementMemosState = (memos) => async dispatch => {
    const hbApi = HeraldbeeApi({
        baseUrl: config.API_URL,
    });

    const { error } = await hbApi.setGoogleRequirementMemosState(memos);

    if (error) {
        setError(error)(dispatch);
        return;
    };

    dispatch(actionSetGoogleRequirementMemos(memos));
}
