// React
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Link } from 'react-router-dom';

// Custom Components
import MaterialCheckCircleOutlineComponent from '../../MaterialComponents/MaterialCheckCircleOutlineComponent';
import MaterialCrossButtonComponent from '../../MaterialComponents/MaterialCrossButtonComponent';

// Stylesheet
import styles from './index.module.less';

const CampaignNewNotificationComponent = ({ clearCampaignHasBeenCreated }) => {

    const onClick = () => {
        clearCampaignHasBeenCreated();
    };

    return (
        <section className={styles.container}>
            <span className={styles.containerStatus}>
                <MaterialCheckCircleOutlineComponent />
                <p className={styles.text}>
                    New Campaign created! Make sure your store meets all the <Link to="/googleRequirements">Google</Link> or Facebook requirements to make the campaign active.
                </p>
            </span>
            <span className={styles.containerControls}>
                <MaterialCrossButtonComponent onClick={onClick} />
            </span>
        </section>
    );
};

CampaignNewNotificationComponent.propTypes = {
    clearCampaignHasBeenCreated: PropTypes.func.isRequired
};

export default CampaignNewNotificationComponent;