// React
import React from 'react';
import PropTypes from 'prop-types'

// Custom Modules
import countryRefferenceTable from '../../../constants/countryRefferenceTable';
import { translateCountryCode } from '../../../utils/payment';

// Custom Components
import FlagIconComponent from '../../../components/FlagIconComponent';
import CityInput from '../FormDecoratedInputs/CityInput';
import AddressInput from '../FormDecoratedInputs/AddressInput';
import CompanyNameInput from '../FormDecoratedInputs/CompanyNameInput';
import FirstNameInput from '../FormDecoratedInputs/FirstNameInput';
import LastNameInput from '../FormDecoratedInputs/LastNameInput';
import VatInput from '../FormDecoratedInputs/VatInput';
import ZipInput from '../FormDecoratedInputs/ZipInput';
import MaterialSelectComponent from '../../../components/MaterialComponents/MaterialSelectComponent';

// Stylesheet
import styles from './index.module.less';

const InvoiceFormContainer = ({ selectedForm, content, stateUpdateControls, setIsFormValid }) => {
    // constants
    const savedForm = content.customer_type;

    const countrySuggestions = countryRefferenceTable.map(({ name, value }) => ({
        value: name,
        key: value,
        prefix: <FlagIconComponent countryCode={value} className={styles.flagInList} />
    }));

    const selectInputPrefix = stateUpdateControls.country.state && <FlagIconComponent countryCode={stateUpdateControls.country.state} className={styles.flagInInput} />

    // methods

    const saveInputCountryValue = suggestion => {
        if (suggestion) {
            setIsFormValid(true);
            stateUpdateControls.country.setState(suggestion.key);
        }
    };

    return (
        <section className={styles.container}>
            <div className={styles.collectorName}>
                <h4 className={styles.collectorTitle}>Name</h4>
                <div className={styles.collectorNameInputs}>
                    {
                        selectedForm === 'company'
                            ? (
                                <CompanyNameInput
                                    placeholder="Company or organization name"
                                    label="Company or organization name"
                                    size="flex"
                                    id="company_name"
                                    formKey="companyName"
                                />
                            ) : (
                                <>
                                    <span className={styles.multiplesEqual}>
                                        <FirstNameInput
                                            placeholder="First name"
                                            label="First name"
                                            size="flex"
                                            id="first_name"
                                            formKey="firstName"
                                        />
                                    </span>
                                    <span className={styles.multiplesEqual}>
                                        <LastNameInput 
                                            placeholder="Last name"
                                            label="Last name"
                                            size="flex"
                                            id="last_name"
                                            formKey="lastName"
                                        />
                                    </span>
                                </>
                            )
                    }
                </div>
            </div>
            <div className={styles.collectorAddress}>
                <h4 className={styles.collectorTitle}>{selectedForm === 'company' ? 'Company address' : 'Address'}</h4>
                <div className={styles.collectorAddressInputs}>
                    <span className={styles.collectorAddressInputsRow1}>
                        <AddressInput
                            placeholder="Street and Number"
                            label="Street and Number"
                            size="flex"
                            id="address"
                            formKey="address"
                        />
                    </span>
                    <span className={styles.collectorAddressInputsRow2}>
                        <span className={styles.multiplesEqual}>
                            <ZipInput 
                                placeholder="Zip code"
                                label="Zip code"
                                size="flex"
                                id="zip"
                                formKey="zip"
                            />
                        </span>
                        <span className={styles.multiplesEqual}>
                            <CityInput
                                placeholder="City"
                                label="City"
                                size="flex"
                                id="city"
                                formKey="city"
                            />
                        </span>
                    </span>
                    <span className={styles.collectorAddressInputsRow3}>
                        <MaterialSelectComponent
                            key={`${selectedForm === savedForm}CountrySelect`}
                            label="Country"
                            id="country"
                            size="flex"
                            formKey="country"
                            prefix={selectInputPrefix}
                            initialSelectedItemKey={stateUpdateControls.country.state}
                            suggestions={countrySuggestions}
                            onSelect={saveInputCountryValue}
                            isSelectedFormEqualToSavedForm={selectedForm === savedForm}
                            fullCountryName={translateCountryCode(stateUpdateControls.country.state)}
                            autoFocus={false}
                        />
                    </span>
                </div>
            </div>
            {
                selectedForm === 'company'
                    ? (
                        <>
                            <div className={styles.collectorVAT}>
                                <h4 className={styles.collectorTitle}>Taxpayer Identification Number</h4>
                                <p className={styles.collectorSubtitle}>VAT Registration Number for European Union</p>
                                <div className={styles.collectorVATInputs}>
                                    <VatInput 
                                        placeholder="TIN/VAT number"
                                        label="TIN/VAT number"
                                        size="flex"
                                        id="vat_number"
                                        formKey="vatNumber"
                                    />
                                </div>
                            </div>
                        </>
                    ) : null
            }
        </section>
    );
};

InvoiceFormContainer.propTypes = {
    selectedForm: PropTypes.oneOf(['company', 'private']).isRequired,
    isFormValid: PropTypes.bool.isRequired,
    content: PropTypes.object.isRequired,
    setIsFormValid: PropTypes.func.isRequired,
    clearInvoiceDataError: PropTypes.func.isRequired,
    isInvoiceDataErrorPresent: PropTypes.bool,
    stateUpdateControls: PropTypes.shape({
        first_name: PropTypes.shape({ state: PropTypes.string, setState: PropTypes.func.isRequired}),
        last_name: PropTypes.shape({ state: PropTypes.string, setState: PropTypes.func.isRequired}),
        company_name: PropTypes.shape({ state: PropTypes.string, setState: PropTypes.func.isRequired}),
        address: PropTypes.shape({ state: PropTypes.string, setState: PropTypes.func.isRequired}),
        zip: PropTypes.shape({ state: PropTypes.string, setState: PropTypes.func.isRequired}),
        city: PropTypes.shape({ state: PropTypes.string, setState: PropTypes.func.isRequired}),
        country: PropTypes.shape({ state: PropTypes.string, setState: PropTypes.func.isRequired}),
        vat_number: PropTypes.shape({ state: PropTypes.string, setState: PropTypes.func.isRequired}) 
    }).isRequired
};

export default InvoiceFormContainer;