// React
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

// Stylesheet
import styles from './index.module.less';
import { useStyles } from './index.material';


const MaterialCrossButtonComponent = ({ onClick }) => {
    
    const classes = useStyles();

    return (
        <IconButton size="small" className={classes.root} onClick={onClick}>
            <Clear className={styles.icon}/>
        </IconButton>
    );
};

MaterialCrossButtonComponent.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default MaterialCrossButtonComponent;