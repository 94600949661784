// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { clearCampaignHasBeenCreated } from '../../ducks/additionalCampaign/methods';

import CampaignNewNotificationComponent from './CampaignNewNotificationComponent';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    clearCampaignHasBeenCreated
};

const CampaignNewNotification = withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignNewNotificationComponent));

export default CampaignNewNotification;