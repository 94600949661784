// React
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Spin } from 'antd';

// Custom Modules
import { PricingTexts } from '../../../../utils/texts';
import { describeCurrentSubscription, createPricingRefTable } from '../../../../utils/subscription';

// Custom Components
import MaterialBreadcrumbsComponent from '../../../../components/MaterialComponents/MaterialBreadcrumbsComponent';
import MaterialCheckIconComponent from '../../../../components/MaterialComponents/MaterialCheckIconComponent';
import MaterialRadioButtonComponent from '../../../../components/MaterialComponents/MaterialRadioButtonComponent';
import MaterialButtonComponent from '../../../../components/MaterialComponents/MaterialButtonComponent';

// Stylesheet
import style from './index.module.less';

const AccountSubscriptionContainer = ({ subscription, history, shopId, changeSubscriptionPlan, nondestructiveErrorMessage, setNondestructiveErrorMessage }) => {

    // local state and working variables
    const userSubscription = subscription.content;
    const linkArray = [
        {
            textContent: 'Account',
            link: '/account'
        },
        {
            textContent: 'Change your subscription plan',
            link: null
        }
    ];
    const availablePlans = createPricingRefTable(PricingTexts.devSubscriptionsPlans.headers, PricingTexts.devSubscriptionsPlans.contents, subscription.content.plan_id);
    const { plan_id: currentlyChosenSubscriptionPlan } = userSubscription;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(currentlyChosenSubscriptionPlan.toUpperCase());

    const onTabClick = event => {
        setNondestructiveErrorMessage({ displayedErrorMessage: null, error: null });
        setSelectedPlan(event.currentTarget.id);
    };

    // methods
    const onClickBack = () => {
        history.goBack();
    };

    const onClickSave = () => {
        const adjustedPlan = selectedPlan.toLowerCase();
        const isFetchingNeeded = shopId && adjustedPlan !== userSubscription.plan_id;
        if (isFetchingNeeded) {
            setIsLoading(true);
            changeSubscriptionPlan(userSubscription.id, adjustedPlan, shopId);
        }
    };

    useLayoutEffect(() => {
        if (isLoading || nondestructiveErrorMessage) {
            setIsLoading(false);
        }
    }, [subscription, nondestructiveErrorMessage]);

    return (
        <section className={style.container}>
            <MaterialBreadcrumbsComponent breadcrumbLinkArray={linkArray} />
            <div className={style.titleTabsContainer}>
                <header className={style.containerHeader}>
                    <h2 className={`${style.title} h2-sm`}>Change your subscription plan</h2>
                    <div className={style.subscriptionStatusContainer}>
                        <p className={style.status}>Your current subscription:</p> <strong>{describeCurrentSubscription(userSubscription)}</strong>
                        {nondestructiveErrorMessage && <p className={style.nondestructiveErrorMessage}>{nondestructiveErrorMessage}</p>}
                    </div>
                </header>
                <main className={style.containerBody}>
                    <div className={style.table}>
                        {
                            availablePlans.map(plan => {
                                const header = plan.header;
                                const content = plan.content;
                                const isPlanPremium = plan.header.title === 'PREMIUM';
                                const isPlanCurrentSub = plan.header.title === currentlyChosenSubscriptionPlan.toUpperCase();
                                const styleForTheGivenTab = () => plan.header.title === selectedPlan ? style.tableCardActive : style.tableCard;
                                const styleForTheContainer = () => isPlanCurrentSub ? style.currentlyChosenSubscriptionPlan : null;
                                return (
                                    <div key={header.title} id={header.title} onClick={onTabClick} className={`${style.tabContainer} ${styleForTheContainer()}`}>
                                        <button className={styleForTheGivenTab()} >
                                            <header className={style.tableCardHeader}>
                                                <div className={style.planHeader}>
                                                    <p className={style.planHeaderTitle}>
                                                        <strong>{header.title}</strong>
                                                    </p>
                                                    <p className={isPlanPremium ? style.planHeaderPricePremium : style.planHeaderPrice}>
                                                        <strong>{header.price}</strong>
                                                    </p>
                                                    <p className={isPlanPremium ? style.planHeaderSubtitlePremium : style.planHeaderSubtitle}>
                                                        <strong>{header.subtitle}</strong>
                                                    </p>
                                                </div>
                                                <MaterialRadioButtonComponent
                                                    isRadioButtonChecked={selectedPlan === header.title}
                                                    onChange={setSelectedPlan}
                                                    buttonValue={header.title}
                                                    cssClass="Secondary"
                                                />
                                            </header>
                                            <aside className={style.tableCardBody}>
                                                {
                                                    content.map((feature, i) =>
                                                        <span className={style.tableFeature} key={i}>
                                                            <MaterialCheckIconComponent cssClass="optionIconSecondary" />
                                                            <p className={style.tableFeatureText}>{feature}</p>
                                                        </span>
                                                    )
                                                }
                                            </aside>
                                        </button>
                                        {isPlanCurrentSub && <h4 className={style.subtitle}>Available subscriptions</h4>}
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className={style.buttons}>
                        <span className={style.buttonsBack}>
                            <MaterialButtonComponent
                                type={"secondary"}
                                size={"small"}
                                text={"BACK"}
                                onClick={onClickBack}
                            />
                        </span>
                        <Spin spinning={isLoading}>
                            <MaterialButtonComponent
                                disabled={(selectedPlan && selectedPlan.toLowerCase() !== currentlyChosenSubscriptionPlan) ? false : true}
                                type={"primary"}
                                size={"small"}
                                text={"SAVE"}
                                onClick={onClickSave}
                            />
                        </Spin>
                    </div>
                </main>
            </div>

        </section>
    );
};

AccountSubscriptionContainer.propTypes = {
    subscription: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    shopId: PropTypes.string.isRequired,
    nondestructiveErrorMessage: PropTypes.string
};

export default AccountSubscriptionContainer;