import { removeUtmSearchParams } from "../../utils/utils";

export const getProductsFromResources = (resources) => {
    const products = resources.filter(validateProduct).map(mapResourceToProduct);

    return products;
}

const validateProduct = ({ product, destinationStatuses }) => {
    /**
     * Filter out just products which are handled by application
     */
    const productOnOnlineChannel = product && product.channel === 'online';
    const productWithShoppingDestination = destinationStatuses && destinationStatuses.find(({ destination }) => destination === 'Shopping');

    return productOnOnlineChannel && productWithShoppingDestination;
}

const mapResourceToProduct = ({ productId, title, link, product, destinationStatuses, itemLevelIssues }) => {
    /** 
     * Prepare URL where product exists on user's shop website
     */
    const linkWithoutUtms = link && removeUtmSearchParams(link);

    /** 
     * URL to image of product
     */
    const { imageLink, targetCountry } = product ? product : {};

    /** 
     * Prepare product errors which user may see
     */
    const [errors, errorWithNotClaimedWebsite] = getErrorsFromItemLevelIssues(itemLevelIssues);

    /** 
     * Determine the status of product acceptance in Google
     */
    const shoppingDestinationStatus = destinationStatuses && destinationStatuses.find(({ destination }) => destination === 'Shopping');
    const errorsCount = errors ? errors.length : 0;

    const status = determineProductStatus(shoppingDestinationStatus, errorsCount, errorWithNotClaimedWebsite)

    return {
        id: productId,
        name: title,
        url: linkWithoutUtms,
        imageUrl: imageLink,
        errors: errors,
        status: status,
        targetCountryCode: targetCountry,
    }
}

const getErrorsFromItemLevelIssues = (itemLevelIssues) => {
    let errorWithNotClaimedWebsite = false;

    const errors = itemLevelIssues 
        && itemLevelIssues
        .filter(({ destination, code }) => {
            const errorWithShoppingDestination = destination === 'Shopping';

            /**
             * Why boolean errorWithNotClaimedWebsite is determined here?
             * 
             * It is needed for determination of product status later on. 
             * 
             * More specifically:
             * There is servability attribute of itemLevelIssue, which may be set to 'disapproved' or 'unaffected' and based on this attribute
             * and code === 'homepage_not_claimed', if there is no more destinationStatuses with 'disapproved' servability, we would then conclude
             * that the product status is 'approved', no matter if from Google API we obtained 'disapproved' in destinationStatuse of Shopping destination.
             * But unfortunately documentation does not say explicitly, that product status of Shopping destination (from destinationStatuses) is 'disapproved'
             * only if at least one itemLevelIssue servability is 'disapproved' (issue with Shopping destination), therefore filter out issue from 
             * error list is ok, as we do not want user to see error with 'homepage_not_claimed', but we should then set errorWithNotClaimedWebsite = true, 
             * to be used later in status determination.
             */
            if (code === 'homepage_not_claimed') {
                errorWithNotClaimedWebsite = true;
                return false;
            }
            
            return errorWithShoppingDestination;
    })
    .map(({ description, detail, documentation, servability }) => {
        return {
            errorDescription: description,
            solutionDescription: detail,
            solutionReferenceUrl: documentation,
            solutionStatus: servability
        }
    });

    return [errors, errorWithNotClaimedWebsite];
}

const determineProductStatus = (shoppingDestinationStatus, errorsCount, errorWithNotClaimedWebsite) => {
    const { approvalStatus, approvalPending } = shoppingDestinationStatus;

    if (errorsCount === 0 && errorWithNotClaimedWebsite) {
        return 'websiteClaimInProgress'
    } else if (approvalStatus === 'disapproved' && !approvalPending) {
        return 'disapproved'
    } else if (approvalStatus === 'disapproved' && approvalPending) {
        return 'waitingForGoogle'
    } else {
        return 'approved'
    }
}