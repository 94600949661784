// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Custom Components
import DynamicCreateCampaignWrapperContainer from '../../../../container/DynamicCreateCampaignWrapperContainer';
import ButtonWithLogoComponent from '../../../ButtonWithLogoComponent';
import ButtonWithLogoAddCampaign from '../../../ButtonWithLogoComponent/decorated/ButtonWithLogoAddCampaign';
import MaterialButtonComponent from '../../../MaterialComponents/MaterialButtonComponent';

// Stylesheet
import styles from './index.module.less';

const MainCreateCampaignGatewayComponent = ({ history, setTargetChannel }) => {

    const [ selection, setSelection ] = useState(null);

    const onClickNext = () => {
        setTargetChannel(selection);
        history.push(`/campaigns/addCampaign/${selection}`);
    };

    return (
        <DynamicCreateCampaignWrapperContainer designation="main">
            <section className={styles.container}>
                <header className={styles.header}>
                    <h1 className={styles.headerTitle}>
                        <strong className={styles.headerTitleBold}>Choose target platform</strong>
                        for your new campaign
                    </h1>
                </header>
                <main className={styles.content}>
                    <div className={styles.contentSelection}>
                        <ButtonWithLogoComponent 
                            onClick={() => setSelection('google')}
                            config={{
                                type: 'google',
                                name: 'G Logo',
                                text: 'Create campaign for',
                                textBold: 'Google'
                            }}
                            isActive={selection === 'google'}
                        />
                        <ButtonWithLogoAddCampaign
                            onClick={() => setSelection('facebook')}
                            config={{
                                type: 'facebook',
                                name: 'FB Logo',
                                text: 'Create campaign for',
                                textBold: 'Facebook'
                            }}
                            isActive={selection === 'facebook'}
                        />
                    </div>
                    <div className={styles.contentButtons}>
                        <MaterialButtonComponent
                            text='Next'
                            size="xLarge"
                            type={'primary'}
                            disabled={selection ? false : true}
                            onClick={onClickNext}
                        />
                    </div>
                </main>
            </section>
        </DynamicCreateCampaignWrapperContainer>
    );
};

MainCreateCampaignGatewayComponent.propTypes = {
    setTargetChannel: PropTypes.func.isRequired
};

export default MainCreateCampaignGatewayComponent;