import AccountChangePaymentAuthContainer from "./AccountChangePaymentAuthContainer";
import { setError } from "../../ducks/app";
import { fetchBraintreeToken, createCustomerWithPaymentAuthorization, actionSetIsNonceReadyForUpdate, validateBraintreeHostedFieldsEnvelope, validateBraintreeClientInstanceEnvelope } from "../../ducks/payment";
import { changePaymentAuth, actionSetShouldSummaryKnowOfCardUpdate, actionConfirmSuccessfulPaymentAuthUpdate, clearPayPalEmail } from '../../ducks/subscription';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { shopIdSelector, planIdFromSubSelector } from "../../utils/reduxSelectors";
import { validateBraintreeInstanceEnvelope } from './../../ducks/payment';

const mapStateToProps = state =>
    ({
        braintreeToken: state.payment.braintreeToken,
        localHostedFieldsInstance: state.payment.localHostedFieldsInstance,
        isNonceReadyForUpdate: state.payment.isNonceReadyForUpdate,
        subscriptionId: state.subscription.content.id,
        paymentAuthSuccessful: state.subscription.paymentAuthSuccessful,
        clientNonce: state.payment.clientNonce,
        shopId: shopIdSelector(state.auth.user),
        planId: planIdFromSubSelector(state),
        summaryShouldKnowOfCardUpdate: state.subscription.summaryShouldKnowOfCardUpdate
    });
const mapDispatchToProps = {
    fetchBraintreeToken,
    setError,
    createCustomerWithPaymentAuthorization,
    changePaymentAuth,
    actionSetIsNonceReadyForUpdate,
    actionSetShouldSummaryKnowOfCardUpdate,
    actionConfirmSuccessfulPaymentAuthUpdate,
    validateBraintreeHostedFieldsEnvelope,
    validateBraintreeClientInstanceEnvelope,
    validateBraintreeInstanceEnvelope,
    clearPayPalEmail
};

const AccountChangePaymentAuth = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountChangePaymentAuthContainer));

export default AccountChangePaymentAuth;