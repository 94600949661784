import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { OnboardingTexts } from '../../../utils/texts';
import { googleAdsLocations, googleAdsLocationCodes } from '../../../constants/googleAdsLocations';
import { setCreditCardVendorLogo, onboardingGoogleSlideTitles, onboardingFacebookSlideTitles, getAdjustedCreditCardVendor }from '../../../utils/onboarding';
import { addCampaignSlideTitles } from '../../../utils/main';
import { isDesignationOnboarding } from '../../../utils/utils';
import { sendAmplitudeEvent } from "../../../utils/amplitude";
import { amplitudeEvents } from "../../../constants/amplitudeEvents";
import { imgSrcPicker } from '../../../utils/onboarding';

// Third Party Modules
import { Spin } from 'antd';

// Custom Components
import DynamicCreateCampaignWrapperContainer from '../../../container/DynamicCreateCampaignWrapperContainer'
import CreateCampaignHeader from '../../../components/OnboardingCreateCampaign/CreateCampaignHeaderComponent';
import CreateCampaignStepper from '../../../components/MaterialComponents/OnboardingMaterialComponents/CreateCampaignStepperComponent/index';
import MaterialButtonComponent from '../../../components/MaterialComponents/MaterialButtonComponent/index';

// Stylesheet
import style from './index.module.less';
import { acceptedPaymentMethods } from '../../../styles/constants';
import VendorLogoComponent from './../../VendorLogoComponent/index';

const FinishContainer = ({
    history,
    countryCode,
    budgetValue,
    campaignName,
    clientNonce,
    creditCardVendorNameForSummary,
    actionSetLastVisitedPath,
    lastFourPaymentCardDigits,
    user,
    subscription,
    platform,
    planId,
    wooCommerceEmail,
    wooCommerceOwnerName,
    wooCommerceShopName,
    gAccountMc,
    gAccountAds,
    campaigns,
    activateSubscription,
    setError,
    setWooCommerceDataPackageToBackend,
    createGoogleAccountAds,
    createGoogleAccountMc,
    createCampaign,
    addNewCampaign,
    setUserFinishedOnboarding,
    designation = 'onboarding',
    selection = 'google',
    clearAddCampaignState,
    firstAcceptedPaymentMethodType
    }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDataFetched, setIsDataFetched ] = useState(false);

    const onboardingSlideTitles = selection === 'google' ? onboardingGoogleSlideTitles : onboardingFacebookSlideTitles;
    const steps = isDesignationOnboarding(designation) ? onboardingSlideTitles : addCampaignSlideTitles;
    const { title, infoButtonText } = OnboardingTexts.finishSlide;
    /** user[platform].myshopifyDomain || user[platform].domain - handles the expected shape of backend-fetched user object */
    const paymentMethod = firstAcceptedPaymentMethodType;
    const userDomain = user[platform].myshopifyDomain || user[platform].domain;
    const userEmail = user[platform].email || wooCommerceEmail;

    useEffect(() => {
        if (isDataFetched) {
            setUserFinishedOnboarding(platform)
        }
    }, [isDataFetched, platform, setUserFinishedOnboarding]);

    /** Migrated legacy user registration method */
    const onFinishClickOnboardingGoogle = async () => {
        setIsLoading(true);
        // save plan and credit card, just if it doesn't exist
        if (!subscription.content || subscription.error) {
            // activateSubscription requires some props, check if they are available
            if (platform === 'woocommerce' && wooCommerceEmail && userDomain) {
                await setWooCommerceDataPackageToBackend({ wooCommerceEmail, wooCommerceOwnerName, wooCommerceShopName, userDomain });
            }
            if (clientNonce && user[platform] && userDomain && userEmail) {
                await activateSubscription(userDomain, planId, clientNonce, userEmail, paymentMethod);
            } else {
                console.error(`Activate subscription failure - the following parameters do not exist: ${!userDomain && 'userDomain'} ${!userEmail && 'userEmail'} ${!planId && 'planId'} ${!clientNonce && 'clientNonce'}`);
                setError(new Error(`Activate subscription failure - the following parameters do not exist: ${!userDomain && 'userDomain'} ${!userEmail && 'userEmail'} ${!planId && 'planId'} ${!clientNonce && 'clientNonce'}`))
            }
        }
        // create Google Ads, just if it doesn't exist
        if (!gAccountAds.content) {
            await createGoogleAccountAds();
        }
    
        // create Google MC, just if it doesn't exist
        if (!gAccountMc.content) {
            await createGoogleAccountMc();
        }
    
        // save campaign, just if it doesn't exist
        const isCampaignCreationNeeded = !campaigns.content || campaigns.content.length === 0;
        if (isCampaignCreationNeeded) {
            await createCampaign(budgetValue, countryCode, [countryCode]);
        }
        sendAmplitudeEvent(amplitudeEvents.ONBOARDING_FINISHED);
        setIsDataFetched(true);
    };

    /** Legacy END */
    const onFinishClickOnboardingFacebook = async () => {
        await activateSubscription(userDomain, planId, clientNonce, userEmail, paymentMethod);
        setIsLoading(true);
        await createCampaign({ campaignName, countryCode });
        setIsDataFetched(true);
    };

    const onFinishClickMainGoogle = async () => {
        await createCampaign(budgetValue, countryCode, [countryCode]);
        clearAddCampaignState();
        history.push('/campaigns');
    };

    const onFinishClickMainFacebook = async () => {
        await createCampaign(campaignName, countryCode);
        clearAddCampaignState();
        history.push('/campaigns');
    };

    const onBackClick = () => {
        actionSetLastVisitedPath(history.location.pathname);
        const url = selection === 'google' ? '/onboarding/google/createCampaign/setPaymentAuthorization' : '/onboarding/facebook/createCampaign/setPaymentAuthorization';
        history.push(url);
    };

    const onBackClickMain = () => {
        const url = selection === 'google' ? '/campaigns/addCampaign/google/chooseBudget' : '/campaigns/addCampaign/facebook/chooseCountry';
        history.push(url);
    };

    const translateCountryCode = () => countryCode ? googleAdsLocations.filter(location => location.value===countryCode)[0].name : 'unset';

    const onFinishClickOnboarding = selection === 'google' ? onFinishClickOnboardingGoogle : onFinishClickOnboardingFacebook;
    const onFinishClickMain = selection === 'google' ? onFinishClickMainGoogle : onFinishClickMainFacebook;

    return (
        <DynamicCreateCampaignWrapperContainer designation={designation}>
            <section className={style.wrapper}>
                <CreateCampaignStepper
                    activeStep={steps.indexOf("Summary")}
                    steps={steps}
                    designation={designation}
                />
                <CreateCampaignHeader title={title} infoButtonText={infoButtonText} />

                    <div className={style.content}>
                    <h4 className={style.contentTitle}>Summary:</h4>
                    <div className={style.contentSectionException}>
                        <div className={style.contentSectionItem}>
                            <p className={style.contentSectionParagraph}>Campaign for:</p>
                            <p className={style.contentSectionParagraph}>
                                <strong className={style.value}>{selection[0].toUpperCase() + selection.slice(1)}</strong>
                            </p>
                        </div>
                        <img className={style.logo} alt="Selected ad platform logo" src={imgSrcPicker(selection)} />
                    </div>
                    <hr className={style.contentBreak} />
                    <div className={style.contentSection}>
                        <p className={style.contentSectionParagraph}>Country of advertising</p>
                        <p className={style.contentSectionParagraph}>
                        <strong className={style.value}>
                            {translateCountryCode()}
                        </strong>
                        </p>
                    </div>
                    <hr className={style.contentBreak} />
                    <div className={style.contentSection}>
                        <p className={style.contentSectionParagraph}>{selection === 'google' ? 'Daily budget' : 'Campaign name'}</p>
                        <p className={style.contentSectionParagraph}>
                            <strong className={style.value}>
                                {
                                    selection === 'google'
                                        ? (
                                            budgetValue ? `$ ${budgetValue}` : 'unset'
                                        ) : campaignName ? campaignName : 'unset'
                                }
                            </strong>
                        </p>
                    </div>
                    { isDesignationOnboarding(designation) ? ( <hr className={style.contentBreak} /> ) : null}
                    {
                        isDesignationOnboarding(designation) 
                            ? (
                                <div className={style.contentSection}>
                                    <p className={style.contentSectionParagraph}>Payment</p>
                                    <span className={style.contentSectionCardDetails}>
                                    <p className={style.contentSectionParagraph}>
                                        {
                                            firstAcceptedPaymentMethodType === acceptedPaymentMethods.payPal
                                                ? (
                                                    <VendorLogoComponent vendorName={'PayPal'} />
                                                )
                                                : (
                                                    <strong className={style.value}>
                                                        {`**** **** **** ${lastFourPaymentCardDigits}`}
                                                    </strong>
                                                )
                                        }
                                    </p>
                                    <img
                                        className={
                                        style[`contentSectionLogo${getAdjustedCreditCardVendor(creditCardVendorNameForSummary)}`]
                                        }
                                        src={setCreditCardVendorLogo(creditCardVendorNameForSummary)}
                                        alt="Your credit card vendor logo"
                                    />
                                    </span>
                                </div>
                            ) : null
                    }
                    <hr className={style.contentBreak} />
                    </div>

                <span className={style.buttons}>
                <MaterialButtonComponent
                    type={"secondary"}
                    size={"xSmall"}
                    text={"BACK"}
                    onClick={isDesignationOnboarding(designation) ? onBackClick : onBackClickMain}
                />
                <Spin spinning={isLoading}>
                    <MaterialButtonComponent
                        type={"primary"}
                        size={"large"}
                        text={"CREATE CAMPAIGN"}
                        disabled={selection === 'google' ? (!countryCode || !budgetValue) : (!countryCode || !campaignName)}
                        onClick={isDesignationOnboarding(designation) ? onFinishClickOnboarding : onFinishClickMain}
                    />
                </Spin>
                </span>
            </section>
        </DynamicCreateCampaignWrapperContainer>
    );
};

FinishContainer.propTypes = {
    countryCode: PropTypes.oneOf(googleAdsLocationCodes),
    budgetValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    campaignName: PropTypes.string,
    cardVendor: PropTypes.string,
    designation: PropTypes.oneOf(['onboarding', 'main']),
    clearAddCampaignState: PropTypes.func,
    history: PropTypes.object.isRequired,
    clientNonce: PropTypes.string.isRequired,
    creditCardVendorNameForSummary: PropTypes.string.isRequired,
    actionSetLastVisitedPath: PropTypes.func.isRequired,
    lastFourPaymentCardDigits: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    platform: PropTypes.string.isRequired,
    planId: PropTypes.string.isRequired,
    wooCommerceEmail: PropTypes.string,
    wooCommerceOwnerName: PropTypes.string,
    wooCommerceShopName: PropTypes.string,
    gAccountMc: PropTypes.object.isRequired,
    gAccountAds: PropTypes.object.isRequired,
    campaigns: PropTypes.object.isRequired,
    activateSubscription: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    setWooCommerceDataPackageToBackend: PropTypes.func.isRequired,
    createGoogleAccountAds: PropTypes.func.isRequired,
    createGoogleAccountMc: PropTypes.func.isRequired,
    createCampaign: PropTypes.func.isRequired,
    addNewCampaign: PropTypes.func,
    setUserFinishedOnboarding: PropTypes.func.isRequired,
    selection: PropTypes.string,
    firstAcceptedPaymentMethodType: PropTypes.string,
};

export default FinishContainer;
