// React
import React from "react";
import PropTypes from "prop-types";

// Third Party Modules
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';

// Styles
import { materialStyleGenerator } from "./index.material";

const MaterialRequirementCheckItemComponent = ({ itemId, checked, onChange, title, children }) => {
    const materialStyle = materialStyleGenerator()();

    const onCheck = (event, checked) => {
        onChange(itemId, checked);
    }

    return (
        <FormControlLabel
            className={materialStyle.formControlLabel}
            control={
                <Checkbox
                    className={materialStyle.checkbox}
                    color="default"
                    checked={checked}
                    onChange={onCheck}
                    checkedIcon={<CheckBoxOutlined />}
                />
            }
            id={itemId}
            labelstyle={{ zIndex: 3 }}
            label={(
                <label htmlFor={itemId}>
                    <span>{title}</span> 
                    {children}
                </label>
            )}
        />
    );
};

MaterialRequirementCheckItemComponent.propTypes = {
    itemId: PropTypes.any,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    title: PropTypes.node,
    children: PropTypes.node,
};

export default MaterialRequirementCheckItemComponent;