import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.less';
import { Link } from 'react-router-dom'

import Clear from '@material-ui/icons/Clear'

// Third-Party Modules
import useMediaQuery from '@material-ui/core/useMediaQuery'

// Custom Modules
import { breakpoints } from '../../../styles/constants';

//utils
import { shortenString } from '../../../utils/texts';

// Custom Components
import StatusIconComponent from '../StatusIconComponent'

// Assets
import GLogo from '../../../images/logo_G_color_240px.png';

const CampaignGoogleItemComponent = ({campaignName, campaignStatus, country, budget, createdAt, click, impression, cost, soldProducts, earnedMoney, showConversion, linkUrl}) => {

    const [showHint, setShowHint] = useState(campaignStatus==='PENDING');

    const breakpoint = `(min-width:${breakpoints.sm})`;
    const cssBreakpoint = useMediaQuery(breakpoint);
    const campaignNameBreakPoint = useMediaQuery(`(min-width:${breakpoints.xl})`)
    const adjustedCampaignName = campaignNameBreakPoint ? campaignName : shortenString(campaignName, 30);

    const dynamicPendingText = cssBreakpoint ?
        `We're creating your google shopping campaign :) Your products are on their way to Google Merchant Center. Google usually needs 3-5 business days to accept products.`
        : 'Your products have to be accepted. It can take from 3 to 5 days.'

    return (
        <div className={styles.campaign} tabIndex="0">
            <Link to={linkUrl}>
                <button className={`${styles.campaignInfo} ${showHint ? styles.topBorder: ''}`}>
                    <section className={styles.campaignInfoType}>
                        <img className={styles.campaignInfoTypeImg} src={GLogo} alt="G logo"></img>
                    </section>
                    <section className={styles.campaignInfoTexts}>
                        <div className={styles.campaignInfoTextsUpper}>
                            <span className={styles.campaignTitle}>
                                <img className={styles.campaignTitleIcon} src={GLogo} alt="G Logo" />
                                <p className={styles.campaignTitleName}>{campaignName}</p>
                            </span>
                            <span className={styles.campaignStatus}>
                                <StatusIconComponent status={campaignStatus}/>
                            </span>
                        </div>
                        <div className={styles.campaignInfoTextsLower}>
                            <p className={styles.campaignInfoTextsLowerText}>COUNTRY <span className={styles.campaignInfoTextsLowerBold}>{country}</span></p>
                            <p className={styles.campaignInfoTextsLowerText}>BUDGET <span className={styles.campaignInfoTextsLowerBold}>${budget}</span></p>
                            {
                                cssBreakpoint 
                                ? (
                                    <p className={styles.campaignInfoTextsLowerText}>CREATED <span className={styles.campaignInfoTextsLowerBold}>{createdAt}</span></p>
                                ) : null
                            }
                        </div>
                    </section>
                    <section className={`${styles.campaignInfoNumbers} ${campaignStatus==='PENDING' ? styles.giveOpacity : ''}`}>
                        <div className={styles.element}>
                            <p className={styles.number}>{click}</p>
                            <p className={styles.tittle}>CLICK</p>
                        </div>
                        <div className={styles.element}>
                            <p className={styles.number}>{impression}</p>
                            <p className={styles.tittle}>IMPRESSIONS</p>
                        </div>
                        <div className={styles.element}>
                            <p className={styles.number}>${cost}</p>
                            <p className={styles.tittle}>COST</p>
                        </div>
                        {showConversion && <>
                            <div className={styles.element}>
                                <p className={styles.number}>{soldProducts}</p>
                                <p className={styles.tittle}>SOLD</p>
                            </div>
                            <div className={styles.element}>
                                <p className={styles.number}>${earnedMoney}</p>
                                <p className={styles.tittle}>REVENUE</p>
                            </div>
                        </>}
                    </section>
                </button>
            </Link>
            {
                showHint &&
                <div className={styles.expectsCampaignInfo}>
                    <p className={styles.text}>{dynamicPendingText}</p>
                    <div className={styles.closeBtn}><Clear className={styles.clearItem} onClick={() => setShowHint(false)}/></div>
                </div>
            }
        </div>
    )
}

CampaignGoogleItemComponent.propTypes = {
    campaignName: PropTypes.string,
    campaignStatus: PropTypes.oneOf(['PENDING', 'ENABLED', 'PAUSED']),
    country: PropTypes.string,
    budget: PropTypes.string,
    click: PropTypes.number,
    impression: PropTypes.number,
    cost: PropTypes.number,
    soldProducts: PropTypes.number,
    earnedMoney: PropTypes.number,
    showConversion: PropTypes.bool,
}

CampaignGoogleItemComponent.defaultProps = {
    campaignName : 'Campaign name',
    campaignStatus : 'PENDING',
    country: 'Country name',
    budget: '0',
    click: 0,
    impression: 0,
    cost: 0,
    soldProducts: 0,
    earnedMoney: 0,
    showConversion: false,
}

export default CampaignGoogleItemComponent;