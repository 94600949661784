// React
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';

// Stylesheet
import styles from './index.module.less';
import { useStylesIcon } from './index.material';

const MaterialCircularAddButtonComponent = ({ onClick }) => {
    const iconClasses = useStylesIcon();

    return (
        <IconButton onClick={onClick}>
            <div className={styles.container}>
                <Add className={iconClasses.root} />
            </div>
        </IconButton>
    );
};

MaterialCircularAddButtonComponent.propTypes = {
    onClick: PropTypes.func
};

export default MaterialCircularAddButtonComponent;
