// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import config from "../../../config";

// Third Party Modules
import RcQueueAnim from "rc-queue-anim";

// Custom Modules
import {
  readFromLocalStorage,
  setToLocalStorage,
  removeFromLocalStorage,
  isPlatformShopify
} from "../../../utils/utils";
import localStorageItems from "../../../constants/localStorageItems";

// Custom Components
import OnboardingContainer from "../../../container/OnboardingContainer";
import LoginCard from "../../../components/LoginCard";
import HorizontalViewWithLogoComponent from "../../../components/HorizontalViewWithLogoComponent";

// Stylesheet
import style from "./index.module.less";
import { getPlatformFromUrl } from "../../../utils/auth";

const LoginWithPlatformContainer = ({
  setPlatform,
  accessGranted,
  history,
  platform,
  checkWooCommerceStoreNameValidity,
  checkShopifyStoreNameValidity
}) => {
  const [chosenPlatform, setChosenPlatform] = useState(
    getPlatformFromUrl(history.location.pathname)
  );

  // constants
  const initialStoreName = readFromLocalStorage(
    localStorageItems.LOGIN_STORE_NAME
  );
  /** The last fallback option is to cover the rare case, when user lands directly
   * on /login/${platform}
   */

  /* Platform may either be already  accessible from props, if the user is logging for the first time
    (had to choose a platform on the previous view), or is available in LocalStorage(then, the previous
    view component was omitted)   */

  /** TODO: useEffect logic may need a refactor, if we are using regex to chose platform,
   * we dont need to use LocalStorage and platform prop */
  useEffect(() => {
    const platformFromLocalStorage = readFromLocalStorage(
      localStorageItems.PLATFORM
    );
    if (platform) {
      setChosenPlatform(platform);
    } else if (platformFromLocalStorage) {
      setChosenPlatform(platformFromLocalStorage);
    }
    return () => removeFromLocalStorage(localStorageItems.PLATFORM);
  }, []);

  // Methods
  const onClickNext = (inputValue, isInputValueValid) => {
    if (isInputValueValid) {
      if (!isPlatformShopify(chosenPlatform)) {
        const { store } = inputValue;
        setToLocalStorage(localStorageItems.LOGIN_STORE_NAME, store);
        setPlatform(chosenPlatform);
        window.location.href = `${config.API_URL}/auth/woocommerce?shopId=${store}`;
      } else if (isPlatformShopify(chosenPlatform)) {
        setToLocalStorage(localStorageItems.LOGIN_STORE_NAME, inputValue);
        setPlatform(chosenPlatform);
        window.location.href = `${
          config.API_URL
        }/auth/shopify?shopId=${inputValue}${".myshopify.com"}`;
      }
    }
  };

  const checkStoreNameValidity = async inputValue => {
    if (!isPlatformShopify(chosenPlatform)) {
      const regex = /^.*\..*$/g;
      const isFormatValid = regex.test(inputValue);

      if (isFormatValid) {
        return await checkWooCommerceStoreNameValidity(inputValue);
      } else {
        return { value: false };
      }
    } else if (isPlatformShopify(chosenPlatform)) {
      return await checkShopifyStoreNameValidity(inputValue);
    }
  };

  return accessGranted ? (
    <Redirect to="/" />
  ) : (
    <OnboardingContainer displayMode="showHeaderMobile">
      <HorizontalViewWithLogoComponent>
        <div className={style.login}>
          <LoginCard
            key="1"
            initialLoginValue={initialStoreName}
            checkStoreNameValidity={checkStoreNameValidity}
            onClickNext={onClickNext}
            platform={chosenPlatform}
            history={history}
            setPlatform={setPlatform}
          />
        </div>
      </HorizontalViewWithLogoComponent>
    </OnboardingContainer>
  );
};

LoginWithPlatformContainer.propTypes = {
  platform: PropTypes.string
};

export default LoginWithPlatformContainer;
