import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.less';
import clsx from 'clsx';

const NoResultViewComponent = ({ headerText, subText, children }) => {

    return (
        <div className={styles.container}>
            <div>
                <p className={styles.largeText}>{ headerText }</p>
                <p className={styles.smallText}>{ subText }</p>
            </div>
            <div className={styles.section}>
                {children}
            </div>
            <p className={ styles.smallText }>If you have questions, <span className={ clsx(styles.openIntercomButton, 'OPEN_INTERCOM') }> us.</span></p>
        </div>
    )
}

NoResultViewComponent.propTypes = {
    headerText : PropTypes.string,
    subText: PropTypes.string,
}

export default NoResultViewComponent;