// React
import React, { useEffect } from 'react';

// Custom Components
import VerificationGateway from '../../../components/VerificationGateway';
import Pricing from '../../../pages/Pricing';
import HorizontalViewWithLogoComponent from '../../../components/HorizontalViewWithLogoComponent';
import OnboardingContainer from '../../../container/OnboardingContainer';
import WoocommerceUserDetailsCollector from '../../../components/WoocommerceUserDetailsCollector/index';
import ErrorPage from '../../ErrorPage';

const AwaitTokenContainer = ({ wooCommerceEmail, wooCommerceOwnerName, wooCommerceShopName, saveWooCommerceUserInfo, networkError, history, planId, logOut }) => {

	useEffect(() => {
		if ( wooCommerceEmail && wooCommerceOwnerName && wooCommerceShopName ) {
			history.push('/');
		}
	}, [ wooCommerceEmail, wooCommerceOwnerName, wooCommerceShopName ]);

	const onUserResigned = () => {
		logOut();
		history.push('/pricing');
	}

	return (
		networkError ? 
		(
			<ErrorPage title='Something went wrong' description={networkError && networkError.message} />
		) : (
			<VerificationGateway platform="woocommerce">
				{
					planId ? (
						<OnboardingContainer displayMode={'showHeaderMobile'} >
                                <HorizontalViewWithLogoComponent>
								<WoocommerceUserDetailsCollector
									inputReferenceTable={{
										email: 'Your email',
										userName: 'Your username',
										storeName: 'Store name'
									}}
									onConfirm={saveWooCommerceUserInfo}
									onUserResigned={onUserResigned}
								/>
                                </HorizontalViewWithLogoComponent>
						</OnboardingContainer>
					) : (
						<Pricing mode={'awaitToken'} />
					)
				}
			</VerificationGateway>
		)
	);
};

export default AwaitTokenContainer;