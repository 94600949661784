import React, { useState, useLayoutEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { PropTypes } from "prop-types";
import { materialStyleGenerator } from "./materialStyleOverride";

const MaterialInputComponent = ({
  placeholder,
  caption,
  type = "text",
  label,
  onChange,
  onFocus,
  onBlur,
  value,
  error,
  size = "small",
  marginBottom,
  prefix,
  suffix,
  select,
  selectOptionsOpen,
  inputProps,
  inputLabelProps,
  autoFocus,
  shrinkException,
  formValue,
  updateInvoiceFormField,
  formKey,
  clearInvoiceDataError,
  ...rest
}) => {
  /** Someone else is the author of the comments below - I don't know what it means, so I won't delete it - mk*/
  const adjustedValue = marginBottom ? value : value || ""; // fix for awaitToken inputs - some weird behaviour without it
  const adjustedFormValue = formValue ? formValue : '';

  const getStartAdornment = () => {
    return (
      prefix && (
        <InputAdornment position="start" className={materialStyle.prefix}>
          {prefix}
        </InputAdornment>
      )
    );
  };

  const getEndAdornment = () => {
    if (select) {
      return (
        <ArrowDropDownIcon
          className={`${materialStyle.selectIcon} ${
            selectOptionsOpen ? materialStyle.selectIconOpened : ""
          }`}
        />
      );
    }
    return suffix && <InputAdornment position="end">{suffix}</InputAdornment>;
  };

  const onChangeInForm = e => {
    clearInvoiceDataError();
    updateInvoiceFormField(formKey, e.target.value);
  };

  const [shrink, setShrink] = useState(
    shrinkException ? false : (inputProps && inputProps.inputProps && inputProps.inputProps.shrink)
  );

  useLayoutEffect(() => {
    if (shrinkException) {
      setShrink(false);
    }
  }, [shrinkException]);

  /** Someone else is the author of the comments below - I don't know what it means, so I won't delete it - mk*/
  // Fix for case in which adornment exists and is showed synamically - may appear and disappear
  const shrinkLabel = event => {
    setShrink(true);
    // inherit other behaviours
    inputProps && inputProps.onFocus && inputProps.onFocus(event);
    inputProps &&
      inputProps.inputProps &&
      inputProps.inputProps.onFocus &&
      inputProps.inputProps.onFocus(event);
  };

  /** Someone else is the author of the comments below - I don't know what it means, so I won't delete it - mk*/
  // Fix for case in which adornment exists and is showed synamically - may appear and disappear
  const unShrinkLabel = event => {
    if (event.target.value.length === 0 && !prefix) {
      setShrink(false); //gotta make sure the input is empty before shrinking the label
    }
    // inherit other behaviours
    inputProps && inputProps.onBlur && inputProps.onBlur(event);
    inputProps &&
      inputProps.inputProps &&
      inputProps.inputProps.onBlur &&
      inputProps.inputProps.onBlur(event);
  };

  useLayoutEffect(() => {
  }, [formValue]);

  const inputPropsProps = inputProps ? inputProps.inputProps : {};

  const materialStyle = materialStyleGenerator({marginBottom})();

  const isInputInForm = formKey ? true : false;

  if (select) {
    return (
      <TextField
        autoFocus={autoFocus}
        onChange={onChange}
        InputLabelProps={{
          ...inputLabelProps,
          shrink: shrink,
          className: `${
            selectOptionsOpen ? materialStyle.selectLabelFocused : ""
          }`
        }}
        type={type}
        variant="outlined"
        label={label}
        placeholder={placeholder}
        helperText={caption}
        margin="normal"
        className={`${materialStyle.root} ${materialStyle[`${size}Size`]}`}
        InputProps={{
          className: `${
            selectOptionsOpen ? materialStyle.selectInputFocused : ""
          }`,
          startAdornment: getStartAdornment(),
          endAdornment: getEndAdornment(),
          inputProps: {
            ...inputPropsProps,
            onFocus: shrinkLabel,
            onBlur: unShrinkLabel
          },
          ...inputProps
        }}
        error={error}
        {...rest}
      />
    );
  } else if (isInputInForm) {
    return (
      <TextField
        autoFocus={autoFocus}
        onChange={onChangeInForm}
        value={adjustedFormValue}
        type={type}
        variant="outlined"
        label={label}
        placeholder={placeholder}
        helperText={caption}
        margin="normal"
        className={`${materialStyle.root} ${materialStyle[`${size}Size`]}`}
        InputProps={{
          startAdornment: getStartAdornment(),
          endAdornment: getEndAdornment(),
          ...inputProps
        }}
        error={error}
        {...rest}
      />
    );
  }
  return (
    <TextField
      autoFocus={autoFocus}
      onChange={onChange}
      value={adjustedValue}
      type={type}
      variant="outlined"
      label={label}
      placeholder={placeholder}
      helperText={caption}
      margin="normal"
      className={`${materialStyle.root} ${materialStyle[`${size}Size`]}`}
      InputProps={{
        startAdornment: getStartAdornment(),
        endAdornment: getEndAdornment(),
        ...inputProps
      }}
      error={error}
      {...rest}
    />
  );
};

MaterialInputComponent.propTypes = {
  placeholder: PropTypes.string,
  caption: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["small", "large", "flex"]),
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  select: PropTypes.bool,
  selectOptionsOpen: PropTypes.bool,
  inputProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  autoFocus: PropTypes.bool,
  shrinkException: PropTypes.bool,
  formValue: PropTypes.string,
  formKey: PropTypes.oneOf(["companyName", "firstName", "lastName", "address", "city", "zip", "vatNumber", "country"]),
  updateInvoiceFormField: PropTypes.func,
};

export default MaterialInputComponent;
