// React
import React from 'react'
import PropTypes from 'prop-types'

// Custom Modules
import PricingPlanHighlightComponent from './PricingPlanHeaderHighlightComponent';
import PricingPlanHeaderComponent from './PricingPlanHeaderComponent';
import PricingPlanButtonComponent from './PricingPlanButtonComponent';
import PricingPlanOptionsComponent from './PricingPlanOptionsComponent';

// Stylesheet
import style from './index.module.less';

const PricingPlanComponent = ({ id, name, value, description, options, buttonText, onSelect, highlighted }) => {
  const getClassNames = () => {
    const plan = style.plan;
    const planHighlighted = highlighted ? style.planHighlighted : '';
    return `${plan} ${planHighlighted}`;
  }

  return (
    <section className={getClassNames()}>
      {highlighted && 
        <PricingPlanHighlightComponent>
          {highlighted.text}
        </PricingPlanHighlightComponent>
      }
      <PricingPlanHeaderComponent
        name={name}
        value={value}
        description={description} />
      <PricingPlanButtonComponent
        highlighted={Boolean(highlighted)}
        onClick={onSelect} >
        {buttonText}
      </PricingPlanButtonComponent>
      <PricingPlanOptionsComponent 
        options={options} />
    </section>
  )
}

PricingPlanComponent.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  description: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string,
  onSelect: PropTypes.func,
  highlighted: PropTypes.shape({
    text: PropTypes.string,
    buttonPrimary: PropTypes.bool
  })
}

export default PricingPlanComponent

