import { addClass, removeClass } from "../../utils/utils";

/** Label-specific methods */

export const generateStyleAlteringMethods = ({ style }) => {
	const moveLabelUp = ({ hostedFieldLabel,
	}) => {
		addClass(hostedFieldLabel, style.hostedFieldLabelMoveUp);
	};

	const moveLabelDown = ({ hostedFieldLabel,
	}) => {
		removeClass(hostedFieldLabel, style.hostedFieldLabelMoveUp);
	};

	/* Style removal methods */

	const removeHostedFieldStyleOnError = ({
		hostedFieldLabel,
		hostedFieldInput,
	}) => {
		removeClass(hostedFieldLabel, style.paintHostedFieldLabelRedOnError);
		removeClass(hostedFieldInput, style.paintHostedFieldInputBorderRedOnError);
	};
	const removeHostedFieldStyleOnBlur = ({
		hostedFieldLabel,
		hostedFieldInput,
	}) => {
		removeClass(hostedFieldInput, style.loseHostedFieldInputHighlight);
		removeClass(hostedFieldLabel, style.loseHostedFieldLabelHighlight);
	};
	const removeHostedFieldStyleOnFocus = ({
		hostedFieldLabel,
		hostedFieldInput,
	}) => {
		removeClass(hostedFieldInput, style.setFocusHighlightForForHostedFieldInput);
		removeClass(hostedFieldLabel, style.setFocusHighlightForHostedFieldLabel);
	};

	const removeAllHostedFieldStyles = ({
		hostedFieldInput,
		hostedFieldLabel,
	}) => {
		/** Clear all the currently applied styles in order to set some new ones */
		[
			removeHostedFieldStyleOnFocus,
			removeHostedFieldStyleOnError,
			removeHostedFieldStyleOnBlur
		].forEach(removalFunction =>
			removalFunction({ hostedFieldInput, hostedFieldLabel })
		);
	};


	/** Setters */

	const setOnFocusStyleForHostedField = ({
		hostedFieldLabel,
		hostedFieldInput
		}) => {
		removeAllHostedFieldStyles({
			hostedFieldInput,
			hostedFieldLabel
		});
		addClass(hostedFieldInput, style.setFocusHighlightForForHostedFieldInput);
		addClass(hostedFieldLabel, style.setFocusHighlightForHostedFieldLabel);
	};

	const setOnErrorStyleForHostedField = ({
		hostedFieldLabel,
		hostedFieldInput
	}) => {
		removeAllHostedFieldStyles({
			hostedFieldInput,
			hostedFieldLabel
		});
		addClass(hostedFieldLabel, style.paintHostedFieldLabelRedOnError);
		addClass(hostedFieldInput, style.paintHostedFieldInputBorderRedOnError);
	};

	const setOnBlurStylingForHostedField = ({
		hostedFieldLabel,
		hostedFieldInput
	}) => {
		removeAllHostedFieldStyles({
			hostedFieldInput,
			hostedFieldLabel
		});
		addClass(hostedFieldInput, style.loseHostedFieldInputHighlight);
		addClass(hostedFieldLabel, style.loseHostedFieldLabelHighlight);
	};





	return {
		moveLabelUp,
		moveLabelDown,
		removeAllHostedFieldStyles,
		setOnFocusStyleForHostedField,
		setOnErrorStyleForHostedField,
		setOnBlurStylingForHostedField
	};
};
