export const initializeThreeDSecureInstance = ({
	braintreeThreeDSecureCreator,
	braintreeClientInstance
}) => {
	return new Promise(function (resolve) {
		braintreeThreeDSecureCreator.create({ client: braintreeClientInstance, version: 2 }, (braintreeThreeDSSecureInstanceCreationError, threeDSecureInstance) => {
			if (braintreeThreeDSSecureInstanceCreationError) {
				return resolve({ value: null, error: braintreeThreeDSSecureInstanceCreationError });
			} else {
				return resolve({
					value: threeDSecureInstance, error: null
				});
			}
		})
	})

};