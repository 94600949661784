// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setTargetChannel } from '../../../ducks/additionalCampaign/methods';

import MainCreateCampaignGatewayComponent from './MainCreateCampaignGatewayComponent';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    setTargetChannel
};

const MainCreateCampaignGateway = withRouter(connect(mapStateToProps, mapDispatchToProps)(MainCreateCampaignGatewayComponent));

export default MainCreateCampaignGateway;