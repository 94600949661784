import React from 'react'
import PropTypes from 'prop-types'
import style from './index.module.less';
import clsx from 'clsx';

function PaymentMethodButtonComponent({ logo, title, onClick, buttonStyle }) {
	return (
		<div >
			<p className={style.PaymentTitle}>
				{title}
			</p>
			<button onClick={onClick} className={clsx(buttonStyle, style.PaymentMethodButtonComponent)}>
				{logo}
			</button>
		</div>
	)
}

PaymentMethodButtonComponent.propTypes = {
	title: PropTypes.string.isRequired,
	logo: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	buttonStyle: PropTypes.string,
}

export default PaymentMethodButtonComponent

