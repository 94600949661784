import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import braintreeClientCreator from "braintree-web/client";
import { useCallback } from "react";
import { initializeBraintreeClient } from "../../../module/BraintreeModule/initializeBraintreeClient";
import style from "./index.module.less";
import OnboardingContainer from "./../../OnboardingContainer/index";
import CreateCampaignStepper from "./../../../components/MaterialComponents/OnboardingMaterialComponents/CreateCampaignStepperComponent/index";
import { onboardingGoogleSlideTitles, onboardingFacebookSlideTitles } from "../../../utils/onboarding";
import { acceptedPaymentMethods } from "../../../styles/constants";
import MaterialButtonComponent from "./../../../components/MaterialComponents/MaterialButtonComponent/index";
import { Spin } from "antd";
import { initializePayPalButtons } from "../../../module/BraintreeModule/initializePayPal";
import { spinnerStyle } from "./../../../styles/constants";
import PayPalButtonsComponent from "../../../components/PayPalButtonsComponent";
import PayPalSuccessfulAuthorizationComponent from "../../../components/PayPalSuccessfulAuthorizationComponent";
import { OnboardingTexts } from "../../../utils/texts";
import CreateCampaignHeader from "./../../../components/OnboardingCreateCampaign/CreateCampaignHeaderComponent/index";

function PayPalContainer({
    fetchBraintreeToken,
    history,
    braintreeToken,
    createCustomerWithPaymentAuthorization,
    setFirstAcceptedPaymentMethodType,
    validateBraintreeClientInstanceEnvelope,
    returnToPaymentMethodSelection,
    clearAlreadyCachedPaymentCardDetails,
    validatePayPalButtonInstance,
    firstAcceptedPaymentMethodType,
    clientNonce,
    payPalButtonsTopMarginStyle,
    selection = 'google'
}) {
    const steps = selection === 'google' ? onboardingGoogleSlideTitles : onboardingFacebookSlideTitles;

    const [isLoading, setIsLoading] = useState(true);
    const [authorizationWasRejected, setAuthorizationWasRejected] = useState(
        false
    );
    const [payPalButtonsInitialized, setPayPalButtonsInitialized] = useState(
        false
    );
    const shouldPayPalSuccessfulAuthViewBeRendered =
        clientNonce &&
        firstAcceptedPaymentMethodType === acceptedPaymentMethods.payPal;

    const handlePayPalSuccessfulAuthorization = useCallback(
        ({ tokenizedPayPalAccount }) => {
            setIsLoading(true);
            const { nonce: clientNonce } = tokenizedPayPalAccount;

            createCustomerWithPaymentAuthorization(clientNonce);
            setFirstAcceptedPaymentMethodType({
                paymentMethod: acceptedPaymentMethods.payPal
            });
            clearAlreadyCachedPaymentCardDetails();
        },
        [
            clearAlreadyCachedPaymentCardDetails,
            createCustomerWithPaymentAuthorization,
            setFirstAcceptedPaymentMethodType
        ]
    );

    const onNextClick = () => {
        const url = selection === 'google' ? '/onboarding/google/createCampaign/finish' : '/onboarding/facebook/createCampaign/finish'
        history.push(url);
    };

    const initializePayPalWithBraintree = useCallback(async () => {
        const {
            value: braintreeClientInstance,
            error: braintreeClientInstanceCreationError
        } = await initializeBraintreeClient({
            braintreeClientCreator,
            braintreeToken
        });

        const {
            validatedBraintreeClientInstance
        } = await validateBraintreeClientInstanceEnvelope({
            envelope: {
                braintreeClientInstance,
                braintreeClientInstanceCreationError
            }
        });

        const { error: payPalButtonsCreationError } = await initializePayPalButtons(
            {
                validatedBraintreeClientInstance,
                handlePayPalSuccessfulAuthorization,
                setAuthorizationWasRejected,
                setPayPalButtonsInitialized,
                setIsLoading
            }
        );
        validatePayPalButtonInstance({
            envelope: { error: payPalButtonsCreationError }
        });

        setPayPalButtonsInitialized(true);
        setIsLoading(false);
    }, [
        braintreeToken,
        handlePayPalSuccessfulAuthorization,
        setPayPalButtonsInitialized,
        validateBraintreeClientInstanceEnvelope,
        validatePayPalButtonInstance
    ]);

    useEffect(() => {
        const shouldPayPalButtonsBeInitialized =
            braintreeToken &&
            !payPalButtonsInitialized &&
            !shouldPayPalSuccessfulAuthViewBeRendered;
        if (shouldPayPalButtonsBeInitialized) {
            setIsLoading(true);
            initializePayPalWithBraintree();
        } else {
            setIsLoading(false);
        }
        if (!braintreeToken) {
            fetchBraintreeToken();
        }
    }, [
        braintreeToken,
        fetchBraintreeToken,
        initializePayPalWithBraintree,
        payPalButtonsInitialized,
        shouldPayPalSuccessfulAuthViewBeRendered
    ]);

    const {
        paymentSlide: { title, infoButtonText }
    } = OnboardingTexts;

    return (
        <Spin spinning={isLoading} size="large" style={spinnerStyle}>
            <OnboardingContainer>
                <CreateCampaignStepper
                    activeStep={steps.indexOf("Payment")}
                    steps={steps}
                />
                <CreateCampaignHeader title={title} infoButtonText={infoButtonText} />
                <div className={style.buttonsNavWrapper}>
                    {shouldPayPalSuccessfulAuthViewBeRendered ? (
                        <PayPalSuccessfulAuthorizationComponent />
                    ) : (
                            <PayPalButtonsComponent
                                authorizationWasRejected={authorizationWasRejected} payPalButtonsTopMarginStyle={payPalButtonsTopMarginStyle}
                            />
                        )}
                    <div className={style.navigationRow}>
                        <MaterialButtonComponent
                            text="BACK"
                            type="secondary"
                            size="xSmall"
                            onClick={returnToPaymentMethodSelection}
                        />
                        <MaterialButtonComponent
                            text="NEXT"
                            type="primary"
                            size="xSmall"
                            onClick={onNextClick}
                            disabled={!shouldPayPalSuccessfulAuthViewBeRendered}
                        />
                    </div>
                </div>
            </OnboardingContainer>
        </Spin>
    );
}

PayPalContainer.propTypes = {
    fetchBraintreeToken: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    braintreeToken: PropTypes.string,
    createCustomerWithPaymentAuthorization: PropTypes.func.isRequired,
    setFirstAcceptedPaymentMethodType: PropTypes.func.isRequired,
    validateBraintreeClientInstanceEnvelope: PropTypes.func.isRequired,
    returnToPaymentMethodSelection: PropTypes.func.isRequired,
    clearAlreadyCachedPaymentCardDetails: PropTypes.func.isRequired,
    validatePayPalButtonInstance: PropTypes.func.isRequired,
    firstAcceptedPaymentMethodType: PropTypes.string,
    clientNonce: PropTypes.string
};

export default PayPalContainer;
