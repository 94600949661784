export const initializeBraintreeClient = async ({ braintreeClientCreator, braintreeToken }) => {
	return new Promise(function (resolve) {
		braintreeClientCreator.create(
			{
				authorization: braintreeToken
			},
			function braintreeClientInstanceCreationCallback(
				braintreeClientInstanceCreationError,
				braintreeClientInstance
			) {
				if (braintreeClientInstanceCreationError) {

					/** This kind of error can happen in case of: 1. invalid client application key, which is retrieved from backend, 2. network error.
					 * The consequence would be that HostedFieldInput fields would not be rendered at all, we show deadBee instead.
					 */
					return resolve({ value: null, error: braintreeClientInstanceCreationError });
				} else {

					resolve({
						value: braintreeClientInstance, error: null
					});
				}
			}
		);
	})

}
