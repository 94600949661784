// React
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Third Party Modules
import { Spin } from 'antd';

// Custom Components
import MaterialButtonComponent from '../../../MaterialComponents/MaterialButtonComponent';
import MaterialInputComponent from '../../../MaterialComponents/MaterialInputComponent';
import MaterialSimpleSelectComponent from '../../../MaterialComponents/MaterialSimpleSelectComponent';
import CampaignBudgetGuidanceComponent from '../../../CampaignBudgetGuidanceComponent';

// Stylesheet
import styles from './index.module.less';

const CampaignSettingContainer = ({ campaign, patchCampaign, toggleCampaignStatus }) => {

    // loader
    const [ isLoading, setIsLoading ] = useState(false);
    // budget
    const [ budget, setBudget ] = useState(campaign.dailyBudget);
    const [ isBudgetValid, setIsBudgetValid ] = useState(true);
    const [ isBudgetEnough, setIsBudgetEnough ] = useState(true);
    const [ validateHelpBudgetMessage, setvalidateHelpBudgetMessage ] = useState('');
    const [ showBudgetGuidance, setShowBudgetGuidance ] = useState(false);
    // name
    const [ name, setName ] = useState(campaign.name);
    const [ isNameValid, setIsNameValid ] = useState(true);
    const [ validateHelpNameMessage, setvalidateHelpNameMessage ] = useState('');
    // status
    const [ status, setStatus ] = useState(campaign.status);
    const isCampaignPending = campaign.status === 'PENDING';

    useLayoutEffect(() => {
        setIsLoading(false);
    }, [ campaign ]);

    useLayoutEffect(() => {
        if (name.length === 0) {
            setIsNameValid(false);
            setvalidateHelpNameMessage(`Campaign name can't be empty`);
        }
    }, [ name ])

    const validateBudget = event => {
        const value = event.target.value;
        if (!isFinite(value) || value < 1 || value > 1000) {
            setIsBudgetValid(false);
            setvalidateHelpBudgetMessage('Please insert valid budget');
        } else if (value < 10) {
            setIsBudgetValid(true);
            setIsBudgetEnough(false);
            setvalidateHelpBudgetMessage('A budget lower than 10$ will not be effective!');
        } else {
            setIsBudgetValid(true);
            setIsBudgetEnough(true);
            setvalidateHelpBudgetMessage('');
        }
        setBudget(value);
    };

    const onFocusBudgetInput = () => {
        setShowBudgetGuidance(true);
    }

    const handleNameChange = event => {
        setName(event.target.value);
        setIsNameValid(true);
        setvalidateHelpNameMessage('');
    };

    const onPatchCampaign = parameter => {
        let patchObject, isUpdateNeeded;

        if (parameter === 'budget') {
            patchObject = { newBudget: budget };
            isUpdateNeeded = campaign.dailyBudget !== patchObject.newBudget;
        } else if (parameter === 'name') {
            patchObject = { newName: name };
            isUpdateNeeded = campaign.name !== patchObject.newName;
        }   

        if (isUpdateNeeded) {
            setIsLoading(true);
            patchCampaign(campaign, patchObject);
        };
    };

    const onToggleStatus = () => {
        const request = status === 'ENABLED' ? 'start' : 'pause';
        if (status !== campaign.status) {
            toggleCampaignStatus(request, campaign);
        };
    };

    return (
        <Spin spinning={isLoading}>
            <section className={styles.container}>
                <div className={styles.card}>
                    <div>
                        <MaterialInputComponent
                            autoFocus={false} 
                            inputProps={{
                                onFocus: onFocusBudgetInput
                            }}
                            label="Budget"
                            size="large"
                            type="number"
                            prefix="$"
                            value={budget}
                            onChange={validateBudget}
                            error={!isBudgetValid || !isBudgetEnough}
                            caption={validateHelpBudgetMessage}
                        />
                        {showBudgetGuidance && <CampaignBudgetGuidanceComponent/>}
                    </div>
                    <MaterialButtonComponent
                        type="primary"
                        disabled={!budget || !isBudgetValid || budget === campaign.dailyBudget}
                        size={'xSmall'}
                        text={'SAVE'}
                        onClick={() => onPatchCampaign('budget')}
                    />
                </div>
                <div className={styles.card}>
                    <MaterialInputComponent 
                        placeholder={campaign.name}
                        label="Name"
                        size="large"
                        value={name}
                        onChange={handleNameChange}
                        error={!isNameValid}
                        caption={validateHelpNameMessage}
                    />
                    <MaterialButtonComponent
                        type="primary"
                        disabled={!name || !isNameValid || name === campaign.name}
                        size={'xSmall'}
                        text={'SAVE'}
                        onClick={() => onPatchCampaign('name')}
                    />
                </div>
                <div className={`${styles.card} ${isCampaignPending ? styles.cardDisabled : null}`}>
                    <MaterialSimpleSelectComponent
                        disabled={isCampaignPending}
                        size="large"
                        value={status}
                        onChange={setStatus}
                        label={'Status'}
                        options={[
                            {
                                value: 'ENABLED',
                                description: 'ACTIVE'
                            },
                            {
                                value: 'PAUSED',
                                description: 'PAUSED'
                            }
                        ]}
                        helperText={isCampaignPending ? `You can't change status of a pending campaign` : null}
                    />
                    <MaterialButtonComponent 
                        type="primary"
                        disabled={isCampaignPending || status === campaign.status}
                        size={'xSmall'}
                        text={'SAVE'}
                        onClick={onToggleStatus}
                    />
                </div>
            </section>
        </Spin>
    );
};

CampaignSettingContainer.propTypes = {
    campaign: PropTypes.object.isRequired, 
    patchCampaign: PropTypes.func.isRequired, 
    toggleCampaignStatus: PropTypes.func.isRequired
};

export default CampaignSettingContainer;