// React
import React from 'react';

// Custom Components
import VerificationGateway from '../../components/VerificationGateway';
import Pricing from '../../pages/Pricing';

const SaveToken = () => {
    return  (
        <VerificationGateway platform="shopify">
            <Pricing mode="saveToken" />
        </VerificationGateway>
    )
};

export default SaveToken;
