// React
import React, { useState, useEffect } from 'react';

// Custom Modules
import { sendAmplitudeEvent } from '../../../utils/amplitude';
import { amplitudeEvents } from '../../../constants/amplitudeEvents';

// Custom Components
import CampaignNewNotification from '../../../components/CampaignNewNotification';
import ConversionsHeader from '../../../container/ConversionsHeader';
import CampaignList from '../../../container/CampaignList';

//import styles
import style from './index.module.less';

const CampaignsContainer = ({ user, platform, campaignsFetchBool, campaignsList, fetchCampaigns, areConversionsAvailable, campaignHasBeenCreated }) => {

    // Local state
    const [ campaignsState, setCampaignsState ] = useState(campaignsList);

    // Lifecycle Hooks
    useEffect(() => {
        sendAmplitudeEvent(amplitudeEvents.CAMPAIGNS_OPEN_CAMAPIGN_LIST_VIEW);

        const { shopify, woocommerce, google } = user.content;
        const canFetchCampaigns = (shopify || woocommerce) && google && campaignsFetchBool
        
        if (canFetchCampaigns) {
            fetchCampaigns();
        }
    }, []);


    useEffect(() => {
        const { shopify, woocommerce, google } = user.content;
        const canFetchCampaigns = (shopify || woocommerce) && google && campaignsFetchBool;
        const doWeHaveCampaigns = campaignsList;

        if (canFetchCampaigns) {
            fetchCampaigns();
        } else if (doWeHaveCampaigns) {
            setCampaignsState(campaignsList);
        }
    }, [user, campaignsList]); 

    return (
        <>
            <header className={campaignHasBeenCreated ? style.headerPopulated : style.headerEmpty}>
                { campaignHasBeenCreated ? ( <CampaignNewNotification /> ) : null }
                { areConversionsAvailable ? ( <ConversionsHeader /> ) : null }
            </header>
            <main className={style.container}>
                <CampaignList campaignsList={ campaignsState } platform={platform}/>
            </main>
        </>  
    );
}

export default CampaignsContainer;