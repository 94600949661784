import { makeStyles } from '@material-ui/core/styles';
import { colorPalette } from '../../../styles/constants';

export const useStyles = makeStyles({
    root: {
        color: colorPalette.primaryGray
    },
    checked: {
        color: colorPalette.secondaryOrange
    },
    Secondary: {
        position: 'absolute',
        left: '5%'
    }
});