// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Custom Components
import MaterialButtonComponent from '../../../components/MaterialComponents/MaterialButtonComponent';

// Stylesheet
import styles from './index.module.less';
import { useStylesIcon, useStylesDialog, breakpoint } from './index.material';

const AddNewCampaignContainer = ({ children, history, clearAddCampaignStateComplete }) => {
    const classesIcon = useStylesIcon();
    const classesDialog = useStylesDialog();
    const cssBreakpoint = useMediaQuery(breakpoint);

    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const onModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    const onModalConfirm = () => {
        clearAddCampaignStateComplete();
        history.push('/campaigns');
    };

    return (
        <section className={styles.container}>
            <header className={styles.header}>
                <div className={styles.headerText}>
                    <p className={styles.headerTextTitle}>Add new campaign</p>
                    <p className={styles.headerTextSubtitle}>for advertising your products</p>
                </div>
                <IconButton className={classesIcon.root} onClick={onModalToggle}>
                    <Clear />
                </IconButton>   
            </header>
            <Dialog
                className={`${classesDialog.root} ${classesDialog[cssBreakpoint ? 'rootDesktop' : 'rootMobile']}`}
                open={isModalOpen}
                onEscapeKeyDown={isModalOpen ? onModalToggle : null}
                onBackdropClick={onModalToggle}
            >
                <p className={styles.modalText}>Are you sure, that you want to close campaign creator?</p>
                <div className={styles.modalButtons}>
                    <MaterialButtonComponent
                        type={"secondary"}
                        size={"xSmall"}
                        text={"CANCEL"}
                        onClick={onModalToggle}
                    />
                    <MaterialButtonComponent
                        type={"primary"}
                        size={"xSmall"}
                        text={"CLOSE"}
                        onClick={onModalConfirm}
                    />
                </div>
            </Dialog>
            {children}
        </section>
    );
};

AddNewCampaignContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    history: PropTypes.object.isRequired,
    clearAddCampaignStateComplete: PropTypes.func.isRequired
};

export default AddNewCampaignContainer;