import { setToLocalStorage, removeFromLocalStorage } from './utils';

// Handling initial URL provided by user

export const setTargetLocation = targetLocation => {
    if (!localStorage.targetPath && targetLocation!=='/login' && !targetLocation.includes('saveToken')) {
        setToLocalStorage('targetPath', targetLocation);
    }
}

export const clearTargetLocation = () => {
    removeFromLocalStorage('targetPath');
}