// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux'
// Methods
import { activateSubscription } from '../../../../ducks/subscription';
import { setError } from '../../../../ducks/app';
import { setWooCommerceDataPackageToBackend, setUserFinishedOnboarding } from '../../../../ducks/auth';
import { createGoogleAccountAds, createGoogleAccountMc } from '../../../../ducks/google';
import { createCampaign } from '../../../../ducks/campaigns';
// Selectors
import { platformSelector, onboardingValueSelector } from '../../../../utils/reduxSelectors'


import FinishContainer from '../../../../components/SharedCreateCampaign/FinishContainer';
import { actionSetLastVisitedPath } from '../../../../ducks/navigation';

const mapStateToProps = state => ({
    countryCode: onboardingValueSelector(state, 'country'),
    budgetValue: onboardingValueSelector(state, 'budget'),
    clientNonce: state.payment.clientNonce,
    user: state.auth.user.content,
    subscription: state.subscription,
    platform: platformSelector(state),
    planId: state.pricing.planId,
    wooCommerceEmail: state.auth.wooCommerceEmail,
    wooCommerceOwnerName: state.auth.wooCommerceOwnerName,
    wooCommerceShopName: state.auth.wooCommerceShopName,
    gAccountMc: state.google.accountMc,
    gAccountAds: state.google.accountAds,
    campaigns: state.campaigns,
    lastFourPaymentCardDigits: state.payment.lastFourPaymentCardDigits,
    creditCardVendorNameForSummary: state.payment.creditCardVendorNameForSummary,
    firstAcceptedPaymentMethodType: state.payment.firstAcceptedPaymentMethodType
});

const mapDispatchToProps = {
    activateSubscription,
    setError,
    setWooCommerceDataPackageToBackend,
    createGoogleAccountAds,
    createGoogleAccountMc,
    createCampaign,
    actionSetLastVisitedPath,
    setUserFinishedOnboarding
};

const Finish = withRouter(connect(mapStateToProps, mapDispatchToProps)(FinishContainer));

export default Finish;