import { connect } from 'react-redux'
import { getPaymentAuthDetails, actionSetShouldSummaryKnowOfCardUpdate, actionSetUpdateMessage, actionConfirmSuccessfulPaymentAuthUpdate } from '../../ducks/subscription';
import SubscriptionSettingsContainer from './SubscriptionSettingsContainer';
import { fetchBraintreeToken, createCustomerWithPaymentAuthorization, setConfirmedPaymentMethodForEdition, actionSetIsNonceReadyForUpdate } from '../../ducks/payment';
import { setNondestructiveErrorMessage } from '../../ducks/app';

const mapStateToProps = state => ({
	subscriptionId: state.subscription.content.id,
	lastFourPaymentCardDigits: state.payment.lastFourPaymentCardDigits,
	creditCardVendorNameForSummary: state.payment.creditCardVendorNameForSummary,
	pricingPlanId: state.subscription.content.plan_id,
	noCardDetailsAvailable: state.subscription.noCardDetailsAvailable,
    summaryShouldKnowOfCardUpdate: state.subscription.summaryShouldKnowOfCardUpdate,
    payPalEmail: state.subscription.payPalEmail
});
const mapDispatchToProps = {
	fetchBraintreeToken,
	createCustomerWithPaymentAuthorization,
	getPaymentAuthDetails,
	actionSetShouldSummaryKnowOfCardUpdate,
	actionSetUpdateMessage,
	actionConfirmSuccessfulPaymentAuthUpdate,
    setNondestructiveErrorMessage,
    setConfirmedPaymentMethodForEdition,
    actionSetIsNonceReadyForUpdate
};
const SubscriptionSettings = connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettingsContainer);

export default SubscriptionSettings;