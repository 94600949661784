// React
import React from "react";
import PropTypes from "prop-types";

// Third-Party Modules
import { Spin } from "antd";
import RcQueueAnim from "rc-queue-anim";

// Custom Modules
import config from "../../../config";

// Custom Components
import MaterialButtonComponent from "../../../components/MaterialComponents/MaterialButtonComponent";

// Stylesheet
import styles from "./index.module.less";

const OnboardingReAskForPermissionsComponent = ({
  facebookListOfPermissionsToReAsk,
  isChannelSelectionLoading,
  setIsChannelSelectionLoading,
  clearFacebookAuthorization
}) => {
  const onClickCancel = () => {
    clearFacebookAuthorization();
  };

  const onClickNext = () => {
    setIsChannelSelectionLoading(true);
    clearFacebookAuthorization();
    window.location.href = `${
      config.API_URL
    }/facebook/reAsk?facebookListOfPermissionsToReAsk=${encodeURIComponent(
      JSON.stringify(facebookListOfPermissionsToReAsk)
    )}&context=onboarding`;
  };

  return (
    <section className={styles.container}>
      <RcQueueAnim type="bottom">
        <Spin spinning={isChannelSelectionLoading}>
          <div className={styles.card} key="1">
            <header className={styles.header}>
              <h1 className={styles.headerTitle}>
                Oops!{" "}
                <strong className={styles.headerTitleBold}>
                  something went wrong
                </strong>
              </h1>
              <h3 className={styles.headerSubtitle}>
                we didn't get required permissions
              </h3>
            </header>
            <main className={styles.content}>
              <div className={styles.contentDescription}>
                <h2 className={styles.contentDescriptionTitle}>
                  The following permissions have been declined:
                </h2>
                <ul className={styles.contentDescriptionList}>
                  {facebookListOfPermissionsToReAsk.map((permission, i) => {
                    return (
                      <li
                        className={styles.contentDescriptionListElement}
                        key={i}
                      >
                        {permission}
                      </li>
                    );
                  })}
                </ul>
                <p className={styles.contentDescriptionText}>
                  If you want to continue with your Facebook account, then
                  please click Next button. Otherwise click cancel and try our
                  Google marketing solution.
                </p>
              </div>
              <div className={styles.contentControls}>
                <MaterialButtonComponent
                  text="Cancel"
                  size="small"
                  type={"secondary"}
                  onClick={onClickCancel}
                />
                <MaterialButtonComponent
                  text="Next"
                  size="small"
                  type={"primary"}
                  onClick={onClickNext}
                />
              </div>
            </main>
          </div>
        </Spin>
      </RcQueueAnim>
    </section>
  );
};

OnboardingReAskForPermissionsComponent.propTypes = {
  facebookListOfPermissionsToReAsk: PropTypes.array,
  isChannelSelectionLoading: PropTypes.bool,
  setIsChannelSelectionLoading: PropTypes.func.isRequired,
  clearFacebookAuthorization: PropTypes.func.isRequired
};

export default OnboardingReAskForPermissionsComponent;
