import { connect } from 'react-redux'
import PaymentCardInfoPresentComponent from './PaymentCardInfoPresentComponent';

const mapStateToProps = state => ({
	lastFourPaymentCardDigits: state.payment.lastFourPaymentCardDigits,
    creditCardVendorNameForSummary: state.payment.creditCardVendorNameForSummary,
    payPalEmail: state.subscription.payPalEmail
});


const PaymentCardInfoPresent = connect(mapStateToProps)(PaymentCardInfoPresentComponent);

export default PaymentCardInfoPresent;