
/**
 * Initialize Inspectlet, must be called before any other action performed in Inspectlet.
 */
export const initInspectlet = (inspectletId) => {
    window.__insp = window.__insp || [];
    window.__insp.push(['wid', inspectletId]);

    var ldinsp = function () {
        if (typeof window.__inspld !== "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' === document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1333556737&r=' + Math.floor(new Date().getTime() / 3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x);
    };

    setTimeout(ldinsp, 0);
};

/**
 * Tag Inspectlet session with user properties or other properties.
 * @param {Object} user Information about the user. Each of object param is optional.
 * @param {string} user.userName The username.
 * @param {string} user.testUser Determines if given user is test user, or the real user - should be set to strings 'True' or 'False'.
 */
export const addInspectletSessionTag = ({ userName, testUser, ...rest }) => {
    window.__insp.push(['tagSession', { userName, testUser, ...rest }])
}
