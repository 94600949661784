import React from "react";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import { PropTypes } from "prop-types";

const MaterialDrawerComponent = ({
  drawerMaterialStyle,
  drawerHeaderMaterialStyle,
  drawerPaperMaterialStyle,
  closeDrawerStyle,
  isDrawerOpen,
  FixedLogoButton,
  handleDrawerClose,
  MaterialIconsGenerator,
}) => (
  <Drawer
    className={drawerMaterialStyle}
    variant="persistent"
    anchor="left"
    open={isDrawerOpen}
    classes={{
      paper: drawerPaperMaterialStyle
    }}
  >
    <div className={drawerHeaderMaterialStyle}>{FixedLogoButton}</div>
    <div className={closeDrawerStyle}>
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
    {MaterialIconsGenerator}
  </Drawer>
);

MaterialDrawerComponent.propTypes = {
  drawerMaterialStyle: PropTypes.string.isRequired,
  drawerHeaderMaterialStyle: PropTypes.string.isRequired,
  drawerPaperMaterialStyle: PropTypes.string.isRequired,
  closeDrawerStyle: PropTypes.string,
  isDrawerOpen: PropTypes.bool.isRequired,
  FixedLogoButton: PropTypes.object.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  MaterialIconsGenerator: PropTypes.object.isRequired
};
export default MaterialDrawerComponent;
