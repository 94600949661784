import { makeStyles } from "@material-ui/core/styles";
import { typography, colorPalette } from '../../../styles/constants';


export const materialStyleGenerator = ({ marginBottom }) => makeStyles({
	smallSize: {
		width: "157px",
		height: "56px"
	},
	largeSize: {
		width: "350px",
		height: "56px"
	},
	flexSize: {
		width: "100%",
		height: "45px",
		margin: "15px 0 5px",
		"& .MuiInputBase-root": {
			fontSize: typography.paragraphMd,
			height: "45px"
		},
		"& .MuiInputLabel-outlined": {
			fontSize: typography.paragraphMd,
			opacity: ".7",
			top: "-5px"
		},
		"& .MuiFormLabel-filled": {
			top: "0"
		},
		"& .Mui-focused": {
			top: "0",
			opacity: "1"
		}
	},
	root: {
		backgroundColor: colorPalette.white,
		borderRadius: "4px",
		marginBottom: marginBottom,
		"& .MuiOutlinedInput-adornedEnd": {
			paddingRight: "0",
			display: "flex",
			alignItems: "center"
		},
		"& .MuiInputAdornment-positionEnd": {
			paddingLeft: "10px",
			paddingRight: "10px",
			backgroundColor: "#eeeae9",
			height: "56px",
			maxHeight: "unset",
			display: "flex",
			alignItems: "center"
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#d5922c"
		},
		"& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
			borderWidth: "2px",
			borderColor: colorPalette.errorRed
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-error": {
			color: colorPalette.errorRed,
		},
		"& .MuiFormLabel-root.Mui-error": {
			color: 'rgba(0, 0, 0, 0.54)'
		},
		"& .MuiFormHelperText-root.Mui-error": {
			color: colorPalette.errorRed,
		},
		"&:hover": {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "#d5922c"
			}
		},
		input: {
			"&.Mui-error .MuiOutlinedInput-notchedOutline": {
				borderColor: colorPalette.primaryOrange
			}
		},
		"& fieldset": {
			borderColor: "#d5922c",
			display: "flex"
		},
		"& label.Mui-focused": {
			"&.Mui-error": {
				color: colorPalette.errorRed
			},
			color: colorPalette.primaryOrange
		},
		"& .MuiInputBase-formControl": {
			"&:hover": {
				borderColor: "red"
			}
		},
		"& .Mui-error": {
			borderWidth: "2px"
		},
		"& .MuiOutlinedInput-input": {
			color: colorPalette.primaryBrown,
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: `rgba(${colorPalette.primaryBrownRGB}, 0.5)`,
		},
		"& .MuiInputLabel-root": {
			color: "rgba(84, 40, 31, 0.7)",
		}
	},
	menu: {
		width: 200
	},
	selectIcon: {
		color: `rgba(${colorPalette.primaryBrownRGB}, 0.5)`,
		margin: "5px",
	},
	selectIconOpened: {
		transform: "rotateZ(180deg)"
	},
	selectLabelFocused: {
		"&.MuiInputLabel-shrink": {
			color: colorPalette.primaryOrange,
		}
	},
	selectInputFocused: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: colorPalette.primaryOrange,
			borderWidth: "2px"
		},
	},
	prefix: {
		"& .MuiTypography-colorTextSecondary": {
			color: colorPalette.primaryBrown,
		}
	}
});