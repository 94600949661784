import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchBraintreeToken, createCustomerWithPaymentAuthorization, validateBraintreeClientInstanceEnvelope, setPayPalButtonsInitialized, validatePayPalButtonInstance, clearAlreadyCachedPaymentCardDetails } from './../../ducks/payment';
import ChangePayPalAuthorizationContainer from './ChangePayPalAuthorizationContainer/index';
import { changePaymentAuth, actionSetShouldSummaryKnowOfCardUpdate } from '../../ducks/subscription';
import { shopIdSelector } from '../../utils/reduxSelectors';

const mapStateToProps = state => ({
    braintreeToken: state.payment.braintreeToken,
    payPalButtonsInitialized: state.payment.payPalButtonsInitialized,
    firstAcceptedPaymentMethodType: state.payment.firstAcceptedPaymentMethodType,
    subscriptionId: state.subscription.content.id,
    shopId: shopIdSelector(state.auth.user),
    summaryShouldKnowOfCardUpdate: state.subscription.summaryShouldKnowOfCardUpdate
});

const mapDispatchToProps = {
    fetchBraintreeToken,
    createCustomerWithPaymentAuthorization,
    validateBraintreeClientInstanceEnvelope,
    setPayPalButtonsInitialized,
    validatePayPalButtonInstance,
    changePaymentAuth,
    clearAlreadyCachedPaymentCardDetails,
    actionSetShouldSummaryKnowOfCardUpdate
};
const ChangePayPalAuthorization = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePayPalAuthorizationContainer));

export default ChangePayPalAuthorization;