import { makeStyles } from '@material-ui/core/styles';
import { colorPalette } from '../../../styles/constants';

export const useStyles = makeStyles({
    smallSize: {
        width: "157px",
        height: "56px",
    },
    largeSize: {
        width: "350px",
        height: "56px"
    },
    root: {
        marginTop: "16px",
        marginBottom: "8px",
        borderRadius: "4px",
        position: "relative",
        "& .MuiSelect-select:focus": {
            backgroundColor: `rgba(${colorPalette.whiteRGB},.01)`
        },
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: "0",
            display: "flex",
            alignItems: "center"
        },
        "& .MuiInputAdornment-positionEnd": {
            paddingLeft: "10px",
            paddingRight: "10px",
            backgroundColor: colorPalette.tintedWhite,
            height: "56px",
            maxHeight: "unset",
            display: "flex",
            alignItems: "center"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colorPalette.primaryOrange
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
            borderColor: colorPalette.errorRed
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-error": {
            color:colorPalette.errorRed,
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: `rgba(${colorPalette.blackRGB}, 0.54)`
        },
        "& .MuiFormHelperText-root.Mui-error": {
            color: colorPalette.errorRed,
        },
        "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colorPalette.primaryOrange
            }
        },
        input: {
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: colorPalette.primaryOrange
            }
        },
        "& fieldset": {
            borderColor: colorPalette.primaryOrange,
            display: "flex"
        },
        "& label.Mui-focused": {
            "&.Mui-error": {
                color: colorPalette.errorRed
            },
            color: colorPalette.primaryOrange
        },
        "& .MuiInputBase-formControl": {
            "&:hover": {
                borderColor: colorPalette.errorColor
            }
        },
        "& .Mui-error": {
            borderWidth: "2px"
        },
        "& .MuiOutlinedInput-input": {
            color: colorPalette.primaryBrown,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `rgba(${colorPalette.primaryBrownRGB}, 0.5)`,
        },
        "& .MuiInputLabel-root": {
            color: `rgba(${colorPalette.primaryBrownRGB}, 0.7)`,
        }
    },
    helperText: {
        position: "absolute",
        bottom: "-20px",
        marginLeft: 0
    }
});