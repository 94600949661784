// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Third-Party Modules
import { Spin } from 'antd';

// Stylesheet
import styles from './index.module.less';

const AccountInvoiceComponent = ({ isLoading, subscriptionId, invoiceOwner, latestInvoiceName, fetchInvoiceHistory, getInvoiceData }) => {    
    useEffect(() => {
        if (subscriptionId) {
            fetchInvoiceHistory();
            getInvoiceData();
        }
    }, []);

    return (
        <section className={styles.container}>
            <Spin spinning={isLoading}>
                <div className={styles.row}>
                    <span className={styles.rowTitle}><p className={styles.rowTitleText}>LAST INVOICE</p></span>
                    <span className={styles.rowContent}>
                        <p className={styles.rowContentValue}>{latestInvoiceName}</p>
                        <Link className={styles.rowContentLink} to="/account/invoiceHistory">Invoice history</Link>
                    </span>
                </div>
                <hr className={styles.divider}/>
                <div className={styles.row}>
                    <span className={styles.rowTitle}><p className={styles.rowTitleText}>INVOICE DATA</p></span>
                    <span className={styles.rowContent}>
                        <p className={styles.rowContentValue}>{invoiceOwner}</p>
                        <Link className={styles.rowContentLink} to="/account/invoiceData">Change invoice data</Link>
                    </span>
                </div>
            </Spin>
        </section>
    );
};

AccountInvoiceComponent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    subscriptionId: PropTypes.string,
    invoiceOwner: PropTypes.string,
    latestInvoice: PropTypes.shape({
        id: PropTypes.string,
        date: PropTypes.string,
        dueDate: PropTypes.string,
        status: PropTypes.oneOf(['paid', 'posted', 'payment_due', 'not_paid', 'voided', 'pending'])
    }),
    fetchInvoiceHistory: PropTypes.func.isRequired,
    getInvoiceData: PropTypes.func.isRequired
};

export default AccountInvoiceComponent;
