import React from 'react'

import { Spin } from 'antd';

import styles from './index.module.less'

const AppSpinner = () => {
  return (
    <div className={styles.spinnerPage}>
      <Spin size='large' />
    </div>
  )
}

export default AppSpinner