import SelectionSuccess from '../pages/Onboarding/SelectionSuccess';
import GoogleChooseCountry from '../pages/OnboardingCreateCampaign/Google/ChooseCountry';
import GoogleChooseBudget from '../pages/OnboardingCreateCampaign/Google/ChooseBudget';
import GoogleFinish from '../pages/OnboardingCreateCampaign/Google/Finish';

import FacebookVerificationSuccess from '../pages/Onboarding/FacebookVerificationSuccess';
import FacebookChooseName from '../pages/OnboardingCreateCampaign/Facebook/ChooseName';
import FacebookChooseCountry from '../pages/OnboardingCreateCampaign/Facebook/ChooseCountry';
import FacebookFinish from '../pages/OnboardingCreateCampaign/Facebook/Finish';

import SharedOnboardingPaymentMethodSelection from '../container/OnboardingPaymentMethodSelection';
export const googleOnboardingSteps = {
    SELECTION_SUCCESS: {
        name: 'SELECTION_SUCCESS',
        appendedRoute: 'google/selectionSuccess',
        component: SelectionSuccess
    },
    CHOOSE_COUNTRY: {
        name: 'CHOOSE_COUNTRY',
        appendedRoute: 'google/createCampaign/chooseCountry',
        description: 'Country',
        component: GoogleChooseCountry
    },
    CHOOSE_BUDGET: {
        name: 'CHOOSE_BUDGET',
        appendedRoute: 'google/createCampaign/chooseBudget',
        description: 'Budget',
        component: GoogleChooseBudget
    },
    PAYMENT: {
        name: 'PAYMENT',
        appendedRoute: 'google/createCampaign/setPaymentAuthorization',
        description: 'Payment',
        component: SharedOnboardingPaymentMethodSelection
    },
    FINISH: {
        name: 'FINISH',
        appendedRoute: 'google/createCampaign/finish',
        description: 'Summary',
        component: GoogleFinish
    }
};
export const facebookOnboardingSteps = {
    VERIFICATION_SUCCESS: {
        name: 'VERIFICATION_SUCCESS',
        appendedRoute: 'facebook/verificationSuccess',
        component: FacebookVerificationSuccess
    },
    CHOOSE_NAME: {
        name: 'CHOOSE_NAME',
        appendedRoute: 'facebook/createCampaign/chooseName',
        description: 'Name',
        component: FacebookChooseName
    },
    CHOOSE_COUNTRY: {
        name: 'CHOOSE_COUNTRY',
        appendedRoute: 'facebook/createCampaign/chooseCountry',
        description: 'Country',
        component: FacebookChooseCountry
    },
    PAYMENT: {
        name: 'PAYMENT',
        appendedRoute: 'facebook/createCampaign/setPaymentAuthorization',
        description: 'Payment',
        component: SharedOnboardingPaymentMethodSelection
    },
    FINISH: {
        name: 'FINISH',
        appendedRoute: 'facebook/createCampaign/finish',
        description: 'Summary',
        component: FacebookFinish
    }
};
