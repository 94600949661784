// React
import React, { useEffect, useState } from 'react';

// Third Party Modules
import Cookies from 'js-cookie';


// Custom Modules
import { isPlatformShopify } from '../../../utils/utils';

// Custom Components
import PageWrapperBig from '../../PageWrapperBig';

const VerificationGatewayContainer = ({ accessGranted, setAccessGranted, isWoocommerceTokenFetched, platform, children, history, pricing, fetchUser, user, fetchWooCommerceToken, isOnboardingFinished, checkIfUserFinishedOnboarding }) => {
    // Local state
    const [ isLoading, setIsLoading ] = useState(true);

    const syncedUserAuthorization = () => {
        setTimeout(async ()=>{
            await fetchUser();
            await checkIfUserFinishedOnboarding(platform);
        }, 1000);
    };

    useEffect(() => {
        const accessToken = Cookies.get('heraldbee_auth_token');
        if (!isPlatformShopify(platform)) {
            fetchWooCommerceToken(window.location.search);
        } else if (isPlatformShopify(platform)) {
            setAccessGranted(accessToken);
        }
    }, []);

    useEffect(() => {
        if (isWoocommerceTokenFetched) {
            const accessToken = Cookies.get('heraldbee_auth_token');
            if (accessToken && !isPlatformShopify(platform)) {
                setAccessGranted(accessToken);
            }
        }
    }, [ isWoocommerceTokenFetched ]);

    useEffect(() => {
        if (accessGranted) {
            syncedUserAuthorization();
        }
    }, [ accessGranted ]);

    useEffect(() => {   // If user logging in and is registered, skip pricing requirement and forward him to the app
        const isUserAfterOnboarding = (isOnboardingFinished && user.content);
        const isDataFetched = (accessGranted && user && user.content && isOnboardingFinished !== null);
        if (isUserAfterOnboarding) {
            history.push('/');
        } else if (isDataFetched) {     
            setIsLoading(false);
        }
    }, [ isOnboardingFinished, user ]);

    useEffect(() => {
        const isShopifyUserReadyToProceed = accessGranted && user && user.content && pricing && isPlatformShopify(platform) && isOnboardingFinished !== null;
        if (isShopifyUserReadyToProceed) {
            history.push('/');
        }
    }, [isLoading]);


    return !isLoading
        ? ( children )
        : (
            <PageWrapperBig
                whiteBackground
                loader={isLoading}
                errorButtonText='Try again'
            >
            </PageWrapperBig>
        )
};

export default VerificationGatewayContainer;