import config from '../../config';
import { setError } from '../app';
import { getProductsFromResources } from './logic';
import { HeraldbeeApi } from '../../api/HeraldbeeApi';

// Actions

const SET_CAMPAIGN_PRODUCTS = 'SET_CAMPAIGN_PRODUCTS';

// Reducer

const initialState = {
    products: [],
    nextPageToken: null,
    allPagesFetched: false,
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SET_CAMPAIGN_PRODUCTS:
            return {
                ...state,
                products: state.products.concat(action.products),
                nextPageToken: action.nextPageToken,
                allPagesFetched: !action.nextPageToken
            }

        default: return state;
    }
}
export default reducer;

// Action Creators

const actionSetCampaignProducts = (products, nextPageToken) => ({ type: SET_CAMPAIGN_PRODUCTS, products, nextPageToken });

// Methods

export const fetchCampaignProducts = (savedNextPageToken) => async dispatch => {
    const hbApi = HeraldbeeApi({
        baseUrl: config.API_URL,
    });

    const { value, error } = await hbApi.fetchCampaignProducts(savedNextPageToken);

    if (error) {
        setError(error)(dispatch);
        return;
    };

    const { resources, nextPageToken } = value;

    const products = resources ? getProductsFromResources(resources) : [];

    dispatch(actionSetCampaignProducts(products, nextPageToken));
}
