// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { validateEmailInput } from '../../../utils/auth';

// Custom Components
import MaterialInputComponent from '../../MaterialComponents/MaterialInputComponent/index';
import OnboardingAgreementComponent from '../../MaterialComponents/OnboardingMaterialComponents/OnboardingAgreementComponent/index';
import MaterialButtonComponent from '../../MaterialComponents/MaterialButtonComponent/index';

// Stylesheet
import style from './index.module.less';

const WoocommerceUserDetailsCollectorComponent = ({ inputReferenceTable, onConfirm, onUserResigned }) => {
	// local state

	// input values
	const [ nameValue, setNameValue ] = useState(null);
	const [ storeNameValue, setStoreNameValue ] = useState(null);
	const [ emailValue, setEmailValue ] = useState(null);
	// input errors
	const [ nameError, setNameError ] = useState(null);
	const [ storeNameError, setStoreNameError ] = useState(null);
	const [ emailError, setEmailError ] = useState(null);
	// TOS and Privacy Policy
	const [ agreement, setAgreement ] = useState(false);
	const [ agreementError, setAgreementError ] = useState(false);

	const { email, userName, storeName } = inputReferenceTable;

	const onClickNext = () => {
		const someFieldsNotFilled = !nameValue || !storeNameValue || !emailValue;
		if (!agreement) {
			setAgreementError(true);
			return;
		}
		if (someFieldsNotFilled) {
			const errorMessage = 'the field is required';
			if (!nameValue) {
				setNameError(errorMessage);
			};
			if (!storeNameValue) {
				setStoreNameError(errorMessage);
			};
			if (!emailValue) {
				setEmailError(errorMessage);
			} else {
				const emailIsValid = validateEmailInput(emailValue);
				if (!emailIsValid) {
					setEmailError('provided email is not valid');
				}
			};
		} else {
			const emailIsValid = validateEmailInput(emailValue); // repeated logic required due to two step validation of email
			if (emailIsValid) {
				onConfirm(emailValue, storeNameValue, nameValue);
			} else {
				setEmailError('provided email is not valid');
			}
		}
	};

	// Input onChange event handlers - each one is clearing error by default to help with UX
	const saveUserNameToState = e => {
		setNameError(null);
		setNameValue(e.target.value);
	};

	const saveStoreNameToState = e => {
		setStoreNameError(null);
		setStoreNameValue(e.target.value);
	};

	const saveEmailToState = e => {
		setEmailError(null);
		setEmailValue(e.target.value)
	};

	const handleAgreement = value => {
		if (agreementError) {
			setAgreementError(false);
		}
		setAgreement(value);
	}

	return (
		<div className={style.container}>
			<div className={style.headerRow}>
				<h1 className={style.headerRowTitle}><strong>Sign Up</strong></h1>
				<h3 className={style.headerRowSubtitle}>with personal data</h3>
			</div>

			<div className={style.inputRow}>
				<MaterialInputComponent 
					marginBottom="16px"
					label={userName}
					size="large"
					onChange={saveUserNameToState}
					error={nameError ? true : false}
					caption={nameError}
				/>
				<MaterialInputComponent
					marginBottom="16px"
					placeholder={storeName}
					label={storeName}
					size="large"
					onChange={saveStoreNameToState}
					error={storeNameError ? true : false}
					caption={storeNameError}
				/>
				<MaterialInputComponent 
					marginBottom="16px"
					placeholder={email}
					label={email}
					size="large"
					onChange={saveEmailToState}
					error={emailError ? true : false}
					caption={emailError}
				/>
			</div>

			<OnboardingAgreementComponent
				status={agreement}
				onChange={handleAgreement}
			/>

			<div className={style.buttonRow}>
				<MaterialButtonComponent
					size='xSmall'
					type='secondary'
					text='BACK'
					onClick={onUserResigned}
				/>
				<MaterialButtonComponent
					size='large'
					type='primary'
					text={'SIGN UP'}
					onClick={onClickNext}
				/>
				{ 
					agreementError
						? (
							<p className={style.agreementError}>Please accept the Terms of Service and Privacy Policy agreement</p>
						) : null
				}
			</div>
		</div>
	)
};

WoocommerceUserDetailsCollectorComponent.propTypes = {
	inputReferenceTable: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onUserResigned: PropTypes.func.isRequired
};

export default WoocommerceUserDetailsCollectorComponent;