// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { clearFacebookAuthorization } from '../../../ducks/auth';

import AccountFacebookVerificationFailureComponent from './AccountFacebookVerificationFailureComponent';

const mapStateToProps = state => ({
    facebookListOfPermissionsToReAsk: state.onboarding.facebookListOfPermissionsToReAsk
});

const mapDispatchToProps = {
    clearFacebookAuthorization
};

const AccountFacebookVerificationFailure = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountFacebookVerificationFailureComponent));

export default AccountFacebookVerificationFailure;