// React
import React, { useEffect } from "react";
import PropTypes from 'prop-types';

// Styles
import styles from './index.module.less';

// Third Party Modules
import clsx from "clsx";

// Custom Modules
import { googleRequirementsTexts } from "../../../utils/texts";

// Custom Components
import MaterialRequirementCheckItemComponent from "./MaterialRequirementCheckItemComponent";
import MaterialPopoverComponent from "../../MaterialComponents/MaterialPopoverComponent";

const GoogleRequirementsComponent = ({ googleRequirementMemos = [], fetchGoogleRequirementMemosState, setGoogleRequirementMemosState }) => {

    useEffect(() => {
        fetchGoogleRequirementMemosState();
    }, [])

    const onChangeRequirementState = (requirementIdx, checked) => {
        const requirementList = getRequirementList();

        const newState = checked ? 'checked' : 'unchecked'
        requirementList[requirementIdx].state = newState;

        const newRequirementMemosStates = requirementList.map(({ title, state }) => ({
            memo: title,
            state: state,
        }))

        setGoogleRequirementMemosState(newRequirementMemosStates);
    }

    const getRequirementList = () => {
        return googleRequirementsTexts.requirements.map(({ title, description }) => {
            const requirement = googleRequirementMemos.find(({ memo }) => memo === title);

            const state = requirement ? requirement.state : 'unchecked';

            return {
                title,
                description,
                state,
            }
        })
    }

    const assignTriggerButton = (props) => {
        return (
            <span {...props} className={styles.triggerButton} >
                learn more
            </span>
        )
    };

    return (
        <section className={styles.container}>
            <header className={styles.header}>
                <h2 className="h2-sm">
                    {googleRequirementsTexts.title}
                </h2>
                <p className={styles.description}>
                    {googleRequirementsTexts.description}
                </p>
            </header>
            <div className={styles.requirementsCard}>
                {getRequirementList().map(({ title, description, state }, idx) =>
                    <div key={idx}>
                        <MaterialRequirementCheckItemComponent 
                            itemId={idx} 
                            title={title} 
                            checked={state === 'checked'}
                            onChange={onChangeRequirementState}>
                            <MaterialPopoverComponent assignTriggerButton={assignTriggerButton}>
                                <div className={styles.descriptionFull}>
                                    {description}
                                    {googleRequirementsTexts.requirementsSuffix}
                                    <span className={clsx(styles.openIntercomButton, 'OPEN_INTERCOM')}>{googleRequirementsTexts.requirementsSuffixAction}</span>
                                </div>
                            </MaterialPopoverComponent>
                        </MaterialRequirementCheckItemComponent>
                    </div>
                )}
            </div>
        </section>
    );
};

GoogleRequirementsComponent.propTypes = {
    googleRequirementMemos: PropTypes.arrayOf(
        PropTypes.shape({
            memo: PropTypes.string,
            state: PropTypes.string,
        })
    ),
    fetchGoogleRequirementMemosState: PropTypes.func,
    setGoogleRequirementMemosState: PropTypes.func,
};

export default GoogleRequirementsComponent;
