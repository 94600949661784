import MaterialAccountIconComponent from '../components/MaterialComponents/MaterialIconComponents/MaterialAcountIconComponent';
import GoogleRequirementsIconComponent from '../components/MaterialComponents/MaterialIconComponents/MaterialGoogleRequirementsIconComponent';
import MaterialFaqIconComponent from '../components/MaterialComponents/MaterialIconComponents/MaterialFaqIconComponent';
import MaterialCampaignIconComponent from '../components/MaterialComponents/MaterialIconComponents/MaterialCampaignIconComponent';

export const menuMaterialIconsList = [
	{
		text: "Campaigns",
		IconSvgComponent: MaterialCampaignIconComponent,
		path: "/campaigns"
	},
	{
		text: "Account",
		IconSvgComponent: MaterialAccountIconComponent,
		path: "/account"
	},
	{
		text: "Google Requirements",
		IconSvgComponent: GoogleRequirementsIconComponent,
		path: "/googleRequirements"
	},
	{
		text: "FAQ",
		IconSvgComponent: MaterialFaqIconComponent,
		path: "/faq"
	}
];

/**
 * @param  {string} {cardVendorName} - vendor name to be translated
 */
export const translateCardVendorName = ({ cardVendorName }) => {
	switch (cardVendorName) {
		case 'visa':
			return 'Visa';
		case 'mastercard':
			return 'MasterCard';
		case 'american_express':
			return 'AmericanExpress';
		case 'master-card':
			return 'MasterCard'
		case 'american-express':
			return 'AmericanExpress';
		case 'American Express':
			return 'AmericanExpress';
		case 'maestro':
			return 'Maestro';
		default:
			return 'other'
	}
};
