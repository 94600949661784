// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods

// Selectors
import { isUserIntegratedWithFacebookSelector } from '../../../../utils/reduxSelectors';

import ButtonWithLogoAddCampaignComponent from './ButtonWithLogoAddCampaignComponent';

const mapStateToProps = state => ({
    isUserConnectedWithFacebook: isUserIntegratedWithFacebookSelector(state)
});

const mapDispatchToProps = {

};

const ButtonWithLogoAddCampaign = withRouter(connect(mapStateToProps, mapDispatchToProps)(ButtonWithLogoAddCampaignComponent));

export default ButtonWithLogoAddCampaign;