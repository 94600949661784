// React
import React, { useRef } from 'react'
import PropTypes from 'prop-types'

// Custom Modules
import subscriptionPlans from '../../../constants/subscriptionPlans';
import { PricingTexts } from '../../../utils/texts';

// Custom Components
import OnboardingContainer from '../../../container/OnboardingContainer';
import PricingComponent from '../../../components/Pricing/PricingComponent';

// Stylesheet
import style from './index.module.less';

const PricingPage = ({ history, setPlatform, setPricing, mode = 'default' }) => {
  const { headerTitle, headerDescription } = PricingTexts;
  const isInitialMount = useRef(true);

  const onChoosePlan = planId => {
    isInitialMount.current && setPricing(planId)
    if (mode==='default') {
      history.push('/');
    } else if (mode==='saveToken') {
      setPlatform('shopify');
      setPricing(planId);
      history.push('/');
    }
  }

  return (
    <OnboardingContainer>
      <div className={style.container}>
        <header className={style.header}>
          <h1 className={style.headerTitle}>{headerTitle}</h1>
          <h3 className={style.headerDescription}>{headerDescription}</h3>
        </header>
        <PricingComponent
          plans={subscriptionPlans}
          onSelectPlan={onChoosePlan}
        />
      </div>
    </OnboardingContainer>
  );
}

PricingPage.propTypes = {
  history: PropTypes.object.isRequired,
  setPricing: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['default', 'awaitToken', 'saveToken'])
}

export default PricingPage;
