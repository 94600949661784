import React from 'react';
import PropTypes from 'prop-types';
import Schedule from '@material-ui/icons/Schedule';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import styles from './index.module.less';
import clsx from 'clsx';

const StatusIconComponent = ({ status }) => {
    
    const icon = () => {
        switch(status) {
            case 'ENABLED':
                return (
                    <>
                        <CheckCircleOutline className={clsx(styles.icon, styles[status.toLowerCase()])} />
                        <p className={`${styles.iconContainerText} ${styles[status.toLowerCase()]}`}>ACTIVE</p>
                    </>
                ) 
            case 'PAUSED':
                return (
                    <>
                        <PauseCircleOutline className={clsx(styles.icon, styles[status.toLowerCase()])} />
                        <p className={`${styles.iconContainerText} ${styles[status.toLowerCase()]}`}>PAUSED</p>
                    </>
                ) 
            default:
                return (
                    <>
                        <Schedule className={clsx(styles.icon, styles[status.toLowerCase()])} />
                        <p className={`${styles.iconContainerText} ${styles[status.toLowerCase()]}`}>PENDING</p>
                    </>
                ) 
        }
    }

    return (
        <div className={styles.iconContainer}>
            { icon() }  
        </div>
    )
}


StatusIconComponent.propTypes = {
    status : PropTypes.oneOf(['ENABLED', 'PAUSED', 'PENDING'])
}
export default StatusIconComponent;