// React
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import MaterialTable, {MTableToolbar} from 'material-table';

// Custom Components

// Stylesheet
import styles from './index.module.less';
import { materialStyles, tableIcons, useIconStyles } from './index.material';

const MaterialInvoiceHistoryComponent = ({ adjustedInvoiceHistory, fetchInvoicePDF }) => {

    const tableColumns = [
        {title: 'DATE', field: 'date', cellStyle: materialStyles.cellStyle},
        {title: 'NAME', field: 'name', cellStyle: materialStyles.cellStyle},
        {title: 'DUE DATE', field: 'dueDate', cellStyle: materialStyles.cellStyle},
        {title: 'STATUS', field: 'status', cellStyle: materialStyles.cellStyle},
    ];

    const iconClasses = useIconStyles();

    const onClickDownload = async (event, rowData) => {
        const pdfUrl = await fetchInvoicePDF(rowData.id);
        if (pdfUrl) {
            window.open(pdfUrl);
        };
    };

    return (
        <section className={styles.container}>
            <MaterialTable
                actions={[
                    () => ({
                        icon: tableIcons.Save,
                        iconProps: {
                            className: iconClasses.root
                        },
                        tooltip: 'To get Invoice PDF file, please contant our support',
                        onClick: null,
                        disabled: false
                    })
                ]}
                icons={tableIcons}
                columns={tableColumns}
                data={adjustedInvoiceHistory}
                title={null}
                options={{
                    paging: false,
                    headerStyle: materialStyles.headerStyle,
                    rowStyle: materialStyles.rowStyle,
                    searchFieldStyle: materialStyles.searchFieldStyle,
                    actionsColumnIndex: -1,
                    actionsCellStyle: materialStyles.actionsCellStyle
                }}
                localization={{
                    header: {
                        actions: 'PDF'
                    }
                }}
                components={{
                    Toolbar: props => (
                        <div className={styles.toolbar}>
                            <MTableToolbar {...props}/>
                        </div>
                    )
                }}
            />
        </section>
    );
};

MaterialInvoiceHistoryComponent.propTypes = {
    adjustedInvoiceHistory: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        date: PropTypes.string,
        dueDate: PropTypes.string,
        status: PropTypes.string,
        name: PropTypes.string
    })),
    fetchInvoicePDF: PropTypes.func.isRequired
};

export default MaterialInvoiceHistoryComponent;