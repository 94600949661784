import { makeStyles } from "@material-ui/core/styles";
import { colorPalette, breakpoints } from "../../../styles/constants";

export const useStylesIcon = makeStyles({
    root: {
        transition: "all .3s",
        position: "relative",

        "&:hover": {
            color: colorPalette.secondaryOrange,
        },

        "& svg": {
            position: "absolute",
            transition: "all .3s"
        },

        "&:hover svg": {
            color: colorPalette.secondaryOrange,
            width: '1.25em',
            height: '1.25em'
        },

        "& .MuiIconButton-label": {
            width: "1.25em",
            height: "1.25em"
        },
    }
});

export const useStylesDialog = makeStyles({
    root: {
        "& .MuiDialog-container .MuiPaper-root": {
            maxWidth: "450px",
            maxHeight: "250px"
        },
        "& .MuiBackdrop-root": {
            backgroundColor: `rgba(${colorPalette.blackRGB}, .16)`
        }
    },
    
    rootMobile: {
        "& .MuiDialog-container .MuiPaper-root": {
            padding: "10px 25px"
        }
    },

    rootDesktop: {
        "& .MuiDialog-container .MuiPaper-root": {
            padding: "50px 75px"
        }
    }
});

export const breakpoint = `(min-width:${breakpoints.sm})`