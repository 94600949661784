// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { initializeFacebookAuthProcess } from '../../ducks/auth';

import FacebookVerificationContainer from './FacebookVerificationContainer';

const mapStateToProps = state => ({
    wasFacebookAuthSuccessful: state.auth.wasFacebookAuthSuccessful,
    facebookListOfPermissionsToReAsk: state.auth.facebookListOfPermissionsToReAsk,
});

const mapDispatchToProps = {
    initializeFacebookAuthProcess,
};

const FacebookVerification = withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookVerificationContainer));

export default FacebookVerification;