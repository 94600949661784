// React
import React from "react";
import PropTypes from "prop-types";

// Third Party Modules
import deburr from "lodash/deburr";

// Third Party Components
import Paper from "@material-ui/core/Paper";

// Custom Components
import SelectListItemComponent from "./SelectListItemComponent";

// Custom Stylesheets
import styles from './index.module.less';

const SelectListComponent = ({ items, downshiftArgs }) => {
    const {
        getItemProps,
        highlightedIndex,
        inputValue,
        selectedItem
    } = downshiftArgs;

    const getItems = (value) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;

        return items && items.filter(item => 
            item.value.slice(0, inputLength).toLowerCase() === inputValue
        );
    }

    return (
        <Paper className={styles.paper} square>
            {getItems(inputValue).map(
                (item, index) =>
                    <SelectListItemComponent
                        key={index}
                        item={item}
                        index={index}
                        itemProps={getItemProps({ item: item })}
                        highlightedIndex={highlightedIndex}
                        selectedItem={selectedItem}
                    />
            )}
        </Paper>
    );
}

SelectListComponent.propTypes = {
    items: PropTypes.arrayOf(
        SelectListItemComponent.propTypes.item
    ),
    downshiftArgs: PropTypes.object.isRequired
};

export default SelectListComponent;