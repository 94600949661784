// React
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheet
import style from './index.module.less';

const CampaignBudgetGuidanceComponent = ({ size = 'normal' }) => {
    return (
        <div className={`${style.guidance} ${style[size]}`}>
            <h5 className={style.guidanceTitle}><strong>Recommended budgets:</strong></h5>
            <div className={style.guidanceSection}>
                <p className={style.guidanceSectionParagraph}>For small and local businesses</p>
                <p className={style.guidanceSectionParagraph}><strong>$ 13</strong></p>
            </div>
            <hr className={style.guidanceBreak}/>
            <div className={style.guidanceSection}>
                <p className={style.guidanceSectionParagraph}>For bigger businesses</p>
                <p className={style.guidanceSectionParagraph}><strong>$ 25</strong></p>
            </div>
            <hr className={style.guidanceBreak}/>
            <div className={style.guidanceSection}>
                <p className={style.guidanceSectionParagraph}>For businesses going international</p>
                <p className={style.guidanceSectionParagraph}><strong>$ 40</strong></p>
            </div>
        </div>
    )
}

CampaignBudgetGuidanceComponent.propTypes = {
    size: PropTypes.oneOf(['normal', 'small'])
};

export default CampaignBudgetGuidanceComponent;