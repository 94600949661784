import paypalCheckoutCreator from "braintree-web/paypal-checkout";
import paypalCheckout from "paypal-checkout";
import config from "../../config";



const initializePayPalButtons = ({ validatedBraintreeClientInstance, handlePayPalSuccessfulAuthorization, setAuthorizationWasRejected }) => {
    return new Promise(resolve => paypalCheckoutCreator.create(
        {
            client: validatedBraintreeClientInstance
        },
        (paypalError, paypalCheckoutInstance) => {
            if (paypalError) {
                return resolve({ value: null, error: paypalError });
            } else {
                paypalCheckout.Button.render(
                    {
                        env: config.PAYPAL_ENV,
                        style: {
                            layout: "vertical",
                            color: "gold",
                            shape: "pill",
                            label: "paypal"
                        },
                        payment: function () {
                            return paypalCheckoutInstance.createPayment({
                                flow: "vault"
                            });
                        },
                        onAuthorize: function (data) {
                            return paypalCheckoutInstance.tokenizePayment(data, function (
                                tokenizationError,
                                tokenizedPayPalAccount
                            ) {
                                if (tokenizationError) {
                                    setAuthorizationWasRejected(true);
                                } else {
                                    handlePayPalSuccessfulAuthorization({ tokenizedPayPalAccount });
                                }
                            });
                        }
                    },
                    "#paypal-button"
                )
                return resolve({ value: paypalCheckoutInstance, error: null });
            }
        }
    ));

};

export { initializePayPalButtons };