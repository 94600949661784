export const describeCurrentSubscription = (userSubscription) => {

    const isFreeTrial = userSubscription.plan_id === 'free'
    if (isFreeTrial) {
        return `free version`;
    }

    const isUserInTrial = userSubscription.status === 'in_trial' ? true : false; 
    const plan = isUserInTrial ? 'trial' : userSubscription.plan_id;
    const planEndDate = new Date((isUserInTrial ? userSubscription.trial_end : userSubscription.current_term_end) * 1000);
    const adjustedDate = `${planEndDate.getDay()}/${planEndDate.getMonth()}/${planEndDate.getFullYear()}`;


    const output = `${plan} version until ${adjustedDate}`;
    return output;
};

export const createPricingRefTable = (headers, contents, subscription) => {
    const pricingHeaders = Object.values(headers);
    const pricingDescriptions = Object.values(contents);
    const pricingTexts = pricingHeaders.map((plan, i) => {
        const outputObject = {
            header: plan,
            content: pricingDescriptions[i],
            isCurrent: plan.title.toUpperCase() === subscription.toUpperCase() ? true : false
        }
        return outputObject;
    });

    return pricingTexts;
};