// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { findOutIfUserHasGrantedAgencyAccess } from '../../../ducks/facebook';
// Selectors
import { isUserConnectedWithFacebookSelector } from '../../../utils/reduxSelectors';

import AccountIntegrationComponent from './AccountIntegrationComponent';

const mapStateToProps = state => ({
    isUserIntegratedWithFacebook: state.facebook.wasAgencyAccessGranted,
    isUserConnectedWithFacebook: isUserConnectedWithFacebookSelector(state)
});

const mapDispatchToProps = {
    findOutIfUserHasGrantedAgencyAccess
};

const AccountIntegration = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountIntegrationComponent));

export default AccountIntegration;