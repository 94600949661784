import React from "react";
import style from "./index.module.less";
import clsx from "clsx";
import { PropTypes } from 'prop-types';

const PayPalButtonsComponent = ({ authorizationWasRejected, payPalButtonsTopMarginStyle }) => {

    return (
        <>
            <section className={style.wrapper}>
                <div className={clsx(style.content, payPalButtonsTopMarginStyle)}>
                    <div id="paypal-button"></div>
                </div>
            </section>
            <div className={style.errorContainer}>
                {authorizationWasRejected && (
                    <p className={style.tokenizationFailedErrorText}>
                        We are sorry your we could not authorize your PayPal account. Pleas
                        try again, or choose another payment method.
          </p>
                )}
            </div>
        </>
    );
};

PayPalButtonsComponent.propTypes = {
    authorizationWasRejected: PropTypes.bool.isRequired
};

export default PayPalButtonsComponent;
