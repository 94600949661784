import { selectDomElement } from "../../utils/utils";

export function destructureEventObject({ event }) {
	const aListOfAllHostedFieldsNames = event.fields;
	const hostedFieldEventEmitterNode =
		aListOfAllHostedFieldsNames[event.emittedBy];
	const {
		container: { id: hostedFieldInputId }
	} = hostedFieldEventEmitterNode;
	const { emittedBy: hostedFieldEventEmitterName } = event;
	const hostedFieldLabel = findLabel({
		hostedFieldEventEmitterNode
	});
	const hostedFieldInput = selectDomElement(
		`#${hostedFieldInputId}`
	);

	return {
		hostedFieldLabel,
		hostedFieldInput,
		hostedFieldEventEmitterNode,
		hostedFieldEventEmitterName,
		aListOfAllHostedFieldsNames,
		findLabel
	};
}
export function findLabel({ hostedFieldEventEmitterNode }) {
	return selectDomElement(
		`[for="${hostedFieldEventEmitterNode.container.id}"]`
	);
};