// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// stylesheet
import styles from './index.module.less';

const AccountWooCommerceDataComponent = ({ ownerName, storeName, email }) => {
    return (
        <section className={styles.container}>
            <div className={styles.row}>
                <span className={styles.rowTitle}><p className={styles.rowTitleText}>NAME</p></span>
                <span className={styles.rowContent}>
                    <p className={styles.rowContentValue}>{ownerName}</p>
                    <Link className={styles.rowContentLink} to="/account/ownerName">Change name</Link>
                </span>
            </div>
            <hr className={styles.divider}/>
            <div className={styles.row}>
                <span className={styles.rowTitle}><p className={styles.rowTitleText}>STORE NAME</p></span>
                <span className={styles.rowContent}>
                    <p className={styles.rowContentValue}>{storeName}</p>
                    <Link className={styles.rowContentLink} to="/account/storeName">Change store's name</Link>
                </span>
            </div>
            <hr className={styles.divider}/>
            <div className={styles.row}>
                <span className={styles.rowTitle}><p className={styles.rowTitleText}>EMAIL</p></span>
                <span className={styles.rowContent}>
                    <p className={styles.rowContentValue}>{email}</p>
                    <Link className={styles.rowContentLink} to="/account/email">Change email</Link>
                </span>
            </div>
        </section>
    );
};

AccountWooCommerceDataComponent.propTypes = {
    ownerName: PropTypes.string.isRequired,
    storeName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
}

export default AccountWooCommerceDataComponent;