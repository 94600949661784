import { connect } from 'react-redux'
import PaymentCardNumberComponent from './PaymentCardNumberComponent';

const mapStateToProps = state => ({
	lastFourPaymentCardDigits: state.payment.lastFourPaymentCardDigits,
});


const PaymentCardNumber = connect(mapStateToProps)(PaymentCardNumberComponent);

export default PaymentCardNumber;


