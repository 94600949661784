import amplitude from 'amplitude-js';
import config from '../config';

export const initAmplitude = () => !config.DEV && amplitude.getInstance().init(
  config.AMPLITUDE_KEY,
  null,
  {
    saveEvents: true,
    includeGclid: true,
    includeUtm: true,
    includeReferrer: true
  }
);
export const setAmplitudeUserDevice = deviceId => !config.DEV && amplitude.getInstance().setDeviceId(deviceId);
export const setAmplitudeUserId = userId => !config.DEV && amplitude.getInstance().setUserId(userId);
export const setAmplitudeUserProperties = userProperties => !config.DEV && amplitude.getInstance().setUserProperties(userProperties);
export const sendAmplitudeEvent = (eventType, eventProperties) => !config.DEV && amplitude.getInstance().logEvent(eventType, eventProperties);