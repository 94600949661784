// React
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Third-Party Modules
import Clear from "@material-ui/icons/Clear";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Custom Modules
import { breakpoints } from "../../../styles/constants";
import { shortenString } from "../../../utils/texts";

// Custom Components
import StatusIconComponent from "../StatusIconComponent";

// Assets
import FBLogo from "../../../images/onboarding/fb-logo.png";

// Stylesheet
import styles from "./index.module.less";

const CampaignFacebookItemComponent = ({
  campaignName,
  campaignStatus,
  country,
  createdAt,
  click,
  impression,
  cost,
  linkUrl,
  wasAgencyAccessGranted
}) => {
  const [showHint, setShowHint] = useState(campaignStatus === "PENDING");

  const breakpoint = `(min-width:${breakpoints.sm})`;
  const cssBreakpoint = useMediaQuery(breakpoint);

  const dynamicPendingText = cssBreakpoint
    ? `We're creating your facebook shopping campaign :) it will take us around 3-5 business days to set everything up.`
    : "Your products have to be accepted. It can take from 3 to 5 days.";

  return (
    <div className={styles.campaign} tabIndex="0">
      <Link to={linkUrl}>
        <div
          className={`${styles.campaignInfo} ${
            showHint ? styles.topBorder : ""
          }`}
        >
          <section className={styles.campaignInfoType}>
            <img className={styles.campaignInfoTypeImg} src={FBLogo}></img>
          </section>
          <section className={styles.campaignInfoTexts}>
            <div className={styles.campaignInfoTextsUpper}>
              <span className={styles.campaignTitle}>
                <img
                  className={styles.campaignTitleIcon}
                  src={FBLogo}
                  alt="FB Logo"
                />
                <p className={styles.campaignTitleName}>
                  <strong>{campaignName}</strong>
                </p>
              </span>
              <span className={styles.campaignStatus}>
                <StatusIconComponent status={campaignStatus} />
              </span>
            </div>
            <div className={styles.campaignInfoTextsLower}>
              <p className={styles.campaignInfoTextsLowerText}>
                COUNTRY{" "}
                <span className={styles.campaignInfoTextsLowerBold}>
                  {country}
                </span>
              </p>
              <p className={styles.campaignInfoTextsLowerText}>
                CREATED{" "}
                <span className={styles.campaignInfoTextsLowerBold}>
                  {createdAt}
                </span>
              </p>
            </div>
          </section>
          <section
            className={`${styles.campaignInfoNumbers} ${
              campaignStatus === "PENDING" ? styles.giveOpacity : ""
            }`}
          >
            <div className={styles.element}>
              <p className={styles.number}>{click}</p>
              <p className={styles.tittle}>CLICK</p>
            </div>
            <div className={styles.element}>
              <p className={styles.number}>{impression}</p>
              <p className={styles.tittle}>IMPRESSIONS</p>
            </div>
            <div className={styles.element}>
              <p className={styles.number}>${cost}</p>
              <p className={styles.tittle}>COST</p>
            </div>
          </section>
        </div>
      </Link>
      {showHint && (
        <div className={styles.expectsCampaignInfo}>
          <div className={styles.infoTextWrapper}>
            {wasAgencyAccessGranted === false && (
              <p className={styles.acceptRequestText}>
                {
                  "Please accept Heraldbee's access request in your Facebook Business Manager"
                }
              </p>
            )}
            <p className={styles.text}>{dynamicPendingText}</p>
          </div>
          <div className={styles.closeBtn}>
            <Clear
              className={styles.clearItem}
              onClick={() => setShowHint(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

CampaignFacebookItemComponent.propTypes = {
  campaignName: PropTypes.string,
  campaignStatus: PropTypes.oneOf(["PENDING", "ENABLED", "PAUSED"]),
  country: PropTypes.string,
  click: PropTypes.number,
  impression: PropTypes.number,
  cost: PropTypes.number,
  soldProducts: PropTypes.number,
  earnedMoney: PropTypes.number,
  showConversion: PropTypes.bool
};

CampaignFacebookItemComponent.defaultProps = {
  campaignName: "Campaign name",
  campaignStatus: "PENDING",
  country: "Country name",
  click: 0,
  impression: 0,
  cost: 0,
  showConversion: false
};

export default CampaignFacebookItemComponent;
