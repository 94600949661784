// React
import { withRouter } from 'react-router';

// Redux
import { connect } from 'react-redux';
    // Methods
import { setAccessGranted, fetchUser, logOut, fetchWooCommerceToken, setPlatform, checkIfUserFinishedOnboarding } from '../../ducks/auth';
import { setError } from '../../ducks/app';

import VerificationGatewayContainer from './VerificationGatewayContainer';

const mapStateToProps = state => ({
    accessGranted: state.auth.accessGranted,
    pricing: state.pricing.planId,
    isWoocommerceTokenFetched: state.auth.isWoocommerceTokenFetched,
    isOnboardingFinished: state.auth.isOnboardingFinished,
    user: state.auth.user
});

const mapDispatchToProps = {
    setAccessGranted,
    fetchUser,
    logOut,
    fetchWooCommerceToken,
    setPlatform,
    checkIfUserFinishedOnboarding,
    setError
};

const VerificationGateway = withRouter(connect(mapStateToProps, mapDispatchToProps)(VerificationGatewayContainer));

export default VerificationGateway;