import React from 'react';
import PropTypes from 'prop-types';

//material ui components
import MaterialButtonComponent from '../../MaterialComponents/MaterialButtonComponent';

//ant :( 
import { Spin } from 'antd';

//custom components
import CodeSnippetComponent from '../../CodeSnippetComponent';

//utils text 
import { modalText }from '../../../utils/texts';

//styles
import style from './index.module.less';

const FirstStepComponent = ({onCopyButtonClick, scriptTag}) => {

    return (
        <div className={style.container}>
            <p className={style.headerText}><strong>{modalText.step1}</strong></p>
            <section className={style.section}>
                {
                    scriptTag === ''
                    ? <Spin size="small"/>
                    : <CodeSnippetComponent scriptTag={scriptTag}/>
                }
            </section>
            <section className={style.button}>
                <MaterialButtonComponent
                    type="primary"
                    size="xSmall"
                    text="COPY"
                    onClick={onCopyButtonClick}
                />
            </section> 
        </div>
    ) 
}

FirstStepComponent.propTypes = {
    onCopyButtonClick: PropTypes.func,
    scriptTag: PropTypes.string,
}

export default FirstStepComponent;