import { values, isEqual, sortBy } from 'lodash';
import countryRefferenceTable from '../../constants/countryRefferenceTable';

export const isUpdateNecessary = (oldState, selectedForm, localState) => {
    const isAnyFieldFilled = localState.find(value => value && value.length > 0)
    const isCustomerTypeEqual = selectedForm === oldState.customer_type;
    const areObjectsEqual = isEqual(localState, values(oldState.billing_address));
    
    if (isCustomerTypeEqual) {
        return !areObjectsEqual;
    } else {
        return isAnyFieldFilled;
    }
};

export const translateCountryCode = countryCode => {
    const selectedCountry = countryRefferenceTable.find(country => country.value === countryCode);
    return selectedCountry ? selectedCountry.name : '';
};

export const sortInvoiceList = (invoiceList, key, sortDirection) => {
    return sortBy(invoiceList, key, sortDirection);
};

export const translateTimestampToString = timestamp => {
    const date = new Date(timestamp*1000);
    return `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`;
};

export const constructInvoiceName = (invoice, storeName) => {
    return `invoice${invoice.id}_${translateTimestampToString(invoice.date)}_${storeName}`;
};

export const translateInvoiceStatus = invoiceStatus => {
    switch(invoiceStatus) {
        case 'payment_due':
            return 'payment due';
        case 'not_paid':
            return 'not paid';
        default:
            return invoiceStatus;
    };
};