// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { clearAddCampaignState, clearAddCampaignStateComplete } from '../../ducks/additionalCampaign/methods';

import AddNewCampaignContainer from './AddNewCampaignContainer';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    clearAddCampaignState,
    clearAddCampaignStateComplete
};

const AddNewCampaign = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNewCampaignContainer));

export default AddNewCampaign;