import { amplitudeEvents } from "../constants/amplitudeEvents";
import { sendAmplitudeEvent } from "../utils/amplitude";

/**
* Pricing step state
**/

// Actions

const SET_PRICING_PLAN = 'SET_PRICING_PLAN';

// Reducer

const initialState = {
  planId: null
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {

    case SET_PRICING_PLAN:
      return {
        ...state, 
        planId: action.planId
      }

    default:
      return state;
  }
}
export default reducer;

// Action Creators

const actionSetPricingPlan = planId => ({ type: SET_PRICING_PLAN, planId });

// Methods

export const setPricing = planId => dispatch => {
  sendAmplitudeEvent(amplitudeEvents.ONBOARDING_SELECT_PLAN, {
    planId: planId
  });
  dispatch(actionSetPricingPlan(planId));
}

