import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from "@material-ui/core";
import { colorPalette, typography } from '../../../../styles/constants';

export const useTableBodyStyles = makeStyles({
    root: {
        '& *': {
            color: colorPalette.primaryBrown,
            fontSize: typography.paragraphLg,
            lineHeight: 1.75,
            fontWeight: 'normal',
        },
        marginRight: 34,
        paddingLeft: 34,
        width:'90%',
    }
})

export const usePaperStyle = makeStyles({
    root : {
        overflowX: 'auto',
    }
})

export const containerStyles = {
    'div': {
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 25,
    },
    'p': {
        margin: 0,
        marginRight:7,
        marginTop:24,
    }  
}

export const tableTheme = createMuiTheme({
    palette: {
      primary: {
          main : colorPalette.primaryBrown,
      },
    },
  });