// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setGoogleField as setCampaignBudget } from '../../../../ducks/additionalCampaign/methods';

import ChooseBudgetContainer from '../../../../components/SharedCreateCampaign/ChooseBudgetContainer';

const mapStateToProps = state => ({
    budgetValue: state.additionalCampaign.google.budget
});

const mapDispatchToProps = {
    setCampaignBudget
};

const ChooseBudgetMain = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseBudgetContainer));

export default ChooseBudgetMain;