import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

const MaterialAppMenuIconsListComponent = ({
  iconsList,
  currentlyActivePath,
  iconContainerStyle,
  iconActiveStyle,
  iconInactiveStyle
}) => {
  return (
    <List>
      {iconsList.map(({ text, path, IconSvgComponent }) => {
        const shouldIconBeActive = currentlyActivePath.includes(path);
        const iconSvgStyle = shouldIconBeActive
          ? iconActiveStyle
          : iconInactiveStyle;
        return (
          <Link to={path} key={text}>
            <ListItem button>
              <ListItemIcon>
                {shouldIconBeActive ? (
                  <div className={`${iconContainerStyle} ${iconSvgStyle}`}>
                    <IconSvgComponent
                    />
                    <ListItemText primary={text} />
                  </div>
                ) : (
                  <div className={`${iconContainerStyle} ${iconSvgStyle}`}>
                    <IconSvgComponent
                    />
                    <ListItemText secondary={text} />
                  </div>
                )}
              </ListItemIcon>
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
};

MaterialAppMenuIconsListComponent.propTypes = {
  iconsList: PropTypes.array.isRequired,
  currentlyActivePath: PropTypes.string.isRequired,
  iconContainerStyle: PropTypes.string
};

export default MaterialAppMenuIconsListComponent;
