import React from 'react';
import PropTypes from 'prop-types';

//material ui components
import ThumbsUpDown from '@material-ui/icons/ThumbsUpDown';
import MaterialNativeSelectComponent from '../../MaterialComponents/MaterialNativeSelectComponent';

//styles
import styles from './index.module.less';

const CampaignProductHeaderComponent = ({ onChange, optionList }) => {

    return (
        <header className={styles.container}>
            <p className={styles.text}>FILTER:</p>
            <ThumbsUpDown className={styles.icon}/>
            <p className={styles.iconText}>STATUS</p>
            <span className={styles.select}>
                <MaterialNativeSelectComponent onChange={onChange} optionList={optionList} />
            </span>
        </header>
    );
}

CampaignProductHeaderComponent.propTypes = {
    onChange: PropTypes.func,
    optionList: PropTypes.arrayOf(PropTypes.string),
}

export default CampaignProductHeaderComponent;

