import config from "../config";
import { setAmplitudeUserId } from "./amplitude";
import { setIntercomUser } from "./intercom";
import get from 'lodash.get';

const parseJson = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export const readFromLocalStorage = key => {
  let item = localStorage.getItem(key);
  item = parseJson(item);
  return item;
}

export const setToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
}

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
}

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const getSearchParam = (url, key) => {
  if (url.indexOf(key) < 0) { return null }

  let offsetSearchByAssignMark = 1;
  let startSearchIndex = url.indexOf(key) + key.length + offsetSearchByAssignMark;
  let endOfParam = url.substring(startSearchIndex).indexOf('&');
  let endSearchIndex = endOfParam < 0 ? undefined : startSearchIndex + endOfParam;

  return url.substring(startSearchIndex, endSearchIndex);
}

export const removeSearchParams = (url, keys) => {
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    let params = urlparts[1].split(/[&;]/g);

    const paramsFiltered = params.filter(param => {
      return keys.every(utmParam => {
        //idiom for string.startsWith
        if (param.lastIndexOf(utmParam + '=', 0) === -1) {
          return true;
        } else {
          return false;
        }
      })
    });

    return urlparts[0] + (paramsFiltered.length > 0 ? '?' + paramsFiltered.join('&') : '');
  }
  return url;
}

export const removeUtmSearchParams = (url) => {
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

  return removeSearchParams(url, utmParams);
}

export const initUserDataForStatistics = (user, subscription, shop, planId) => {
  const shopDataExists = user.content && Boolean(user.content.shopify || user.content.woocommerce);
  const userLoading = user.loader;
  const subscriptionLoading = subscription.loader;

  if (!config.DEV) {
    //ReactGA.pageview(window.location.pathname + window.location.search);
    if (shopDataExists && !userLoading && !subscriptionLoading) {
      user.content && setAmplitudeUserId(user.content.username);
    }
    setIntercomUser(user && user.content, subscription && subscription.content, shop, planId);
  }
}

export const getShop = (user, wooCommerceDataPackage) => {
  if (user) {
    if (user.shopify) {
      return {
        ...user.shopify,
        name: user.shopify.shopOwner
      }
    } else if (user.woocommerce) {
      if (wooCommerceDataPackage) {
        const { email, shopName, name } = wooCommerceDataPackage;
        const shop = {
          email,
          shopName,
          name,
          id: user.woocommerce.domain,
          domain: user.woocommerce.domain
        };
        return shop;
      }
      return { // Fix for silly lack of integrity between db tables
        ...user.woocommerce,
        name: user.woocommerce.shopOwner,
        shopName: user.woocommerce.name
      };
    }
  } else {
    return null
  }
};

export const to = promise => {
  return promise.then(data => {
    return [null, data];
  })
    .catch(err => [err])
}
/* The utility receives a promise, and then resolve the success response to an array
with the return data as second item. And the Error received from the catch as the first.*/


export const selectDomElement = querySelector => document.querySelector(querySelector);
export const addClass = (domElement, className) => domElement.classList.add(className);
export const removeClass = (domElement, className) => domElement.classList.remove(className);
export const isNodeEmpty = querySelector => get(selectDomElement(querySelector), "childNodes.length") === 0;

// Gathering all the wooCommerce data when available
export const adjustWooCommerceDataPackage = (user, wooCommerceDataPackage) => {
  const { email, shopOwner, name } = wooCommerceDataPackage;
  if (user.content) {
    if (user.content.woocommerce) {
      const { name: dbName, email: dbEmail, shopOwner: dbShopOwner } = user.content.woocommerce;
      if (!(dbName || dbEmail || dbShopOwner) && !(name || email || shopOwner)) {
        return { name: 'Guest' };
      }
      return (email && shopOwner && name) ? wooCommerceDataPackage : null;
    }
  } else {
    return null;
  }
};

export const isPlatformShopify = platform => {
  return platform === 'shopify';
};

export const avatarSizeTranslator = size => {
    switch (size) {
        case 'small':
            return '40px';
        case 'large':
            return '80px';
        default:
            return '40px';
    }
};

export const capitalizeFirstLetter = stringForCapitalization => 
stringForCapitalization && `${stringForCapitalization.slice(0, 1).toUpperCase()}${stringForCapitalization.slice(1)}`;

export const isDesignationOnboarding = designation => {
  return designation === 'onboarding';
};

export const FacebookAgencyAccessRequestStatus = Object.freeze({
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
})

