import React, { useEffect, useState } from 'react';
import RcQueueAnim from 'rc-queue-anim';
import woocommerceLogo from '../../../images/woocomerce_logo.svg';
import ShopifyLogo from '../../../images/shopify/shopify_logo_whitebg.svg';
import PropTypes from 'prop-types';
import { setToLocalStorage, isPlatformShopify } from '../../../utils/utils';
import { checkIfPlanIsValid } from '../../../utils/account';
import queryString from 'query-string';
import style from './styles.module.less';
import { history } from '../../../store';
import Cookies from 'js-cookie';
//new Layout components

import HorizontalViewWithLogoComponent from '../../../components/HorizontalViewWithLogoComponent'
import OnboardingContainer from '../../../container/OnboardingContainer';
import MaterialButtonComponent from '../../../components/MaterialComponents/MaterialButtonComponent/index';


const LoginChoosePlatformComponent = ({ setPlatform, location, softLogOut, didSoftLogOutOccur, clearDidSoftLogOutOccur }) => {

	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
	const [selectedPlatform, setSelectedPlatform] = useState(null);

	const isAuthCookiePresent = Cookies.get('heraldbee_auth_token') ? true : false;

	const cleanup = () => {
		if (isAuthCookiePresent) {
			Cookies.remove('heraldbee_auth_token');
			softLogOut();
		}
	};

	useEffect(() => {
		cleanup();

		const query = location && location.search;
		const parsedQuery = queryString.parse(query);

		// check if query is provided and if it's valid
		if (query && checkIfPlanIsValid(parsedQuery.pricing)) {
			setToLocalStorage('pricing', parsedQuery.pricing);
		} else if (query && parsedQuery.register) {
			history.push('/pricing');
		}
	}, []);

	const onPlatformSelection = event => {
		setSelectedPlatform(event.currentTarget.id);
		setIsNextButtonDisabled(false);
	};

	const handleNextClick = () => {
		clearDidSoftLogOutOccur();
		setPlatform(selectedPlatform);
		history.push(`/login/${selectedPlatform}`);
	};

			
    return (
        <OnboardingContainer displayMode='showHeaderMobile'>
            <HorizontalViewWithLogoComponent>
			<div className={style.choosePlatform}>
					<div className={style.choosePlatformCard}>
						<header className={style.selectionText}>
							<h1 className={style.firstRow}>Choose your <strong>platform</strong></h1>
							<h3 className={style.secondRow}>and connect your store</h3>
						</header>
						<div className={style.selectionButtonsRow}>
							<button id='shopify' className={`${isPlatformShopify(selectedPlatform) ? `${style.buttonWrapper} ${style['buttonWrapper--selected']}` : style.buttonWrapper}`} onClick={onPlatformSelection}>
								<img className={style.shopifyLogo} src={ShopifyLogo} alt="Choose Shopify" />
							</button>
							<button id='woocommerce' className={`${selectedPlatform === 'woocommerce' ? `${style.buttonWrapper} ${style['buttonWrapper--selected']}` : style.buttonWrapper}`} onClick={onPlatformSelection}>
								<img className={style.woocommerceLogo} src={woocommerceLogo} alt="Choose woocommerce" />
							</button>
						</div>
						<p className={didSoftLogOutOccur ? style.cleanupTextActive : style.cleanupText}>You have been logged out, please sign in again</p>
						<div className={style.nextButtonRow}>
							<MaterialButtonComponent
								text='Next'
								size="xLarge"
								type={'primary'}
								disabled={isNextButtonDisabled}
								onClick={handleNextClick}
							/>
						</div>
					</div>
			</div>
            </HorizontalViewWithLogoComponent>
		</OnboardingContainer>
	);
}

LoginChoosePlatformComponent.propTypes = {
	platform: PropTypes.string,
	setPlatform: PropTypes.func,
	location: PropTypes.object.isRequired,
}
export default LoginChoosePlatformComponent;