// React
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

//antd :(
import { Spin } from 'antd';

// Custom Components
import ProductListComponent from '../../../components/ProductListComponent';
import ProductItemComponent from '../../../components/ProductListComponent/ProductItemComponent';
import CampaignProductsHeaderComponent from '../../../components/ProductListComponent/CampaignProductHeaderComponent'
import NoResultViewComponent from '../../../components/NoResultViewComponent'

//utils
import { productNoResultText } from '../../../utils/texts';

// Stylesheet
import style from './index.module.less';

const CampaignProductsContainer = ({ products, targetCountryCodes, campaignId, nextProductsPayloadToken, allProductsPayloadsFetched, fetchCampaignProducts }) => {
    const productsPerPage = 20;
    const maxLoadingTimeInSeconds = 15;
    const filterValues = {
        'ALL' : 'ALL',
        'APPROVED': 'approved',
        'DISAPPROVED': 'disapproved',
        'WAITING FOR GOOGLE':'waitingForGoogle' ,
        'WEBSITE CLAIM IN PROGRESS': 'websiteClaimInProgress',
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [loadingStarted, setLoadingStarted] = useState(new Date());
    const [pickedFilterValue, setPickedFilterValue] = useState('ALL')
    
    const getProductsByCountryCodes = (allProducts, countryCodes) => {
        if (allProducts && !countryCodes) {
            return allProducts;
        } else if (allProducts) {
            return allProducts.filter(product =>
                countryCodes.some(code =>
                    product.targetCountryCode === code
                )
            )
        } else {
            return [];
        }
    }
    
    const [productsByCountryCodes, setProductsByCountryCodes] = useState(getProductsByCountryCodes(products, targetCountryCodes));
    
    const isPageEntireFilled = (page, totalProductsCount) => {
        const productsCountPossibleOnCurrentAndPrevPages = page * productsPerPage;
        const productsCountNeededOnCurrentAndPrevPages = productsCountPossibleOnCurrentAndPrevPages - totalProductsCount;

        return productsCountNeededOnCurrentAndPrevPages <= 0;
    }

    const shouldFetchCampaignProducts = (page, productsByCountries) => {
        if (allProductsPayloadsFetched) {
            return false;
        } else if (productsByCountries && nextProductsPayloadToken) {
            if (!isPageEntireFilled(page, productsByCountries.length)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const isLoadingTimeExceeded = () => {
        const now = new Date();
        const timeDiff = (now - loadingStarted) / 1000;
        if (loadingStarted && timeDiff >= maxLoadingTimeInSeconds) {
            return true;
        } else {
            return false;
        }
    }

    useLayoutEffect(() => {
        const filteredProductsByCountry = getProductsByCountryCodes(products, targetCountryCodes);
        setProductsByCountryCodes(filteredProductsByCountry);

        const shouldFetchProducts = shouldFetchCampaignProducts(currentPage, filteredProductsByCountry);
        const loadingTimeNotExceeded = !isLoadingTimeExceeded();

        if (shouldFetchProducts && loadingTimeNotExceeded) {
            fetchCampaignProducts(nextProductsPayloadToken);
        } else {
            setLoadingStarted(null);
        }
    }, [products, nextProductsPayloadToken])

    const onPageChange = (page) => {
        const shouldFetchProducts = shouldFetchCampaignProducts(currentPage, productsByCountryCodes);

        if (shouldFetchProducts) {
            setLoadingStarted(new Date());
            fetchCampaignProducts(nextProductsPayloadToken);
        }
        setCurrentPage(page);
    }

    const handleFilterProductsByStatus = (products, filterValue) => {
        if (filterValue === 'ALL'){
            return products;
        }
        return products.filter(product => product.status === filterValue);
    }

    const handleChangeFilterValue = (event) => {
        setPickedFilterValue(filterValues[event.target.value])
    }


    if (loadingStarted) {
        return (
            <div className={style.container}>
                <NoResultViewComponent
                    headerText={productNoResultText.loaderHeaderText}
                    subText={productNoResultText.loaderSubText}
                >
                    <Spin size='small'/>
                </NoResultViewComponent>
            </div>
        );
    } else {
        return (
            <div className={style.container}>
                <CampaignProductsHeaderComponent onChange={handleChangeFilterValue} optionList={Object.keys(filterValues)}/>
                {
                    productsByCountryCodes.length === 0
                    ? <NoResultViewComponent
                        headerText={productNoResultText.headerText}
                        subText={productNoResultText.subText}
                    />
                    : <ProductListComponent
                        currentPage={currentPage}
                        productsPerPage={productsPerPage}
                        onPageChange={onPageChange}
                        products={handleFilterProductsByStatus(productsByCountryCodes, pickedFilterValue)}
                        campaignId={campaignId}
                    />
                }
               
            </div>
        );
    }
};

CampaignProductsContainer.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape({
            ...ProductItemComponent.propTypes,
            targetCountryCode: PropTypes.string,
        })
    ),
    nextProductsPayloadToken: PropTypes.string,
    fetchCampaignProducts: PropTypes.func,
};

export default CampaignProductsContainer
