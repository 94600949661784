import React from 'react';

//utils text 
import { modalText }from '../../../utils/texts';

//images
import addScriptTagImage from '../../../images/conversions/addScriptTagImage.png'

//styles
import style from './index.module.less';

const ThirdStepComponent = () => {

    return (
        <div className={style.container}>
            <p className={style.headerText}><strong>{modalText.step3}</strong></p>
            <section className={style.content}>    
                <img className={style.hintImage} src={addScriptTagImage} alt="hbApp helper view"/>
            </section>
        </div>
    ) 
}


export default ThirdStepComponent;