// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods

import OnboardingPlatformSelectionSuccessComponent from './OnboardingPlatformSelectionSuccessComponent';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

const OnboardingPlatformSelectionSuccess = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingPlatformSelectionSuccessComponent));

export default OnboardingPlatformSelectionSuccess;