import React from 'react';
import PropTypes from 'prop-types';

//css
import styles from './index.module.less';

//other libs
import clsx from "clsx";

const NoHistoryViewComponent = ({dateFrom, dateTo}) => {
    return (
        <div className={styles.container}>
            <section className={styles.section}>
                <p className={styles.headerText}>No records found</p>
                <div className={styles.userChoice}>
                    <p className={styles.smallText}>We couldn't find any results for:</p>
                    <p className={styles.smallText}><strong>`{dateFrom}' - {dateTo}.</strong></p>
                </div>
                <p className={styles.smallText}>Please train again.</p>
                <p className={clsx(styles.smallText, styles.listHeader)}>Suggestions:</p>
                <ul className={styles.sugestionList}>
                    <li>make sure the date is correct</li>
                    <li>try different date</li>
                    <li>try to insert a more specific date.</li>
                </ul>
            </section>
            <p className={styles.smallText}>If you have any questions, <span className={clsx(styles.openIntercomButton, 'OPEN_INTERCOM')}>contact us.</span></p>
        </div>
    )
}

NoHistoryViewComponent.porpTypes = {
    dateFrom : PropTypes.string,
    dateTo: PropTypes.string
}

export default NoHistoryViewComponent;