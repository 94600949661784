// React Modules
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { ConnectedRouter as Router } from 'connected-react-router';
import { history } from './store';

// Redux
import { connect } from 'react-redux';

// Custom Components
import ErrorBoundary from './components/ErrorBoundary';
import AuthenticationRouter from './routers/AuthenticationRouter/index';
import OnboardingRouter from './routers/OnboardingRouter/index';
import PageNotFound from './components/PageNotFound';

// Custom Modules
import { ErrorTrackingModule } from './module/ErrorTrackingModule';
import { UserTracerModule } from './module/UserTracerModule';

// Data Statistics Utility
import { initUserDataForStatistics, adjustWooCommerceDataPackage, getShop } from './utils/utils';
import { amplitudeEvents } from './constants/amplitudeEvents';
import { sendAmplitudeEvent } from './utils/amplitude';
import { appPaths as appPathsObject } from './constants/routerData';
import { actionSetCachedListOfAvailableAppRoutes } from '../src/ducks/auth';

const App = ({ globalError, user, subscription, wooCommerceDataPackage, cachedListOfAvailableAppRoutes, actionSetCachedListOfAvailableAppRoutes, planId }) => {
  useEffect(() => {
    /**
     * This event is very general, but bear in mind, that sending the first event for amplitude,
     * will cause that the user will exist in amplitude from the beginning, and all of him/her parameters
     * will also be possible to retrieve and thus possible to analyse, including UTM params.
     * If this event is not send and user leave app at the very beginning, then his/her properties
     * like UTM params are consequently not shown in amplitude stats.
     * 
     * This event will be associated with anonymous user or with user which will login to the app later -
     * the order of events dispatch doesn't matter (it will be associated with the same user after all).
     */
    sendAmplitudeEvent(amplitudeEvents.APP_OPEN_APP);
  }, [])

  const [isPathKnown, setIsPathKnown] = useState();

  // Setup and initialize statistics gathering when associated props get updated
  useEffect(() => {
    const shop = getShop(user && user.content, adjustWooCommerceDataPackage(user, wooCommerceDataPackage));

    ErrorTrackingModule.setUser(user.content, shop);
    UserTracerModule.setUser(user.content);
    initUserDataForStatistics(user, subscription, shop, planId);
  }, [user, subscription, wooCommerceDataPackage]);

  /** Decide whether the requested pathname is known to the app */
  useLayoutEffect(() =>
    isTheRequestedPathKnownToTheApp(history.location.pathname)
    , [history.location.pathname]
  )
  function isTheRequestedPathKnownToTheApp(path) {
    const attemptedPath = path;
    /** Flatten the imported appPathsObject */
    if (!cachedListOfAvailableAppRoutes) {
      /** Avoid flattening routes object at every path change */
      const listOfAvailableAppRoutes = Object.keys(appPathsObject).map(key => 
        appPathsObject[key]
      );
      actionSetCachedListOfAvailableAppRoutes(listOfAvailableAppRoutes);
      setIsPathKnown(listOfAvailableAppRoutes.some(specificPath =>
        specificPath.test(attemptedPath)
      ));
    } else {
      setIsPathKnown(cachedListOfAvailableAppRoutes.some(specificPath =>
        specificPath.test(attemptedPath)
      ));
    }
  };

  const Application = (
    <ErrorBoundary history={history} error={globalError}>
      <AuthenticationRouter path="/" >
        <OnboardingRouter />
      </AuthenticationRouter>
    </ErrorBoundary>
  );

  return (
    <Router history={history}>
      <>
        {isPathKnown ?
          Application
          :
          <PageNotFound />
        }
      </>
    </Router>
  );
}

const mapStateToProps = state => ({
  user: state.auth.user,
  subscription: state.subscription,
  planId: state.pricing.planId,
  wooCommerceDataPackage: { // If not required data is present in store, default values to null
    email: state.auth.wooCommerceEmail || null,
    shopOwner: state.auth.wooCommerceShopName || null,
    name: state.auth.wooCommerceOwnerName || null
  },
  listOfAvailableAppRoutes: state.auth.listOfAvailableAppRoutes
});
const mapDispatchToProps = {
  actionSetCachedListOfAvailableAppRoutes
};




export default connect(mapStateToProps, mapDispatchToProps)(App);