// React
import React from 'react';
import { NavLink, Route, Redirect, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';

// Third-Party Modules
import { useMediaQuery } from '@material-ui/core';

// Custom Components
import MaterialBreadcrumbsComponent from '../../../../../components/MaterialComponents/MaterialBreadcrumbsComponent';
import { CheckCircleOutline, PauseCircleOutline, Schedule } from '@material-ui/icons';
import CampaignSettings from '../../../../../components/CampaignDetails/CampaignSettings';
import CampaignHistory from '../../../../../container/CampaignHistory';
import CampaignProducts from '../../../../../container/CampaignProducts';

// Custom Modules
import { googleAdsLocations } from '../../../../../constants/googleAdsLocations';
import { shortenString } from '../../../../../utils/texts';
import moment from 'moment';

// Assets
import GLogo from '../../../../../images/logo_G_color_240px.png';

// Stylesheet
import style from './index.module.less';
import { breakpoints } from '../../../../../styles/constants';

const CampaignGoogleContainer = ({ campaign, platform, areConversionsActive }) => {
    const cssBreakpoint = useMediaQuery(`(min-width:${breakpoints.sl})`);
    const adjustedCampaignName = cssBreakpoint ? campaign.name : shortenString(campaign.name);

    const linkArray = [
        {
            textContent: 'Back to Campaigns list',
            link: '/campaigns'
        }, 
        {
            textContent: adjustedCampaignName,
            link: null
        }
    ];

    const StatusIcon = () => {
        switch (campaign.status) {
            case 'ENABLED':
                return (
                    <CheckCircleOutline />
                );
            case 'PAUSED':
                return (
                    <PauseCircleOutline />
                );
            case 'PENDING':
                return (
                    <Schedule />
                );
            default:
                return (
                    <Schedule />
                );
        }
    };

    const variedColor = () => {
        return campaign.status === 'ENABLED' ? style.green : style.gray;
    };

    const statusColor = () => {
        return campaign.status === 'PENDING' ? style.inactive : variedColor();
    }

    const translateCountryCode = () => {
        return googleAdsLocations.find(country => 
            country.value === campaign.salesCountryCode
        ).name;
    };

    const detailsStyle = () => {
        return platform === 'shopify' && areConversionsActive ? style.detailsRightExtended : style.detailsRight;
    };
    
    return (
        <section className={style.singleCampaignWrapper}>
            <header className={style.container}>
            <MaterialBreadcrumbsComponent breadcrumbLinkArray={linkArray} />
                <div className={style.details}>
                    <div className={style.detailsType}>
                        <img className={style.detailsTypeImg} src={GLogo} alt="G logo"></img>
                    </div>
                    <div className={style.detailsLeft}>
                        <span className={style.detailsLeftTitle}>
                            <span className={style.detailsLeftTitleName}>
                                <img className={style.detailsLeftTitleNameIcon} src={GLogo} alt="G Logo" />
                                <h2 className={`h2-sm ${style.detailsLeftTitleNameHeader}`}>{campaign.name}</h2>
                            </span>
                            <span className={`${style.detailsLeftTitleDetails} ${statusColor()}`}>
                                {StatusIcon()}
                                <p className={style.detailsLeftTitleDetailsText}>{campaign.status}</p>
                            </span>
                        </span>
                        <span className={style.detailsLeftStats}>
                            <span className={style.detailsLeftStatsCountry}>
                                <p className={style.description}>
                                    <span className={style.descriptionOpaque}>COUNTRY</span>
                                    <span className={style.valueSmall}>{` ${translateCountryCode()}`}</span>
                                </p>
                            </span>
                            <span className={style.detailsLeftStatsBudget}>
                                <p className={style.description}>
                                    <span className={style.descriptionOpaque}>BUDGET</span>
                                    <span className={style.valueSmall}>{` $${campaign.dailyBudget}`}</span>
                                </p>
                            </span>
                            <span className={style.detailsLeftStatsCreated}>
                                <p className={style.description}>
                                    <span className={style.descriptionOpaque}>CREATED</span>
                                    <span className={style.valueSmall}>{` ${moment(campaign.startTime).format('YYYY/MM/DD')} `}</span>
                                </p>
                            </span>
                        </span>
                    </div>
                    <div className={detailsStyle()}>
                        <div className={style.detailsRightStat}>
                            <p className={`${style.statValue} ${statusColor()}`}>{campaign.clicks}</p>
                            <p className={`${style.statName} ${statusColor()}`}>CICKS</p>
                        </div>
                        <div className={style.detailsRightStat}>
                            <p className={`${style.statValue} ${statusColor()}`}>{campaign.impressions}</p>
                            <p className={`${style.statName} ${statusColor()}`}>IMPRESSIONS</p>
                        </div>
                        <div className={style.detailsRightStat}>
                            <p className={`${style.statValue} ${statusColor()}`}>{`$${campaign.cost}`}</p>
                            <p className={`${style.statName} ${statusColor()}`}>COST</p>
                        </div>
                            {
                                platform === 'shopify' && areConversionsActive ? (
                                    <>
                                        <div className={style.detailsRightStat}>
                                            <p className={`${style.statValue} ${statusColor()}`}>{campaign.soldProducts || 0}</p>
                                            <p className={`${style.statName} ${statusColor()}`}>SOLD PRODUCTS</p>
                                        </div>
                                        <div className={style.detailsRightStat}>
                                            <p className={`${style.statValue} ${statusColor()}`}>{`$${campaign.earnedMoney || 0}`}</p>
                                            <p className={`${style.statName} ${statusColor()}`}>EARNED MONEY</p>
                                        </div>
                                    </>
                                ) : null
                            }
                    </div>
                </div>
                <div className={style.navigation}>
                    <NavLink className={style.navigationLink} activeClassName={style.navigationLinkActive} to={`/campaigns/google/${campaign.id}/products`}>
                        <p className={style.navigationText}>PRODUCTS</p>
                    </NavLink>
                    <NavLink className={style.navigationLink} activeClassName={style.navigationLinkActive} to={`/campaigns/google/${campaign.id}/settings`}>
                        <p className={style.navigationText}>CAMPAIGN SETTINGS</p>
                    </NavLink>
                    <NavLink className={style.navigationLink} activeClassName={style.navigationLinkActive} to={`/campaigns/google/${campaign.id}/history`}>
                        <p className={style.navigationText}>HISTORY</p>
                    </NavLink>
                </div>
            </header>
            <main>
            <Switch>
                <Route 
                    path={`/campaigns/google/${campaign.id}/products`} 
                    render={() => <CampaignProducts targetCountryCodes={campaign.targetCountryCodes} campaignId={campaign.id} />}/>
                <Route 
                    path={`/campaigns/google/${campaign.id}/settings`} 
                    render={() => <CampaignSettings campaign={campaign}/>}/>
                <Route 
                    path={`/campaigns/google/${campaign.id}/history`}
                    render={() => <CampaignHistory campaignId={campaign.id}/>}/>
                <Route exact path={`/campaigns/google/${campaign.id}`} render={() => <Redirect to={`/campaigns/google/${campaign.id}/products`}/>} />
            </Switch>
            </main>
        </section>
    );
};

CampaignGoogleContainer.propTypes = {
    campaign: PropTypes.object.isRequired,
    platform: PropTypes.oneOf(['woocommerce', 'shopify']),
};

export default CampaignGoogleContainer;