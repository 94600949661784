import React, { useState, useEffect } from "react";
import PaymentInfoAbsentComponent from "../../../components/PaymentInfoAbsentComponent";
import clsx from "clsx";
import HorizontalDivisorComponent from "../../../components/HorizontalDivisorComponent";
import { Link } from "react-router-dom";
import style from "./index.module.less";
import { PropTypes } from "prop-types";
import SubscriptionPlanInfo from "../../../components/SubscriptionPlanInfo";
import { Spin } from "antd";
import PayPalAcceptedMethods from './../../../components/PayPalAcceptedMethods/index';
import PaymentCardInfoPresent from './../../../components/PaymentInfoPresent/index';

const SubscriptionSettingsContainer = ({
  getPaymentAuthDetails,
  subscriptionId,
  lastFourPaymentCardDigits,
  creditCardVendorNameForSummary,
  pricingPlanId,
  noCardDetailsAvailable,
  summaryShouldKnowOfCardUpdate,
  actionSetShouldSummaryKnowOfCardUpdate,
  actionSetIsNonceReadyForUpdate,
  actionSetUpdateMessage,
  setNondestructiveErrorMessage,
  payPalEmail,
  setConfirmedPaymentMethodForEdition,
  actionConfirmSuccessfulPaymentAuthUpdate
}) => {
  const [isLoading, setIsLoading] = useState();
  const [arePaymentDetailsAvailable, setArePaymentDetailsAvailable] = useState(
    (lastFourPaymentCardDigits && creditCardVendorNameForSummary) || payPalEmail
  );
  const [isWaitingForUpdate, setIsWaitingForUpdate] = useState(
    arePaymentDetailsAvailable && summaryShouldKnowOfCardUpdate
  );

    useEffect(() => setConfirmedPaymentMethodForEdition(null), [setConfirmedPaymentMethodForEdition]);

    // useEffect(() =>{if (subscriptionId) { getPaymentAuthDetails({ subscriptionId }) }}, []);

    useEffect(() => {
        actionConfirmSuccessfulPaymentAuthUpdate(false);
        actionSetIsNonceReadyForUpdate(false);
        setNondestructiveErrorMessage({ displayedErrorMessage: null, error: null });
        setArePaymentDetailsAvailable(
            (lastFourPaymentCardDigits && creditCardVendorNameForSummary ) || payPalEmail
        );
        setIsWaitingForUpdate(
            arePaymentDetailsAvailable && summaryShouldKnowOfCardUpdate
        );
        const updateIsImminent = !isLoading && summaryShouldKnowOfCardUpdate;
        const noDetailsWillBeAvailable = !arePaymentDetailsAvailable && !noCardDetailsAvailable;

        /** Case when user was logged out, and no card details are kept in Redux Store */
        if (subscriptionId && (updateIsImminent || noDetailsWillBeAvailable)) {
            setIsLoading(true);
            getPaymentAuthDetails({ subscriptionId });
        }
        if (arePaymentDetailsAvailable || noCardDetailsAvailable) {
            setIsLoading(false);
        }
  }, [lastFourPaymentCardDigits, payPalEmail, creditCardVendorNameForSummary, summaryShouldKnowOfCardUpdate, isWaitingForUpdate, arePaymentDetailsAvailable, setNondestructiveErrorMessage, getPaymentAuthDetails, subscriptionId, noCardDetailsAvailable]);

  useEffect(() => {
    if (isWaitingForUpdate) {
      actionSetUpdateMessage("Your payment authorization was successfully updated");
      actionSetShouldSummaryKnowOfCardUpdate(false);
    }
  }, [actionSetShouldSummaryKnowOfCardUpdate, actionSetUpdateMessage, arePaymentDetailsAvailable, isWaitingForUpdate, noCardDetailsAvailable, summaryShouldKnowOfCardUpdate]);

  const isSubscriptionPlanAvailable = pricingPlanId;

  const ChangeSubscriptionPlanLinkButton = () => (
    <Link className={style.link} to="/account/changeSubscriptionPlan">
      Change plan
    </Link>
  );
  const ChangePaymentAuthLinkButton = () => (
    <Link to="/account/changePaymentMethod" className={style.link}>
      Change payment method
    </Link>
  );

  let LinkButtonForSubscriptionPlanInfo,
    LinkButtonForPaymentCardInfoPresent,
    PaymentInfoComponent, SubscriptionPlanStatusComponent;

  function makeRenderDecision() {
    if (isSubscriptionPlanAvailable) {
      LinkButtonForSubscriptionPlanInfo = () => <ChangeSubscriptionPlanLinkButton />;
      SubscriptionPlanStatusComponent = () =>
        <SubscriptionPlanInfo
          pricingPlanId={pricingPlanId}
          LinkButton={<LinkButtonForSubscriptionPlanInfo />}
        />;

    } else {
      SubscriptionPlanStatusComponent = () =>
        <div className={style.settingInnerContainer}><span>No subscription plan is attached to your account. Please contact our support team.</span></div>;

    }
    if (arePaymentDetailsAvailable) {
        LinkButtonForPaymentCardInfoPresent = ChangePaymentAuthLinkButton;
        if (payPalEmail) {
            PaymentInfoComponent = () => (
                <PayPalAcceptedMethods LinkButton={<LinkButtonForPaymentCardInfoPresent />}
                    settingInnerContainerStyle={style.settingInnerContainer} />
            );
        } else {
          PaymentInfoComponent = () => (
            <PaymentCardInfoPresent
              LinkButton={<LinkButtonForPaymentCardInfoPresent />}
              settingInnerContainerStyle={style.settingInnerContainer}
            />
          );

            }

        } else {
            if (isLoading) {
                PaymentInfoComponent= () => <Spin></Spin>
            } else {
                PaymentInfoComponent = () => (
                    <PaymentInfoAbsentComponent
                        settingInnerContainerStyle={style.settingInnerContainer}
                    />
                );
            }
        }
    }
    makeRenderDecision();

    return (
    <article
      className={clsx(style.pricingPaymentContainer, style.articleContainer)}
    >
      <div className={style.specificSetting}>
        <span className={style.sectionTitle}>{"SUBSCRIPTION"}</span>
        <SubscriptionPlanStatusComponent />

      </div>
      <HorizontalDivisorComponent />
      <div className={style.specificSetting}>
        <span className={style.sectionTitle}>{"PAYMENT"}</span>
        {isLoading ? <Spin spinning={true} /> : <PaymentInfoComponent />}
      </div>
    </article>
  );
};

SubscriptionSettingsContainer.propTypes = {
  getPaymentAuthDetails: PropTypes.func.isRequired,
  subscriptionId: PropTypes.string,
  lastFourPaymentCardDigits: PropTypes.string,
  creditCardVendorNameForSummary: PropTypes.string,
  pricingPlanId: PropTypes.string,
  noCardDetailsAvailable: PropTypes.bool,
  summaryShouldKnowOfCardUpdate: PropTypes.bool,
  actionSetShouldSummaryKnowOfCardUpdate: PropTypes.func.isRequired,
  actionSetUpdateMessage: PropTypes.func.isRequired,
  setNondestructiveErrorMessage: PropTypes.func.isRequired,
  payPalEmail: PropTypes.string,
  setConfirmedPaymentMethodForEdition: PropTypes.func.isRequired,
};

export default SubscriptionSettingsContainer;
