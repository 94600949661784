// React
import React from 'react';
import PropTypes from 'prop-types';

// Third Party Modules
import { Tooltip, Icon } from 'antd';

// StyleSheet
import style from './index.module.less';

const InfoButtonComponent = ({ content, placement, cssClass }) => {
    InfoButtonComponent.propTypes = {
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]).isRequired,
        placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom']).isRequired,
        cssClass: PropTypes.string
    }
    
    InfoButtonComponent.defaultProps = {
        cssClass: 'InfoButton'
    }

    return (
        <div role="button" className={style[cssClass]}>
            <Tooltip
                placement={placement} 
                trigger="click"
                title={content}
                >

                <Icon
                    type="question-circle"
                    theme="outlined"
                    className={style[`${cssClass}Inner`]} />

            </Tooltip>
        </div>
    )
}

export default InfoButtonComponent;