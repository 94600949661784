// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { fetchInvoiceHistory, getInvoiceData } from '../../ducks/payment';

// Selectors
import { invoiceIsLoadingSelector, subscriptionIdSelector, invoiceOwnerSelector, latestInvoiceNameSelector } from '../../utils/reduxSelectors';

import AccountInvoiceComponent from './AccountInvoiceComponent';

const mapStateToProps = state => ({
    isLoading: invoiceIsLoadingSelector(state),
    subscriptionId: subscriptionIdSelector(state),
    invoiceOwner: invoiceOwnerSelector(state),
    latestInvoiceName: latestInvoiceNameSelector(state)
});

const mapDispatchToProps = {
    fetchInvoiceHistory,
    getInvoiceData
};

const AccountInvoice = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInvoiceComponent));

export default AccountInvoice;