import React from 'react';
import { acceptedPaymentMethods } from '../../../styles/constants';
import style from './index.module.less';
import { PropTypes } from 'prop-types';
import VendorLogoComponent from '../../VendorLogoComponent';


const PayPalAcceptedMethodsComponent = ({payPalEmail, LinkButton, settingInnerContainerStyle}) => (

    <div className={settingInnerContainerStyle}>
    <div className={style.cardDetailsContainer}>
      <p className={style.payPalEmail} >{payPalEmail}</p>
      <VendorLogoComponent vendorName={acceptedPaymentMethods.payPal}/>
    </div>
      {LinkButton}
  </div>
  );

PayPalAcceptedMethodsComponent.propTypes = {
    payPalEmail: PropTypes.string,
    LinkButton: PropTypes.node,
    settingInnerContainerStyle: PropTypes.string,
};

export default PayPalAcceptedMethodsComponent;