import { makeStyles } from "@material-ui/core/styles";
import { colorPalette } from "../../../styles/constants";

export const materialStyleGenerator = () => makeStyles({
    popover: {
        "& .MuiPaper-elevation8": {
            boxShadow: `0 3px 8px 0 rgba(${colorPalette.primaryBrownRGB}, 0.25)`,
            margin: "0 10px",
            borderRadius: "8px",
            color: `${colorPalette.primaryBrown}`,
            maxWidth: "400px",
            fontSize: "12px",
        }
    },
})