
import { setError } from './../ducks/app';
export const HeraldbeeApi = ({ baseUrl }) => {
  HeraldbeeApi.ERROR_UNAUTHORISED = 'UnauthorisedError'


  /** Private methods */

  /**
   * Return message from response depending on what type it is.
   * It prevents from error throws when trying to read not proper message type.
   */
  const getResponseMessage = async (response) => {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return await response.json()
    } else {
      return await response.text()
    }
  }

  /**
   * Wrapper of fetch function,
   * returns processed response message as envelope with value and error
   */
  const fetchFromApi = async (path, httpMethod, jsonBody, customRequest) => {
    try {
      const request = customRequest ? customRequest : {
        method: httpMethod,
        headers: {
          'Accept': 'application/json, text/plain, text/html, */*',
          'Content-Type': 'application/json'
        },
        body: jsonBody && JSON.stringify(jsonBody),
        mode: 'cors',
        credentials: 'include'
      };

      const response = await fetch(`${baseUrl}${path}`, request);
      if (response.ok) {
        const responseMsg = await getResponseMessage(response);
        return {
          value: responseMsg,
          error: null,
        }
        /**
         * TODO: need to be considered whether HeraldbeeApi should handle 401, 403 errors at all 
         */
      } else if ([401, 403].includes(response.status)) {
        const responseMsg = await getResponseMessage(response)
        const error = new Error(responseMsg)
        error.name = HeraldbeeApi.ERROR_UNAUTHORISED
        throw error
      } else {
        const responseMsg = await getResponseMessage(response)
        if (responseMsg && responseMsg.error) {
          return {
            value: null,
            error: responseMsg.error
          }
        } else {
          const error = new Error(responseMsg)
          throw error
        }
      }
    } catch (error) {
      return {
        value: null,
        error: error,
      }
    }
  };


  /** Exposed interface */

  const checkWooCommerceStoreNameValidity = async shopId => {
    return await fetchFromApi(`/woocommerce/shop-exists?malformedWooCommerceShopAddress=${encodeURIComponent(shopId)}`, 'GET');

  };

  const checkShopifyStoreNameValidity = async shopId => {
    return await fetchFromApi(`/shopify/shop-exists?malformedMyshopifyDomain=${encodeURIComponent(shopId)}`, 'GET');

  };

  const fetchWooCommerceToken = async shopId => {
    const {
      err, value
    } = await fetchFromApi(`/auth/woocommerce/callback?domain=${shopId}`, 'GET');
    return { err, value };
  };

  const fetchUser = async () => {
    return await fetchFromApi('/legacy/me', 'GET');
  };

  const setWooCommerceDataPackageToBackend = async ({ wooCommerceEmail, wooCommerceOwnerName, wooCommerceShopName, userDomain }) => {
    return await fetchFromApi(`/auth/woocommerce/saveData?domain=${encodeURIComponent(userDomain)}&email=${encodeURIComponent(wooCommerceEmail)}&shopName=${wooCommerceShopName}&ownerName=${wooCommerceOwnerName}`, `POST`);
  };

  const patchWooCommerceData = async (fieldToUpdate, updateValue) => {
    return await fetchFromApi(`/auth/woocommerce/saveData?fieldToUpdate=${fieldToUpdate}&value=${updateValue}`, 'PATCH');
  };

  const toggleCampaignStatus = async (apiRequest, campaign) => {
    return await fetchFromApi(`/legacy/google/campaigns/${campaign.id}/${apiRequest}`, 'POST');
  }

  const fetchGoogleAccountAds = async () => {
    return await fetchFromApi('/user/google/gaa', 'GET');
  };

  const fetchGoogleAccountMc = async () => {
    return await fetchFromApi('/user/google/gmca', 'GET');
  };

  const fetchSubscription = async (shopId) => {
    return await fetchFromApi(`/subscription/subscription?shopId=${encodeURIComponent(shopId)}`, 'GET')
  }

  const activateSubscription = async (shopId, planId, clientNonce, customerEmail, paymentMethod) => {
    const params = {
      nonce: clientNonce
    }
    return await fetchFromApi(`/subscription/activateSubscription?shopId=${shopId}&planId=${planId}&customerEmail=${encodeURIComponent(customerEmail)}&paymentMethod=${paymentMethod}`, 'POST', params);
  };

  const changeSubscriptionPlan = async (subscriptionId, planId) => {
    return await fetchFromApi(`/subscription/changePlan?subscriptionId=${subscriptionId}&planId=${planId}`, `POST`);
  };

  const fetchCampaigns = async () => {
    return await fetchFromApi('/legacy/google/campaigns', 'GET');
  }

  const fetchCampaignProducts = async (nextPageToken) => {
    return await fetchFromApi(`/legacy/google/mc/products?maxResults=240${nextPageToken ? '&nextPageToken=' + nextPageToken : ''}`, 'GET');
  }

  const createCampaign = async (campaign) => {
    return await fetchFromApi(`/legacy/google/campaigns`, 'POST', campaign);
  };

  const addNewCampaign = async (campaign) => {
    return await fetchFromApi(`/legacy/google/campaigns/addNew`, 'POST', campaign);
  };

  const patchCampaign = async campaign => {
    return await fetchFromApi(`/legacy/google/campaigns`, 'PATCH', campaign);
  }

  const createGoogleAccountAds = async () => {
    return await fetchFromApi(`/user/google/gaa`, 'POST');
  };

  const createGoogleAccountMc = async () => {
    return await fetchFromApi(`/user/google/gmca`, 'POST')
  };

  const verifyDomain = async (platform, domain) => {
    return await fetchFromApi(`/auth/shop-exists?platform=${platform}&domain=${domain}`, 'GET');
  };

  const checkIsUserFinishedOnboarding = async platform => {
    const adjustedPlatform = platform === 'woocommerce' ? 'wooCommerce' : 'shopify';
    return await fetchFromApi(`/user/${adjustedPlatform}/isOnboardingCompleted`, 'GET');
  };

  const setIsUserFinishedOnboarding = async platform => {
    const adjustedPlatform = platform === 'woocommerce' ? 'wooCommerce' : 'shopify';
    return await fetchFromApi(`/user/${adjustedPlatform}/isOnboardingCompleted?hasFinishedOnboarding=true`, 'PUT');
  };

  const fetchGoogleRequirementMemosState = async () => {
    return await fetchFromApi(`/user/memos/googleRequirements`, 'GET');
  }

  const setGoogleRequirementMemosState = async (memos) => {
    return await fetchFromApi(`/user/memos/googleRequirements`, 'POST', { memos: memos });
  }

  const logoutFromServer = async () => {
    return await fetchFromApi('/user/logout', 'GET');
  };

  const uploadUserAvatar = async avatar => {
    const customRequest = {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, text/html, */*',
      },
      body: avatar,
      mode: 'cors',
      credentials: 'include'
    };
    return await fetchFromApi('/user/avatar', null, null, customRequest);
  };

  const getPaymentCardDetailsHbApi = async ({ subscriptionId }) => {
    return await fetchFromApi(`/subscription/getPaymentCardDetails?subscriptionId=${subscriptionId}`, 'GET');
  }
  const fetchCampaignHistory = async (campaignId, dateFrom, dateTo) => {
    return await fetchFromApi(`/campaign/${campaignId}/stats?dateFrom=${dateFrom}&dateTo=${dateTo}`, 'GET')
  }

  const getConversionsStatus = async () => {
    return await fetchFromApi('/user/shopify/didUserActivateConversions', 'GET');
  };

  const setConversionsStatus = async value => {
    return await fetchFromApi(`/user/shopify/didUserActivateConversions?value=${value}`, 'PUT');
  };

  const changePaymentAuth = async ({ subscriptionId, clientNonce, paymentMethod }) => {
    return await fetchFromApi(`/subscription/changePaymentAuth?updatedClientNonce=${clientNonce}&subscriptionId=${subscriptionId}&paymentMethod=${paymentMethod}`, 'POST');
  };

  const getConversionsScript = async () => {
    return await fetchFromApi('/googleAdsConversionTracking/shopify/snippet', 'GET');
  }

  const fetchBraintreeToken = async () => {
    return await fetchFromApi('/payment/token');
  }

  const fetchInvoiceData = async () => {
    return await fetchFromApi('/payment/billing', 'GET');
  };

  const updateInvoiceData = async updateObject => {
    return await fetchFromApi('/payment/billing', 'POST', updateObject);
  };

  const fetchInvoiceHistory = async () => {
    return await fetchFromApi('/payment/invoiceHistory', 'GET');
  };

  const fetchInvoicePDF = async invoiceId => {
    return await fetchFromApi(`/payment/invoiceDetails?invoiceId=${invoiceId}`, 'GET');
  }
  
  const getSubscriptionValueEstimate = async ({ planId }) => {
    return await fetchFromApi(`/subscription/getSubscriptionValueEstimate?planId=${planId}`, 'POST');
  };

  const initializeFacebookAuthProcess = async ({ code, context }) => {
    return await fetchFromApi(`/facebook/callback?code=${code}&context=${context}`, 'POST');
  };

  const createCampaignFacebook = async ({ campaign }) => {
    return await fetchFromApi(`/facebook/campaigns`, 'POST', campaign);
  };

  const getAdAccountList = async () => {
    return await fetchFromApi(`/facebook/adAccounts`, 'GET');
  };

  const askForAdAccountAgencyAccess = async ({ chosenAdAccount }) => {
    return await fetchFromApi(`/facebook/askAdAccountAgencyAccess?chosenAdAccount=${chosenAdAccount}`, 'POST');
  };

  const findOutIfUserHasGrantedAgencyAccess = async () => {
    return await fetchFromApi(`/facebook/wasAdAccountAgencyAccessGranted`, 'GET');
  };

  return {
    toggleCampaignStatus,
    fetchSubscription,
    activateSubscription,
    changeSubscriptionPlan,
    fetchCampaigns,
    fetchCampaignProducts,
    createCampaign,
    addNewCampaign,
    patchCampaign,
    createGoogleAccountAds,
    createGoogleAccountMc,
    fetchUser,
    fetchGoogleAccountAds,
    fetchGoogleAccountMc,
    fetchWooCommerceToken,
    checkWooCommerceStoreNameValidity,
    checkShopifyStoreNameValidity,
    setWooCommerceDataPackageToBackend,
    patchWooCommerceData,
    verifyDomain,
    checkIsUserFinishedOnboarding,
    setIsUserFinishedOnboarding,
    logoutFromServer,
    uploadUserAvatar,
    getPaymentCardDetailsHbApi,
    changePaymentAuth,
    fetchGoogleRequirementMemosState,
    setGoogleRequirementMemosState,
    fetchCampaignHistory,
    fetchBraintreeToken,
    fetchInvoiceData,
    fetchInvoiceHistory,
    fetchInvoicePDF,
    updateInvoiceData,
    getConversionsStatus,
    setConversionsStatus,
    getConversionsScript,
    getSubscriptionValueEstimate,
    initializeFacebookAuthProcess,
    createCampaignFacebook,
    getAdAccountList,
    askForAdAccountAgencyAccess,
    findOutIfUserHasGrantedAgencyAccess
  }
};



