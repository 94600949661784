// React
import React from 'react'
import PropTypes from 'prop-types'

// Custom Modules
import PricingPlanComponent from '../PricingPlanComponent';

// Stylesheet
import style from './index.module.less';

const PricingComponent = ({ plans, onSelectPlan }) => {
  return (
    <div className={style.pricing}>
      {plans.map((plan, key) =>
        <PricingPlanComponent
          key={key} 
          {...plan} 
          onSelect={() => onSelectPlan(plan.id)} />
      )}
    </div>
  )
}

PricingComponent.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape(PricingPlanComponent.propTypes)
  ).isRequired,
  onSelectPlan: PropTypes.func.isRequired
}

export default PricingComponent

