import React from 'react';
import SetCardAuth from '../../../pages/OnboardingCreateCampaign/Google/SetCardAuth/index';
import { PropTypes } from 'prop-types';
import PaymentMethodSelection from './../../PaymentMethodSelection/index';
import PayPal from './../../PayPal/index';




const OnboardingPaymentMethodSelectionContainer = ({ history, selection }) => {
    OnboardingPaymentMethodSelectionContainer.propTypes = {
        history: PropTypes.object.isRequired,
    }
    const onBackClick = () => {
        const url = selection === 'google' ? '/onboarding/google/createCampaign/chooseBudget' : '/onboarding/facebook/createCampaign/chooseCountry';
        history.push(url);
    };

    const PayPalAuthComponent = PayPal;

    const CardAuthComponent = SetCardAuth;


    return <PaymentMethodSelection onboardingMode="true" PaymentMethodSelectionConfigObject={{ onBackClick, PayPalAuthComponent, CardAuthComponent }} />
}

export default OnboardingPaymentMethodSelectionContainer;

