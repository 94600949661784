import React from 'react';
import { Layout, Spin, Alert, Button } from 'antd';
import * as PropTypes from 'prop-types';
import './index.less';
import config from '../../config';
import classNames from 'classnames';
const { Content } = Layout;

/**
 * @deprecated Please do not use this component anymore!!! It must be deleted.
 */
class PageWrapperBig extends React.Component {
  static defaultProps = {
    error: null,
    loader: false,
    errorButtonText: 'Reload',
    responsive: true,
    whiteBackground: false,
  }
  static propTypes = {
    children: PropTypes.node,
    error: PropTypes.object,
    loader: PropTypes.bool,
    errorButtonText: PropTypes.string,
    onErrorButtonClick: PropTypes.func,
    responsive: PropTypes.bool,
    whiteBackground: PropTypes.bool,
  };

  render() {
    const {
      error,
      loader,
      errorButtonText,
      onErrorButtonClick,
      children,
      responsive,
      whiteBackground,
    } = this.props;

    const contentClassName = classNames(
      'contentWrapperBig',
      {
        'contentWrapperBigResponsive': responsive,
        'whiteBackground': whiteBackground
      }
    );

    return (
      <>
        <Content className={contentClassName}>
          {error ? (
            <div className='errorView'>
              <div>
                <Alert showIcon
                  message="Error"
                  description={error.message}
                  type="error"
                />
                <Button
                  size='large'
                  style={{ display: 'block', margin: '20px auto' }}
                  onClick={onErrorButtonClick}>
                  {errorButtonText}
                </Button>
              </div>
            </div>
          ) : loader ? (
            <Spin style={{
              height: '100vh',
              marginTop: '-94px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }} size='large' />
          ) : (
                  children
                )}
          <span className='appVersion'>v. {config && config.VERSION}</span>
          {/* Defensive approach here enables test runner to avoid errors. */}
        </Content>
      </>
    )
  }
}

export default PageWrapperBig;