import config from '../config';
import { getAuthHeaders } from '../utils/auth';
import { processResponseJson } from './auth';

// Actions

const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
const ERROR_NOTIFICATIONS = 'ERROR_NOTIFICATIONS';

// Reducer

const initialState = {
  fetch: true,
  loader: true,
  content: null,
  // [
  //   { text: 'Data from redux store...', description: 'Description first here', type: 'INFO' },
  //   { text: 'Another notification', description: 'Description second here', type: 'INFO' },
  //   { text: 'Old notification', description: 'Description older here' },
  //   { text: 'Quite old notification', description: 'Description quite older here', type: 'WARNING' },
  //   { text: 'Very old notification', description: 'Description very older here', type: 'WARNING' },
  //   { text: 'Error notification', description: 'Description older here', type: 'ERROR' },
  //   { text: 'Another notification', description: 'Description quite older here', type: 'ERROR' },
  //   { text: 'Other notification', type: 'ERROR' },
  //   { text: 'Other another notification', type: 'ERROR' }
  // ],
  error: null
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state, fetch: false, loader: true, error: null
      }

    case SET_NOTIFICATIONS:
      return {
        ...state, loader: false, content: action.content
      }

    case ERROR_NOTIFICATIONS:
      return {
        ...state, loader: false, error: action.error
      }

    default: return state;
  }
}
export default reducer;

// Action Creators

const actionFetchNotifications = () => ({ type: FETCH_NOTIFICATIONS });
const actionSetNotifications = content => ({ type: SET_NOTIFICATIONS, content });
const actionSetError = error => ({ type: ERROR_NOTIFICATIONS, error });

// Methods

export const fetchNotifications = () =>
  dispatch => {
    dispatch(actionFetchNotifications());
    return fetch(`${config.API_URL}/legacy/notifications`, {
      method: 'GET',
      headers: getAuthHeaders(),
    }).then(response => processResponseJson(response)(dispatch))
      .then(json => {
        dispatch(actionSetNotifications(json));
      }).catch(error => dispatch(actionSetError(error)));
  }