// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux'
// Methods
import { setOnboardingFacebookCreateCampaignField as setCampaignName } from '../../../../ducks/onboarding'

import ChooseNameContainer from '../../../../components/SharedCreateCampaign/ChooseNameContainer';

const mapStateToProps = state => ({
    campaignName: state.onboarding.facebook.createCampaign.name,
    selection: 'facebook'
});

const mapDispatchToProps = {
    setCampaignName
};

const ChooseName = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseNameContainer));

export default ChooseName;