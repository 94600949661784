import React from "react";

const MaterialAccountIconComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <g id="Group_211" data-name="Group 211" transform="translate(8728 18868)">
        <circle
          id="Ellipse_62"
          data-name="Ellipse 62"
          cx="17"
          cy="17"
          r="17"
          transform="translate(-8728 -18868)"
          data-circle="true"
        />
        <g id="outline-view_list-24px" transform="translate(-8723 -18863)">
          <path
            id="Path_1812"
            data-name="Path 1812"
            d="M0,0H24V24H0Z"
            fill="none"
            opacity="0.87"
          />
          <path
            id="Path_1813"
            data-name="Path 1813"
            d="M12,6a2,2,0,1,1-2,2,2.006,2.006,0,0,1,2-2m0,9c2.7,0,5.8,1.29,6,2v1H6v-.99C6.2,16.29,9.3,15,12,15M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"
            data-outline="true"
          />
        </g>
      </g>
    </svg>
  );
};

export default MaterialAccountIconComponent;
