// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { AdChannelSelectionTexts } from '../../../../utils/texts';

// Custom Components
import OnboardingContainer from '../../../../container/OnboardingContainer';
import HorizontalViewWithLogoComponent from '../../../../components/HorizontalViewWithLogoComponent';
import OnboardingPlatformSelectionSuccess from '../../../../components/OnboardingPlatformSelectionSuccess';

const FacebookVerificationSuccessComponent = ({ history, setFacebookVerificationSuccess }) => {

    const onClick = () => {
        setFacebookVerificationSuccess(true);
        history.push('/onboarding/facebook/adAccountSelection');
    };

    return (
        <OnboardingContainer displayMode="showHeaderMobile">
            <HorizontalViewWithLogoComponent>
                <OnboardingPlatformSelectionSuccess
                    title={AdChannelSelectionTexts.verificationSuccess.title}
                    subtitle={AdChannelSelectionTexts.verificationSuccess.subtitle}
                    description={AdChannelSelectionTexts.verificationSuccess.description}
                    onClick={onClick}
                    buttonText={AdChannelSelectionTexts.verificationSuccess.buttonText}
                />
            </ HorizontalViewWithLogoComponent>
        </OnboardingContainer>
    );
};

FacebookVerificationSuccessComponent.propTypes = {
    setFacebookVerificationSuccess: PropTypes.func.isRequired,
    getAdAccountList: PropTypes.func.isRequired
};

export default FacebookVerificationSuccessComponent; 
