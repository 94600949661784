// React
import React from 'react';
import FacebookAdAccountRequestSuccessful from '../../FacebookAdAccountRequestSuccessful';

const OnboardingFacebookAdAccountRequestSuccessful = () => {
    return (
        <FacebookAdAccountRequestSuccessful redirectionUrl='/onboarding/facebook/createCampaign/chooseName' />
    );
};

export default OnboardingFacebookAdAccountRequestSuccessful; 
