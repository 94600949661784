// React
import { withRouter } from 'react-router-dom';

import FacebookAdAccountSelectionContainer from './FacebookAdAccountSelectionContainer';
import { connect } from 'react-redux';
import { getAdAccountList, askForAdAccountAgencyAccess, resetWasAgencyAccessRequestSentSeuccessfully } from './../../ducks/facebook/index';
import { setSelectedAdChannel } from '../../ducks/onboarding';



const mapStateToProps = state => ({
    listedAdAccounts: state.facebook.listedAdAccounts,
    wasAgencyAccessRequestSentSuccessfully: state.facebook.wasAgencyAccessRequestSentSuccessfully
});

const mapDispatchToProps = {
    getAdAccountList,
    askForAdAccountAgencyAccess,
    resetWasAgencyAccessRequestSentSeuccessfully,
    setSelectedAdChannel
};

const FacebookAdAccountSelection = withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookAdAccountSelectionContainer));

export default FacebookAdAccountSelection;

