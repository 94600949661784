// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods

import FacebookAdAccountSelectionViewComponent from './FacebookAdAccountSelectionViewComponent';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};  

const FacebookAdAccountSelectionView = withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookAdAccountSelectionViewComponent));

export default FacebookAdAccountSelectionView;