import React, { useEffect, useState } from 'react';

//custom modules
import FaqPageItemComponent from './FaqPageItemComponent'
import CampaignSearchInputComponent from '../../components/Campaigns/CampaignSearchInputComponent'
import { amplitudeEvents } from '../../constants/amplitudeEvents';
import { sendAmplitudeEvent } from '../../utils/amplitude';
import { FaqTextList } from '../../utils/texts';

//third part modules
import clsx from "clsx";

//styles
import styles from './index.module.less';

const FaqPage = () => {

    const [searchText, setSeachText] = useState('')

    useEffect(()=>{
        sendAmplitudeEvent(amplitudeEvents.FAQ_OPEN_FAQ_VIEW);
    },[])

    const handleSearchInput = (event) => {
        setSeachText(event.target.value)
    }

    const handleFiltering = () => {
        const typedText = searchText.trim()
        if( typedText ==='') {
            return FaqTextList
        }else {
            return FaqTextList.filter( 
                faq => typedText.toLowerCase().split(' ').some(word => faq.header.toLowerCase().includes(word) && word !=='')
            )
        }
    }

    const filteredFaqs = handleFiltering()

    return ( 
        <div className={styles.container}>
            <header className={styles.containerHeader}>
                <div>
                    <p className={styles.headerText}><strong>FAQ - </strong>Frequently Asked Questions</p>
                    <p className={styles.secondaryText}>You have created your first campaign and you don’t know what to do next? Maybe you are facing some issues?<br/>Don’t panic! Stay calm and check out our FAQ.</p>
                </div>
                <div className={styles.containerHeaderSearch}>
                    <CampaignSearchInputComponent onChange={handleSearchInput} placeholder='SEARCH'/>
                </div>
            </header>
            <div className={styles.faqItemList}>
                { filteredFaqs.length > 0 ?
                    filteredFaqs.map( (el, index, arr) => 
                        <div className={styles.faqItem} key={index}>
                            <FaqPageItemComponent
                                headerText={el.header}
                                descriptionTextList={el.description}
                            />
                            {index!==arr.length-1 && <hr className={styles.line}/>}
                        </div>
                    )
                    :<div className={styles.noSearchResult}>
                        <p className={styles.message}>No records found</p>
                        <p className={styles.tryAgainText}>We couldn’t find any results for <strong>‚{searchText}’.</strong></p>
                        <p>Please try again.</p><br/><br/>
                        <p>Suggestions:</p>
                        <ul>
                            <li>Make sure all words are spelled correctly</li>
                            <li>Try different keywords</li>
                            <li>Try more general keywords</li>
                        </ul>
                        <p>Do you have more questions, <span className={clsx(styles.openIntercomButton, 'OPEN_INTERCOM')}>write to us.</span></p>
                    </div>
                }
            </div>
        </div>
    )
}

export default FaqPage
