import { makeStyles } from '@material-ui/core/styles';
import { colorPalette } from '../../../styles/constants';

export const useStyles = makeStyles({
    root: {
        color: `rgba(${colorPalette.primaryBrownRGB},0.5)`,

        '&$checked': {
            color: colorPalette.primaryYellow,
        }
    },
    checked: {}
});
