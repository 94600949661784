// React
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom'; 

// Custom Modules
import { isPlatformShopify } from '../../../utils/utils';

// Custom Components
import AppSpinner from '../../../components/AppSpinner';
import Campaigns from "../../../pages/Campaigns";
import MainCreateCampaignRouter from '../../MainCreateCampaign/MainCreateCampaignRouter';
import CampaignGoogle from '../../../pages/Campaigns/Campaign/CampaignGoogle';
import CampaignFacebook from '../../../pages/Campaigns/Campaign/CampaignFacebook';
import FaqPage from '../../../pages/FaqPage/index'
import GoogleRequirements from "../../../components/GoogleRequirements";
import MaterialFixedMainAppElements from '../../../container/MaterialFixedMainAppElements/index';
import AccountRouter from '../../AccountRouter/index';

const MainAppRouterContainer = ({ user, campaigns, subscription, platform, fetchUser, fetchCampaigns, fetchSubscription, setIsUserLoggedIn, checkIfConversionsActive }) => {
    // Local State
    const [ isLoading, setIsLoading ] = useState(true);

    // Hooks
    useEffect(() => {
        setIsUserLoggedIn();
        const contentLocation = isPlatformShopify(platform) ? 'myshopifyDomain' : 'domain';
        if (isPlatformShopify(platform)) {
            checkIfConversionsActive();
        }
        fetchUser();
        fetchCampaigns();
        fetchSubscription(user.content[platform][contentLocation]);
    }, []);


    useLayoutEffect(() => {
        const isDataFetched = (campaigns.content) && (subscription && subscription.content);
        if (isDataFetched) {
            setIsLoading(false);
        }
    }, [ campaigns, subscription ]);
    
    if (isLoading) {
        return (
            <AppSpinner />
        );
    } else {
        return (
            <MaterialFixedMainAppElements>
                <Switch>
                    <Route exact path="/" component={() => <Redirect to="/campaigns" />} />
                    <Route exact path="/campaigns" component={Campaigns} />
                    <Route path="/campaigns/addCampaign" component={MainCreateCampaignRouter} />
                    <Route path="/campaigns/google/:id" component={CampaignGoogle} />
                    <Route path="/campaigns/facebook/:id" component={CampaignFacebook} />
                    <Route path="/account" component={AccountRouter} />
                    <Route path="/faq" component={FaqPage} />
                    <Route path="/googleRequirements" component={GoogleRequirements} />
                </Switch>
            </MaterialFixedMainAppElements>
        );
    }
};

export default MainAppRouterContainer;