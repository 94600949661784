// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { imgSrcPicker } from '../../utils/onboarding';

// Stylesheet
import styles from './index.module.less';

const ButtonWithLogoComponent = ({ onClick, config, isActive, disabled }) => 
    <button 
        className={`${styles.button} ${isActive ? styles.buttonActive : null} ${disabled ? styles.buttonDisabled : styles.buttonEnabled}`}
        onClick={onClick}
        disabled={disabled}
    >
        <div className={styles.buttonLogo}>
            <img
                className={styles.buttonLogoImg}
                src={imgSrcPicker(config.type)}
                alt={config.name}
            />
        </div>
        <p className={styles.buttonText}>{`${config.text} `}<strong>{config.textBold}</strong></p>
    </button>

ButtonWithLogoComponent.propTypes = {
    onClick: PropTypes.func.isRequired,
    config: PropTypes.shape({
        type: PropTypes.oneOf(['heraldbee', 'google', 'facebook']),
        name: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        textBold: PropTypes.string.isRequired
    }),
    isActive: PropTypes.bool
};

export default ButtonWithLogoComponent;