import React, { useState } from "react";
import { PropTypes } from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";

// Custom Components
import Avatar from '../../../components/Avatar';

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MaterialAppMenuIconsListComponent from "../../../components/AppMenuIconListComponent/index";
import { menuMaterialIconsList } from "../../../utils/mainApp";
import clsx from "clsx";
import style from "./index.module.less";
import { materialStyleGenerator, breakpoint } from "./index.material";
import MaterialAppBarComponent from "../../../components/MaterialComponents/MaterialAppBarComponent";
import { Link } from "react-router-dom";
import appBarBee from "../../../images/campaignImages/appBarBee.png";
import MaterialDrawerComponent from "../../../components/MaterialComponents/MaterialDrawerComponent/index";
import MaterialDesktopMenuComponent from "../../../components/MaterialComponents/MaterialDesktopMenuComponent";
import MaterialMobileMenuComponent from "../../../components/MaterialComponents/MaterialMobileMenuComponent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
const MaterialFixedMainAppElementsContainer = ({
  history,
  children,
  logOut,
  shopName
}) => {

  const theme = useTheme();
  const cssBreakpoint = useMediaQuery(breakpoint, { noSsr: true });
  const [isDrawerOpen, setIsDrawerOpen] = useState(
    useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true })
  );
  const [
    desktopMaterialAnchorElement,
    setMaterialDesktopAnchorElement
  ] = useState(null);
  const [
    mobileMaterialAnchorElement,
    setMobileMaterialAnchorElement
  ] = useState(null);

  /* Boolean makes sense in here, and is used by default by Material's component boilerplate */
  const isMenuOpen = Boolean(desktopMaterialAnchorElement);
  const isMobileMenuOpen = Boolean(mobileMaterialAnchorElement);

  /** Local state event handlers */
  function handleDrawerOpen() {
    setIsDrawerOpen(true);
  }

  function handleDrawerClose() {
    setIsDrawerOpen(false);
  }
  function handleProfileMenuOpen(event) {
    setMaterialDesktopAnchorElement(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMaterialAnchorElement(null);
  }

  function handleMenuClose() {
    setMaterialDesktopAnchorElement(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMaterialAnchorElement(event.currentTarget);
  }

  /** materialStyle are generated through Material specific function makeStyles() invoked at './styleOverrides.js', which is necessary to override CSS specificity of Material classes */

  const materialStyle = materialStyleGenerator({
    drawerWidth: 88
  })();

  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";

  /* Passing the entire component down is necessary, in order to avoid informing MaterialFixedMainAppElementsComponent about 'history' object */

  const MaterialIconsGeneratorComponent = () => (
    <MaterialAppMenuIconsListComponent
      currentlyActivePath={history.location.pathname}
      iconsList={menuMaterialIconsList}
      iconContainerStyle={style.menuIconContainer}
      iconActiveStyle={style.activeSvgIcon}
      iconInactiveStyle={style.inactiveSvgIcon}
      handleDrawerClose={handleDrawerClose}
    />
  );

  const DrawerButtonComponent = () => (
    <IconButton
      color="primary"
      aria-label="Open drawer"
      onClick={handleDrawerOpen}
      className={clsx(
        materialStyle.menuButton,
        isDrawerOpen && materialStyle.hide,
        !isDrawerOpen && materialStyle.show
      )}
    >
      <MenuIcon />
    </IconButton>
  );

  const FixedLogoButtonComponent = () => (
    <Link to="/campaigns">
      <div className={style.beeLogoContainer}>
        <img
          className={style.fixedLogo}
          alt="Top bar Heraldbee logo"
          height="80px"
          src={appBarBee}
        />
      </div>
    </Link>
  );

  const appBarStyles = cssBreakpoint ? materialStyle.appBarTall : materialStyle.appBar;

  return (
    <div className={materialStyle.root}>
      <CssBaseline />
      <MaterialAppBarComponent
        Avatar={<Avatar />}
        DrawerButton={<DrawerButtonComponent />}
        materialStyle={materialStyle}
        handleProfileMenuOpen={handleProfileMenuOpen}
        style={style}
        handleMobileMenuOpen={handleMobileMenuOpen}
        mobileMenuId={mobileMenuId}
        menuId={menuId}
        userShopName={shopName}
        appBarMaterialStyle={appBarStyles}
      />
      <MaterialMobileMenuComponent
        mobileMaterialAnchorElement={mobileMaterialAnchorElement}
        mobileMenuId={mobileMenuId}
        isMobileMenuOpen={isMobileMenuOpen}
        handleMobileMenuClose={handleMobileMenuClose}
        logOut={logOut}
        logOutStyle={style.logOutButton}
      />
      <MaterialDesktopMenuComponent
        menuId={menuId}
        desktopMaterialAnchorElement={desktopMaterialAnchorElement}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
        logOut={logOut}
      />
      <MaterialDrawerComponent
        FixedLogoButton={<FixedLogoButtonComponent />}
        MaterialIconsGenerator={<MaterialIconsGeneratorComponent />}
        drawerMaterialStyle={materialStyle.drawer}
        drawerHeaderMaterialStyle={materialStyle.drawerHeader}
        drawerPaperMaterialStyle={materialStyle.drawerPaper}
        closeDrawerStyle={style.closeDrawerIcon}
        materialStyle={materialStyle}
        isDrawerOpen={isDrawerOpen}
        style={style}
        handleDrawerClose={handleDrawerClose}
      />
        <main
      className={clsx(materialStyle.content, {
        [materialStyle.contentShift]: isDrawerOpen
      })}
    >
      {children}
    </main>
    </div>
  );
};

MaterialFixedMainAppElementsContainer.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  shopName: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired
};

export default MaterialFixedMainAppElementsContainer;
