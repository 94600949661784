// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setPlatform, checkWooCommerceStoreNameValidity, checkShopifyStoreNameValidity } from '../../ducks/auth';
// Selectors
import { platformSelector } from '../../utils/reduxSelectors'

import LoginWithPlatformContainer from './LoginWithPlatformContainer/index';


const mapStateToProps = state => ({
  accessGranted: state.auth.accessGranted,
  platform: platformSelector(state),
});
const mapDispatchToProps = {
  setPlatform,
  checkWooCommerceStoreNameValidity,
  checkShopifyStoreNameValidity
}


const Login = withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginWithPlatformContainer));

export default Login;
