// React
import React from 'react'
import PropTypes from 'prop-types';

// Third Party Modules
import TablePagination from '@material-ui/core/TablePagination';

const MaterialProductListPaginationComponent = ({ currentPage, productsPerPage, totalProductsCount, onPageChangeClick }) => {
    return (
        <TablePagination
            rowsPerPageOptions={[]}
            component="nav"
            page={currentPage - 1}
            rowsPerPage={productsPerPage}
            count={totalProductsCount}
            onChangePage={onPageChangeClick}
        />
    )
}

MaterialProductListPaginationComponent.propTypes = {
    currentPage: PropTypes.number, 
    productsPerPage: PropTypes.number, 
    totalProductsCount: PropTypes.number, 
    onPageChangeClick: PropTypes.func,
}

export default MaterialProductListPaginationComponent;