// React
import React from 'react'
import PropTypes from 'prop-types'

// Stylesheet
import style from './index.module.less';

const PricingPlanHighlightComponent = ({ children }) => {
  return (
    <div className={style.container}>
      <span className={style.text}>
        {children}
      </span>
    </div>
  )
}

PricingPlanHighlightComponent.propTypes = {
  children: PropTypes.node,
}

export default PricingPlanHighlightComponent
