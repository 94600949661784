// React
import React from 'react'
import PropTypes from 'prop-types'

// Stylesheet
import style from './index.module.less';

const PricingPlanHeaderComponent = ({ name, value, description }) => {
  const getValueClassName = () => {
    return value.type === 'price' ? style.headerValuePrice : style.headerValueOther;
  }

  return (
    <header className={style.header}>
      <p className={style.headerName}>{name}</p>
      <p className={getValueClassName()}>{value.textRepresentation}</p>
      <p className={style.headerDescription}>{description}</p>
    </header>
  )
}

PricingPlanHeaderComponent.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    textRepresentation: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['price', 'other']).isRequired
  }).isRequired,
  description: PropTypes.string
}

export default PricingPlanHeaderComponent

