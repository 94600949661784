import AwaitTokenContainer from './AwaitTokenContainer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { saveWooCommerceEmail, saveWooCommerceUserInfo, logOut } from '../../ducks/auth';

const mapStateToProps = state => ({
	wooCommerceEmail: state.auth.wooCommerceEmail,
	wooCommerceOwnerName: state.auth.wooCommerceOwnerName,
	wooCommerceShopName: state.auth.wooCommerceShopName,
	networkError: state.app.error,
	planId: state.pricing.planId
});

const mapDispatchToProps = {
	saveWooCommerceEmail,
	saveWooCommerceUserInfo,
	logOut
}

const AwaitToken = withRouter(connect(mapStateToProps, mapDispatchToProps)(AwaitTokenContainer))

export default AwaitToken;