// React
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Third Party Modules
import { Spin } from 'antd';

// Assets
import localStorageItems from '../../../constants/localStorageItems';

// Stylesheet
import style from './styles.module.less';

// Custom Modules
import { removeFromLocalStorage, isPlatformShopify } from '../../../utils/utils';

// Custom Components
import OnboardingAgreementComponent from '../../MaterialComponents/OnboardingMaterialComponents/OnboardingAgreementComponent/index';
import MaterialInputComponent from '../../MaterialComponents/MaterialInputComponent/index';
import MaterialButtonComponent from '../../MaterialComponents/MaterialButtonComponent/index';

const LoginCardComponent = ({ setPlatform, history, initialLoginValue, platform, checkIfDomainIsInDatabase, hasPricingPlanBeenSelected, ...rest } = { initialLoginValue: '' }) => {

    // constants
    const {
        onClickNext,
        checkStoreNameValidity
    } = rest;

    // Local state
    const [inputStoreValue, setStoreInputValue] = useState();
    const [activateSpinner, setActivateSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [agreement, setAgreement] = useState(false);

    const textContent = {
        'woocommerce': {
            title: 'with WooCommerce address',
            inputPlaceholder: 'Your WooCommerce store address',
            loginInputErrorText: 'Please enter a valid web address',
            loginVerificationErrorText: 'Before you login, please Sign Up first'
        },
        'shopify': {
            title: 'with Shopify address',
            inputPlaceholder: 'Your store\'s domain',
            loginInputErrorText: 'Non-existent store. Please check the address and try again',
            loginVerificationErrorText: 'Before you login, please Sign Up first'
        }
    };
    /* Grab correct part of textContent object */
    const {
        [platform]: {
            loginInputErrorText,
            title,
            inputPlaceholder,
            loginVerificationErrorText
        }
    } = textContent;

    const onChangeStoreInput = e => {
        setStoreInputValue(e.target.value)
    }

    /** If the user wishes to change the already chosen platform,
    * clear the cached platform name from LocalStorage and redirect to
    * the first login view.
    */
    const handleBackButton= () => {
        removeFromLocalStorage(localStorageItems.PLATFORM);
        setPlatform(null);
        history.push('/login');
    }
    const shouldNextButtonBeDisabled = () => {
        return !inputStoreValue || !inputStoreValue.length;
    }

    const handleInputValidation = async () => {
        
        if(shouldNextButtonBeDisabled() ) return
        
        setActivateSpinner(true);
        const { value, errorText }  = await checkStoreNameValidity(inputStoreValue);

        if (!agreement && hasPricingPlanBeenSelected) {
            setErrorMessage('Please accept the Terms of Service and Privacy Policy agreement');
            setActivateSpinner(false);
            return;
        }

        if (value) {
            const { value: isDomainInDatabase, error } = await checkIfDomainIsInDatabase(platform, inputStoreValue);
            
            if (!error) {
                if (hasPricingPlanBeenSelected || isDomainInDatabase) {
                if (!isPlatformShopify(platform)) {
                    onClickNext({ store: inputStoreValue }, true);
                } else if (isPlatformShopify(platform)) {
                    onClickNext(inputStoreValue, true);
                }
            } else {
                setErrorMessage(errorText || loginVerificationErrorText);
                setActivateSpinner(false);
            }
            }
        } else {
            setErrorMessage(errorText || loginInputErrorText);
            setActivateSpinner(false);
        }
    };

    const handleAgreement = value => {
        if (errorMessage) {
            setErrorMessage(null);
        }
        setAgreement(value);
    }

    /** Extended the functionality to handle both platforms */
    const onSubmitForm = e => {
        e.preventDefault(); 
        handleInputValidation()
    };

    return (
        <Spin wrapperClassName={style.loginLoader} size="large" spinning={activateSpinner}>
            <div className={style.container}>
                <div className={style.loginCard}>
                    <header className={style.textContainer}>
                        <h2 className={style.signInText}><strong>{hasPricingPlanBeenSelected ? "Sign up" : "Log in"}</strong></h2>
                        <h3 className={style.textTitle}>{title}</h3>
                    </header>
                    <form onSubmit={onSubmitForm}>
                        <MaterialInputComponent
                            suffix={platform==="shopify" ? ".myshopify.com" : ""}
                            placeholder={inputPlaceholder}
                            label={inputPlaceholder}
                            size="large"
                            onChange={onChangeStoreInput}
                            caption={errorMessage}
                            error={errorMessage ? true : false}
                            value={inputStoreValue}
                        />
                        {
                            hasPricingPlanBeenSelected 
                                ? (
                                    <OnboardingAgreementComponent
                                        rodoError={false}
                                        status={agreement}
                                        onChange={handleAgreement}
                                    />
                                ) : null
                        }
                        <div className={`${style.loginButtons} ${hasPricingPlanBeenSelected ?  style.loginButtonsSecondary : style.loginButtonsPrimary}`}>
                            <MaterialButtonComponent
                                    size="xSmall"
                                    text="Back"
                                    type={'secondary'}
                                    onClick={handleBackButton}
                            />
                            <MaterialButtonComponent
                                size="large"
                                text={hasPricingPlanBeenSelected ? "Sign up" : "Log in"}
                                type="primary"
                                disabled={shouldNextButtonBeDisabled()}
                                onClick={onSubmitForm}
                            />
                        </div>
                        {
                            !hasPricingPlanBeenSelected 
                                ? (
                                    <p className={style.policyText}>Are you new to Heraldbee? <br/> <Link to="/pricing"><strong>Click here to Sign Up!</strong></Link></p>
                                ) : null
                        }
                    </form>
                </div>
            </div>
        </Spin>
    )
}

LoginCardComponent.propTypes = {
    initialLoginValue: PropTypes.string,
    checkStoreNameValidity: PropTypes.func,
    onClickNext: PropTypes.func,
    platform: PropTypes.oneOf(['woocommerce', 'shopify']).isRequired,
    setPlatform: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    checkIfDomainIsInDatabase: PropTypes.func.isRequired,
    hasPricingPlanBeenSelected: PropTypes.string,
}

export default LoginCardComponent;