// Redux
import { connect } from 'react-redux';
// Methods
import { clearInvoiceDataError } from '../../ducks/payment';
// Selectors
import { isInvoiceDataErrorPresent } from '../../utils/reduxSelectors';

import InvoiceFormContainer from './InvoiceFormContainer';

const mapStateToProps = state => ({
    content: state.payment.invoiceData.content,
    isInvoiceDataErrorPresent: isInvoiceDataErrorPresent(state)
});

const mapDispatchToProps = {
    clearInvoiceDataError
};

const InvoiceForm = connect(mapStateToProps, mapDispatchToProps)(InvoiceFormContainer);

export default InvoiceForm;