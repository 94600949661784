// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Spin } from 'antd';
import RcQueueAnim from 'rc-queue-anim';

// Custom Modules
import config from '../../config';

// Custom Components
import ButtonWithLogoComponent from '../../components/ButtonWithLogoComponent';
import MaterialButtonComponent from '../../components/MaterialComponents/MaterialButtonComponent';

// Stylesheet
import styles from './index.module.less';

const OnboardingAdChannelSelectorComponent = ({ setSelectedAdChannel, setIsChannelSelectionLoading, isChannelSelectionLoading }) => {
    
    const [ selection, setSelection ] = useState(null);

    const onClickNext = () => {
        switch(selection) {
            case 'google':
                setSelectedAdChannel(selection);
                break;
            case 'facebook':
                setIsChannelSelectionLoading(true);
                window.location.href = `${config.API_URL}/facebook?context=onboarding`;
                break;
            default:
                return null;
        }
    };

    return (
        <section className={styles.container}>
            <RcQueueAnim type="bottom">
                <Spin spinning={isChannelSelectionLoading}>
                    <div className={styles.card} key="1">
                        <header className={styles.header}>
                            <h1 className={styles.headerTitle}>Choose the first <strong className={styles.headerTitleBold}>Advertising Channel</strong></h1>
                            <h3 className={styles.headerSubtitle}>on which we will start your campaign</h3>
                        </header>
                        <main className={styles.content}>
                            <div className={styles.contentButtons}>
                                <ButtonWithLogoComponent 
                                    onClick={() => setSelection('google')}
                                    config={{
                                        type: 'google',
                                        name: 'G Logo',
                                        text: 'Advertise on',
                                        textBold: 'Google'
                                    }}
                                    isActive={selection === 'google'}
                                />
                                <ButtonWithLogoComponent 
                                    onClick={() => setSelection('facebook')}
                                    config={{
                                        type: 'facebook',
                                        name: 'FB Logo',
                                        text: 'Advertise on',
                                        textBold: 'Facebook'
                                    }}
                                    isActive={selection === 'facebook'}
                                />
                            </div>
                            <div className={styles.contentControls}>
                                <MaterialButtonComponent
                                    text='Next'
                                    size="xLarge"
                                    type={'primary'}
                                    disabled={selection ? false : true}
                                    onClick={onClickNext}
                                />
                            </div>
                        </main>
                    </div>
                </Spin>
            </RcQueueAnim>
        </section>
    );
};

OnboardingAdChannelSelectorComponent.propTypes = {
    setSelectedAdChannel: PropTypes.func.isRequired,
    setIsChannelSelectionLoading: PropTypes.func.isRequired,
    isChannelSelectionLoading: PropTypes.bool
};

export default OnboardingAdChannelSelectorComponent;