// React
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import Icon, { Stack } from '@mdi/react';
import { mdiCircle, mdiCheck } from '@mdi/js';

// Stylesheet
import styles from './index.module.less';

const MaterialCheckIconComponent = ({cssClass}) => 
    <Stack size={1} className={styles[cssClass]}>
        <Icon path={mdiCircle}
            size={0.7}
            color="rgba(237, 191, 21, 0.2)"/>
        <Icon path={mdiCheck}
            color="rgba(237, 191, 21, 1)"/>
    </Stack>

MaterialCheckIconComponent.propTypes = {
    cssClass: PropTypes.string.isRequired
};

export default MaterialCheckIconComponent;