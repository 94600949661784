import React from 'react';
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import { nativeSelectStyles, baseSelectStyles } from './index.material'

const MaterialNativeSelectComponent = ({onChange, optionList}) => {

    const selectClasses = nativeSelectStyles();
    const baseClasses = baseSelectStyles();

    return (
        <Select
            native
            onChange={onChange}
            className={baseClasses.root}
            classes={selectClasses}
        >
        {optionList.map((text, index )=> <option key={index} value={text}>{text}</option>)}
        </Select>
    )

}

MaterialNativeSelectComponent.propTypes = {
    onChange: PropTypes.func,
    optionList: PropTypes.arrayOf(PropTypes.string)
}

export default MaterialNativeSelectComponent