// React
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Custom Modules
import { generateOnboardingMap, hasPreviousGoogleStepCompleted, hasPreviousFacebookStepCompleted } from "../../../../utils/onboarding";
import { readFromLocalStorage, setToLocalStorage } from "../../../../utils/utils";
import localStorageItems from "../../../../constants/localStorageItems";

// Custom Components


const SharedOnboardingRouterContainer = ({ history, selectedAdChannel, setSelectedAdChannel, clearOnboardingStateByChannel }) => {

    const adjustedLocalStorageItem = selectedAdChannel === 'google' ? localStorageItems.ONBOARDING_STEP_GOOGLE : localStorageItems.ONBOARDING_STEP_FACEBOOK;
    // local state
    const [currentStep, setCurrentStep] = useState(
        readFromLocalStorage(adjustedLocalStorageItem) || 0
    );

    useEffect(() => {
        return () => {
            // Save currentStep to LS on redirect outside of the app domain
            setToLocalStorage(adjustedLocalStorageItem, currentStep);
            // Clear selectedAdChannel
            clearOnboardingStateByChannel(selectedAdChannel);
            setSelectedAdChannel(null);
        };
    }, []);

    // componentDidUpdate HOOK - handle route rendering in case of user going back in onboarding chain to change info
    useLayoutEffect(() => {
        const stepRoutes = generateOnboardingMap(selectedAdChannel).map(step => step.appendedRoute);
        stepRoutes.forEach((route, i) => {
            if (history.location.pathname.includes(route) && currentStep !== i) {
                setCurrentStep(i);
            };
        });
    }, [history.location.pathname]);

    /**
   * Generate current available route map
   */
    const onboardingMap = generateOnboardingMap(selectedAdChannel)
        .filter(step =>  selectedAdChannel === 'google' ? hasPreviousGoogleStepCompleted(step.name) : hasPreviousFacebookStepCompleted(step.name)) // filter by state
        .filter((step, index) => index <= currentStep + 1); // filter by index to adjust for user going back in onboarding chain

    return (
        <Switch>
            {
                // Iterate over steps available to the user and render a path to each of those steps
                onboardingMap.map(({ appendedRoute, component: Component }, i) =>
                    <Route
                        exact
                        path={`/onboarding/${appendedRoute}`}
                        render={() => <Component />}
                        key={i}
                    />
                )
            }
            {/** If user refreshes the app or provides url outside of available scope, redirect him to start */}
            {/** This component doesn't redirect to most recent state due to redux state wipe on refresh which is forced when directly navigating to URL */}
            <Redirect
                from="/"
                to={`/onboarding/${onboardingMap[onboardingMap.length - 1].appendedRoute}`}
            />
        </Switch>
    );
};

SharedOnboardingRouterContainer.propTypes = {
    selectedAdChannel: PropTypes.oneOf(['google', 'facebook']).isRequired,
    setSelectedAdChannel: PropTypes.func.isRequired,
    clearOnboardingStateByChannel: PropTypes.func.isRequired
};

export default SharedOnboardingRouterContainer;
