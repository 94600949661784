// React
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheet
import style from './index.module.less';

const FlagIconComponent = ({ countryCode, className }) => {
    const getClassNames = () => {
        return `${style.flag} ${style[`flag-${countryCode.toLowerCase()}`]} ${className}`;
    }

    return (
        <span className={getClassNames()}></span>
    );
};

FlagIconComponent.propTypes = {
    countryCode: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default FlagIconComponent;