// React
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheet
import style from './index.module.less';

const CodeSnippetComponent = ({ scriptTag }) => {

    return (
        <pre className={style.codeSnippet}>
            <code className={style.code}>
                {scriptTag}
            </code>
        </pre>
    )
};

CodeSnippetComponent.propTypes = {
    children: PropTypes.string
}

export default CodeSnippetComponent;