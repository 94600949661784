// React
import React from "react";
import PropTypes from "prop-types";

// Third Party Components
import MenuItem from "@material-ui/core/MenuItem";

const SelectListItemComponent = ({
    item,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
}) => {
    const isHighlighted = highlightedIndex === index;
    const isSelected = ((selectedItem && selectedItem.value) || '').indexOf(item.value) > -1;

    return (
        <MenuItem
            {...itemProps}
            key={item.key}
            selected={isHighlighted}
            component="div"
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                fontWeight: isSelected ? 500 : 400
            }}
        >
            {item.prefix}
            {item.value}
        </MenuItem>
    );
}

SelectListItemComponent.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.object,
    item: PropTypes.shape({ 
        value: PropTypes.string, 
        key: PropTypes.any.isRequired,
        prefix: PropTypes.node,
    }).isRequired
};

export default SelectListItemComponent;