// Redux
import { connect } from 'react-redux';

// Methods
import { fetchCampaignProducts } from '../../ducks/campaignProducts';

import CampaignProductsContainer from './CampaignProductsContainer';

const mapStateToProps = (state) => ({
    products: state.campaignProducts.products,
    nextProductsPayloadToken: state.campaignProducts.nextPageToken,
    allProductsPayloadsFetched: state.campaignProducts.allPagesFetched,
});

const mapDispatchToProps = {
    fetchCampaignProducts,
};

const CampaignProducts = connect(mapStateToProps, mapDispatchToProps)(CampaignProductsContainer);

export default CampaignProducts;