import config from '../../config';
import { createCampaignParams } from './logic';
import { amplitudeEvents } from '../../constants/amplitudeEvents';
import { setError } from '../app';
import { sendAmplitudeEvent } from '../../utils/amplitude';
import { defineWhatHasBeenUpdated } from './logic';
import { HeraldbeeApi } from '../../api/HeraldbeeApi';

// Actions

const LOAD_CAMPAINGS = 'LOAD_CAMPAINGS';
const FORCE_FETCH_CAMPAINGS = 'FORCE_RELOAD_CAMPAINGS';
const FETCH_CAMPAINGS = 'FETCH_CAMPAINGS';
const SET_CAMPAINGS = 'SET_CAMPAINGS';
const ERROR_CAMPAINGS = 'ERROR_CAMPAINGS';

// Reducer

const initialState = {
  fetch: true,
  loader: true,
  content: null,
  error: null
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_CAMPAINGS:
      return {
        ...state, loader: true, error: null
      }

    case FORCE_FETCH_CAMPAINGS:
      return {
        ...state, fetch: true, loader: true
      }

    case FETCH_CAMPAINGS:
      return {
        ...state, fetch: false, loader: true, error: null
      }

    case SET_CAMPAINGS:
      return {
        ...state, loader: false, content: action.content
      }

    case ERROR_CAMPAINGS:
      return {
        ...state, loader: false, error: action.error
      }

    default: return state;
  }
}
export default reducer;

// Action Creators

const actionLoadCampaigns = () => ({ type: LOAD_CAMPAINGS });
const actionForceFetchCampaigns = () => ({ type: FORCE_FETCH_CAMPAINGS });
const actionFetchCampaigns = () => ({ type: FETCH_CAMPAINGS });
const actionSetCampaigns = content => ({ type: SET_CAMPAINGS, content });
const actionSetError = error => ({ type: ERROR_CAMPAINGS, error });
const actionBlank = () => ({ type: null });

// Methods

export const forceFetchCampaigns = () => dispatch => dispatch(actionForceFetchCampaigns());

export const fetchCampaigns = () => async dispatch => {
    dispatch(actionFetchCampaigns());

    const hbApi = HeraldbeeApi({
      baseUrl: config.API_URL,
    });

    const { value, error } = await hbApi.fetchCampaigns();
    
    if (error) {
        dispatch(actionSetError(error));
        return setError(error)(dispatch);

    };
    dispatch(actionSetCampaigns(value));
  }

/**
 * Create campaign
 * 
 * @param {number} dailyBudget
 *   Daily campaign budget
 * @param {googleAdsLocations.value} salesCountryCode
 *   Sales country
 * @param {[googleAdsLocations.value]} targetCountryCodes
 *   Target countries, if is null, then all countries are taken
 */
export const createCampaign = (dailyBudget, salesCountryCode, targetCountryCodes) => async dispatch => {
  dispatch(actionLoadCampaigns());

  const campaign = createCampaignParams(dailyBudget, salesCountryCode, targetCountryCodes)

  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { value, error } = await hbApi.createCampaign(campaign);

  if (error) {
    setError(error)(dispatch);
    return;
  }

  sendAmplitudeEvent(amplitudeEvents.CAMPAIGNS_CREATE_CAMPAIGN, {
    status: 'Success',
    data: value
  });
  fetchCampaigns()(dispatch);
}

export const toggleCampaignStatus = (request, campaign) => async dispatch => {

  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { error } = await hbApi.toggleCampaignStatus(request, campaign);
  const command = request === 'start' ? 'ENABLED' : 'PAUSED';

  let eventProps = {
    status: command,
    data: campaign
  };

  if (error) {
    eventProps = {
      status: null,
      data: error
    };
    sendAmplitudeEvent(amplitudeEvents.CAMPAIGNS_TOGGLE_STATUS_CAMPAIGN, eventProps);
    setError(error)(dispatch);
    return;
  }

  sendAmplitudeEvent(amplitudeEvents.CAMPAIGNS_TOGGLE_STATUS_CAMPAIGN, eventProps);
  fetchCampaigns()(dispatch)
};

export const patchCampaign = (campaign, patchObject) => async dispatch => {

  // Create updated campaign object
  const updatedCampaign = {
    ...campaign,
    dailyBudget: patchObject.newBudget || campaign.dailyBudget,
    name: patchObject.newName || campaign.name,
  };

  // Construct amplitude event description
  const { amplitudeError, amplitudeValue }= defineWhatHasBeenUpdated(patchObject);

  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { error } = await hbApi.patchCampaign(updatedCampaign);

  let eventProps;

  if (error) {
    eventProps = {
      status: null,
      data: error
    };
    dispatch(setError(error));
  } else if (amplitudeError) {
    eventProps = {
      status: null,
      data: amplitudeError
    };
    dispatch(setError(amplitudeError));
  } else {
    eventProps = {
      status: `update campaign ${amplitudeValue}`,
      data: updatedCampaign
    };
    dispatch(fetchCampaigns());
  }
  sendAmplitudeEvent(amplitudeEvents.CAMPAIGNS_EDIT_CAMPAIGN, eventProps);
};

export const createCampaignFacebook = ({ campaignName, countryCode }) => async dispatch => {
    const campaign = {
        campaignName,
        countryCode
    };

    const { createCampaignFacebook } = HeraldbeeApi({
        baseUrl: config.API_URL
    });
    const { value, error } = await createCampaignFacebook({ campaign });

    if (error) {
        return dispatch(setError(error));

    }

    if (value) {
        return dispatch(actionBlank());
    }
};