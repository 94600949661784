import { makeStyles } from '@material-ui/core/styles'
import { colorPalette, typography } from '../../../../../styles/constants' 

export const useTableHeadStyles = makeStyles(({
    root: {
        backgroundColor : colorPalette.secondaryGray,
        height: 48,
        
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}))

export const useTableSortLabelStyles = makeStyles(({  
        root: {
            fontSize:typography.paragraphMd,
            color: colorPalette.primaryBrown,
            opacity: 0.5,
            fontWeight: 500,
            lineHeight: 2.33,
            '&:hover': {
                color: colorPalette.primaryBrown,
            }          
        },
}))