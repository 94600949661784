// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Custom Components
import ButtonWithLogoComponent from '../../..';
import InfoButtonComponent from '../../../../InfoButtonComponent';

// Stylesheet
import styles from './index.module.less';

const ButtonWithLogoAddCampaignComponent = ({ isUserConnectedWithFacebook, ...rest }) => {
    return (
        <div className={styles.container}>
            <ButtonWithLogoComponent
                disabled={!isUserConnectedWithFacebook}
                {...rest}
            />
            {/** TODO: replace with more precise link after FB integration is implemented */}
            <InfoButtonComponent
                content={(
                    <>
                        <p>To create a campaign targeted for Facebook you need to integrate your Heraldbee account with your Facebook Account first in Account settings</p>
                        <Link to="/account">Integrate with Facebook</Link>
                    </>
                )}
                placement="top"
                cssClass={isUserConnectedWithFacebook ? 'hidden' : 'AddCampaign'}
            />
        </div>
    );
};

ButtonWithLogoAddCampaignComponent.propTypes = {
    isUserConnectedWithFacebook: PropTypes.bool.isRequired
};

export default ButtonWithLogoAddCampaignComponent;