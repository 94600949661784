// Redux
import { connect } from 'react-redux';
// Methods
import { setPlatform, softLogOut, clearDidSoftLogOutOccur } from '../../ducks/auth';
// Selectors
import { platformSelector } from '../../utils/reduxSelectors'

import LoginChoosePlatformComponent from './LoginChoosePlatformComponent';



const mapStateToProps = state => ({
	platform: platformSelector(state),
	accessGranted: state.auth.accessGranted,
	didSoftLogOutOccur: state.auth.didSoftLogOutOccur
});

const mapDispatchToProps = {
	setPlatform,
	softLogOut,
	clearDidSoftLogOutOccur
};

const LoginChoosePlatform = connect(mapStateToProps, mapDispatchToProps)(LoginChoosePlatformComponent);

export default LoginChoosePlatform;
