import React from 'react';

//utils text 
import { modalText }from '../../../utils/texts';

//images
import shopifyModalImage from '../../../images/conversions/shopifyModalImage.png'

//styles
import style from './index.module.less';

const SecondStepComponent = () => {

    return (
        <div className={style.container}>
            <p className={style.headerText}><strong>{modalText.step2}</strong></p>
            <section className={style.content}>      
                <img className={ style.hintImage } src={shopifyModalImage} alt="shopify helper view"/>
            </section>
        </div>
    ) 
}

export default SecondStepComponent;