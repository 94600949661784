// React
import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
// Stylesheet
import style from './style.module.less';

// Custom Components
import ProductItemComponent from './ProductItemComponent';
import MaterialProductListPaginationComponent from './MaterialProductListPaginationComponent';
import NoResultViewComponent from '../NoResultViewComponent';

//utils text
import { productNoResultText } from '../../utils/texts';


const ProductListComponent = ({ products,campaignId, currentPage = 1, productsPerPage = 10, onPageChange }) => {
    const getProductsCount = () => {
        return products ? products.length : 0;
    }

    const getProductsOnPage = () => {
        const indexOfLastProductOnPage = currentPage * productsPerPage;
        const indexOfFirstProductOnPage = indexOfLastProductOnPage - productsPerPage;
        const currentProducts = products ? products.slice(indexOfFirstProductOnPage, indexOfLastProductOnPage) : [];

        return currentProducts;
    }

    const onPageChangeClick = (event, pageIndex) => {
        onPageChange(pageIndex + 1);
    }


    if (products.length === 0) {
        return (
            <NoResultViewComponent
                headerText={productNoResultText.headerTextAfterFilter}
                subText={productNoResultText.subTextAfterFilter}
            />
        )
    } else {
        return (
            <>
                <div className={style.container}>
                    {getProductsOnPage().map((product, i) =>
                        <Link key={i} to={`/campaigns/google/${campaignId}/products/${product.id}`}>
                            <ProductItemComponent {...product} />
                        </Link>
                    )}
                </div>
                <MaterialProductListPaginationComponent
                    currentPage={currentPage}
                    productsPerPage={productsPerPage}
                    totalProductsCount={getProductsCount()}
                    onPageChangeClick={onPageChangeClick}
                />
            </>
        )
    }
}

ProductListComponent.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape(ProductItemComponent.propTypes)
    ),
    currentPage: PropTypes.number,
    productsPerPage: PropTypes.number,
    onPageChange: PropTypes.func,
}

export default ProductListComponent;