import React from "react";
import style from "./index.module.less";
import { acceptedPaymentMethods } from "../../styles/constants";
import VendorLogoComponent from './../VendorLogoComponent/index';

const PayPalSuccessfulAuthorizationComponent = () => {
    return (
        <div>
            <hr className={style.contentBreak} />
            <p className={style.contentSectionParagraph}>Payment</p>
            <div className={style.NumberAndVendorRow}>
                <VendorLogoComponent vendorName={acceptedPaymentMethods.payPal} />
            </div>
            <hr className={style.contentBreak} />
            <p className={style.cardIsValidText}>Your PayPal account was successfully connected</p>
        </div>
    );
};

export default PayPalSuccessfulAuthorizationComponent;
