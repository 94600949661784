import { setSentryUser, sendSentryException, initSentry } from "./sentry";
import config from "../../config";

const withProdEnv = (func) => {
    if (!config.DEV) {
        func();
    }
}

export const ErrorTrackingModule = {

    init: () => withProdEnv(() => {
        initSentry(config.SENTRY_DSN, config.VERSION);
    }),

    setUser: (user, shop) => withProdEnv(() => {
        if (user) {
            const shopDetails = shop && {
                username: shop.name,
                email: shop.email,
            }
    
            setSentryUser({
                id: user.username,
                ...shopDetails
            })
        }
    }),

    reportError: (error, extraDetails) => withProdEnv(() => {    
        console.error(error);
        sendSentryException(error, extraDetails)
    })
    
}