import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import styles from './index.module.less';
import { useStylesIcon, breakpoint } from './index.material';

const MaterialProductStatusIconContainer = ({ status }) => {

    const classes = useStylesIcon();
    const cssBreakpoint = useMediaQuery(breakpoint);
    
    const icon = () => {
        switch(status) {
            case 'websiteClaimInProgress':
                return (
                    <>
                        <InfoOutlined className={`${cssBreakpoint ? classes.rootDesktop : classes.rootMobile} ${styles[status]}`} />
                        <p className={`${styles.iconContainerText} ${styles[status]}`}>WEBSITE CLAIM IN PROGRESS</p>
                    </>
                );
            case 'waitingForGoogle':
                return (
                    <>
                        <InfoOutlined className={`${cssBreakpoint ? classes.rootDesktop : classes.rootMobile} ${styles[status]}`} />
                        <p className={`${styles.iconContainerText} ${styles[status]}`}>WAITING FOR GOOGLE</p>
                    </>
                );
            case 'disapproved':
                return (
                    <>
                        <CancelOutlined className={`${cssBreakpoint ? classes.rootDesktop : classes.rootMobile} ${styles[status]}`} />
                        <p className={`${styles.iconContainerText} ${styles[status]}`}>DISAPPROVED</p>
                    </>
                );
            default:
                return (
                    <>
                        <CheckCircleOutline className={`${cssBreakpoint ? classes.rootDesktop : classes.rootMobile} ${styles[status]}`} />
                        <p className={`${styles.iconContainerText} ${styles[status]}`}>APPROVED</p>
                    </>
                );
        }
    }

    return (
        <div className={styles.iconContainer}>
            { icon() }  
        </div>
    )
}

MaterialProductStatusIconContainer.propTypes = {
    status: PropTypes.oneOf(['approved', 'disapproved', 'waitingForGoogle', 'websiteClaimInProgress']),
}

export default MaterialProductStatusIconContainer;