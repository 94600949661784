// React
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Spin } from 'antd';

// Custom Components
import MaterialBreadcrumbsComponent from '../../../../components/MaterialComponents/MaterialBreadcrumbsComponent';
import MaterialRadioButtonComponent from '../../../../components/MaterialComponents/MaterialRadioButtonComponent';
import MaterialButtonComponent from '../../../../components/MaterialComponents/MaterialButtonComponent';
import InvoiceForm from '../../../../container/InvoiceForm';

// Stylesheet
import styles from './index.module.less'

const AccountInvoiceDataContainer = ({ history, content, error, success, isUpdating, selectedForm, updateObject, saveInvoiceData, clearInvoiceDataError, clearInvoiceDataSuccess, clearInvoiceFormValues, updateInvoiceFormField, repopulateInvoiceFormValues, createInvoiceUpdateObject }) => {
    // constants
    const linkArray = [
        {
            textContent: 'Account',
            link: '/account'
        },
        {
            textContent: 'Invoice data',
            link: null
        }
    ];
    const billingAddress = content.billing_address
    const customerType = content.customer_type;
    const invoiceDataError = error;
    const invoiceDataSuccess = success;

    const isNotificationAnError = () => {
        if (invoiceDataError && !invoiceDataSuccess) {
            return true;
        } else if (invoiceDataSuccess && !invoiceDataError) {
            return false;
        }
    }
    const setSelectedForm = value => updateInvoiceFormField('selectedType', value);

    // local state
    const [ isFormValid, setIsFormValid ] = useState(true);

    const [ country, setCountry ] = useState(billingAddress.country)  || '';

    const stateUpdateControls = { 'country': { state: country, setState: setCountry } };

    // lifecycle
    useEffect(() => {
        if (success) {
            clearInvoiceDataSuccess();
        };
        if (error) {
            clearInvoiceDataError();
        };
    }, []);

    useLayoutEffect(() => {
        setCountry(billingAddress.country || '');
    }, [content]);

    useLayoutEffect(() => {
        if (error) {
            setIsFormValid(false);
        };
        setIsFormValid(true);
    }, [error]);

    useEffect(() => {
        if (success) {
            clearInvoiceDataSuccess();
        }
        if (error) {
            clearInvoiceDataError();
        }
        // Clear and repopulate all the fields depending on check if selected form is equal to previously saved form
        const { country } = billingAddress;
        const isFormTypeSame = selectedForm === customerType;
        setCountry(isFormTypeSame ? country : '');
        if (!isFormTypeSame) {
            clearInvoiceFormValues();
        } else {
            repopulateInvoiceFormValues(selectedForm);
        };
    }, [selectedForm]);

    useEffect(() => {
        if (updateObject) {
            saveInvoiceData(updateObject);
        };
    }, [ updateObject ]);

    // methods
    const onClickBack = () => {
        history.push('/account');
    };

    const onClickSave = () => {
        if (success) {
            clearInvoiceDataSuccess();
        };
        if (error) {
            clearInvoiceDataError();
        };
        createInvoiceUpdateObject(country);
    };

    return (
        <section className={styles.container}>
            <header className={styles.header}>
                <MaterialBreadcrumbsComponent className={styles.headerBreadcrumbs} breadcrumbLinkArray={linkArray}/>
                <h2 className={`${styles.headerTitle} h2-sm`}>Invoice data</h2>
            </header>
            <main className={styles.content}>
                <div className={styles.contentCard}>
                    <div className={styles.contentCardControls}>
                        <span className={styles.controller}>
                            <MaterialRadioButtonComponent 
                                isRadioButtonChecked={selectedForm === 'company'}
                                onChange={() => setSelectedForm('company')}
                                buttonValue="company"
                            />
                            <p className={styles.controllerText}>Company or organization</p>
                        </span>
                        <span className={styles.controller}>
                            <MaterialRadioButtonComponent
                                isRadioButtonChecked={selectedForm === 'private'}
                                onChange={() => setSelectedForm('private')}
                                buttonValue="private"
                            />
                            <p className={styles.controllerText}>Private person</p>
                        </span>
                        
                    </div>
                    <div className={styles.contentCardForm}>
                        <InvoiceForm
                            isFormValid={isFormValid}
                            selectedForm={selectedForm}
                            setIsFormValid={setIsFormValid}
                            stateUpdateControls={stateUpdateControls}
                        />
                    </div>
                </div>
                <div className={styles.contentError}>
                    <p className={isNotificationAnError() ? styles.contentErrorMessage : styles.contentSuccessMessage}>{invoiceDataSuccess || invoiceDataError}</p>
                </div>
                <div className={styles.contentButtons}>
                    <MaterialButtonComponent
                        type={"secondary"}
                        size={"small"}
                        text={"BACK"}
                        onClick={onClickBack}
                    />
                    <Spin spinning={isUpdating}>
                        <MaterialButtonComponent
                            disabled={!isFormValid}
                            type={"primary"}
                            size={"large"}
                            text={"SAVE"}
                            onClick={onClickSave}
                        />
                    </Spin>
                </div>
            </main>
        </section>
    );
};

AccountInvoiceDataContainer.propTypes = {
    history: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    error: PropTypes.string,
    success: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    getInvoiceData: PropTypes.func.isRequired,
    saveInvoiceData: PropTypes.func.isRequired,
};

export default AccountInvoiceDataContainer;