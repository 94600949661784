// Redux
import { connect } from 'react-redux';
// Methods
import { setAreConversionsActiveStatus } from '../../ducks/conversions';

// Selectors
import { campaignStatSumSelector, areConversionsActiveAndRunningSelector } from '../../utils/reduxSelectors';

import ConversionsHeaderContainer from './ConversionsHeaderContainer';

const mapStateToProps = state => ({
    areConversionsActive: state.conversions.areConversionsActive,
    areConversionsActiveAndRunning: areConversionsActiveAndRunningSelector(state),
    soldProductsSum: campaignStatSumSelector(state, 'soldProducts'),
    earnedMoneySum: campaignStatSumSelector(state, 'earnedMoney')
});

const mapDispatchToProps = {
    setAreConversionsActiveStatus
};

const ConversionsHeader = connect(mapStateToProps, mapDispatchToProps)(ConversionsHeaderContainer);

export default ConversionsHeader;