import config from '../../config';
import { setError } from '../app';
import { HeraldbeeApi } from './../../api/HeraldbeeApi';
import { FacebookAgencyAccessRequestStatus } from '../../utils/utils';

// Actions

const SET_ARE_CONVERSIONS_ACTIVE = 'SET_ARE_CONVERSIONS_ACTIVE';
const SET_FACEBOOK_AD_ACCOUNT_LIST = 'SET_FACEBOOK_AD_ACCOUNT_LIST';
const SET_WAS_AGENCY_ACCESS_REQUEST_SENT_SUCCESSFULLY = "SET_WAS_AGENCY_ACCESS_REQUEST_SENT_SUCCESSFULLY";
const SET_WAS_AGENCY_ACCESS_GRANTED = "SET_WAS_AGENCY_ACCESS_GRANTED";

// Reducer

const initialState = {
    areConversionsActive: false,
    listedAdAccounts: [],
    wasAgencyAccessRequestSentSuccessfully: null,
    wasAgencyAccessGranted: null
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SET_ARE_CONVERSIONS_ACTIVE:
            return {
                ...state,
                areConversionsActive: action.value
            };
        case SET_FACEBOOK_AD_ACCOUNT_LIST:
            return {
                ...state,
                listedAdAccounts: action.listedAdAccounts
            };
        case SET_WAS_AGENCY_ACCESS_REQUEST_SENT_SUCCESSFULLY:
            return {
                ...state,
                wasAgencyAccessRequestSentSuccessfully: action.wasAgencyAccessRequestSentSuccessfully
            };
        case SET_WAS_AGENCY_ACCESS_GRANTED:
            return {
                ...state,
                wasAgencyAccessGranted: action.wasAgencyAccessGranted
            };

        default: return state;
    }
};
export default reducer;

// Action Creators

const actionSetFacebookAdAccountList = ({ listedAdAccounts }) => ({ type: SET_FACEBOOK_AD_ACCOUNT_LIST, listedAdAccounts });
const actionSetWasAgencyAccessRequestSentSuccessfully = wasAgencyAccessRequestSentSuccessfully => ({ type: SET_WAS_AGENCY_ACCESS_REQUEST_SENT_SUCCESSFULLY, wasAgencyAccessRequestSentSuccessfully });
const actionSetWasAgencyAccessGranted = wasAgencyAccessGranted => ({ type: SET_WAS_AGENCY_ACCESS_GRANTED, wasAgencyAccessGranted });
const actionBlank = () => ({ type: null });

// Methods
export const redirectForFacebookAuthorization = () => dispatch => {

    dispatch(actionBlank());

    return window.location.href = `${config.API_URL}/facebook`;
};

export const getAdAccountList = () => async dispatch => {
    const { getAdAccountList } = HeraldbeeApi({
        baseUrl: config.API_URL
    });

    const { error, value } = await getAdAccountList();

    if (error) {
        return dispatch(setError({ message: error }));

    } else {
        const { listedAdAccounts } = value;
        return dispatch(actionSetFacebookAdAccountList({ listedAdAccounts }));

    }
};

export const askForAdAccountAgencyAccess = ({ chosenAdAccount }) => async dispatch => {
    const { askForAdAccountAgencyAccess } = HeraldbeeApi({
        baseUrl: config.API_URL
    });

    const { value, error } = await askForAdAccountAgencyAccess({ chosenAdAccount });
    if (error) {
        const message = { error };
        return dispatch(setError(message));

    } else {
        const { requestStatus } = value;
        const wasAgencyAccessRequestSentSuccessfully = requestStatus === FacebookAgencyAccessRequestStatus.PENDING;
        return dispatch(actionSetWasAgencyAccessRequestSentSuccessfully(wasAgencyAccessRequestSentSuccessfully));

    }
};

export const resetWasAgencyAccessRequestSentSeuccessfully = () => async dispatch => {
    return dispatch(actionSetWasAgencyAccessRequestSentSuccessfully(null));
};

export const findOutIfUserHasGrantedAgencyAccess = () => async dispatch => {
    dispatch(actionSetWasAgencyAccessGranted(null));

    const { findOutIfUserHasGrantedAgencyAccess } = HeraldbeeApi({
        baseUrl: config.API_URL
    });
    const { value, error } = await findOutIfUserHasGrantedAgencyAccess();

    if (error) {
        return dispatch(setError(error));

    } else {
        const { wasAgencyAccessGranted } = value;
        return dispatch(actionSetWasAgencyAccessGranted(wasAgencyAccessGranted));

    }
};