// Redux
import { connect } from 'react-redux'
// Methods
import { updateInvoiceFormField, clearInvoiceDataError } from '../../../../ducks/payment';
// Selectors
import { formValueSelector } from '../../../../utils/reduxSelectors';

import MaterialInputComponent from '../../../../components/MaterialComponents/MaterialInputComponent';

const mapStateToProps = state => ({
    formValue: formValueSelector(state, 'firstName')
});

const mapDispatchToProps = {
    updateInvoiceFormField,
    clearInvoiceDataError
};

const FirstNameInput = connect(mapStateToProps, mapDispatchToProps)(MaterialInputComponent);

export default FirstNameInput;
