// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux'
// Methods
import { setOnboardingFacebookCreateCampaignField as setCampaignCountry } from '../../../../ducks/onboarding'

import ChooseCountryContainer from '../../../../components/SharedCreateCampaign/ChooseCountryContainer';

const mapStateToProps = state => ({
    countryCode: state.onboarding.facebook.createCampaign.country,
    selection: 'facebook',
    shouldBackButtonBeAvailable: true
});

const mapDispatchToProps = {
    setCampaignCountry
};

const ChooseCountry = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseCountryContainer));

export default ChooseCountry;