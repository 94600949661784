import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Layout, Spin, Alert, Button } from 'antd';
const { Content, Footer } = Layout;

class PageWrapper extends React.Component {
  static propTypes = {
    campaigns: PropTypes.any,
    children: PropTypes.node,
    errors: PropTypes.array,
    footer: PropTypes.any,
    localRunning: PropTypes.any,
    onReloadClick: PropTypes.any,
    subscription: PropTypes.any,
    user: PropTypes.any
  };
  
  constructor(props) {
    super(props);
    this.state = {
      initialRunning: props.user.loader && props.subscription.loader && props.campaigns.loader,
      renderError: false
    }
  }

  componentDidMount() {
    const { user, subscription, campaigns } = this.props;

    if (!user.loader && !subscription.loader && !campaigns.loader) {
      this.setState({initialLoader: false});
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user, subscription, campaigns } = nextProps;

    if (!user.loader && !subscription.loader && !campaigns.loader) {
      this.setState({initialRunning: false});
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { renderError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    const { footer, localRunning, errors, onReloadClick, children } = this.props;
    const { renderError } = this.state;

    return (
      <div>
        <Content style={{ margin: '30px 4% 40px 4%' }}>
          {renderError ?
            <div>
              <Alert showIcon
                  message="Error"
                  description={'App rendering went wrong!'}
                  type="error"
                />
            </div>
          : errors && errors.length > 0 ? 
            <div>
              {errors.map((error, i) => 
                <Alert showIcon
                  key={i}
                  message="Error"
                  description={error.message}
                  type="error"
                />)}
              <Button 
                size='large' 
                style={{ display: 'block', margin: '20px auto' }}
                onClick={onReloadClick}>
                Reload
              </Button>
            </div>
            :
            /* alwaysShowChildrenComponents was needed, because ref on children can be instantiated once, and removing and adding children to DOM would cause ref==null */
            localRunning ?
              <div>
                {(this.state.initialRunning || localRunning) &&
                  <Spin style={{
                    height: '100vh',
                    marginTop: '-94px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }} size='large' />
                }
              </div>
              :
              children
          }
        </Content>
        {footer && ((!this.state.initialRunning && !localRunning)) &&
          <Footer>
            {footer}
          </Footer>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  subscription: state.subscription,
  campaigns: state.campaigns
});

export default connect(mapStateToProps)(PageWrapper);