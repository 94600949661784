import React from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import AccountSummary from "../../../container/AccountSummary/index";
import AccountSubscription from "../../../pages/Account/AccountSubscription";

// Custom Components
import FacebookVerification from '../../../container/FacebookVerification';
import AccountFacebookVerificationSuccess from '../../../components/Account/AccountFacebookVerificationSuccess';
import AccountEmail from "../../../pages/Account/AccountEmail";
import AccountOwnerName from "../../../pages/Account/AccountOwnerName";
import AccountStoreName from "../../../pages/Account/AccountStoreName";
import AccountEditPayment from './../../../container/AccountEditPaymentAuthContainer/index';
import AccountInvoiceData from '../../../pages/Account/AccountInvoiceData';
import AccountInvoiceHistory from '../../../pages/Account/AccountInvoiceHistory';

const AccountRouterComponent = () => {

  return (
    <Switch>
      <Route exact path="/account" component={AccountSummary} />
      <Route path="/account/facebookVerification" component={FacebookVerification} />
      <Route path="/account/facebookIntegrationSuccess" component={AccountFacebookVerificationSuccess} />
      <Route
        path="/account/changePaymentMethod"
        component={AccountEditPayment}
      />
      <Route
        path="/account/changeSubscriptionPlan"
        component={AccountSubscription}
      />
      <Route
        path="/account/ownerName"
        component={() => (
          <AccountOwnerName fieldToUpdate="wooCommerceShopOwnerName" />
        )}
      />
      <Route
        path="/account/storeName"
        component={() => (
          <AccountStoreName fieldToUpdate="wooCommerceShopName" />
        )}
      />
      <Route
        path="/account/email"
        component={() => <AccountEmail fieldToUpdate="email" />}
      />
      <Route
        path="/account/invoiceData"
        component={AccountInvoiceData}
      />
      <Route
        path="/account/invoiceHistory"
        component={AccountInvoiceHistory}
      />
    </Switch>
  );
};

export default AccountRouterComponent;