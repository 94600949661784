import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sortBy, reverse } from 'lodash';

import HistoryTableHead from './HistoryTableHead';

//mateiral ui components
import Paper from '@material-ui/core/Paper'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

import { useTableBodyStyles, usePaperStyle } from './index.material.js'

const CampaignHistoryTableComponent = ({ historyData }) => {

    const daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [sortDirection, setSortDirection] = useState('desc');
    const [orderBy, setOrderBy] = useState('date');
    
    const tableBodyStyles = useTableBodyStyles();
    const paperStyles = usePaperStyle();

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && sortDirection === 'desc';
        setSortDirection(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    const sortHistory = (historyData, orderDirection, orderBy) => {
        if(sortDirection === 'asc'){
            return sortBy(historyData, orderBy)
        } else {
            return reverse(sortBy(historyData, orderBy))
        } 
    }

    return (
            <Paper classes={paperStyles}>
                <Table>
                    <HistoryTableHead
                        order={sortDirection}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody >
                    {
                        sortHistory(historyData, sortDirection, orderBy)
                            .map( (row, index) => {
                                const labelId = `tableRow/${index}`
                                return (
                                    <TableRow
                                        classes={tableBodyStyles}
                                        hover
                                        key={index}
                                    >
                                        <TableCell component='th' id={labelId} scope='col' >{row.date}</TableCell>
                                        <TableCell align="left">{daysList[row.day]}</TableCell>
                                        <TableCell align="right">{row.budget}</TableCell>
                                        <TableCell align="right">{row.clicks}</TableCell>
                                        <TableCell align="right">{row.impressions}</TableCell>
                                        <TableCell align="right">{row.cost}</TableCell>
                                    </TableRow>
                                )
                        })
                    }
                    </TableBody>
                </Table>
            </Paper>
)
}

CampaignHistoryTableComponent.propTypes = {
    historyData : PropTypes.arrayOf(PropTypes.object)
}
CampaignHistoryTableComponent.defaultProps = {
    historyData : []
}

export default CampaignHistoryTableComponent