import React from "react";
import PropTypes from "prop-types";

// Third Party Modules
import Button from "@material-ui/core/Button";
import { materialStyleGenerator } from "./materialStyleOverride";

const MaterialButtonComponent = ({ type, size, text, onClick, disabled }) => {
  const materialStyle = materialStyleGenerator()();

  const ContainedButton = props => (
    <Button disabled={disabled} variant="contained" {...props}>
      {text}
    </Button>
  );

  /** Interpret accepted props, to provide the user with the correct type of button */
  const { genericStyle } = materialStyle;
  const chosenSize = materialStyle[`${size}Size`];
  const chosenType = materialStyle[`${type}Type`];
  const chosenClasses = `${genericStyle} ${chosenSize} ${chosenType}`;

  return (
    <ContainedButton
      text="SIGN IN"
      className={chosenClasses}
      onClick={onClick}
    />
  );
};

MaterialButtonComponent.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "disabled"]),
  size: PropTypes.oneOf(["xSmall", "small", "medium", "large", "xLarge", "dynamic"]),
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MaterialButtonComponent;
