import React from "react";
import style from "./index.module.less";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../../utils/utils";

const SubscriptionPlanInfoComponent = ({ LinkButton, pricingPlanId }) => {
  const planIdCapitalized = pricingPlanId && capitalizeFirstLetter(pricingPlanId);
  return (
    <div className={style.SubscriptionPlanInfoComponent}>
      <span className={style.planId}>{planIdCapitalized}</span>
      {LinkButton}
    </div>
  );
};

SubscriptionPlanInfoComponent.propTypes = {
  LinkButton: PropTypes.object.isRequired,
  pricingPlanId: PropTypes.string.isRequired
};

export default SubscriptionPlanInfoComponent;
