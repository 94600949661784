// React
import { withRouter } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';

import PaymentSelectionButtonsContainer from './PaymentSelectionButtonsContainer';

const mapStateToProps = state => ({
    selection: state.onboarding.selectedAdChannel
});

const mapDispatchToProps = {

};

const PaymentSelectionButtons = withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentSelectionButtonsContainer));

export default PaymentSelectionButtons;