// React
import React from 'react';
import PropTypes from 'prop-types';

// Thrid Party Modules
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { FormHelperText } from '@material-ui/core';

// Stylesheet
import { useStyles } from './index.material';

const MaterialSimpleSelectComponent = ({ size, options, value, label, onChange, helperText, disabled = false }) => {

	const classes = useStyles();

    const inputLabel = React.useRef(null);
    
    const handleOnChange = e => {
        onChange(e.target.value)
    };

    const HelperText = () => disabled && helperText 
        ? (
            <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        ) : null;

    return (
        <FormControl className={classes.root} variant="outlined">
            <InputLabel disabled={true} ref={inputLabel}>{label}</InputLabel>
            <Select
                native
                disabled={disabled}
                className={`${classes[`${size}Size`]}`}
                value={value}
                onChange={handleOnChange}
                input={<OutlinedInput name="age" labelWidth={50} />}
            >
                {options.map((option, i) => <option key={i} value={option.value}>{option.description}</option>)}
            </Select>
            <HelperText />
        </FormControl>
    );
};

MaterialSimpleSelectComponent.propTypes = {
    size: PropTypes.oneOf(['small', 'large']).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        description: PropTypes.string
    })),
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    helperText: PropTypes.string,
    disabled: PropTypes.bool
}

export default MaterialSimpleSelectComponent;