import moment from 'moment-timezone';

import config from "../../config";

export const createCampaignParams = (dailyBudget, salesCountryCode, targetCountryCodes) => {
  const name = moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss z') + ` - ${config.APP_NAME} Shopping Campaign`;

  const params = {
    id: null,
    status: null,
    name: name,
    startTime: null,
    endTime: null,
    dailyBudget: dailyBudget,
    salesCountryCode: salesCountryCode,
    targetCountryCodes: targetCountryCodes
  };

  return params;
};

export const defineWhatHasBeenUpdated = patchObject => {
  const output = Object.keys(patchObject).map(key => {
      switch (key) {
          case 'newBudget':
              return 'budget';
          case 'newName':
              return 'name';
          default:
              return null;
      };
  });
  
  if (output.includes(null) || output.length === 0) {
      const error = new Error('Provided patchObject is malformed or empty');
      return {
          error: error,
          value: null
      };
  } else {
      return  {
          error: null,
          value: output.join(', ')
      };
  };
};