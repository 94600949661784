import { connect } from 'react-redux'
import SubscriptionPlanInfoComponent from './SubscriptionPlanInfoComponent';

const mapStateToProps = state => ({
	pricingPlanId: state.subscription.content.plan_id,
});


const SubscriptionPlanInfo = connect(mapStateToProps)(SubscriptionPlanInfoComponent);

export default SubscriptionPlanInfo;