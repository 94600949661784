import React, { useEffect } from "react";
import style from "./index.module.less";
import SubscriptionSettings from "../../SubscriptionSettings";

// Custom Modules
import { isPlatformShopify } from "../../../utils/utils";

// Custom Components
import AccountIntegration from '../../../components/Account/AccountIntegration';
import AccountInvoice from '../../../components/AccountInvoice';
import AccountWooCommerceData from "../../../components/AccountWooCommerceData";
import { PropTypes } from "prop-types";
import AvatarPicker from '../../../components/AvatarPicker/index';

const AccountSummaryContainer = React.memo(
  ({ platform, updateMessage, actionSetUpdateMessage }) => {
    useEffect(() => {
      /** Use timer to make confirmation message disappear. In case when unmount happens before 5000 ms has elapsed, the timer is cleared, in order to avoid rerender of the next target component, which, in case if this target component is ChangePayment, would cause iFrame failure. */
      let timerId;
      if (updateMessage) {
        timerId = setTimeout(() => {
          actionSetUpdateMessage(null);
        }, 5000);
      }
      return () => {
        clearTimeout(timerId);
      };
    }, [updateMessage]);

    return (
      <section className={style.accountSummary}>
        <div className={style.titleContainer}>
          <h2 className={style.viewTitle}>Account</h2>
          {updateMessage && (
            <p className={style.updateMessage}>{updateMessage}</p>
          )}
        </div>
        <AvatarPicker />
        <AccountIntegration />
        <SubscriptionSettings style={style} />
        <AccountInvoice />
        {isPlatformShopify(platform) ? null : <AccountWooCommerceData />}
      </section>
    );
  }
);

AccountSummaryContainer.propTypes = {
  platform: PropTypes.string.isRequired,
  updateMessage: PropTypes.string,
  actionSetUpdateMessage: PropTypes.func.isRequired,
  actionConfirmSuccessfulPaymentAuthUpdate: PropTypes.func.isRequired
};

export default AccountSummaryContainer;
