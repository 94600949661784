// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import MaterialBreadcrumbsComponent from '../../../../components/MaterialComponents/MaterialBreadcrumbsComponent';
import NoInvoiceHistoryComponent from './NoInvoiceHistoryComponent';
import MaterialInvoiceHistoryComponent from '../../../../components/MaterialComponents/MaterialInvoiceHistoryComponent';

// Stylesheet
import styles from './index.module.less';

const AccountInvoiceHistoryContainer = ({ adjustedInvoiceHistory, isInvoiceHistoryAvailable, fetchInvoicePDF }) => {
    // constants
    const linkArray = [
        {
            textContent: 'Account',
            link: '/account'
        },
        {
            textContent: 'Invoice history',
            link: null
        }
    ];

    const determineRenderedContent = () => {
        return isInvoiceHistoryAvailable
            ? <MaterialInvoiceHistoryComponent adjustedInvoiceHistory={adjustedInvoiceHistory} fetchInvoicePDF={fetchInvoicePDF}/> 
            : <NoInvoiceHistoryComponent/>;
    };

    return (
        <section className={styles.container}>
            <header className={styles.header}>
                <MaterialBreadcrumbsComponent breadcrumbLinkArray={linkArray}/>
                <h2 className={`${styles.headerTitle} h2-sm`}>Invoice history</h2>
            </header>
            <main className={styles.content}>
                { determineRenderedContent() }
            </main>
        </section>
    );
};

AccountInvoiceHistoryContainer.propTypes = {
    adjustedInvoiceHistory: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        date: PropTypes.string,
        dueDate: PropTypes.string,
        status: PropTypes.string,
        name: PropTypes.string
    })),
    isInvoiceHistoryAvailable: PropTypes.bool.isRequired,
    fetchInvoicePDF: PropTypes.func.isRequired
};

export default AccountInvoiceHistoryContainer;