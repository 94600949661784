// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Spin } from 'antd';

// Custom Modules
import { fieldToUpdateTranslator } from '../../utils/account';
import { validateEmailInput } from '../../utils/auth';

// Custom Components
import MaterialBreadcrumbsComponent from '../../components/MaterialComponents/MaterialBreadcrumbsComponent';
import MaterialInputComponent from '../../components/MaterialComponents/MaterialInputComponent';
import MaterialButtonComponent from '../../components/MaterialComponents/MaterialButtonComponent';

// Stylesheet
import styles from './index.module.less';

const WooCommerceDataUpdaterContainer = ({ fieldToUpdate, valueToUpdate, history, patchWooCommerceData }) => {
    const dynamicContent = fieldToUpdateTranslator(fieldToUpdate);
    const linkArray = [
        {
            textContent: 'Account',
            link: '/account'
        }, 
        {
            textContent: `Change ${dynamicContent.title}`,
            link: null
        }
    ];

    const [ newValue, setNewValue ] = useState(null);
    const [ newValueError, setNewValueError ] = useState(null); 
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        };
    }, [ valueToUpdate ]);

    const onBackClick = () => {
        history.push('/account');
    };

    const onSaveClick = () => {
        if (!newValue) {
            setNewValueError(`new value can't be empty`);
            return;
        };
        if (fieldToUpdate === 'email') {
            const emailIsValid = validateEmailInput(newValue);
            if (!emailIsValid) {
                setNewValueError('provided email is not valid');
                return;
            }  
        };
        setIsLoading(true);
        patchWooCommerceData(fieldToUpdate, newValue);
    };

    const saveNewValueToState = e => {
		setNewValueError(null);
		setNewValue(e.target.value);
	};

    return (
        <section className={styles.container}>
            <MaterialBreadcrumbsComponent breadcrumbLinkArray={linkArray} />
            <p className={styles.containerTitle}>{`Change ${dynamicContent}`}</p>
            <main className={styles.content}>
                <div className={styles.card}>
                    <header className={styles.cardHeader}>
                        <p className={styles.cardHeaderDescription}>{`YOUR CURRENT ${dynamicContent.toUpperCase()}`}</p>
                        <p className={styles.cardHeaderValue}>{valueToUpdate}</p>
                    </header>
                    <div className={styles.cardInput}>
                        <p className={styles.cardInputDescription}>{`YOUR NEW ${dynamicContent.toUpperCase()}`}</p>
                        <MaterialInputComponent 
                            marginBottom="16px"
                            placeholder={valueToUpdate}
                            label={dynamicContent}
                            size="large"
                            onChange={saveNewValueToState}
                            error={newValueError ? true : false}
                            caption={newValueError}
                        />
                    </div>
                </div>
                <div className={styles.buttons}>
                    <MaterialButtonComponent
                        type={'secondary'}
                        size={'xSmall'}
                        text={'BACK'}
                        onClick={onBackClick}
                    />
                    <Spin spinning={isLoading}>
                        <MaterialButtonComponent
                            type="primary"
                            size={'medium'}
                            text={'SAVE'}
                            onClick={onSaveClick}
                        />
                    </Spin>
                </div>
            </main>
        </section>
    );
};

WooCommerceDataUpdaterContainer.propTypes = {
    fieldToUpdate: PropTypes.oneOf(['email', 'wooCommerceShopName', 'wooCommerceShopOwnerName']).isRequired,
    valueToUpdate: PropTypes.string.isRequired,
    patchWooCommerceData: PropTypes.func.isRequired
};

export default WooCommerceDataUpdaterContainer;