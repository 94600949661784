// React
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { ShoppingCartOutlined, LocalAtm } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import { Spin } from 'antd';

// Custom Components
import ConversionModal from '../../ConversionModal'

// StyleSheet
import styles from './index.module.less';
import { useStyles } from './index.material';

const ConversionsHeaderContainer = ({ areConversionsActive, areConversionsActiveAndRunning, setAreConversionsActiveStatus, soldProductsSum, earnedMoneySum }) => {

    const [isLoading, setIsLoading] = useState(false);

    useLayoutEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        }
    }, [areConversionsActive]);

    const onConfirmAddingScript = (event) => {
        setIsLoading(true);
        setAreConversionsActiveStatus(event.target.checked);
    };

    const classes = useStyles();

    const renderAddConversionButton = () => {
        return (
            <article className={styles.contentBodyControls}>
                <p className={styles.contentBodyControlsText}>ADD GOOGLE CONVERSION TRACKING CODE</p>
                <ConversionModal />
            </article>
        );
    }

    const renderConversionStats = () => {
        return (
            <div className={styles.stat}>
                <div className={styles.statUnit}>
                    <span className={styles.statUnitContent}>
                        <ShoppingCartOutlined className={styles.statUnitContentIcon} />
                        <p className={styles.statUnitContentValue}>{soldProductsSum}</p>
                    </span>
                    <p className={styles.statUnitDescription}>TOTAL SOLD VIA ADS</p>
                </div>
                <div className={styles.statUnit}>
                    <span className={styles.statUnitContent}>
                        <LocalAtm className={styles.statUnitContentIcon} />
                        <p className={styles.statUnitContentValue}>${earnedMoneySum}</p>
                    </span>
                    <p className={`${styles.statUnitDescription} ${styles.statUnitDescriptionAdjusted}`}>TOTAL REVENUE FROM ADS</p>
                </div>
            </div>
        );
    }

    return (
        <section className={styles.container}>
            <main className={`${styles.content} ${areConversionsActive ? null : styles.contentCentered}`}>
                <div className={styles.contentHeader}>
                    <h2 className={`${styles.contentHeaderTitle} h2-sm`}>Google Conversions</h2>
                    <p className={styles.contentHeaderSubtitle}>Sales summary from your store</p>
                </div>
                <span className={styles.contentBody}>
                    {areConversionsActive ? renderConversionStats() : renderAddConversionButton()}
                </span>
            </main>
            { // Checkbox controls remain visible until application detects non 0 conversion stats in any campaign
                areConversionsActiveAndRunning
                    ? null
                    : (
                        <aside className={styles.controls}>
                            <hr className={styles.controlsDivisor} />
                            <span className={styles.controlsContent}>
                                <p className={styles.controlsContentText}>Confirm adding the code to your Shopify panel</p>
                                <Spin className={styles.spinner} spinning={isLoading}>
                                    <Checkbox
                                        checked={areConversionsActive}
                                        onChange={onConfirmAddingScript}
                                        classes={classes}
                                        color="default"
                                    />
                                </Spin>
                            </span>
                        </aside>
                    )
            }
        </section>
    );
};

ConversionsHeaderContainer.propTypes = {
    areConversionsActive: PropTypes.bool.isRequired,
    areConversionsActiveAndRunning: PropTypes.bool.isRequired,
    soldProductsSum: PropTypes.number.isRequired,
    earnedMoneySum: PropTypes.number.isRequired
};

export default ConversionsHeaderContainer;