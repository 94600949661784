import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback
} from "react";
import { PropTypes } from "prop-types";

import CreateCampaignHeader from "../../../../../components/OnboardingCreateCampaign/CreateCampaignHeaderComponent";
import OnboardingContainer from "../../../../../container/OnboardingContainer";
import CreateCampaignStepper from "../../../../../components/MaterialComponents/OnboardingMaterialComponents/CreateCampaignStepperComponent/index";
import HostedFieldsComponent from "../../../../../components/HostedFieldsComponent";
import SavedPaymentCardComponent from "../../../../../components/SavedPaymentCardComponent/index";

import braintreeClientCreator from "braintree-web/client";
import braintreeThreeDSecureCreator from 'braintree-web/three-d-secure';
import { initializeBraintreeClient } from "../../../../../module/BraintreeModule/initializeBraintreeClient";
import { initializeHostedFieldsClient } from "../../../../../module/BraintreeModule/initializeHostedFieldsClient";

import { OnboardingTexts } from "../../../../../utils/texts";
import { onboardingGoogleSlideTitles, onboardingFacebookSlideTitles } from "../../../../../utils/onboarding.js";
import { Spin } from "antd";
import { generateStyleConfigObject } from "../../../../../module/BraintreeModule/generateStyleConfigObject";
import style from "./index.module.less";
import MaterialButtonComponent from "../../../../../components/MaterialComponents/MaterialButtonComponent";
import PaymentButtonsRowComponent from "../../../../../components/PaymentButtonsRowComponent/index";
import VendorLogoComponent from "../../../../../components/VendorLogoComponent";
import { tokenizePaymentCardData } from "../../../../../module/BraintreeModule/tokenizePaymentCardData";
import { initializeThreeDSecureInstance } from "../../../../../module/BraintreeModule/initializeThreeDSecureInstance";
import { acceptedPaymentMethods } from "../../../../../styles/constants";

const SetCardAuthContainer = ({
  fetchBraintreeToken,
  braintreeToken,
  createCustomerWithPaymentAuthorization,
  clientNonce,
  lastFourPaymentCardDigits,
  creditCardVendorNameForSummary,
  actionSetLastFourPaymentCardDigitsForSummary,
  actionSetPaymentCardVendorNameForSummary,
  history,
  validateBraintreeHostedFieldsEnvelope,
  validateBraintreeClientInstanceEnvelope,
  validateBraintreeInstanceEnvelope,
  planId,
  returnToPaymentMethodSelection,
  setFirstAcceptedPaymentMethodType,
  firstAcceptedPaymentMethodType,
  selection = 'google'
}) => {

  SetCardAuthContainer.propTypes = {
    braintreeToken: PropTypes.string,
    clientNonce: PropTypes.string,
    lastFourPaymentCardDigits: PropTypes.string,
    creditCardVendorNameForSummary: PropTypes.string,
    createCustomerWithPaymentAuthorization: PropTypes.func.isRequired,
    fetchBraintreeToken: PropTypes.func.isRequired,
    actionSetLastFourPaymentCardDigitsForSummary: PropTypes.func.isRequired,
    actionSetPaymentCardVendorNameForSummary: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    validateBraintreeHostedFieldsEnvelope: PropTypes.func.isRequired,
    validateBraintreeClientInstanceEnvelope: PropTypes.func.isRequired,
    planId: PropTypes.string.isRequired,
    validateBraintreeInstanceEnvelope: PropTypes.func.isRequired,
    returnToPaymentMethodSelection: PropTypes.func.isRequired,
    setFirstAcceptedPaymentMethodType: PropTypes.func.isRequired,
    firstAcceptedPaymentMethodType: PropTypes.string,
    selection: PropTypes.oneOf(['google', 'facebook'])
  };

  const [
    localBraintreeHostedFieldsInstance,
    setLocalBraintreeHostedFieldsInstance
  ] = useState();
  const [localBraintreeThreeDSecureInstance, setLocalBraintreeThreeDSecureInstance] = useState();
  const [detectedCardVendorName, setDetectedCardVendorName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [
    shouldHostedFieldsBeInitialized,
    setShouldHostedFieldsBeInitialized
  ] = useState();
  const [
    listeningForReduxConfirmation,
    setListeningForReduxConfirmation
  ] = useState(false);
  const [expirationDateErrorText, setExpirationDateErrorText] = useState();
  const [cardNumberErrorText, setCardNumberErrorText] = useState();
  const [cardRejectedErrorText, setCardRejectedErrorText] = useState();
  const [cvvErrorText, setCvvErrorText] = useState();
  const [, setShouldConfirmationButtonBeDisabled] = useState();
  const [
    savedCardComponentShouldBeRendered,
    setSavedCardComponentShouldBeRendered
  ] = useState(false);

  const shouldConfirmationButtonBeDisabledRef = useRef(true);

  const navigateToSummary = useCallback(() => {

    localBraintreeHostedFieldsInstance &&
      localBraintreeHostedFieldsInstance.teardown();
    setFirstAcceptedPaymentMethodType({ paymentMethod: acceptedPaymentMethods.paymentCard });
    const url = selection === 'google' ? '/onboarding/google/createCampaign/finish' : '/onboarding/facebook/createCampaign/finish';
    history.push(url);
  }, [history, localBraintreeHostedFieldsInstance, setFirstAcceptedPaymentMethodType]);

  const onBackClick = () => {
    localBraintreeHostedFieldsInstance &&
      localBraintreeHostedFieldsInstance.teardown();
      return returnToPaymentMethodSelection();
  };

  const onNextClick = async () => {
    setIsLoading(true);
    if (
      !areAllCardDetailsCached({
        lastFourPaymentCardDigits,
        creditCardVendorNameForSummary
      })
    ) {
      setIsLoading(true);

      const {
        clientNonce,
        cardType,
        tokenizeErrorMessage,
        lastFourDigits,
      } = await tokenizePaymentCardData({
        localBraintreeHostedFieldsInstance,
        localBraintreeThreeDSecureInstance,
      });

      if (tokenizeErrorMessage) {
        setCardRejectedErrorText(tokenizeErrorMessage);
        setIsLoading(false);
        handleConfirmationButtonStatus({
          shouldConfirmationButtonBeDisabled: true
        });
      } else {
        setIsLoading(false);
        createCustomerWithPaymentAuthorization(clientNonce);
        setDetectedCardVendorName(cardType);
        actionSetLastFourPaymentCardDigitsForSummary(lastFourDigits);
        actionSetPaymentCardVendorNameForSummary(cardType);
      }
    } else {
      if (!listeningForReduxConfirmation) {
        navigateToSummary();
      }
    }

    setListeningForReduxConfirmation(true);
  };

  const areCardDetailsCached = ({
    lastFourPaymentCardDigits,
    creditCardVendorNameForSummary
  }) =>
    [
      lastFourPaymentCardDigits,
      creditCardVendorNameForSummary
    ].some(Boolean);

  const areAllCardDetailsCached = ({
    lastFourPaymentCardDigits,
    creditCardVendorNameForSummary
  }) =>
    [
      lastFourPaymentCardDigits,
      creditCardVendorNameForSummary
    ].every(Boolean);

  const {
    paymentSlide: { title, infoButtonText }
  } = OnboardingTexts;

  const steps = selection === 'google' ? onboardingGoogleSlideTitles : onboardingFacebookSlideTitles;

  const handleErrorTextSetting = ({ hostedFieldEventEmitterName }) => {
    switch (hostedFieldEventEmitterName) {
      case "number":
        setCardNumberErrorText("Invalid Card Number");
        break;
      case "expirationDate":
        setExpirationDateErrorText("Invalid Expiration Date");
        break;
      case "cvv":
        setCvvErrorText("Invalid CVV/PIN Number");
        break;
      default:
    }
  };

  const handleErrorTextClearing = ({ hostedFieldEventEmitterName }) => {
    switch (hostedFieldEventEmitterName) {
      case "number":
        setCardNumberErrorText("");
        break;
      case "expirationDate":
        setExpirationDateErrorText("");
        break;
      case "cvv":
        setCvvErrorText("");
        break;
      default:
    }
  };

  const handleConfirmationButtonStatus = ({
    shouldConfirmationButtonBeDisabled
  }) => {
    shouldConfirmationButtonBeDisabledRef.current = shouldConfirmationButtonBeDisabled;
    setShouldConfirmationButtonBeDisabled(shouldConfirmationButtonBeDisabled);
  };

  const initializeLocalHostedFieldsInstance = useCallback(async () => {
    /** hostedFieldsInstance === initializeHostedFieldsClient(initializeBraintreeClient) */
    const {
      value: braintreeClientInstance,
      error: braintreeClientInstanceCreationError
    } = await initializeBraintreeClient({
      braintreeClientCreator,
      braintreeToken
    });
    
    const {
      validatedBraintreeClientInstance
    } = await validateBraintreeClientInstanceEnvelope({
      envelope: {
        braintreeClientInstance,
        braintreeClientInstanceCreationError
      }
    });
    
    const { value: threeDSecureInstance, error: braintreeThreeDSSecureInstanceCreationError } = await initializeThreeDSecureInstance({
      braintreeThreeDSecureCreator,
      braintreeClientInstance: validatedBraintreeClientInstance
    })

    const { validatedThreeDSecureInstance } = await validateBraintreeInstanceEnvelope({
      envelope: {
        value: threeDSecureInstance,
        error: braintreeThreeDSSecureInstanceCreationError
      }
    });


    const styleInitializationObject = generateStyleConfigObject({
      validatedBraintreeClientInstance
    });
    const {
      value: hostedFieldsInstance,
      error: hostedFieldsCreationError
    } = await initializeHostedFieldsClient({
      setDetectedCardVendorName,
      styleInitializationObject,
      handleErrorTextClearing,
      handleErrorTextSetting,
      handleConfirmationButtonStatus,
      setCardRejectedErrorText,
      style
    });

    const {
      validatedHostedFieldsInstance
    } = await validateBraintreeHostedFieldsEnvelope({
      envelope: {
        hostedFieldsInstance,
        hostedFieldsCreationError
      }
    });
    validatedHostedFieldsInstance.focus("number");
    setLocalBraintreeHostedFieldsInstance(validatedHostedFieldsInstance);
    setLocalBraintreeThreeDSecureInstance(validatedThreeDSecureInstance);
    setShouldHostedFieldsBeInitialized(false);
    setIsLoading(false);
  }, [braintreeToken, validateBraintreeClientInstanceEnvelope, validateBraintreeHostedFieldsEnvelope, validateBraintreeInstanceEnvelope]);

  useLayoutEffect(() => {
    setIsLoading(true);
    if (!braintreeToken) {
      fetchBraintreeToken();
    }
    /* As soon as carousel is mounted, there's a call for backend to
        retrieve braintree auth token for front-end application, which
        is used to initiate dropin braintree iframe, which can be seen
        if the response from backend is successful. */

    if (
      areAllCardDetailsCached({
        lastFourPaymentCardDigits,
        creditCardVendorNameForSummary
      })
    ) {
      setSavedCardComponentShouldBeRendered(true);
    }
  }, [
    braintreeToken,
    clientNonce,
    creditCardVendorNameForSummary,
    fetchBraintreeToken,
    lastFourPaymentCardDigits
  ]);

  useEffect(() => {
    /** listeningForReduxConfirmation const prevents automatic redirections at mount, in cases when user goes back from summary */
    if (listeningForReduxConfirmation) {
      const shouldUserBeMovedToSummary = areAllCardDetailsCached({
        lastFourPaymentCardDigits,
        creditCardVendorNameForSummary
      });

      if (shouldUserBeMovedToSummary) {
        setListeningForReduxConfirmation(false);
        navigateToSummary();
      }
    }
  }, [clientNonce, lastFourPaymentCardDigits, creditCardVendorNameForSummary, listeningForReduxConfirmation, history, localBraintreeHostedFieldsInstance, navigateToSummary]);

  useEffect(
    () => {
      setIsLoading(true);
      const shouldHostedFieldsBeInitialized =
        braintreeToken &&
        !listeningForReduxConfirmation &&
        !localBraintreeHostedFieldsInstance &&
        !areCardDetailsCached({
          lastFourPaymentCardDigits,
          creditCardVendorNameForSummary
        });

      if (shouldHostedFieldsBeInitialized) {
        setShouldHostedFieldsBeInitialized(true);
        initializeLocalHostedFieldsInstance();
      } else {
        setShouldHostedFieldsBeInitialized(false);
        setIsLoading(false);
      }
    },
    /* Initiating braintree iframe, as soon as braintree token
        arrives from backend */
    [
      braintreeToken,
      clientNonce,
      creditCardVendorNameForSummary,
      initializeLocalHostedFieldsInstance,
      lastFourPaymentCardDigits,
      listeningForReduxConfirmation,
      localBraintreeHostedFieldsInstance
    ]
  );

  /** Control the spinner, depending on the state of braintree initialization */

  useEffect(() => {
    if (shouldHostedFieldsBeInitialized) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [shouldHostedFieldsBeInitialized]);

const NextButton = () => (
    <MaterialButtonComponent
      text="NEXT"
      type="primary"
      size="xSmall"
      className={style.nextButton}
      onClick={onNextClick}
      disabled={shouldConfirmationButtonBeDisabledRef.current}
    />
  );

  return (
    <Spin
      spinning={isLoading}
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center"
      }}
      size="large"
    >
      <OnboardingContainer>
        <section className={style.wrapper}>
          <CreateCampaignStepper
            activeStep={steps.indexOf("Payment")}
            steps={steps}
          />
          <CreateCampaignHeader title={title} infoButtonText={infoButtonText} />
          <div className={style.content}>
            <div className={style.creditCardOparatorEmblems}>
              <span>Credit Card</span>
              <div className={style.vendorEmblemsContainer}>
                <VendorLogoComponent vendorName={"MasterCard"} />
                <VendorLogoComponent vendorName={"Visa"} />
              </div>
            </div>
            {savedCardComponentShouldBeRendered ? (
              <SavedPaymentCardComponent
                clientNonce={clientNonce}
                lastFourPaymentCardDigits={lastFourPaymentCardDigits}
                creditCardVendorNameForSummary={creditCardVendorNameForSummary}
                onBackClick={onBackClick}
                onNextClick={onNextClick}
                isBlockingTheButtonJustified={false}
                detectedCardVendorName={detectedCardVendorName}
              />
            ) : (
                <>
                  <HostedFieldsComponent
                    cardRejectedErrorText={cardRejectedErrorText}
                    expirationDateErrorText={expirationDateErrorText}
                    cardNumberErrorText={cardNumberErrorText}
                    detectedCardVendorName={detectedCardVendorName}
                    cvvErrorText={cvvErrorText}
                    hostedFieldContainerStyle={style.hostedFieldContainer}
                    hostedFieldContainerCardNumberStyle={
                      style.hostedFieldContainerCardNumber
                    }
                    expirationCvvRowStyle={style.expirationCvvRow}
                  >
                    {/* if there's a card vendor detected, render its logo */}
                    {detectedCardVendorName && (
                      <VendorLogoComponent
                        vendorName={detectedCardVendorName}
                        additionalStyle={style.logoInsideOfCardNumberInput}
                      />
                    )}
                  </HostedFieldsComponent>
                  <PaymentButtonsRowComponent
                    ConfirmButton={<NextButton />}
                    onBackClick={onBackClick}
                    imposedStyling={style.navButtonsRow}
                  />
                </>
              )}
          </div>
        </section>
      </OnboardingContainer>
    </Spin>
  );
};

export default SetCardAuthContainer;
