// Redux
import { connect } from 'react-redux';
// Methods
import { setIsChannelSelectionLoading } from '../../ducks/onboarding';
import { clearFacebookAuthorization } from '../../ducks/auth';

import OnboardingReAskForPermissionsComponent from './OnboardingReAskForPermissionsComponent';

const mapStateToProps = state => ({
    facebookListOfPermissionsToReAsk: state.auth.facebookListOfPermissionsToReAsk,
    isChannelSelectionLoading: state.onboarding.isChannelSelectionLoading
});

const mapDispatchToProps = {
    setIsChannelSelectionLoading,
    clearFacebookAuthorization
};

const OnboardingReAskForPermissions = connect(mapStateToProps, mapDispatchToProps)(OnboardingReAskForPermissionsComponent);

export default OnboardingReAskForPermissions; 
