// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { OnboardingTexts } from '../../../utils/texts';
import { onboardingGoogleSlideTitles, onboardingFacebookSlideTitles } from '../../../utils/onboarding';
import { addCampaignSlideTitles } from '../../../utils/main';
import { isDesignationOnboarding } from '../../../utils/utils';

// Custom Components
import DynamicCreateCampaignWrapperContainer from '../../../container/DynamicCreateCampaignWrapperContainer';
import CreateCampaignHeader from '../../OnboardingCreateCampaign/CreateCampaignHeaderComponent';
import CreateCampaignStepper from '../../MaterialComponents/OnboardingMaterialComponents/CreateCampaignStepperComponent/index';
import MaterialButtonComponent from '../../MaterialComponents/MaterialButtonComponent/index';
import MaterialInputComponent from '../../MaterialComponents/MaterialInputComponent/index';
import CampaignBudgetGuidanceComponent from '../../CampaignBudgetGuidanceComponent';

// Stylesheet
import style from './index.module.less';

const ChooseBudgetContainer = ({ setCampaignBudget, history, budgetValue, designation = 'onboarding', selection = 'google' }) => {

    const onboardingSlideTitles = selection === 'google' ? onboardingGoogleSlideTitles : onboardingFacebookSlideTitles;
    const steps = isDesignationOnboarding(designation) ? onboardingSlideTitles : addCampaignSlideTitles;
    const { title, infoButtonText } = OnboardingTexts.budgetSlide;

    const [ budget, setBudget ] = useState(budgetValue || 10); // Default selection for a budget === 10$
    const [ isBudgetValid, setBudgetValid ] = useState(true);
    const [ isBudgetEnough, setIsBudgetEnough ] = useState(true);
    const [ validateHelp, setValidateHelp ] = useState('');

    const validateBudget = event => {
        const value = event.target.value;
        if (isNaN(value) || value < 1 || value > 1000) {
            setBudgetValid(false);
            setValidateHelp(`Please insert valid budget`);
        } else if (value < 10) {
            setBudgetValid(true);
            setIsBudgetEnough(false);
            setValidateHelp('A budget lower than 10$ will not be effective!');
        } else {
            setBudgetValid(true);
            setIsBudgetEnough(true);
            setValidateHelp('');
        }
        setBudget(value);
    };

    const onNextClick = () => {
        setCampaignBudget('budget', budget);
        const url = isDesignationOnboarding(designation) ? '/onboarding/google/createCampaign/setPaymentAuthorization' : '/campaigns/addCampaign/google/summary';
        history.push(url);
    };

    const onBackClick = () => {
        const url = isDesignationOnboarding(designation) ? '/onboarding/google/createCampaign/chooseCountry' : '/campaigns/addCampaign/google/chooseCountry';
        history.push(url);
    };

    return (
        <DynamicCreateCampaignWrapperContainer designation={designation}>
            <section className={style.wrapper}>
                <CreateCampaignStepper 
                    activeStep={steps.indexOf('Budget')}
                    steps={steps}
                    designation={designation}
                />
                <CreateCampaignHeader 
                    title={title}
                    infoButtonText={infoButtonText}
                />

                <div className={style.content} >
                    <MaterialInputComponent
                        autoFocus
                        label="Budget"
                        size="large"
                        type="number"
                        prefix="$"
                        value={budget}
                        onChange={validateBudget}
                        error={!isBudgetValid || !isBudgetEnough}
                        caption={validateHelp}
                    />
                    <CampaignBudgetGuidanceComponent size="small"/>
                </div>

                <span className={style.buttons}>
                    <MaterialButtonComponent
                        type={'secondary'}
                        size={'xSmall'}
                        text={'BACK'}
                        onClick={onBackClick}
                    />
                    <MaterialButtonComponent
                        type="primary"
                        disabled={!budget || !isBudgetValid}
                        size={'xSmall'}
                        text={'NEXT'}
                        onClick={onNextClick}
                    />
                </span>
            </section>
        </DynamicCreateCampaignWrapperContainer>
    );
};

ChooseBudgetContainer.propTypes = {
    setCampaignBudget: PropTypes.func.isRequired, 
    history: PropTypes.object.isRequired, 
    budgetValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    designation: PropTypes.oneOf(['onboarding', 'main'])
};

export default ChooseBudgetContainer;