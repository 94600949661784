import config from '../../config';
import { HeraldbeeApi } from '../../api/HeraldbeeApi';

// Actions

const SET_USER_AVATAR_URL = 'SET_USER_AVATAR_URL';
const SET_USER_AVATAR_ERROR = 'SET_USER_AVATAR_ERROR';
const SET_USER_AVATAR_IS_LOADING = 'SET_USER_AVATAR_IS_LOADING'

// Reducer

const initialState = {
    avatarUrl: null,
    avatarUploadError: null,
    isLoading: false
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SET_USER_AVATAR_URL:
            return {
                ...state,
                avatarUrl: action.url
            };

        case SET_USER_AVATAR_ERROR:
            return {
                ...state,
                avatarUploadError: action.error
            }

        case SET_USER_AVATAR_IS_LOADING:
            return {
                ...state,
                isLoading: action.value
            }

        default: return state;
    }
};
export default reducer;

// Action Creators

const actionSetUserAvatarUrl = url => ({ type: SET_USER_AVATAR_URL, url });
const actionSetUserAvatarError = error => ({ type: SET_USER_AVATAR_ERROR, error});
const actionSetUserAvatarIsLoading = value => ({ type: SET_USER_AVATAR_IS_LOADING, value });

// Methods

export const uploadUserAvatar = avatar => async dispatch => {
    dispatch(actionSetUserAvatarIsLoading(true));
    const hbApi = HeraldbeeApi({
        baseUrl: config.API_URL
    });

    const { value, error } = await hbApi.uploadUserAvatar(avatar);

    if (error) {
        if (value && value.error) {
            dispatch(actionSetUserAvatarError(value.error));
        } else {
            dispatch(actionSetUserAvatarError(error));
        };
    } else if (value) {
        if (value.error) {
            dispatch(actionSetUserAvatarError(value.error));
        } else if (value.value) {
            dispatch(actionSetUserAvatarUrl(value.value));
        };
    };
    return dispatch(actionSetUserAvatarIsLoading(false));
};

export const clearAvatarError = () => dispatch => {
    dispatch(actionSetUserAvatarError(null));
};
