import React, { useLayoutEffect, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types';
import FacebookAdAccountList from '../../../components/Facebook/FacebookAdAccountList';
import ReplacingViewSpinnerContainer from '../../SpinnerWrapper/ReplacingViewSpinnerContainer';
import FacebookAdAccountSelectionView from '../../../components/FacebookAdAccountSelection';
import { availableAdChannel } from '../../../utils/onboarding';

const FacebookAdAccountSelectionContainer = ({ listedAdAccounts, getAdAccountList, history, wasAgencyAccessRequestSentSuccessfully, redirectionUrl, askForAdAccountAgencyAccess, resetWasAgencyAccessRequestSentSeuccessfully, setSelectedAdChannel }) => {
    const [chosenAdAccount, setChosenAdAccount] = useState();
    const [isLoading, setIsContentLoading] = useState(true);
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [chosenAdAccountRejectedErrorText, setChosenAdAccountRejectedErrorText] = useState("");

    const onAdAccountSelectionConfirmationClick = () => {
        resetWasAgencyAccessRequestSentSeuccessfully();
        setIsRequestInProgress(true);
        askForAdAccountAgencyAccess({ chosenAdAccount });

    }

    useEffect(() => { setSelectedAdChannel(availableAdChannel.facebook) }, [setSelectedAdChannel]);

    useLayoutEffect(() => {
        if (!listedAdAccounts.length) {
            getAdAccountList();

        } else {
            setIsContentLoading(false);

        }

    }, [getAdAccountList, listedAdAccounts]);

    useEffect(() => {
        setIsRequestInProgress(false);
        
        if (wasAgencyAccessRequestSentSuccessfully === false) {
            setChosenAdAccountRejectedErrorText("Sorry, we couldn't connect with the selected ad account, please choose another one or contact our support for further help.");
            
        } else if (wasAgencyAccessRequestSentSuccessfully === true) {
            history.push(redirectionUrl);

        }

    }, [history, redirectionUrl, wasAgencyAccessRequestSentSuccessfully]);

    return (
        <FacebookAdAccountSelectionView
            isRequestInProgress={isRequestInProgress}
            chosenAdAccountRejectedErrorText={chosenAdAccountRejectedErrorText}
            onAdAccountSelectionConfirmationClick={onAdAccountSelectionConfirmationClick}
            isButtonDisabled={!chosenAdAccount}
        >
            <ReplacingViewSpinnerContainer spinnerSize="small" isLoading={isLoading} >
                <FacebookAdAccountList
                    setChosenAdAccount={setChosenAdAccount}
                    chosenAdAccount={chosenAdAccount}
                />
            </ReplacingViewSpinnerContainer>
        </FacebookAdAccountSelectionView>
        );

}

FacebookAdAccountSelectionContainer.propTypes = {
    listedAdAccounts: PropTypes.array,
    redirectionUrl: PropTypes.string.isRequired,
    history: PropTypes.object,
    getAdAccountList: PropTypes.func.isRequired,
    resetWasAgencyAccessRequestSentSeuccessfully: PropTypes.func.isRequired,
    setSelectedAdChannel: PropTypes.func.isRequired,
    askForAdAccountAgencyAccess: PropTypes.func.isRequired,
    wasAgencyAccessRequestSentSuccessfully: PropTypes.bool,
};


export default FacebookAdAccountSelectionContainer;