
import React from 'react';
import OverlayingSpinnerContainer from './../../../container/SpinnerWrapper/OverlayingSpinnerContainer/index';
import InfoButtonComponent from '../../InfoButtonComponent';
import MaterialButtonComponent from '../../MaterialComponents/MaterialButtonComponent';
import style from './index.module.less';
import { FacebookAdAccountSelectionTexts } from '../../../utils/texts';
import { PropTypes } from 'prop-types';



const FacebookAdAccountSelectionViewComponent = ({ children, isRequestInProgress, chosenAdAccountRejectedErrorText, onAdAccountSelectionConfirmationClick, isButtonDisabled }) => {
    const { title, subtitle, description } = FacebookAdAccountSelectionTexts;


    return <section className={style.adAccountSelection}>
        <OverlayingSpinnerContainer spinnerSize="large" isLoading={isRequestInProgress}>
            <div className={style.viewWrapper}>
                <header className={style.header}>
                    <span className={style.headerRow}>
                        <h1 className={style.headerTitle}><strong>{title}</strong></h1>
                    </span>
                    <div className={style.subtitleInfoButtonWrapper}><h2 className={style.headerSubtitle}>{subtitle}</h2><InfoButtonComponent
                        content={(
                            <p>
                                {description}
                                {/* <strong className={style.contentDescriptionBold}>You're just few clicks away from launching your ads!</strong> */}
                            </p>
                        )}
                        placement="left"
                    /></div>
                </header>
                <div className={style.loaderWrapper}>
                    {children}
                </div>
                {chosenAdAccountRejectedErrorText && <p className={style.errorText}>{chosenAdAccountRejectedErrorText}</p>}
                <div className={style.contentControls}>
                    <MaterialButtonComponent
                        onClick={onAdAccountSelectionConfirmationClick}
                        size="xLarge"
                        text="Confirm"
                        type="primary"
                        disabled={isButtonDisabled}
                    />
                </div>
            </div>
        </OverlayingSpinnerContainer>
    </section>;
}

FacebookAdAccountSelectionViewComponent.propTypes = {
    children: PropTypes.node.isRequired,
    isRequestInProgress: PropTypes.bool.isRequired,
    chosenAdAccountRejectedErrorText: PropTypes.string,
    onAdAccountSelectionConfirmationClick: PropTypes.func.isRequired,
    isButtonDisabled: PropTypes.bool.isRequired,

};


export default FacebookAdAccountSelectionViewComponent;



