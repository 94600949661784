import { makeStyles } from "@material-ui/core/styles";


export const materialStyleGenerator = () => makeStyles({
	genericStyle: {
		height: "36px",
		borderRadius: "4px"
	},
	/**Sizes */
	xSmallSize: {
		width: "93px",
		"& .MuiButton-label": {
			width: "40px",
			height: "16px",
			fontSize: "14px",
			fontWeight: "500",
			fontStyle: "normal",
			fontStretch: "normal",
			lineHeight: "1.14",
			letterSpacing: "1.2px",
			textAlign: "left"
		}
	},
	smallSize: {
		width: "132.5px"
	},
	mediumSize: {
		width: "196.2px",
		"& .MuiButton-label": {
			width: "61px",
			height: "16px"
		}
	},
	largeSize: {
		width: "181px",
		"& .MuiButton-label": {
			minWidth: "152px",
			height: "16px",
			fontSize: "14px",
			fontWeight: "500",
			fontStyle: "normal",
			fontStretch: "normal",
			lineHeight: "1.14",
			letterSpacing: "1.2px",
			textAlign: "left",
			color: "#54281f"
		}
	},
	xLargeSize: {
		width: "334.5px",
		boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.2)",
		backgroundColor: "#ffd742"
	},
	dynamicSize: {
		width: "100%"
	},
	/**Styles */
	primaryType: {
		boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.2)",
		backgroundColor: "#ffd742",
		fontWeight: "500",
		"&:hover": {
			backgroundColor: "#fae183"
		},
		"& .Mui-focusVisible": {
			backgroundColor: "#fae183"
		},
		"& .MuiTouchRipple-root": {
			color: "white"
		},
		"& .MuiButton-label": {
			fontFamily: "Roboto",
			fontSize: "14px",
			fontWeight: "500",
			fontStyle: "normal",
			fontStretch: "normal",
			lineHeight: "1.14",
			letterSpacing: "1.2px",
			textAlign: "left",
			color: "#54281f"
		}
	},
	secondaryType: {
		border: "solid 1px rgba(21,19,19,0.1)",
		backgroundColor: "#ffffff",
		boxShadow: "unset",
		color: "#edbf15",
		"& .MuiButton-label": {
			color: "#edbf15"
		},
		"&:hover": {
			backgroundColor: "#fcf6df"
		}
	},
	disabledType: {
		"& .MuiButton-label": {
			color: "#707070"
		}
	}
})