import config from "../config";
import { PricingTexts } from '../utils/texts';

const { devSubscriptionsPlans: {
  contents: { freeContent, basicContent, premiumContent },
  buttons: { freeButton, basicButton, premiumButton }

} } = PricingTexts;

const devSubscriptionPlans = Object.freeze([
  Object.freeze({
    id: 'free',
    name: 'Free',
    value: {
      textRepresentation: '0$',
      type: 'price'
    },
    description: 'per month',
    buttonText: freeButton,
    options: freeContent
  }),
  Object.freeze({
    id: 'basic',
    name: 'Basic',
    value: {
      textRepresentation: '49$',
      type: 'price'
    },
    description: 'per month',
    buttonText: basicButton,
    options: basicContent,
    highlighted: {
      text: 'Most popular',
      buttonPrimary: true
    }
  }),
  Object.freeze({
    id: 'premium',
    name: 'Premium',
    value: {
      textRepresentation: 'Individual price',
      type: 'other'
    },
    description: 'per month',
    buttonText: premiumButton,
    options: premiumContent
  })
]);

// TODO ...
const prdSubscriptionPlans = devSubscriptionPlans;

export default config && config.DEV ? devSubscriptionPlans : prdSubscriptionPlans;