// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { fetchCampaigns } from '../../../ducks/campaigns';
// Selectors
import { isUserIntegratedWithFacebookSelector } from '../../../utils/reduxSelectors';

import MainCreateCampaignRouterContainer from './MainCreateCampaignRouterContainer';

const mapStateToProps = state => ({
    isUserConnectedToFacebook: isUserIntegratedWithFacebookSelector(state)
});

const mapDispatchToProps = {
    fetchCampaigns
};

const MainCreateCampaignRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(MainCreateCampaignRouterContainer));

export default MainCreateCampaignRouter;