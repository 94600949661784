import { makeStyles } from "@material-ui/core/styles";
import { colorPalette, typography } from "../../styles/constants";

export const useStylesIcon = makeStyles({
    root: {
        transition: "all .3s",
        position: "relative",
        marginTop:23,
        marginRight: 20,
        padding:0,
       
        "& svg": {
            position: "absolute",
            transition: "all .3s"
        },

        "& .MuiIconButton-label": {
            width: "1.25em",
            height: "1.25em"
        },
    }
});

export const useModalTabsStyles = makeStyles({
    indicator : {
        backgroundColor: colorPalette.primaryYellow,
    }

})

export const useModalOneTabStyles = makeStyles({
    root : {
        color: colorPalette.primaryBrown,
        opacity: 0.6,
        fontSize: typography.body.fontSize,
        fontWeight: 500,
        lineHeight: 1.14,
        letterSpacing: 1.25,
        '&:focus': {
            color: colorPalette.primaryYellow,
        },
    },
    selected: {
        color: colorPalette.primaryYellow,
    },
})