import React from 'react';

//css
import styles from './index.module.less';

const PendingViewComponent = () => {
    return (
        <div className={styles.container}>
            <p className={styles.headerText}>No history yet.</p>
            <p className={styles.smallText}>The history appears once the campaign is active.</p>
        </div>
    )
}

export default PendingViewComponent;