import config from './config';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import app from './ducks/app';
import auth from './ducks/auth';
import avatar from './ducks/avatar';
import user from './ducks/user';
import google from './ducks/google';
import campaigns from './ducks/campaigns/index';
import conversions from './ducks/conversions';
import additionalCampaign from './ducks/additionalCampaign'
import campaignProducts from './ducks/campaignProducts/index';
import notifications from './ducks/notifications';
import feedFixer from './ducks/feedFixer';
import pricing from './ducks/pricing';
import setup from './ducks/setup';
import subscription from './ducks/subscription/index';
import payment from './ducks/payment';
import navigation from './ducks/navigation';
import campaignHistory from './ducks/campaignHistory/index';
import onboarding from './ducks/onboarding';
import facebook from './ducks/facebook';

import { recordAnalyticsPaths } from './utils/analytics';

export const history = createBrowserHistory();

export const historyListener = location => {
  !config.DEV && recordAnalyticsPaths(location);
};

export const LOG_OUT = 'LOG_OUT';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'pricing', 'setup', 'payment', 'onboarding'],
  stateReconciler: autoMergeLevel2
};

const appReducer = combineReducers({
  app,
  auth,
  avatar,
  user,
  google,
  campaigns,
  conversions,
  additionalCampaign,
  campaignProducts,
  campaignHistory,
  notifications,
  feedFixer,
  pricing,
  setup,
  subscription,
  payment,
  navigation,
  onboarding,
  facebook
});


const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    /** Clean each persisted persist storage key */
    storage.removeItem('persist:root');
    
    
    /** Here, I am taking advantage of the fact that reducers are supposed to return the initial state when called with undefined as the first argument, no matter the action type */
    state = undefined;
  }
  
  return appReducer(state, action);
};

const persistedReducers = persistReducer(persistConfig, rootReducer);

let enhancers;
if (config && config.DEV) {
  /* Defensive short-circuit eval to enable test runner to work */
  // Used for React Redux DevTools
  // https://github.com/zalmoxisus/redux-devtools-extension#usage
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancers = composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)));
} else {
  enhancers = applyMiddleware(thunk, routerMiddleware(history));
}

const store = createStore(
  connectRouter(history)(persistedReducers),
  enhancers
);

export const persistor = persistStore(store);

export default store;