// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { createCampaign } from '../../../../ducks/campaigns';
import { clearAddCampaignState } from '../../../../ducks/additionalCampaign/methods';

import FinishContainer from '../../../../components/SharedCreateCampaign/FinishContainer';

const mapStateToProps = state => ({
    countryCode: state.additionalCampaign.google.country,
    budgetValue: state.additionalCampaign.google.budget,
});

const mapDispatchToProps = {
    createCampaign,
    clearAddCampaignState
};

const FinishMain = withRouter(connect(mapStateToProps, mapDispatchToProps)(FinishContainer));

export default FinishMain;