/** Actions */


const SET_LAST_VISITED_PATH = 'SET_LAST_VISITED_PATH';


const initialState = {
	lastVisitedRoute: null
};

const reducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case SET_LAST_VISITED_PATH:
			return {
				...state,
				lastVisitedRoute: action.lastVisitedRoute
			}

		default:
			return state;
	}
}

export const actionSetLastVisitedPath = lastVisitedRoute => ({ type: SET_LAST_VISITED_PATH, lastVisitedRoute })
export default reducer;
