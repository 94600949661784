// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setSelectionSuccess } from '../../../ducks/onboarding'

import SelectionSuccessComponent from './SelectionSuccessComponent';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    setSelectionSuccess
};  

const SelectionSuccess = withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectionSuccessComponent));

export default SelectionSuccess;