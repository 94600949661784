// React
import React from 'react';
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods

import CampaignFacebookContainer from './CampaignFacebookContainer';

const mapStateToProps = state => ({
    campaigns: state.campaigns.content && state.campaigns.content.facebookCampaignsList,
});

const mapDispatchToProps = {

};

const CampaignFacebook = ({ campaigns, match }) => {

    const selectRequestedCampaign = () => {
        const requestedId = match.params.id;
        return campaigns.find(campaign => 
            campaign.id === requestedId
        );
    };

    const campaign = selectRequestedCampaign();

    return campaign 
        ? (
            <CampaignFacebookContainer campaign={campaign}/>
        ) : (
            <h1>No such campaign exists</h1>
        )
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignFacebook));