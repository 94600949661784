import React, { useMemo } from 'react';
import style from './index.module.less';
import { acceptedPaymentMethods } from '../../styles/constants';
import { PropTypes } from 'prop-types';
import MaterialButtonComponent from './../MaterialComponents/MaterialButtonComponent/index';
import PaymentMethodButtonComponent from './../PaymentMethodButtonComponent/index';
import VendorLogoComponent from '../VendorLogoComponent';
import clsx from 'clsx';

const PaymentMethodsComponent = ({ decideNavigationTarget, decideIfHighlighted, selectCardMethod, selectPayPalMethod, onBackClick, onboardingMode }) => {

    const PayPalLogo = () => useMemo(() => <VendorLogoComponent vendorName={"PayPal"} />, []);
    const DoubleCardLogo = () => useMemo(() =>
        <>
            <VendorLogoComponent additionalStyle={style.visaLogoShift} vendorName={"Visa"} />
            <VendorLogoComponent vendorName={"MasterCard"} />
        </>
        , []);
    const appropriateMarginStyle = onboardingMode ? style.lowMargin : style.highMargin;
    
    return (
        <div className={style.buttonsNavWrapper}>
            <div className={clsx(style.paymentSelectionButtonComponent, appropriateMarginStyle)}>
                <div className={(style.paymentOptions)}>
                    <PaymentMethodButtonComponent
                        onClick={selectCardMethod}
                        buttonStyle={decideIfHighlighted(acceptedPaymentMethods.paymentCard)}
                        title={acceptedPaymentMethods.paymentCard}
                        logo={<DoubleCardLogo />}
                    />
                    <PaymentMethodButtonComponent
                        buttonStyle={decideIfHighlighted(acceptedPaymentMethods.payPal)}
                        onClick={selectPayPalMethod} title={acceptedPaymentMethods.payPal} logo={<PayPalLogo />}
                    />
                </div>
                <div className={style.navigationRow} >
                    <MaterialButtonComponent
                        text="BACK"
                        type="secondary"
                        size="xSmall"
                        onClick={onBackClick}
                    />
                    <MaterialButtonComponent
                        text="NEXT"
                        type="primary"
                        size="xSmall"
                        onClick={decideNavigationTarget}
                    />
                </div>
            </div>
        </div>
    )
};

PaymentMethodsComponent.propTypes = {
    decideNavigationTarget: PropTypes.func.isRequired,
    decideIfHighlighted: PropTypes.func.isRequired,
    selectCardMethod: PropTypes.func.isRequired,
    selectPayPalMethod: PropTypes.func.isRequired,
    onBackClick: PropTypes.func.isRequired,
};

export default PaymentMethodsComponent;