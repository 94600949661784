import { connect } from 'react-redux';
import moment from 'moment';
//Methods
import { fetchCampaignHistory } from '../../ducks/campaignHistory';

import CampaignHistoryContainer from './CampaignHistoryContainer';

const campaignHistorySelector = state => {
    return state.campaignHistory.history.map(element => {
        return { 
            ...element,
            day: moment(element.date).day(),
            date: moment(element.date).format('YYYY MM DD')
        } 
    })
}

const mapStateToProps = (state, ownProps )=> ({
    campaignHistory: campaignHistorySelector(state),
    campaignStatus : state.campaigns.content.googleCampaignsList.find(campaign => campaign.id === ownProps.campaignId).status
})

const mapDispatchToProps = {
    fetchCampaignHistory,
}

const CampaignHistory = connect(mapStateToProps, mapDispatchToProps)(CampaignHistoryContainer)

export default CampaignHistory

