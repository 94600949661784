// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// Third Party Modules
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import styles from './index.module.less';
import { useStyles } from './index.material';

const MaterialBreadcrumbsComponent = ({ breadcrumbLinkArray, className }) => {

    const classes = useStyles();

    /**
     * Integrating Material-UI Link with React-Router to omit releoading of the whole page 
     */
    const LinkRouter = props => <Link {...props} component={RouterLink} />;

    const Separator = (
        <span className={styles.separator}>
            <NavigateNextIcon className={`${classes.icon} ${classes.iconLeft}`} />
            <NavigateNextIcon className={`${classes.icon} ${classes.iconRight}`}/>
        </span>
    )

    return (
        <Breadcrumbs className={className} separator={Separator} aria-label="breadcrumbs">
            {
                breadcrumbLinkArray.map((breadcrumbObject, i) => 
                    breadcrumbObject.link 
                        ? (
                            <LinkRouter 
                                className={classes.link}
                                component={RouterLink}
                                to={breadcrumbObject.link}
                                key={i}
                            >
                                {breadcrumbObject.textContent}
                            </LinkRouter>
                        ) : (
                            <p className={styles.text} key={i}>{breadcrumbObject.textContent}</p>
                        )
                )
            }
        </Breadcrumbs>
    );
};

MaterialBreadcrumbsComponent.propTypes = {
    /**
     * @WARNING position of object in an array determines it's position in the breadcrumb
     */
    breadcrumbLinkArray: PropTypes.arrayOf(PropTypes.shape({
        textContent: PropTypes.string.isRequired,
        link: PropTypes.string || null
    })).isRequired
};

export default MaterialBreadcrumbsComponent;