import config from '../../config';
import { actionSetError, actionSetNondestructiveErrorMessage } from '../app';
import { HeraldbeeApi } from '../../api/HeraldbeeApi';
import { actionSetLastFourPaymentCardDigitsForSummary, actionSetPaymentCardVendorNameForSummary, actionSetActivePaymentMethodType } from '../payment';
import { translateCardVendorName } from '../../utils/mainApp';
import { acceptedPaymentMethods } from '../../styles/constants';
// Actions

const LOAD_SUBSCRIPTION = 'LOAD_SUBSCRIPTION';
const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
const ERROR_SUBSCRIPTION = 'ERROR_SUBSCRIPTION';
const SET_CONFIRM_PAYMENT_AUTH_UPDATE = 'SET_CONFIRM_PAYMENT_AUTH_UPDATE';
const SET_NO_CARD_DETAILS_AVAILABLE = 'NO_CARD_DETAILS_AVAILABLE';
const SET_SHOULD_SUMMARY_KNOW_OF_UPDATE = 'SET_SHOULD_SUMMARY_KNOW_OF_UPDATE';
const SET_UPDATE_MESSAGE = 'SET_UPDATE_MESSAGE';
const SET_PLAN_CHANGE_DECLINED = 'SET_PLAN_CHANGE_DECLINED';
const SET_SUBSCRIPTION_VALUE_ESTIMATE = 'SET_SUBSCRIPTION_VALUE_ESTIMATE';
const SET_PAYPAL_EMAIL = 'SET_PAYPAL_EMAIL';
// Reducer

const initialState = {
  loader: true,
  content: null,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_SUBSCRIPTION:
      return {
        ...state, loader: true
      }

    case SET_SUBSCRIPTION:
      return {
        ...state, loader: false, content: action.content
      }
    case ERROR_SUBSCRIPTION:
      return {
        ...state, loader: false, content: action.error, error: true
      }
    case SET_CONFIRM_PAYMENT_AUTH_UPDATE:
      return {
        ...state, paymentAuthSuccessful: action.status
      }
    case SET_NO_CARD_DETAILS_AVAILABLE:
      return {
        ...state, noCardDetailsAvailable: true
      }
    case SET_SHOULD_SUMMARY_KNOW_OF_UPDATE:
      return {
        ...state, summaryShouldKnowOfCardUpdate: action.boolean
      }
    case SET_UPDATE_MESSAGE:
      return {
        ...state, updateMessage: action.updateMessage
      }
    case SET_PLAN_CHANGE_DECLINED:
      return {
        ...state, planChangeDeclinedMessage: action.message
      }
    case SET_SUBSCRIPTION_VALUE_ESTIMATE:
      return {
        ...state, subscriptionValueEstimate: action.subscriptionValueEstimate
      }
    case SET_PAYPAL_EMAIL: 
    return {
        ...state, payPalEmail:action.payPalEmail
    }
    default: return state;
  }
}
export default reducer;

// Action Creators
export const actionSetUpdateMessage = updateMessage => ({ type: SET_UPDATE_MESSAGE, updateMessage });

export const actionSetShouldSummaryKnowOfCardUpdate = boolean => ({ type: SET_SHOULD_SUMMARY_KNOW_OF_UPDATE, boolean });
export const actionSetNoCardDetailsAreAvailable = () => ({ type: SET_NO_CARD_DETAILS_AVAILABLE });
const actionLoadSubscription = () => ({ type: LOAD_SUBSCRIPTION });
const actionSetSubscription = content => ({ type: SET_SUBSCRIPTION, content });
const actionErrorSubscription = error => ({ type: ERROR_SUBSCRIPTION, error });
export const actionConfirmSuccessfulPaymentAuthUpdate = status => ({ type: SET_CONFIRM_PAYMENT_AUTH_UPDATE, status })
export const actionSetSubscriptionValueEstimate = ({ subscriptionValueEstimate }) => ({ type: SET_SUBSCRIPTION_VALUE_ESTIMATE, subscriptionValueEstimate });
export const actionSetPayPalAttachedEmail = ({payPalEmail}) => ({type: SET_PAYPAL_EMAIL, payPalEmail});
// Store Methods

export const fetchSubscription = shopId => async dispatch => {
  dispatch(actionLoadSubscription());
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL,
  });
  const { value, error } = await hbApi.fetchSubscription(shopId);

  if (error) {
    if (error.name === HeraldbeeApi.ERROR_UNAUTHORISED) {
      dispatch(actionErrorSubscription(error));
      // logOut()(dispatch);
      /** I am leaving this method out, as it clears my woocommerce
       * token in response to the 401 from backend.
       * New actionErrorSubscription handles the failed subscription case instead.
       */
    } else {
      dispatch(actionErrorSubscription(error));
    }
    return;
  }
  dispatch(actionSetSubscription(value));
}

// Simple Methods

export const activateSubscription = (shopId, planId, clientNonce, customerEmail, paymentMethod) => async dispatch => {
  dispatch(actionLoadSubscription());

  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL,
  });
  const { error } = await hbApi.activateSubscription(shopId, planId, clientNonce, customerEmail, paymentMethod);

  if (error) {
    dispatch(actionSetError(error))
    return dispatch(actionSetSubscription(null))
  }

  fetchSubscription(shopId)(dispatch);
}

export const getPaymentAuthDetails = ({ subscriptionId }) => async dispatch => {
  const { getPaymentCardDetailsHbApi } = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { error: paymentDetailsError, value } = await getPaymentCardDetailsHbApi({ subscriptionId });
  const paymentDetails = value && value.paymentDetails;
  if (paymentDetailsError) {
    return dispatch(actionSetError(paymentDetailsError));

  } else {
   if(paymentDetails) {
     if(paymentDetails.payment_source.paypal) {
          const {payment_source: {paypal: {email:payPalEmail}}} = paymentDetails;
        dispatch(actionSetPayPalAttachedEmail({payPalEmail}))
        dispatch(actionSetLastFourPaymentCardDigitsForSummary(null));
        return dispatch(actionSetPaymentCardVendorNameForSummary(null));
      } else if (paymentDetails.payment_source.card) {
      let { payment_source: { card: { last4: lastFourDigits, brand: cardVendorName } } } = paymentDetails;
      cardVendorName = translateCardVendorName({ cardVendorName });
      dispatch(actionSetLastFourPaymentCardDigitsForSummary(lastFourDigits));
      dispatch(actionSetActivePaymentMethodType({ paymentType: acceptedPaymentMethods.paymentCard }));
      return dispatch(actionSetPaymentCardVendorNameForSummary(cardVendorName));

    }  
    } else {
      dispatch(actionSetNoCardDetailsAreAvailable());
      dispatch(actionSetLastFourPaymentCardDigitsForSummary(null));
      return dispatch(actionSetPaymentCardVendorNameForSummary(null));

   }
};
}

export const changeSubscriptionPlan = (subscriptionId, planId, shopId) => async dispatch => {
  const hbApi = HeraldbeeApi({
    baseUrl: config.API_URL
  });
  const { error, value } = await hbApi.changeSubscriptionPlan(subscriptionId, planId);

  if (error) {
    return dispatch(actionSetError(error));
  } else {
    if (value !== "true") {
      dispatch(actionSetNondestructiveErrorMessage({ displayedErrorMessage: "We are sorry, subscription plan change was not successful.", error: value }));

    } else {
      dispatch(actionSetNondestructiveErrorMessage({ displayedErrorMessage: null, error: null }));
      dispatch(fetchSubscription(shopId));

    }
  }
};

export const changePaymentAuth = ({ subscriptionId, clientNonce, shopId, paymentMethod }) => async dispatch => {
  const { changePaymentAuth } = HeraldbeeApi({ baseUrl: config.API_URL });

  const { error: paymentUpdateError } = await changePaymentAuth({ subscriptionId, clientNonce ,paymentMethod});

  if (paymentUpdateError) {
    return dispatch(actionSetError(paymentUpdateError));
  } else {
    return dispatch(actionConfirmSuccessfulPaymentAuthUpdate(true));
    /** Update subscription object, in order to display latest card details at Account Summary */
    // dispatch(fetchSubscription(shopId));
  }
};

export const getSubscriptionValueEstimate = ({ planId }) => async dispatch => {
  const { getSubscriptionValueEstimate } = HeraldbeeApi({ baseUrl: config.API_URL });

  const { value: subscriptionValueEstimate, error: chargebeeConnectionError } = await getSubscriptionValueEstimate({ planId });

  if (chargebeeConnectionError) {
    return dispatch(actionSetError(chargebeeConnectionError));


  } else {
    dispatch(actionSetSubscriptionValueEstimate({ subscriptionValueEstimate }));
  }

};

export const clearPayPalEmail = () => dispatch => dispatch(actionSetPayPalAttachedEmail({ payPalEmail: null }));
