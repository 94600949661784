import moment from 'moment-timezone';
import config from '../config';

export const initIntercom = () => {
  if (!config.DEV) {
    let w = window; let ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { let d = document; let i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; let l = function () { let s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + config.INTERCOM_ID; let x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } };
    // w.intercomSettings.custom_launcher_selector = '.open_intercom';
  }
}
export const setIntercomUser = (user, subscription, shop, planId) => {
  /** In case there's no user, get the current timestamp */
  const userCreationTimestampInSeconds = user ? moment(user.createdAt).unix() : moment().unix();
  /** If there's no subscription, subscriptionName will be undefined */
  const subscriptionName = (subscription && subscription.plan_id) || planId;
  const subscriptionCost = subscription && !isNaN(subscription.plan_unit_price) && subscription.plan_unit_price / 100;
  const subscriptionStatus = subscription && subscription.status;

  const custom_launcher_selector = '.OPEN_INTERCOM';

  const intercomGuest = {
    custom_launcher_selector,
    app_id: config.INTERCOM_ID,
    name: "Guest",
    created_at: userCreationTimestampInSeconds,
    company: {
      id: 0,
      created_at: userCreationTimestampInSeconds,
      plan: subscriptionName,
      monthly_spend: subscriptionCost,
      custom_attributes: {
        plan_status: subscriptionStatus
      }
    }
  };

  window.Intercom('boot', !user || !shop ? intercomGuest : {
    custom_launcher_selector,
    app_id: config.INTERCOM_ID,
    email: shop.email,
    user_id: user.username,
    name: shop.name,
    created_at: userCreationTimestampInSeconds,
    company: {
      id: shop.domain,
      name: shop.domain,
      website: shop.domain,
      created_at: userCreationTimestampInSeconds,
      plan: subscriptionName,
      monthly_spend: subscriptionCost,
      custom_attributes: {
        plan_status: subscriptionStatus
      }
    }
  });
};



