import config from '../config';
import { processResponseJson } from './auth';

// Actions

const FETCH_FEED_PRODUCTS_PREVIEW = 'FETCH_FEED_PRODUCTS_PREVIEW';
const SET_FEED_PRODUCTS_PREVIEW = 'SET_FEED_PRODUCTS_PREVIEW';
const ERROR_FEED_PRODUCTS_PREVIEW = 'ERROR_FEED_PRODUCTS_PREVIEW';

// Reducer

const initialState = {
  productsPreview: {
    fetch: true,
    loader: true,
    error: null,
    content: []
  }
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {    
    case FETCH_FEED_PRODUCTS_PREVIEW:
      return {
        ...state,
        productsPreview: { ...state.productsPreview, fetch: false, loader: true, error: null }
      }
    
    case SET_FEED_PRODUCTS_PREVIEW:
      return {
        ...state,
        productsPreview: { 
          ...state.productsPreview, 
          loader: false, 
          content: action.content
        }
      }

    case ERROR_FEED_PRODUCTS_PREVIEW:
      return {
        ...state,
        productsPreview: { ...state.productsPreview, loader: false, error: action.error }
    }

    default: return state;
  }
}
export default reducer;

// Action Creators

const actionFetchProductsPreview = () => ({ type: FETCH_FEED_PRODUCTS_PREVIEW });
const actionSetProductsPreview = content => ({ type: SET_FEED_PRODUCTS_PREVIEW, content });
const actionErrorProductsPreview = error => ({ type: ERROR_FEED_PRODUCTS_PREVIEW, error });

// Methods
export const fetchProductsPreview = () =>
  dispatch => {
    dispatch(actionFetchProductsPreview());
    return fetch(`${config.API_URL}/legacy/google/mc/products?maxResults=250`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain, text/html, */*',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      credentials: 'include'
    }).then(response => processResponseJson(response)(dispatch))
      .then(json => {
        const content = json.resources;
        const okContent = !!content ? content.filter(product => {
          const shoppingDestination = !!product.product && product.product.channel === 'online' && product && product.destinationStatuses && product.destinationStatuses.find(destStatus => destStatus.destination === 'Shopping');
          return !!shoppingDestination && (shoppingDestination.approvalStatus !== 'disapproved' || shoppingDestination.approvalPending);
        }) : [];
        dispatch(actionSetProductsPreview(okContent));
      }).catch(error => dispatch(actionErrorProductsPreview(error)));
  }