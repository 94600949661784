// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { changeSubscriptionPlan } from '../../../ducks/subscription';

import AccountSubscriptionContainer from './AccountSubscriptionContainer';
import { shopIdSelector } from '../../../utils/reduxSelectors';
import { setNondestructiveErrorMessage } from '../../../ducks/app';



const mapStateToProps = state => ({
    subscription: state.subscription,
    shopId: shopIdSelector(state.auth.user),
    nondestructiveErrorMessage: state.app.nondestructiveErrorMessage
});

const mapDispatchToProps = {
    changeSubscriptionPlan,
    setNondestructiveErrorMessage
};

const AccountSubscription = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSubscriptionContainer));

export default AccountSubscription;