// React
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { CheckCircleOutline, PauseCircleOutline, Schedule } from '@material-ui/icons';
import moment from 'moment';

// Custom Components
import MaterialBreadcrumbsComponent from '../../../../../components/MaterialComponents/MaterialBreadcrumbsComponent';
import CampaignUnsupportedComponent from '../../../../../components/CampaignDetails/CampaignUnsupportedComponent';

// Custom Modules
import { googleAdsLocations } from '../../../../../constants/googleAdsLocations';

// Assets
import FBLogo from '../../../../../images/onboarding/fb-logo.png';

// Stylesheet
import style from './index.module.less';

const CampaignFacebookContainer = ({ campaign }) => {
    const linkArray = [
        {
            textContent: 'Back to Campaigns list',
            link: '/campaigns'
        }, 
        {
            textContent: campaign.name,
            link: null
        }
    ];

    const StatusIcon = () => {
        switch (campaign.status) {
            case 'ENABLED':
                return (
                    <CheckCircleOutline />
                );
            case 'PAUSED':
                return (
                    <PauseCircleOutline />
                );
            case 'PENDING':
                return (
                    <Schedule />
                );
            default:
                return (
                    <Schedule />
                );
        }
    };

    const variedColor = () => {
        return campaign.status === 'ENABLED' ? style.green : style.gray;
    };

    const statusColor = () => {
        return campaign.status === 'PENDING' ? style.inactive : variedColor();
    }

    const translateCountryCode = () => {
        return googleAdsLocations.find(country => 
            country.value === campaign.salesCountryCode
        ).name;
    };
    
    return (
        <section className={style.singleCampaignWrapper}>
            <header className={style.container}>
            <MaterialBreadcrumbsComponent breadcrumbLinkArray={linkArray} />
                <div className={style.details}>
                    <div className={style.detailsType}>
                        <img className={style.detailsTypeImg} src={FBLogo} alt="FB logo"></img>
                    </div>
                    <div className={style.detailsLeft}>
                        <span className={style.detailsLeftTitle}>
                            <span className={style.detailsLeftTitleName}>
                                <img className={style.detailsLeftTitleNameIcon} src={FBLogo} alt="FB Logo" />
                                <h2 className={`h2-sm ${style.detailsLeftTitleNameHeader}`}>{campaign.name}</h2>
                            </span>
                            <span className={`${style.detailsLeftTitleDetails} ${statusColor()}`}>
                                {StatusIcon()}
                                <p className={style.detailsLeftTitleDetailsText}>{campaign.status}</p>
                            </span>
                        </span>
                        <span className={style.detailsLeftStats}>
                            <span className={style.detailsLeftStatsCountry}>
                                <p className={style.description}>
                                    <span className={style.descriptionOpaque}>COUNTRY</span>
                                    <span className={style.valueSmall}>{` ${translateCountryCode()}`}</span>
                                </p>
                            </span>
                            <span className={style.detailsLeftStatsCreated}>
                                <p className={style.description}>
                                    <span className={style.descriptionOpaque}>CREATED</span>
                                    <span className={style.valueSmall}>{` ${moment(campaign.startTime).format('YYYY/MM/DD')} `}</span>
                                </p>
                            </span>
                        </span>
                    </div>
                    <div className={style.detailsRight}>
                        <div className={style.detailsRightStat}>
                            <p className={`${style.statValue} ${statusColor()}`}>0</p>
                            <p className={`${style.statName} ${statusColor()}`}>CICKS</p>
                        </div>
                        <div className={style.detailsRightStat}>
                            <p className={`${style.statValue} ${statusColor()}`}>0</p>
                            <p className={`${style.statName} ${statusColor()}`}>IMPRESSIONS</p>
                        </div>
                        <div className={style.detailsRightStat}>
                            <p className={`${style.statValue} ${statusColor()}`}>{`$0`}</p>
                            <p className={`${style.statName} ${statusColor()}`}>COST</p>
                        </div>
                    </div>
                </div>
            </header>
            <main>
                <CampaignUnsupportedComponent />
            </main>
        </section>
    );
};

CampaignFacebookContainer.propTypes = {
    campaign: PropTypes.object.isRequired,
};

CampaignFacebookContainer.defaultProps = {
    campaign: {
        name: 'Campaign Name',
        status: 'PENDING',
        salesCountryCode: 'US',
        startTime: null,
        clicks: 0,
        impressions: 0,
        cost: 0
    }
}

export default CampaignFacebookContainer;