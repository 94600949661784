// React
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
// Methods
import { fetchCampaigns } from "../../ducks/campaigns";

// Selectors
import { areConversionsAvailableSelector } from "../../utils/reduxSelectors";

import CampaignsContainer from "./Campaigns";



const mapStateToProps = state => ({
  campaignsList: state.campaigns.content,
  campaignsFetchBool: state.campaigns.fetch,
  user: state.auth.user,
  platform: state.auth.platform,
  areConversionsAvailable: areConversionsAvailableSelector(state),
  campaignHasBeenCreated: state.additionalCampaign.campaignHasBeenCreated
});

const mapDispatchToProps = {
  fetchCampaigns
};

const Campaigns = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignsContainer)
);

export default Campaigns;
