// React
import React from 'react'
import PropTypes from 'prop-types'

// Custom Components
import MaterialButtonComponent from '../../../MaterialComponents/MaterialButtonComponent/index';

// Stylesheet
import style from './index.module.less';

const PricingPlanButtonComponent = ({ children, highlighted, onClick }) => {
  const buttonType = highlighted ? 'primary' : 'secondary';

  return (
    <div className={style.buttonContainer}>
      <MaterialButtonComponent
        type={buttonType}
        text={children}
        onClick={onClick}
      />
    </div>
  )
}

PricingPlanButtonComponent.propTypes = {
  children: PropTypes.node,
  highlighted: PropTypes.bool,
  onClick: PropTypes.func
}

export default PricingPlanButtonComponent

