// React
import { withRouter } from 'react-router';

// Redux
import { connect } from 'react-redux';
// Methods

import OnboardingSelectPaymentContainer from './OnboardingPaymentMethodSelectionContainer/index';

const mapStateToProps = state => ({
    selection: state.onboarding.selectedAdChannel
});

const mapDispatchToProps = {

};

const OnboardingPaymentMethodSelection = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingSelectPaymentContainer));


export default OnboardingPaymentMethodSelection;