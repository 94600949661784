// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import config from '../../config';

// Assets
import LogoHeraldbee from '../../images/logo_hb_reversed.svg';
import logoForbes from '../../images/logo_forbes.svg';
import logoGooglePartner from '../../images/GPartner.jpg';

// Stylesheet
import style from './index.module.less';

const OnboardingContainer = ({ children, displayMode = 'showHeaderAlways' }) => {
    return (
        <>
            <header className={style[`${displayMode}Header`]}>
                {displayMode === 'showHeaderMobile' &&
                    <div className={style.headerHbPartners}>
                        <img className={style.headerLogoPartnerG} src={logoGooglePartner} alt="Our Main Partner Logo" height="21px" />
                        <img className={style.headerLogoPartnerForbes} src={logoForbes} alt="Forbes Logo" height="31px" />
                    </div>
                }
                <img className={style.headerLogo} src={LogoHeraldbee} alt={config.APP_NAME} height="37px" />
            </header>
            <main className={style[`${displayMode}ContentContainer`]}>
                {children}
            </main>
        </>
    );
};

OnboardingContainer.propTypes = {
    children: PropTypes.node,
    displayMode: PropTypes.oneOf(['showHeaderAlways', 'showHeaderMobile'])
};

export default OnboardingContainer
