// React
import React, { useEffect } from 'react';

// Custom Modules
import { getSearchParam } from '../../../utils/utils';
import { sendAmplitudeEvent } from '../../../utils/amplitude';
import { amplitudeEvents } from '../../../constants/amplitudeEvents';

// Custom Components
import AppSpinner from '../../../components/AppSpinner';

const SaveUtm = ({ history, location }) => {
    useEffect(() => {
        const redirect = getSearchParam(location.search, 'redirect');

        if (redirect) {
            sendAmplitudeEvent(amplitudeEvents.APP_CAMPAIGN_REDIRECT, {
                redirectUrl: redirect
            });
            window.location.href = redirect;
        } else {
            history.push('/');
        }
    }, [])

    return <AppSpinner />
};

export default SaveUtm;
