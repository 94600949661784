import React, { useState } from 'react';
import PropTypes from 'prop-types';

//material-ui components
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';

//custom Components
import FirstStepComponent from '../../components/ConversionsModalSteps/FirstStepComponent';
import SecondStepComponent from '../../components/ConversionsModalSteps/SecondStepComponent';
import ThirdStepComponent from '../../components/ConversionsModalSteps/ThirdStepComponent';

//utils text
import { modalText } from '../../utils/texts'
//styles
import style from './index.module.less';
import { useModalTabsStyles, useModalOneTabStyles, useStylesIcon } from './index.material';

const ConverionsModalBodyContainer = ({isVisible, scriptTag, handleModalClose}) => {

    const tabsStyles = useModalTabsStyles()
    const oneTabStyle = useModalOneTabStyles()
    const styleIcon = useStylesIcon()
    const [step, setStep] = useState(0);
    

    const handleTabChange = (event, newStepValue) =>  {
        setStep(newStepValue) 
    }

    const handleCopyClick = async () => {
       await navigator.clipboard.writeText(scriptTag);
    }

    const showCurrentStep = () => {
        switch(step){
            case 2:
                return <ThirdStepComponent/>
            case 1:
                return <SecondStepComponent/>
            default:
                return <FirstStepComponent onCopyButtonClick={handleCopyClick} scriptTag={scriptTag}/>
        }
    }
    return (
        <Dialog
            onClose={handleModalClose}
            open={isVisible}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <header className={style.header}>
                <div className={style.description}>
                    <p className={style.descriptionText}>{modalText.modalHeaderText}</p>
                    <p className={style.text}>{modalText.modalSecondaryText}</p>
                </div>
                <div>
                    <IconButton className={styleIcon.root} onClick={handleModalClose}>
                        <Clear />
                    </IconButton>   
                </div>
            </header>
            <section className={style.tabView}>
                <header>
                    <Tabs
                        value={step}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        classes={tabsStyles}
                    >
                        <Tab label="Step 1" classes={oneTabStyle}/>
                        <Tab label="Step 2" classes={oneTabStyle}/>
                        <Tab label="Step 3" classes={oneTabStyle}/>
                    </Tabs>
                    <hr className={style.line}/>
                </header>
                { showCurrentStep() }
            </section>
            <footer className={style.footer}>
                <p className={style.footerText}><span className={`OPEN_INTERCOM ${style.link}`}>Let us know</span> in case of any issues</p>
            </footer>
        </Dialog>
    )
}

ConverionsModalBodyContainer.propTypes = {
    isVisible: PropTypes.bool,
    scriptTag: PropTypes.string,
    handleModalClose: PropTypes.func,
}

export default ConverionsModalBodyContainer
