import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchBraintreeToken, createCustomerWithPaymentAuthorization, setFirstAcceptedPaymentMethodType, validateBraintreeClientInstanceEnvelope, setPayPalButtonsInitialized, clearAlreadyCachedPaymentCardDetails, validatePayPalButtonInstance } from './../../ducks/payment';
import PayPalContainer from './PayPalContainer';

const mapStateToProps = state => ({
  braintreeToken: state.payment.braintreeToken,
  payPalButtonsInitialized: state.payment.payPalButtonsInitialized,
  firstAcceptedPaymentMethodType: state.payment.firstAcceptedPaymentMethodType,
  clientNonce: state.payment.clientNonce,
  selection: state.onboarding.selectedAdChannel
});

const mapDispatchToProps = {
  fetchBraintreeToken,
  createCustomerWithPaymentAuthorization,
  setFirstAcceptedPaymentMethodType,
  validateBraintreeClientInstanceEnvelope,
  setPayPalButtonsInitialized,
  clearAlreadyCachedPaymentCardDetails,
  validatePayPalButtonInstance
};

const PayPal = withRouter(connect(mapStateToProps, mapDispatchToProps)(PayPalContainer));

export default PayPal;