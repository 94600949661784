import { connect } from 'react-redux';

import { getConversionScriptTag } from '../../ducks/conversions';

import ConversionModalContainer from './ConversionModalContainer';

const mapDispatchToProps = {
    getConversionScriptTag,
};

const ConversionModal = connect(null, mapDispatchToProps)(ConversionModalContainer);

export default ConversionModal;