import React from 'react'
import PropTypes from 'prop-types'
import PaymentSelectionButtons from '../../../components/PaymentSelectionButtons'
import { useState } from 'react';
import { acceptedPaymentMethods } from '../../../styles/constants';
import style from './index.module.less';

const PaymentMethodSelectionContainer = ({ setConfirmedPaymentMethodForEdition, paymentMethodForEdition, onboardingMode, PaymentMethodSelectionConfigObject: { PayPalAuthComponent, CardAuthComponent, onBackClick } }) => {

	/** Config objects are provided by decorators, currently at AccountEditPayment and OnboardingSelectPayment:
	 *  API:
	 * PayPalAuthComponent: component to handle PayPal auth manipulation at a given location
	 * CardAuthComponent: component to handle Payment Card auth manipulation at a given location
	 * onBackClick: callback deciding where will the user be navigated to when clicking 'back' button at a given location 
	 */
    const [selectedMethod, setSelectedMethod] = useState(acceptedPaymentMethods.paymentCard);
    const { payPal, paymentCard } = acceptedPaymentMethods;
    const returnToPaymentMethodSelection = () => setConfirmedPaymentMethodForEdition(null);
    const payPalButtonsTopMarginStyle = !onboardingMode ? style.payPalButtonsTopMargin : null;

    const decideRenderOutput = () => {
        switch (paymentMethodForEdition) {
            case paymentCard:
                return <CardAuthComponent returnToPaymentMethodSelection={returnToPaymentMethodSelection} />
            case payPal:
                return <PayPalAuthComponent payPalButtonsTopMarginStyle={payPalButtonsTopMarginStyle} onboardingMode={onboardingMode} returnToPaymentMethodSelection={returnToPaymentMethodSelection} />
            default:
                return <PaymentSelectionButtons onboardingMode={onboardingMode} setConfirmedMethod={setConfirmedPaymentMethodForEdition} selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} onBackClick={onBackClick} />
        }
    }
    return decideRenderOutput();
}

PaymentMethodSelectionContainer.propTypes = {
    PaymentMethodSelectionConfigObject: PropTypes.object.isRequired,
    version: PropTypes.string,
    setConfirmedPaymentMethodForEdition: PropTypes.func.isRequired,
    paymentMethodForEdition: PropTypes.string,
}

export default PaymentMethodSelectionContainer

