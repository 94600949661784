// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux'
    // Methods
    import { setOnboardingGoogleCreateCampaignField as setCampaignBudget } from '../../../../ducks/onboarding'

import ChooseBudgetContainer from '../../../../components/SharedCreateCampaign/ChooseBudgetContainer';

const mapStateToProps = state => ({
    budgetValue: state.onboarding.google.createCampaign.budget
});

const mapDispatchToProps = {
    setCampaignBudget
};

const ChooseBudget = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseBudgetContainer));

export default ChooseBudget;