// React
import React from 'react';
import PropTypes from 'prop-types';

// Third Party Modules
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Stylesheet
import style from './index.module.less';
import { colorPalette } from '../../../../styles/constants';

const OnboardingAgreementComponent = ({ status, onChange }) => {
    
    const useStyleContainer = makeStyles({
        root: {
                margin: '25px 0 25px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
        }
    });

    const useStyleCheckbox = makeStyles({
        root: {
            marginLeft: '10px',
            color: `rgba(${colorPalette.primaryBrownRGB},0.5)`,

            '&$checked': {
                color: colorPalette.primaryYellow,
            }
        },
        checked: {}
    })

    const containerClasses = useStyleContainer();
    const checkboxClasses = useStyleCheckbox();
    
    const handleChange = () => {
        onChange(!status);
    };

    const Label = () => (
        <p className={style.policy}>
            By creating an account I agree to the 
            <a className={style.policyLink} href="https://heraldbee.com/terms-of-service/" target="blank"><strong> Terms of Service </strong></a>
            and
            <a className={style.policyLink} href="https://heraldbee.com/privacy-policy/" target="blank"><strong> Privacy Policy</strong></a>
        </p>
    );

    const CustomCheckbox = (
        <Checkbox
            classes={checkboxClasses}
            color="default"
            checked={status}
            onChange={handleChange}
        />
    );

    return (
        <FormControl required className={containerClasses.root}>
            <FormControlLabel
                control={CustomCheckbox}
                labelPlacement="end"
            />
            <Label />
        </FormControl>
    )
};

OnboardingAgreementComponent.propTypes = {
    status: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

export default OnboardingAgreementComponent;
