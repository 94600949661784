import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd';
import OnboardingContainer from '../../../container/OnboardingContainer/index';
import CreateCampaignStepper from '../../MaterialComponents/OnboardingMaterialComponents/CreateCampaignStepperComponent/index';
import { onboardingGoogleSlideTitles, onboardingFacebookSlideTitles } from '../../../utils/onboarding';
import style from './index.module.less';
import PaymentMethodsComponent from './../../PaymentMethodsComponent/index';
import { acceptedPaymentMethods } from '../../../styles/constants';

const PaymentSelectionButtonsContainer = ({ selectedMethod, setSelectedMethod, setConfirmedMethod, onBackClick, onboardingMode, selection = 'google' }) => {
    const isLoading = false;
    const steps = selection === 'google' ? onboardingGoogleSlideTitles : onboardingFacebookSlideTitles;
    const { payPal, paymentCard } = acceptedPaymentMethods;

    const selectCardMethod = () => setSelectedMethod(paymentCard);
    const selectPayPalMethod = () => setSelectedMethod(payPal);
    const decideIfHighlighted = paymentMethod => paymentMethod === selectedMethod ? style.highlightedButton : style.defaultButton;
    const decideNavigationTarget = () => setConfirmedMethod(selectedMethod);

    const PaymentMethodComponentWithProps = () =>
        <PaymentMethodsComponent onboardingMode={onboardingMode} selectPayPalMethod={selectPayPalMethod} selectCardMethod={selectCardMethod} decideIfHighlighted={decideIfHighlighted} decideNavigationTarget={decideNavigationTarget} onBackClick={onBackClick} />

    return (
        <Spin
            spinning={isLoading}
            style={style.spinnerStyle}
            size="large"
        >
            {onboardingMode ?
                <OnboardingContainer>
                    <section className={style.wrapper}>
                        <CreateCampaignStepper
                            activeStep={steps.indexOf("Payment")}
                            steps={steps}
                        />
                        <PaymentMethodComponentWithProps></PaymentMethodComponentWithProps>
                    </section>
                </ OnboardingContainer>
                :
                <PaymentMethodComponentWithProps />
            }

        </Spin>
    )
}

PaymentSelectionButtonsContainer.propTypes = {
    setSelectedMethod: PropTypes.func.isRequired,
    setConfirmedMethod: PropTypes.func.isRequired,
    selectedMethod: PropTypes.string.isRequired,
    onBackClick: PropTypes.func.isRequired,
}

export default PaymentSelectionButtonsContainer

