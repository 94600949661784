import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { PropTypes } from "prop-types";


const MaterialAppBarComponent = ({
  materialStyle,
  style,
  Avatar,
  DrawerButton,
  userShopName,
  appBarMaterialStyle,
  menuId,
  mobileMenuId,
  handleProfileMenuOpen,
  handleMobileMenuOpen
}) => (
  <AppBar position="fixed" className={appBarMaterialStyle}>
    <Toolbar variant="regular">
      <div className={style.burgerContainer}>{DrawerButton}</div>
      <div className={materialStyle.grow} />
      <div className={style.userName}>
        <span>Hello,</span>
        <span className={style.shopName}>{userShopName}</span>
      </div>
      <div className={materialStyle.sectionDesktop}>
        <IconButton
          edge="end"
          data-testid="avatar button"
          aria-label="Account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          {Avatar}
        </IconButton>
      </div>
      <div className={materialStyle.sectionMobile}>
        <IconButton
          data-testid="avatar"
          aria-label="Show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </div>
    </Toolbar>
  </AppBar>
);


MaterialAppBarComponent.propTypes = {
  materialStyle: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  Avatar: PropTypes.object.isRequired,
  userShopName: PropTypes.string.isRequired,
  appBarMaterialStyle: PropTypes.string.isRequired,
  DrawerButton: PropTypes.object.isRequired,
  menuId: PropTypes.string.isRequired,
  mobileMenuId: PropTypes.string.isRequired,
  handleProfileMenuOpen: PropTypes.func.isRequired,
  handleMobileMenuOpen: PropTypes.func.isRequired
};



export default MaterialAppBarComponent;
