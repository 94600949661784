// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { checkIfUserFinishedOnboarding } from '../../ducks/auth';

import OnboardingRouterContainer from './OnboardingRouterContainer';

const isOnboardingFinishedSelector = state => {
  switch (state) {
    case null: 
      return false;
    case true:
      return 'true';
    case false:
      return 'false';
    default:
      return false;
  }
}

const mapStateToProps = state => ({
  isOnboardingFinished: state.auth.isOnboardingFinished,
  isLoading: !isOnboardingFinishedSelector(state.auth.isOnboardingFinished),
  isUserLoggedIn: state.auth.isUserLoggedIn
});

const mapDispatchToProps = {
  checkIfUserFinishedOnboarding
};


const OnboardingRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingRouterContainer));

export default OnboardingRouter;