// React
import { withRouter } from 'react-router-dom';

import FacebookAdAccountListComponent from './FacebookAdAccountListComponent';
import { connect } from 'react-redux';



const mapStateToProps = state => ({
    listedAdAccounts: state.facebook.listedAdAccounts

});

const mapDispatchToProps = {

};
const FacebookAdAccountList = withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookAdAccountListComponent));

export default FacebookAdAccountList;



