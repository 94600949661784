import moment from 'moment-timezone';
import { validate } from 'validate.js';

/**
 * @deprecated Do not use this anymore. Instead use HeraldbeeApi.
 */
export const getAuthHeaders = () => {
  return {
    'Content-type': 'application/json'
  };
}

export const isLoginRequired = statusCode => {
  const forbiddenCodes = [401, 403];
  return forbiddenCodes.includes(statusCode)
}

/**
 * @deprecated use doesUserExists, hasUserGoogleAccount and hasUserGoogleAdsAndMc instead
 */
export const isUserConnectedToGoogle = user => {
  if (user) {
    const google = user.google;
    return google && google.adWordsExists && google.mcExists;
  }
  return false;
}

export const doesUserExists = user => {
  return user;
}

export const hasUserGoogleAccount = gAccount => {
  return gAccount;
}

export const hasUserGoogleAdsAndMc = (gAccountAds, gAccountMc) => {
  return gAccountAds && gAccountMc;
}

export const hasUserCampaign = campaigns => {
  if (campaigns) {
    return campaigns.length > 0;
  }
  return false;
}

export const hasUserSubscription = subscription => {
  return subscription;
}

export const getShopParams = user => {
  const { shopify, woocommerce } = user;

  if (shopify) {
    return shopify
  } else if (woocommerce) {
    return woocommerce
  }
}

export const isThreeDaysPassedSinceCampaignCreated = campaigns => {
  const currentTime = moment();
  return Boolean(campaigns && campaigns.some(campaign => {
    const startTimeUTC = moment(campaign.startTime);
    const startTimeLocal = moment.utc(startTimeUTC).local();
    return currentTime.diff(startTimeLocal,'days') >= 3;
  }));
}

// RegEx validator for proper email format
export const validateEmailInput = (email) => {
  const constraints = {
    from: {
      email: true
    }
  };

  const isEmailInvalid = validate({from: email}, constraints);

  return isEmailInvalid ? false : true; 
};

export const facebookAuthorizationStatus = Object.freeze({
  reAsk: 'reAsk',
  finished: 'finished'
});

export const getPlatformFromUrl = url => /^\/login\/(\w*)$/g.exec(url)[1];