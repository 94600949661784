// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import MaterialButtonComponent from '../../../MaterialComponents/MaterialButtonComponent/index';

// Assets
import deadBee from '../../../../images/icon_dead.svg';

// Stylesheet
import style from './index.module.less';

const ErrorComponent = ({ title, description, buttonText, onButtonClick }) => {
    return (
        <div className={style.container}>
            <img height='200px' width='200px' src={deadBee} alt='Error' />
            <h2 className={style.title}>{title}</h2>
            <p className={style.description}>{description}</p>
            <MaterialButtonComponent 
                type='primary' 
                text={buttonText} 
                onClick={onButtonClick} />
        </div>
    );
}

ErrorComponent.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
}

export default ErrorComponent;