import React from 'react'
import { PropTypes } from 'prop-types';
import { Spin } from 'antd';

const ReplacingViewSpinnerContainer = ({ isLoading, children, spinnerSize = "default" }) => {

    return isLoading ?
        <Spin size={spinnerSize} spinning={isLoading} />
        :
         children 

}

ReplacingViewSpinnerContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    spinnerSize: PropTypes.oneOf(["small", "large", "default"]),
};

export default ReplacingViewSpinnerContainer;