import React from 'react'
import styles from './styles.module.less'
import logo from '../../images/logo_hb_full.svg';
import logoForbes from '../../images/logo_forbes.svg';
import logoGooglePartner from '../../images/GPartner.jpg'

const SignUpStaticLogoPage = () => 
    <div className={styles.logoBackground}>
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={logo} alt="logo_hb"/>
        <h2 className={styles.hbGreeting}><span>Skyrocket</span> your sales<br/>with our shopping campaign</h2>
        <div className={styles.hbPartners}>
          <img className={styles.hbPartnersG} src={logoGooglePartner} alt="G Partner Logo"/>
          <img className={styles.hbPartnersForbes}  src={logoForbes} alt="Forbes Logo"/>
        </div>
      </div>
    </div>

export default SignUpStaticLogoPage