// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Third Party Components
import Downshift from "downshift";
import { makeStyles } from "@material-ui/core/styles";

// Custom Components
import MaterialInputComponent from '../MaterialInputComponent/index';
import SelectListComponent from './MaterialSelectListComponent/index';

const MaterialSelectComponent = ({
    suggestions,
    placeholder,
    caption,
    label,
    onSelect,
    onChangeInput,
    initialSelectedItemKey,
    clearSelectionIfEmpty = true,
    error,
    size,
    autoFocus,
    isSelectedFormEqualToSavedForm,
    fullCountryName,
    ...rest
}) => {
    const useStyles = makeStyles(theme => ({
        root: {
            width: size === 'flex' ? "100%" : "350px",
        },
        container: {
            flexGrow: 1,
            zIndex: 999,
            position: "relative",
            width: size === 'flex' ? "100%" : "350px",

            "& .MuiFormControl-root": {
                width: size === 'flex' ? "100%" : "350px"
            }
        },
        chip: {
            margin: theme.spacing(0.5, 0.25)
        },
        inputRoot: {
            flexWrap: "wrap",
        },
        inputInput: {
            width: "auto",
            flexGrow: 1
        },
        inputInputFlex: {
            width: "auto",
            flexGrow: 1,
            paddingTop: "15px"
        },
        divider: {
            height: theme.spacing(2)
        }
    }));

    const [ didClear, setDidClear ] = useState(false);
    const [ shrinkException, setShrinkException ] = useState(false);

    const classes = useStyles();

    const initialSelectedItem = suggestions.find(({ key }) => key === initialSelectedItemKey);

    const onChangeInputValue = (event, clearSelection) => {
        if (clearSelectionIfEmpty && event.target.value === "") {
            clearSelection();
        }
        onChangeInput && onChangeInput(event)
    }

    const determineDownshiftInputValue = item => {
        return item ? item.value : '';
    };

    useEffect(() => {
        setDidClear(false);
        if (shrinkException) {
            setShrinkException(false);
        };
    }, [isSelectedFormEqualToSavedForm]);

    /**
     * The implementation of Downshift methods (autocomplete/dropdown/select).
     */
    const renderDownshift = downshiftArgs => {
        const {
            clearSelection,
            getInputProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            openMenu,
            setState,
        } = downshiftArgs;

        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onChange: event => onChangeInputValue(event, clearSelection),
            onFocus: openMenu,
            placeholder: placeholder,
        });

        /**
         * TODO:
         * This code is a hack, it works and is stable but functionality should be properly hooked to lifecycle 
         */
        if (size === 'flex' && !didClear) {
            if (!isSelectedFormEqualToSavedForm) {
                clearSelection();
                setDidClear(true);
                setShrinkException(true);
            } else {
                setState({inputValue: fullCountryName, selectedItem: initialSelectedItem});
                setDidClear(true);
                setShrinkException(false);
            };
        };

        return (
            <div className={classes.container}>
                <MaterialInputComponent
                    autoFocus={autoFocus}
                    inputProps={{
                        classes: {
                            root: classes.inputRoot,
                            input: classes.inputInput
                        },
                        onBlur, 
                        onChange,
                        onFocus,
                        ...inputProps
                    }}
                    select
                    shrinkException={shrinkException}
                    fullWidth={true}
                    label={label}
                    selectOptionsOpen={isOpen}
                    inputLabelProps={getLabelProps()}
                    caption={caption}
                    error={error}
                    size={size}
                    {...rest}
                />
                <div {...getMenuProps()}>
                    {isOpen && (
                        <SelectListComponent
                            items={suggestions}
                            downshiftArgs={downshiftArgs}
                        />
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {/* 
                Downshift comes with user interactions and state handling for autocomplete/dropdown/select - it doesn't
                render anything, it delivers just logical methods.
                The implementation of Downshift methods is done in renderDownshift function passed as child prop.
            */}
            <Downshift 
                id="downshift-options"
                itemToString={determineDownshiftInputValue}
                onSelect={onSelect}
                initialSelectedItem={initialSelectedItem}
            >
                {renderDownshift}
            </Downshift>
        </div>
    );
}

MaterialSelectComponent.propTypes = {
    suggestions: SelectListComponent.propTypes.items,
    placeholder: PropTypes.string,
    caption: PropTypes.string,
    label: PropTypes.string,
    onSelect: PropTypes.func,
    onChangeInput: PropTypes.func,
    initialSelectedItemKey: PropTypes.any,
    clearSelectionIfEmpty: PropTypes.bool,
    error: PropTypes.bool,
    size: PropTypes.string,
    autoFocus: PropTypes.bool,
    cssClass: PropTypes.string,
};

export default MaterialSelectComponent;