import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//custom components
import ConverionsModalBodyContainer from '../../ConversionModalBodyContainer';
import MaterialCircularAddButtonComponent from '../../../components/MaterialComponents/MaterialCircularAddButtonComponent';

const ConversionsModalContainer = ({getConversionScriptTag}) => {

    const [isVisible, setIsVisible] = useState(false);
    const [scriptTag, setScriptTag] = useState('');
    const [shouldFetchScriptTag, setShouldFetchScriptTag] = useState(false);

    useEffect(()=>{
        const fetchScriptTag = async () => {
            if(shouldFetchScriptTag) {
                const newScriptTag = await getConversionScriptTag();
                setScriptTag(newScriptTag)
            }
        }
        fetchScriptTag()
    },[shouldFetchScriptTag]);

    const handleModalClose = () => {
        setIsVisible(false)
    }
    const handleModalShow = () => {
        setIsVisible(true)
        setShouldFetchScriptTag(true)
    }
    return (
        <>
            <MaterialCircularAddButtonComponent onClick={handleModalShow}/>
            <ConverionsModalBodyContainer
                isVisible={isVisible}
                handleModalClose={handleModalClose}
                scriptTag={scriptTag}
            />
        </>
    )
}

ConversionsModalContainer.propTypes = {
    getConversionScriptTag: PropTypes.func,
}
export default ConversionsModalContainer;