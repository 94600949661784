import { sortInvoiceList, constructInvoiceName, translateTimestampToString, translateInvoiceStatus } from '../utils/payment';

export const ownerNameSelector = state => state.auth.user.content.woocommerce.shopOwner || state.auth.wooCommerceOwnerName;

export const storeNameSelector = state => state.auth.user.content.woocommerce.name || state.auth.wooCommerceShopName;

export const emailSelector = state => state.auth.user.content.woocommerce.email || state.auth.wooCommerceEmail;

export const platformSelector = state => {
    if (state.user.platform) {
        return state.user.platform;
    } else {
        const userContent = state.auth.user.content;
        if (userContent && userContent.shopify) {
            return 'shopify';
        } else if (userContent && userContent.woocommerce) {
            return 'woocommerce';
        };
    };
};

export const planIdFromSubSelector = state => 
state.subscription.content.plan_id
;

export const areConversionsAvailableSelector = state => {
    const platform = platformSelector(state);
    if (platform === 'shopify') {
        return true;
    } else {
        return false;
    };
};

export const areConversionsActiveAndRunningSelector = state => {
    const areConversionsActive = state.conversions.areConversionsActive;
    const areStatsActive = campaignStatSumSelector(state, 'soldProducts') > 0 && campaignStatSumSelector(state, 'earnedMoney') > 0;
    return (areConversionsActive && areStatsActive);
};

const getCampaignStatSum = (campaigns, stat) => {
    const reducer = (acc, campaign) => campaign[stat] ? acc + campaign[stat] : acc;
    return campaigns.reduce(reducer, 0);
};

export const campaignStatSumSelector = (state, stat) => {
    const campaigns = state.campaigns.content;
    return (campaigns && campaigns.length > 0) ? getCampaignStatSum(campaigns, stat) : 0;
};

export const shopIdSelector = user => {
	if (user.content.shopify) {
		return user.content.shopify.myshopifyDomain;
	} else {
		return user.content.woocommerce.domain;
	};
};

export const shopNameSelector = state => {
    const shopify =
        state.auth.user.content.shopify && state.auth.user.content.shopify.name;
    const woocommerce =
        (state.auth.user.content.woocommerce &&
        state.auth.user.content.woocommerce.name) ||
        state.auth.wooCommerceShopName;
    return shopify || woocommerce;
};

export const isInvoiceDataErrorPresent = state => {
    return state.payment.invoiceData.error ? true : false;
};

export const isSubscriptionPresentSelector = state => {
    return state.subscription.content !== null;
};

export const invoiceIsLoadingSelector = state => {
    return state.payment.invoiceHistory.isFetching || state.payment.invoiceData.isFetching;
};

export const subscriptionIdSelector = state => {
    const subscriptionContent = state.subscription.content;
    return subscriptionContent ? subscriptionContent.id : null;
};

export const invoiceOwnerSelector = state => {
    const billingAddress = state.payment.invoiceData.content.billing_address;
    const fallback = shopNameSelector(state);

    if (billingAddress.company_name) {
        return billingAddress.company_name;
    } else if (billingAddress.first_name || billingAddress.last_name) {
        return `${billingAddress.first_name ? `${billingAddress.first_name} ` : null}${billingAddress.last_name}`;
    } else if (fallback) {
        return fallback;
    };
};

export const latestInvoiceNameSelector = state => {
    const invoiceList = state.payment.invoiceHistory.content;
    if (invoiceList && invoiceList.length > 0) {
        const latestInvoice = sortInvoiceList(invoiceList, 'date', 'asc')[0];
        const storeName = shopNameSelector(state);
        return constructInvoiceName(latestInvoice, storeName);
    } else {
        return null;
    }
};

export const adjustedInvoiceHistorySelector = state => {
    const invoiceList = state.payment.invoiceHistory.content;
    if (invoiceList && invoiceList.length > 0) {
        const storeName = shopNameSelector(state);
        return invoiceList.map(invoice => ({
            ...invoice,
            date: translateTimestampToString(invoice.date),
            dueDate: translateTimestampToString(invoice.dueDate),
            name: constructInvoiceName(invoice, storeName),
            status: translateInvoiceStatus(invoice.status)
        }));
    } else {
        return null;
    };
};

export const isInvoiceHistoryAvailableSelector = state => {
    const invoiceHistoryContent = state.payment.invoiceHistory.content 
    return invoiceHistoryContent && invoiceHistoryContent.length > 0 ? true : false;
};

export const formValueSelector = (state, key) => state.payment.invoiceForm[key];

export const onboardingValueSelector = (state, key) => {
    return state.onboarding.google.createCampaign[key] || state.onboarding.facebook.createCampaign[key];
};

export const isUserConnectedWithFacebookSelector = state => {
    const isUserConnectedWithFacebook = state.auth.user.content && state.auth.user.content.isUserConnectedWithFacebook;
    return isUserConnectedWithFacebook || state.auth.wasFacebookAuthSuccessfull || false;
}

export const isUserIntegratedWithFacebookSelector = state => {
    const isUserConnectedWithFacebook = state.auth.user.content && state.auth.user.content.isUserConnectedWithFacebook;
    const areAgencyPermissionsGranted = state.user.wasAgencyAccessGranted;
    return (isUserConnectedWithFacebook && areAgencyPermissionsGranted);
};