// React
import React from 'react';

// Stylesheet
import styles from './index.module.less';

const PendingViewComponent = () =>
    <div className={styles.container}>
        <p className={styles.title}>No invoice history available yet.</p>
        <p className={styles.text}>Invoice history appears once your first invoice will be generated.</p>
    </div>

export default PendingViewComponent;