export const appPaths = {
  root: /\/$/,
  saveUtm: /\/saveUtm$/,
  awaitToken: /\/awaitToken$/,
  saveToken: /\/saveToken$/,
  connect: /\/connect$/,
  pricing: /\/pricing$/,
  editCampaign: /\/editCampaign$/,
  campaigns: /\/campaigns$/,
  addNewCampaign: /\/campaigns\/addCampaign.*$/,
  addNewCampaignCountry: /\/campaigns\/addCampaign\/chooseCountry.*$/,
  addNewCampaignBudget: /\/campaigns\/addCampaign\/chooseBudget.*$/,
  addNewCampaignFinish: /\/campaigns\/addCampaign\/summary.*$/,
  campaignGoogle: /\/campaigns\/google.*$/,
  campaignGoogleProducts: /\/campaigns\/google\/.*\/products$/,
  campaignGoogleSettings: /\/campaigns\/google\/.*\/settings$/,
  campaignGoogleHistory: /\/campaigns\/google\/.*\/history$/,
  campaignFacebook: /\/campaigns\/facebook\/.*$/,
  account: /\/account$/,
  accountFacebookVerification: /\/account\/facebookVerification$/,
  accountFacebookIntegrationSuccess: /\/account\/facebookIntegrationSuccess$/,
  accountChangePaymentMethod: /\/account\/changePaymentMethod$/,
  accountSubscription: /\/account\/changeSubscriptionPlan$/,
  accountOwnerName: /\/account\/ownerName$/,
  accountStoreName: /\/account\/storeName$/,
  accountEmail: /\/account\/email$/,
  accountInvoiceData: /\/account\/invoiceData$/,
  accountInvoiceHistory: /\/account\/invoiceHistory$/,
  faq: /\/faq$/,
  googleRequirements: /\/googleRequirements$/,
  login: /\/login$/,
  shopify: /\/login\/shopify$/,
  woocommerce: /\/login\/woocommerce$/,
  index: /\/setup$/,
  onboardingStart: /\/onboarding$/,
  googleSelectionSuccess: /\/onboarding\/google\/selectionSuccess$/,
  googleCountry: /\/onboarding\/google\/createCampaign\/chooseCountry$/,
  googleBudget: /\/onboarding\/google\/createCampaign\/chooseBudget$/,
  googlePaymentAuthorization: /\/onboarding\/google\/createCampaign\/setPaymentAuthorization$/,
  googleFinish: /\/onboarding\/google\/createCampaign\/finish$/,
  facebookVerificationSuccess: /\/onboarding\/facebook\/verificationSuccess$/,
  facebookName: /\/onboarding\/facebook\/createCampaign\/chooseName$/,
  facebookCountry: /\/onboarding\/facebook\/createCampaign\/chooseCountry$/,
  facebookPaymentAutorization: /\/onboarding\/facebook\/createCampaign\/setPaymentAuthorization$/,
  facebookFinish: /\/onboarding\/facebook\/createCampaign\/finish$/,
  facebookAdAccountSelection: /\/onboarding\/facebook\/adAccountSelection$/,
  facebookAdAccountRequestSuccess: /\/onboarding\/facebook\/adAccountRequestSuccess$/,
  facebookAdAccountRequestSuccess: /\/onboarding\/facebook\/adAccountRequestSuccess$/
}