// React
import { withRouter } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
// Methods
import { setIsUserLoggedIn } from '../../ducks/auth';
import { fetchUser } from '../../ducks/auth';
import { fetchCampaigns } from '../../ducks/campaigns';
import { fetchSubscription } from '../../ducks/subscription';
// Selectors
import { platformSelector } from '../../utils/reduxSelectors'
import { checkIfConversionsActive } from '../../ducks/conversions';

import MainAppRouterContainer from './MainAppRouterContainer';
import { findOutIfUserHasGrantedAgencyAccess } from '../../ducks/facebook';
const mapStateToProps = state => ({
    user: state.auth.user,
    subscription: state.subscription,
    campaigns: state.campaigns,
    platform: platformSelector(state)
});

const mapDispatchToProps = {
    setIsUserLoggedIn,
    fetchUser,
    fetchCampaigns,
    fetchSubscription,
    checkIfConversionsActive,
    findOutIfUserHasGrantedAgencyAccess
};

const MainAppRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(MainAppRouterContainer));

export default MainAppRouter;