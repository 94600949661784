// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux'
    // Methods
    import { setOnboardingGoogleCreateCampaignField as setCampaignCountry } from '../../../../ducks/onboarding'

import ChooseCountryContainer from '../../../../components/SharedCreateCampaign/ChooseCountryContainer';

const mapStateToProps = state => ({
    countryCode: state.onboarding.google.createCampaign.country
});

const mapDispatchToProps = {
    setCampaignCountry
};

const ChooseCountry = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseCountryContainer));

export default ChooseCountry;