import React from 'react';
import PropTypes from 'prop-types';
import HorizontalViewWithLogoComponent from '../../HorizontalViewWithLogoComponent';
import OnboardingPlatformSelectionSuccess from '../../OnboardingPlatformSelectionSuccess';
import OnboardingContainer from '../../../container/OnboardingContainer';
import { AdChannelRequestSuccessfulTexts } from '../../../utils/texts';



const FacebookAdAccountRequestSuccessfulComponent = ({ history, setFacebookVerificationSuccess, redirectionUrl }) => {

    const onClick = () => {
        setFacebookVerificationSuccess(true);
        history.push(redirectionUrl);
    };

    return (
        <OnboardingContainer displayMode="showHeaderMobile">
            <HorizontalViewWithLogoComponent>
                <OnboardingPlatformSelectionSuccess
                    title={AdChannelRequestSuccessfulTexts.title}
                    subtitle={AdChannelRequestSuccessfulTexts.subtitle}
                    description={AdChannelRequestSuccessfulTexts.description}
                    onClick={onClick}
                    buttonText={AdChannelRequestSuccessfulTexts.buttonText}
                />
            </HorizontalViewWithLogoComponent>
        </OnboardingContainer>
    );
};

FacebookAdAccountRequestSuccessfulComponent.propTypes = {
    setFacebookVerificationSuccess: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    redirectionUrl: PropTypes.string.isRequired,
};

export default FacebookAdAccountRequestSuccessfulComponent; 
