import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { PropTypes } from "prop-types";

const MaterialDesktopMenuComponent = ({
  desktopMaterialAnchorElement,
  menuId,
  isMenuOpen,
  handleMenuClose,
  logOut
}) => (
  <Menu
    anchorEl={desktopMaterialAnchorElement}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    id={menuId}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    open={isMenuOpen}
    onClose={handleMenuClose}
  >
    <MenuItem onClick={logOut}>Log Out</MenuItem>
  </Menu>
);

MaterialDesktopMenuComponent.propTypes = {
  desktopMaterialAnchorElement: PropTypes.object,
  menuId: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired
};
export default MaterialDesktopMenuComponent;
