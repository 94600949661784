// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setSelectedAdChannel, clearOnboardingStateByChannel } from '../../../ducks/onboarding';

import SharedOnboardingRouterContainer from './SharedOnboardingRouterContainer';

const mapStateToProps = state => ({
    selectedAdChannel: state.onboarding.selectedAdChannel
});

const mapDispatchToProps = {
    setSelectedAdChannel,
    clearOnboardingStateByChannel
};

const SharedOnboardingRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(SharedOnboardingRouterContainer));

export default SharedOnboardingRouter;