// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { onboardingGoogleSlideTitles, onboardingFacebookSlideTitles } from '../../../utils/onboarding';
import { addCampaignSlideTitles } from '../../../utils/main';
import { isDesignationOnboarding } from '../../../utils/utils';
import { OnboardingTexts } from '../../../utils/texts';
import googleAdsLocations, { googleAdsLocationCodes } from '../../../constants/googleAdsLocations';

// Custom Components
import DynamicCreateCampaignWrapperContainer from '../../../container/DynamicCreateCampaignWrapperContainer';
import CreateCampaignHeader from '../../../components/OnboardingCreateCampaign/CreateCampaignHeaderComponent';
import FlagIconComponent from '../../../components/FlagIconComponent';
import CreateCampaignStepper from '../../../components/MaterialComponents/OnboardingMaterialComponents/CreateCampaignStepperComponent/index';
import MaterialButtonComponent from '../../../components/MaterialComponents/MaterialButtonComponent/index';
import MaterialSelectComponent from '../../../components/MaterialComponents/MaterialSelectComponent/index';

// Stylesheet
import style from './index.module.less';

const ChooseCountryContainer = ({ countryCode, setCampaignCountry, history, shouldBackButtonBeAvailable, designation = 'onboarding', selection = 'google' }) => {

    const onboardingSlideTitles = selection === 'google' ? onboardingGoogleSlideTitles : onboardingFacebookSlideTitles;
    const steps = isDesignationOnboarding(designation) ? onboardingSlideTitles : addCampaignSlideTitles;
    const { title, infoButtonText } = OnboardingTexts.countrySlide;

    const [ adjustedCountryCode, setAdjustedCountryCode ] = useState(countryCode);

    const onSelectSuggestedCountry = suggestion => {
      setAdjustedCountryCode(suggestion && suggestion.key);
    };

    const onNextClick = () => {
      setCampaignCountry('country', adjustedCountryCode);
      const googleUrl = isDesignationOnboarding(designation) ? '/onboarding/google/createCampaign/chooseBudget' : '/campaigns/addCampaign/google/chooseBudget';
      const facebookUrl = isDesignationOnboarding(designation) ? '/onboarding/facebook/createCampaign/setPaymentAuthorization' : '/campaigns/addCampaign/facebook/summary'
      history.push(selection === 'google' ? googleUrl : facebookUrl);
    };

    const onBackClick = () => {
      const googleUrl = '/campaigns/addCampaign';
      const facebookUrl = isDesignationOnboarding(designation) ? '/onboarding/facebook/createCampaign/chooseName' : '/campaigns/addCampaign/facebook/chooseName';
      history.push(selection === 'google' ? googleUrl : facebookUrl);
    };

    const countrySuggestions = googleAdsLocations.map(({ name, value }) => ({
        value: name,
        key: value,
        prefix: <FlagIconComponent countryCode={value} className={style.flagInList} />
    }));

    const selectInputPrefix = adjustedCountryCode && <FlagIconComponent countryCode={adjustedCountryCode} className={style.flagInInput} />

    return (
      <DynamicCreateCampaignWrapperContainer designation={designation}>
        <section className={style.wrapper}>
          <CreateCampaignStepper
            activeStep={steps.indexOf("Country")}
            steps={steps}
            designation={designation}
          />
          <CreateCampaignHeader
            title={title}
            infoButtonText={infoButtonText}
          />

          <div className={style.content}>
            <MaterialSelectComponent
              size="large"
              label="Countries"
              prefix={selectInputPrefix}
              initialSelectedItemKey={countryCode}
              suggestions={countrySuggestions}
              onSelect={onSelectSuggestedCountry}
            />
          </div>

          <span className={`${style.buttons} ${shouldBackButtonBeAvailable ? style.buttonsExpanded : null}`}>
            {
              shouldBackButtonBeAvailable
                ? (
                  <MaterialButtonComponent
                    type={'secondary'}
                    size={'xSmall'}
                    text={'BACK'}
                    onClick={onBackClick}
                  />
                ) : null
            }
            <MaterialButtonComponent
              type="primary"
              disabled={!adjustedCountryCode}
              size={"xSmall"}
              text={"NEXT"}
              onClick={onNextClick}
            />
          </span>
        </section>
      </DynamicCreateCampaignWrapperContainer>
    );
};

ChooseCountryContainer.propTypes = {
    countryCode: PropTypes.oneOf(googleAdsLocationCodes),
    setCampaignCountry: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    designation: PropTypes.oneOf(['onboarding', 'main']),
    selection: PropTypes.oneOf(['google', 'facebook'])
};

export default ChooseCountryContainer;