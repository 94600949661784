// React
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Custom Modules
import { ErrorTexts } from '../../../utils/texts';

// Custom Components
import ErrorComponent from './ErrorComponent';

class ErrorBoundaryComponent extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        history: PropTypes.object.isRequired,
        error: PropTypes.object,
        setError: PropTypes.func.isRequired,
        unsetError: PropTypes.func.isRequired,
    };
    
    constructor(props) {
        super(props);
        this.state = {
            renderError: null
        }
    }

    static getDerivedStateFromError(renderError) {
        // Update state so the next render will show the fallback UI.
        return { renderError };
    }

    decideOnLogoutMethod = () => {
        if (document.cookie) {
            // This handy function came from https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
            document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        };
        this.props.softLogOut();
    };

    componentDidCatch(renderError, componentStack) {
        const { setError } = this.props;

        // Save error and log it to an error reporting service
        setError(renderError, componentStack)
    }

    getErrorMessage = () => {
        const { error } = this.props;
        const { renderError } = this.state;
        
        if (error) {
            return error.message;
        } else if (renderError) {
            return renderError.message;
        }
    }

    onErrorContinueButtonClick = () => {
        const { unsetError, history } = this.props;
        
        this.setState({ 
            renderError: null
        });
        unsetError();
        this.decideOnLogoutMethod();
        history.push('/login');
    }

    render() {
        const { children, error } = this.props;
        const { renderError } = this.state;

        const errorMessage = this.getErrorMessage();

        if (error && error.name === 'UnauthorisedError') {
            this.decideOnLogoutMethod();
            return (
                <Redirect to='/login' />
            )
        } else if (error || renderError) {
            return (
                <ErrorComponent
                    title={ErrorTexts.title}
                    description={errorMessage}
                    buttonText={ErrorTexts.buttonText}
                    onButtonClick={this.onErrorContinueButtonClick} />
            )
        } else {
            return (
                children
            )
        }
    }
}

export default ErrorBoundaryComponent;