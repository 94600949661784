import React from "react";
import style from "./index.module.less";
import lostBee from "../../../images/lostBee.png";
import { Link } from "react-router-dom";

const PageNotFoundComponent = () => {
  return (
    <div className={style.PageNotFoundComponent}>
      <div className={style.TextAndImageContainer}>
        <img className={style.LostBeeImage} src={lostBee} alt="Lost bee" />
        <div className={style.TextContentFor404}>
          <h1 className={style.BeeIsLostText}>
            Oh no the{" "}
            <strong className={style.beeIsLostTextStrong}>
              bee is lost :(
            </strong>
          </h1>
          <p className={style.ErrorText}>Error 404</p>

          <div className={style.HelpfulLinksText}>
            <p className={style.ListHeader}>
				Find it using a helpful link:
			</p>
            <ul className={style.HelpfulLinksList}>
              <li>
                <Link to="/">Homepage</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFoundComponent;
