// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux'
// Methods
import { fetchBraintreeToken, createCustomerWithPaymentAuthorization, actionSetLastFourPaymentCardDigitsForSummary, actionSetPaymentCardVendorNameForSummary, validateBraintreeHostedFieldsEnvelope, validateBraintreeClientInstanceEnvelope, setFirstAcceptedPaymentMethodType } from '../../../../ducks/payment';
import { setError } from '../../../../ducks/app';


import SetCardAuthContainer from './SetCardAuthContainer';
import { actionSetLastVisitedPath } from '../../../../ducks/navigation';
import { validateBraintreeInstanceEnvelope } from '../../../../ducks/payment';

const mapStateToProps = state => ({
    clientNonce: state.payment.clientNonce,
    braintreeToken: state.payment.braintreeToken,
    isPaymentMethodSet: state.payment.paymentMethod,
    lastFourPaymentCardDigits: state.payment.lastFourPaymentCardDigits,
    creditCardVendorNameForSummary: state.payment.creditCardVendorNameForSummary,
    lastVisitedRoute: state.navigation.lastVisitedRoute,
    fatalNetworkError: state.app.fatalNetworkError,
    planId: state.pricing.planId,
    firstAcceptedPaymentMethodType: state.payment.firstAcceptedPaymentMethodType,
    selection: state.onboarding.selectedAdChannel
});
const mapDispatchToProps = {
    fetchBraintreeToken,
    createCustomerWithPaymentAuthorization,
    actionSetLastFourPaymentCardDigitsForSummary,
    actionSetPaymentCardVendorNameForSummary,
    setError,
    actionSetLastVisitedPath,
    validateBraintreeHostedFieldsEnvelope,
    validateBraintreeClientInstanceEnvelope,
    validateBraintreeInstanceEnvelope,
    setFirstAcceptedPaymentMethodType
};
const SetCardAuth = withRouter(connect(mapStateToProps, mapDispatchToProps)(SetCardAuthContainer));

export default SetCardAuth;