export const tokenizePaymentCardData = ({ localBraintreeHostedFieldsInstance, localBraintreeThreeDSecureInstance }) => {
	const cardRejectedErrorMessage = "The provided card number was not accepted. Please use another card.";
	return new Promise(function (resolve) {
		localBraintreeHostedFieldsInstance.tokenize(
			(tokenizeErrorMessage, nonceObject) => {
				if (tokenizeErrorMessage) {
					/** The card was declined - inform user of the fact and let him insert some other card number */

					resolve({ clientNonce: null, cardType: null, tokenizeErrorMessage: cardRejectedErrorMessage });
				} else {
					const {
						details: { cardType, lastFour: lastFourDigits },
						nonce: clientNonce
					} = nonceObject;


					/** Fallback at 'amount' is a workaround for API deficiency, as it doesn't accept falsy values, which results in rejecting 0 number primitive, which appears to be a valid price amount, as free plans are rather frequently offered by vendors. Braintree support was notified of the issue, especially as their documentation clearly states, that the value at 'amount' can't be a negative one, nowhere stating it must be positive.
					 */
					localBraintreeThreeDSecureInstance.verifyCard({
						amount: 1,
						nonce: clientNonce,
						onLookupComplete: (threeDSLookupResponseObject, callbackAfterProcessing3DSObject) => {
							if (threeDSLookupResponseObject) {
								callbackAfterProcessing3DSObject();
							}
						}
					}, function braintreeCallback(cardVerificationError, verificationEndedWithNoErrorResponseObject) {

						if (cardVerificationError) {
							resolve({
								tokenizeErrorMessage: cardRejectedErrorMessage,
								clientNonce: null,
								cardType: null,
								lastFourDigits: null
							});

						} else {
							const { nonce: threeDSVerifiedNonce, liabilityShiftPossible, liabilityShifted } = verificationEndedWithNoErrorResponseObject;
							/** 3DS authentication process is successful in case when the liability for hypothetical fraudulent transaction is shifted for the bank issuing the card.  */
							const isThreeDSecurityVerificationPassed = (liabilityShiftPossible && liabilityShifted) || !liabilityShiftPossible;
							if (isThreeDSecurityVerificationPassed) {
								/** Payment card provided by the user was eligible for 3ds authentication, and the result was positive. Proceed with the 3DS-verified payment nonce. */
								resolve({
									tokenizeErrorMessage: null,
									clientNonce: threeDSVerifiedNonce,
									cardType,
									lastFourDigits
								});

							} else {
								/** Payment card provided by the user was eligible for 3DS authentication, but the result was negative. Request another payment card. */
								resolve({ clientNonce: null, cardType: null, tokenizeErrorMessage: cardRejectedErrorMessage });

							}
						}
					});
				}
			}
		);
	}
	);
};
