import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//custom components
import CampaignHistoryTableComponent from '../../../components/CampaignDetails/CampaignHistory/CampaignHistoryTableComponent';
import CampaignHistoryDatePickerComponent from '../../../components/CampaignDetails/CampaignHistory/CampaignHistoryDatePickerComponent'
import PendingViewComponent from './HistoryNoResultsViews/PendingViewComponent';
import NoHistoryViewComponent from './HistoryNoResultsViews/NoHistoryViewComponent';

//other dependencies
import moment from 'moment';
import { DateUtils } from 'react-day-picker'

//css
import styles from './index.module.less'


const CampaignHistoryContainer = ({ campaignHistory, fetchCampaignHistory, campaignId, campaignStatus}) => {

    const [choosenDateRange, setChoosenDateRange] = useState({
        from: moment().subtract(7,'d').toDate(),
        to: moment().toDate(),
    })

    useEffect( () => {
        const getData = async () =>{
            const historyIsInInitialState = campaignHistory.length === 0;

            if(historyIsInInitialState){
                const dateFrom = moment().subtract(7,'d').format('YYYY-MM-DD');
                const dateTo = moment().format('YYYY-MM-DD');
                await fetchCampaignHistory(campaignId, dateFrom, dateTo);
            }
        }
        getData();
    },[])
    
    const handleFetchNewHistory = () => {
        const canFetch = choosenDateRange.from !== null && choosenDateRange.to !== null;
        
        if (canFetch) {
            const dateFrom = moment(choosenDateRange.from).format('YYYY-MM-DD');
            const dateTo = moment(choosenDateRange.to).format('YYYY-MM-DD')
            fetchCampaignHistory(campaignId, dateFrom, dateTo)
        }
    }

    const handleDateChange = date => {
        const range = DateUtils.addDayToRange(date, choosenDateRange)
        setChoosenDateRange(range)
    }
    

    if (campaignStatus === 'PENDING') {
        return (
            <div className={styles.container}>
                <CampaignHistoryDatePickerComponent 
                    handleDateChange={handleDateChange}
                    dataRange={choosenDateRange}
                    handleFetchNewHistory={handleFetchNewHistory}
                />
                <PendingViewComponent/>
            </div>
        )
    } else {
        return (
            <div className={styles.container}>
                <CampaignHistoryDatePickerComponent 
                    handleDateChange={handleDateChange}
                    dataRange={choosenDateRange}
                    handleFetchNewHistory={handleFetchNewHistory}
                />
                {campaignHistory.length === 0 
                    ? <NoHistoryViewComponent
                        dateFrom={moment(choosenDateRange.from).format('YYYY-MM-DD')}
                        dateTo={moment(choosenDateRange.to).format('YYYY-MM-DD')}
                        />
                    : <CampaignHistoryTableComponent historyData={campaignHistory}/>    
                }
            </div>
        )
    }
}

export default CampaignHistoryContainer;

CampaignHistoryContainer.propTypes = {
    campaignHistory: PropTypes.array,
    fetchCampaignHistory: PropTypes.func
}

