// React
import React from 'react';

// Third-Party Modules
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase'

// Custom Modules
import { useStyles } from './index.material'
import { PropTypes } from 'prop-types';


const CampaignSearchInputComponent = ({ onChange }) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <SearchIcon className={classes.iconButton} />
            <InputBase
                className={classes.input}
                placeholder="Search..."
                inputProps={{ 'aria-label': 'search' }}
                onChange={onChange}
            />
        </div>
        
    )
}

CampaignSearchInputComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default CampaignSearchInputComponent

