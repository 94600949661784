// React
import React from 'react';

// Custom Components
import MaterialButtonComponent from '../../../MaterialComponents/MaterialButtonComponent';

// Stylesheet
import styles from './index.module.less';

const AccountFacebookVerificationSuccessComponent = ({ history }) => {
    const onClickNotNow = () => {
        history.push('/account');
    };

    const onClickAddCampaign = () => {
        history.push('/onboarding/facebook/adAccountSelection');
    };

    return (
        <section className={styles.container}>
            <header className={styles.header}>
                <h1 className={styles.headerTitle}><strong>Success!</strong></h1>
            </header>
            <main className={styles.content}>
                <div className={styles.contentDescription}>
                    <p className={styles.contentDescriptionText}>Heraldbee successfuly connected to your Facebook account and you are now ready to create your first Facebook marketing campaign!</p>
                </div>
                <div className={styles.contentControls}>
                    <MaterialButtonComponent
                        size="small"
                        text="Not Now"
                        type="secondary"
                        onClick={onClickNotNow}
                    />
                    <MaterialButtonComponent
                        size="large"
                        text="Add Campaign"
                        type="primary"
                        onClick={onClickAddCampaign}
                    />
                </div>
            </main>
        </section>
    );
};

AccountFacebookVerificationSuccessComponent.propTypes = {

};

export default AccountFacebookVerificationSuccessComponent;