// Redux
import { connect } from 'react-redux';

// Methods
import { fetchGoogleRequirementMemosState, setGoogleRequirementMemosState } from '../../ducks/user';

import GoogleRequirementsComponent from './GoogleRequirementsComponent';

const mapStateToProps = state => ({
    googleRequirementMemos: state.user.googleRequirementMemos,
});

const mapDispatchToProps = {
    fetchGoogleRequirementMemosState,
    setGoogleRequirementMemosState,
};

const GoogleRequirements = connect(mapStateToProps, mapDispatchToProps)(GoogleRequirementsComponent);

export default GoogleRequirements;