// React
import React from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { isDesignationOnboarding } from '../../../../utils/utils';

// Third Party Modules
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from '@material-ui/core';

// Stylesheet
import { colorPalette } from '../../../../styles/constants';

const CreateCampaignStepper = ({ activeStep, steps, designation = 'onboarding' }) => {
   // declare css override classes
   const useStyle = makeStyles({
      root: {
            maxWidth: isDesignationOnboarding(designation) ? '1200px' : '600px',
            margin: "auto",
            backgroundColor: isDesignationOnboarding(designation) ? colorPalette.white : colorPalette.backgroundGray,

            "& .MuiStep-root .Mui-disabled .MuiStepLabel-iconContainer": {
               opacity: 0.5
            }
      },
      stepper: {
         "& .MuiStep-horizontal": {
            maxWidth: "1200px",
            paddingLeft: 0,
            paddingRight: 0,
            
         },
         "& .MuiStepConnector-alternativeLabel": {
            left: "calc(-50% + 10px)",
            right: "calc(50% + 10px)",
         }
      },
      connectorLine: {
         width: '100%',
         borderColor: colorPalette.primaryBrown,
         borderWidth: "2px",
         opacity: 0.2,
      },
      connectorActive: {
         '& $connectorLine': {
            borderColor: colorPalette.secondaryOrange,
            opacity: 1,
         }
      },
      label: {
         "& .MuiStepLabel-label": {
            fontSize: "16px",
            color: colorPalette.primaryBrown,
            opacity: 0.5
         },
         "& .MuiStepLabel-active": {
            color: colorPalette.secondaryOrange,
            opacity: 1,
         },
         "& .MuiStepLabel-completed": {
            color: colorPalette.primaryBrown,
            opacity: 0.5,
            fontWeight: 400
         }
      }
   });

   // define classes
   const styles = useStyle();
   const stepperStyles = { line: styles.connectorLine, active: styles.connectorActive, completed: styles.connectorActive };
   const rootStyle = { root: styles.root };

   // customized sub-components
   const connector = ( <StepConnector classes={stepperStyles}/> );

   const icon = index => {
      const strokeColor = index<=activeStep ? colorPalette.secondaryOrange : colorPalette.primaryBrown;
      const strokeOpacity = index<=activeStep ? 1 : 0.5;
      const fillColor = index<=activeStep-1 ? colorPalette.secondaryOrange : colorPalette.white;
      return (
         <SvgIcon>
            <circle cx="12" cy="12" r="9" stroke={strokeColor} strokeWidth="3" fill={fillColor} opacity={strokeOpacity} />
         </SvgIcon>
      )
   };

   return (
      <Stepper activeStep={activeStep} connector={connector} alternativeLabel classes={rootStyle} className={styles.stepper}>
         {steps.map((step, i) =>
               <Step key={i} className={styles.label}>
                  <StepLabel icon={icon(i)}>{step}</StepLabel>
               </Step>    
         )}
      </Stepper>
   );
};

CreateCampaignStepper.propTypes = {
   activeStep: PropTypes.number.isRequired,
   steps: PropTypes.arrayOf(PropTypes.string),
   designation: PropTypes.oneOf(['onboarding', 'main'])
};

export default CreateCampaignStepper;