// React
import React from 'react';

// Third-Party Modules
import clsx from "clsx";

// Stylesheet
import styles from './index.module.less';

const CampaignUnsupportedComponent = () =>
    <section className={styles.container}>
        <h1 className={styles.title}>Facebook campaign details unavailable</h1>
        <p className={styles.text}>
            Detailed campaign information, statistics and controls are not yet available, if you're interrested in more information please 
            <span className={clsx(styles.openIntercomButton, 'OPEN_INTERCOM')}>{' contact us'}.</span>
        </p>
    </section>

export default CampaignUnsupportedComponent;