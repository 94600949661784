// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Methods
import { setPricing } from '../../ducks/pricing';
import { setPlatform } from '../../ducks/auth';

// Custom Components
import PricingPage from './PricingPage';

const mapStateToProps = state => ({

})

const mapDispatchToProps = {
  setPricing,
  setPlatform
}

const Pricing = withRouter(connect(mapStateToProps, mapDispatchToProps)(PricingPage))

export default Pricing;