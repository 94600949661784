// React
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Custom Components
import ChooseBudgetMain from '../../../../pages/MainCreateCampaign/Google/ChooseBudgetMain';
import ChooseCountryMain from '../../../../pages/MainCreateCampaign/Google/ChooseCountryMain';
import FinishMain from '../../../../pages/MainCreateCampaign/Google/FinishMain';

const MainCreateCampaignGoogleContainer = () =>
    <Switch>
        <Route
            exact path ="/campaigns/addCampaign/google"
            component={() => <Redirect to="/campaigns/addCampaign/google/chooseCountry" />}
        />
        <Route
            path="/campaigns/addCampaign/google/chooseCountry"
            component={() => <ChooseCountryMain designation="main" selection="google" shouldBackButtonBeAvailable={true}/>}
        />
        <Route
            path="/campaigns/addCampaign/google/chooseBudget"
            component={() => <ChooseBudgetMain designation="main" selection="google"/>}
        />
        <Route
            path="/campaigns/addCampaign/google/summary"
            component={() => <FinishMain designation="main" selection="google"/>}
        />
    </Switch>

export default MainCreateCampaignGoogleContainer;