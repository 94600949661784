// React
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import RcQueueAnim from 'rc-queue-anim';

// Custom Components
import MaterialCheckCircleOutlineComponent from '../../MaterialComponents/MaterialCheckCircleOutlineComponent';
import MaterialButtonComponent from '../../MaterialComponents/MaterialButtonComponent';

// Stylesheet
import styles from './index.module.less';

const OnboardingPlatformSelectionSuccessComponent = ({ buttonText,title, subtitle, description, onClick }) => 
    <section className={styles.wrapper}>
        <RcQueueAnim type="bottom">
            <div className={styles.container} key="1">
                <header className={styles.header}>
                    <span className={styles.headerRow}>
                        <h1 className={styles.headerTitle}><strong>{title}</strong></h1>
                        <MaterialCheckCircleOutlineComponent cssClass="onboarding"/>
                    </span>
                    <h2 className={styles.headerSubtitle}>{subtitle}</h2>
                </header>
                <main className={styles.content}>
                    <div className={styles.contentDescription}>
                        <p>
                            {description}
                            <strong className={styles.contentDescriptionBold}>You're just few clicks away from launching your ads!</strong>
                        </p>
                    </div>
                    <div className={styles.cuntentControls}>
                        <MaterialButtonComponent
                            size="xLarge"
                            text={buttonText}
                            type="primary"
                            onClick={onClick}
                        />
                    </div>
                </main>
            </div>
        </RcQueueAnim>
    </section>

OnboardingPlatformSelectionSuccessComponent.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    description: PropTypes.string,
    onClick: PropTypes.func.isRequired
};

export default OnboardingPlatformSelectionSuccessComponent;