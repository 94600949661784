import React from 'react';
import { PropTypes } from 'prop-types';
import AccountChangePaymentAuth from './../../../components/AccountChangePaymentAuth/index';
import PaymentMethodSelection from './../../PaymentMethodSelection/index';
import ChangePayPalAuthorization from './../../ChangePayPalAuthorization/index';



const AccountEditPaymentContainer = ({ history }) => {

	AccountEditPaymentContainer.propTypes = {
		history: PropTypes.object.isRequired,
		currentlyActivePaymentMethodType: PropTypes.string,
	}

	const onBackClick = () => {
		history.push("/account");
	};

	const PayPalAuthComponent = ChangePayPalAuthorization;

	const CardAuthComponent = AccountChangePaymentAuth;


	return <PaymentMethodSelection PaymentMethodSelectionConfigObject={{ onBackClick, PayPalAuthComponent, CardAuthComponent }} />
}

export default AccountEditPaymentContainer;

