// React
import React from 'react';
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

import CampaignGoogleContainer from './CampaignGoogleContainer';

const mapStateToProps = state => ({
    campaigns: state.campaigns.content && state.campaigns.content.googleCampaignsList,
    platform: state.auth.platform,
    areConversionsActive: state.conversions.areConversionsActive
});

const mapDispatchToProps = {

};

const CampaignGoogle = ({ campaigns, platform, match, areConversionsActive }) => {

    const selectRequestedCampaign = () => {
        const requestedId = match.params.id;
        return campaigns.find(campaign => 
            campaign.id === requestedId
        );
    };

    const campaign = selectRequestedCampaign();

    return campaign 
        ? (
            <CampaignGoogleContainer campaign={campaign} platform={platform} areConversionsActive={areConversionsActive}/>
        ) : (
            <h1>No such campaign exists</h1>
        )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignGoogle));